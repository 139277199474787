var emojis = [
  { unicode: "\u{1F600}", emoji: "😀", name: "grinning face", key: ":grinning_face:", group: "Smileys & Emotion", },
  { unicode: "\u{1F603}", emoji: "😃", name: "grinning face with big eyes", key: ":grinning_face_with_big_eyes:", group: "Smileys & Emotion", },
  { unicode: "\u{1F604}", emoji: "😄", name: "grinning face with smiling eyes", key: ":grinning_face_with_smiling_eyes:", group: "Smileys & Emotion", },
  { unicode: "\u{1F601}", emoji: "😁", name: "beaming face with smiling eyes", key: ":beaming_face_with_smiling_eyes:", group: "Smileys & Emotion", },
  { unicode: "\u{1F606}", emoji: "😆", name: "grinning squinting face", key: ":grinning_squinting_face:", group: "Smileys & Emotion", },
  { unicode: "\u{1F605}", emoji: "😅", name: "grinning face with sweat", key: ":grinning_face_with_sweat:", group: "Smileys & Emotion", },
  { unicode: "\u{1F923}", emoji: "🤣", name: "rolling on the floor laughing", key: ":rolling_on_the_floor_laughing:", group: "Smileys & Emotion", },
  { unicode: "\u{1F602}", emoji: "😂", name: "face with tears of joy", key: ":face_with_tears_of_joy:", group: "Smileys & Emotion", },
  { unicode: "\u{1F642}", emoji: "🙂", name: "slightly smiling face", key: ":slightly_smiling_face:", group: "Smileys & Emotion", },
  { unicode: "\u{1F643}", emoji: "🙃", name: "upside-down face", key: ":upside_down_face:", group: "Smileys & Emotion", },
  { unicode: "\u{1F609}", emoji: "😉", name: "winking face", key: ":winking_face:", group: "Smileys & Emotion", },
  { unicode: "\u{1F60A}", emoji: "😊", name: "smiling face with smiling eyes", key: ":smiling_face_with_smiling_eyes:", group: "Smileys & Emotion", },
  { unicode: "\u{1F607}", emoji: "😇", name: "smiling face with halo", key: ":smiling_face_with_halo:", group: "Smileys & Emotion", },
  // { unicode: "\u{1F970}", emoji: "🥰", name: "smiling face with hearts", key: ":smiling_face_with_hearts:", group: "Smileys & Emotion", },
  { unicode: "\u{1F60D}", emoji: "😍", name: "smiling face with heart-eyes", key: ":smiling_face_with_heart_eyes:", group: "Smileys & Emotion", },
  { unicode: "\u{1F929}", emoji: "🤩", name: "star-struck", key: ":star_struck:", group: "Smileys & Emotion", },
  { unicode: "\u{1F618}", emoji: "😘", name: "face blowing a kiss", key: ":face_blowing_a_kiss:", group: "Smileys & Emotion", },
  { unicode: "\u{1F617}", emoji: "😗", name: "kissing face", key: ":kissing_face:", group: "Smileys & Emotion", },
  { unicode: "\u{263A}", emoji: "☺️", name: "smiling face", key: ":smiling_face:", group: "Smileys & Emotion", },
  { unicode: "\u{1F61A}", emoji: "😚", name: "kissing face with closed eyes", key: "_:kissing_face_with_closed_eyes:", group: "Smileys & Emotion", },
  { unicode: "\u{1F619}", emoji: "😙", name: "kissing face with smiling eyes", key: ":kissing_face_with_smiling_eyes:", group: "Smileys & Emotion", },
  { unicode: "\u{1F60B}", emoji: "😋", name: "face savoring food", key: ":face_savoring_food:", group: "Smileys & Emotion", },
  { unicode: "\u{1F61B}", emoji: "😛", name: "face with tongue", key: ":face_with_tongue:", group: "Smileys & Emotion", },
  { unicode: "\u{1F61C}", emoji: "😜", name: "winking face with tongue", key: ":winking_face_with_tongue:", group: "Smileys & Emotion", },
  { unicode: "\u{1F92A}", emoji: "🤪", name: "zany face", key: ":zany_face:", group: "Smileys & Emotion", },
  { unicode: "\u{1F61D}", emoji: "😝", name: "squinting face with tongue", key: ":squinting_face_with_tongue:", group: "Smileys & Emotion", },
  { unicode: "\u{1F911}", emoji: "🤑", name: "money-mouth face", key: ":money_mouth_face:", group: "Smileys & Emotion", },
  { unicode: "\u{1F917}", emoji: "🤗", name: "hugging face", key: ":hugging_face:", group: "Smileys & Emotion", },
  { unicode: "\u{1F92D}", emoji: "🤭", name: "face with hand over mouth", key: ":face_with_hand_over_mouth:", group: "Smileys & Emotion", },
  { unicode: "\u{1F92B}", emoji: "🤫", name: "shushing face", key: ":shushing_face:", group: "Smileys & Emotion", },
  { unicode: "\u{1F914}", emoji: "🤔", name: "thinking face", key: ":thinking_face:", group: "Smileys & Emotion", },
  { unicode: "\u{1F910}", emoji: "🤐", name: "zipper-mouth face", key: ":zipper_mouth_face:", group: "Smileys & Emotion", },
  { unicode: "\u{1F928}", emoji: "🤨", name: "face with raised eyebrow", key: ":face_with_raised_eyebrow:", group: "Smileys & Emotion", },
  { unicode: "\u{1F610}", emoji: "😐", name: "neutral face", key: ":neutral_face:", group: "Smileys & Emotion", },
  { unicode: "\u{1F611}", emoji: "😑", name: "expressionless face", key: ":expressionless_face:", group: "Smileys & Emotion", },
  { unicode: "\u{1F636}", emoji: "😶", name: "face without mouth", key: ":face_without_mouth:", group: "Smileys & Emotion", },
  { unicode: "\u{1F60F}", emoji: "😏", name: "smirking face", key: ":smirking_face:", group: "Smileys & Emotion", },
  { unicode: "\u{1F612}", emoji: "😒", name: "unamused face", key: ":unamused_face:", group: "Smileys & Emotion", },
  { unicode: "\u{1F644}", emoji: "🙄", name: "face with rolling eyes", key: ":face_with_rolling_eyes:", group: "Smileys & Emotion", },
  { unicode: "\u{1F62C}", emoji: "😬", name: "grimacing face", key: ":grimacing_face:", group: "Smileys & Emotion", },
  { unicode: "\u{1F925}", emoji: "🤥", name: "lying face", key: ":lying_face:", group: "Smileys & Emotion", },
  { unicode: "\u{1F60C}", emoji: "😌", name: "relieved face", key: ":relieved_face:", group: "Smileys & Emotion", },
  { unicode: "\u{1F614}", emoji: "😔", name: "pensive face", key: ":pensive_face:", group: "Smileys & Emotion", },
  { unicode: "\u{1F62A}", emoji: "😪", name: "sleepy face", key: ":sleepy_face:", group: "Smileys & Emotion", },
  { unicode: "\u{1F924}", emoji: "🤤", name: "drooling face", key: ":drooling_face:", group: "Smileys & Emotion", },
  { unicode: "\u{1F634}", emoji: "😴", name: "sleeping face", key: ":sleeping_face:", group: "Smileys & Emotion", },
  { unicode: "\u{1F637}", emoji: "😷", name: "face with medical mask", key: ":face_with_medical_mask:", group: "Smileys & Emotion", },
  { unicode: "\u{1F912}", emoji: "🤒", name: "face with thermometer", key: ":face_with_thermometer:", group: "Smileys & Emotion", },
  { unicode: "\u{1F915}", emoji: "🤕", name: "face with head-bandage", key: ":face_with_head_bandage:", group: "Smileys & Emotion", },
  { unicode: "\u{1F922}", emoji: "🤢", name: "nauseated face", key: ":nauseated_face:", group: "Smileys & Emotion", },
  { unicode: "\u{1F92E}", emoji: "🤮", name: "face vomiting", key: ":face_vomiting:", group: "Smileys & Emotion", },
  { unicode: "\u{1F927}", emoji: "🤧", name: "sneezing face", key: ":sneezing_face:", group: "Smileys & Emotion", },
  // { unicode: "\u{1F975}", emoji: "🥵", name: "hot face", key: ":hot_face:", group: "Smileys & Emotion", },
  // { unicode: "\u{1F976}", emoji: "🥶", name: "cold face", key: ":cold_face:", group: "Smileys & Emotion", },
  // { unicode: "\u{1F974}", emoji: "🥴", name: "woozy face", key: ":woozy_face:", group: "Smileys & Emotion", },
  { unicode: "\u{1F635}", emoji: "😵", name: "dizzy face", key: ":dizzy_face:", group: "Smileys & Emotion", },
  { unicode: "\u{1F92F}", emoji: "🤯", name: "exploding head", key: ":exploding_head:", group: "Smileys & Emotion", },
  { unicode: "\u{1F920}", emoji: "🤠", name: "cowboy hat face", key: ":cowboy_hat_face:", group: "Smileys & Emotion", },
  // { unicode: "\u{1F973}", emoji: "🥳", name: "partying face", key: ":partying_face:", group: "Smileys & Emotion", },
  { unicode: "\u{1F60E}", emoji: "😎", name: "smiling face with sunglasses", key: ":smiling_face_with_sunglasses:", group: "Smileys & Emotion", },
  { unicode: "\u{1F913}", emoji: "🤓", name: "nerd face", key: ":nerd_face:", group: "Smileys & Emotion", },
  { unicode: "\u{1F9D0}", emoji: "🧐", name: "face with monocle", key: ":face_with_monocle:", group: "Smileys & Emotion", },
  { unicode: "\u{1F615}", emoji: "😕", name: "confused face", key: ":confused_face:", group: "Smileys & Emotion", },
  { unicode: "\u{1F61F}", emoji: "😟", name: "worried face", key: ":worried_face:", group: "Smileys & Emotion", },
  { unicode: "\u{1F641}", emoji: "🙁", name: "slightly frowning face", key: ":slightly_frowning_face:", group: "Smileys & Emotion", },
  { unicode: "\u{2639}\u{FE0F}", emoji: "☹️", name: "frowning face", key: ":frowning_face:", group: "Smileys & Emotion", },
  { unicode: "\u{1F62E}", emoji: "😮", name: "face with open mouth", key: ":face_with_open_mouth:", group: "Smileys & Emotion", },
  { unicode: "\u{1F62F}", emoji: "😯", name: "hushed face", key: ":hushed_face:", group: "Smileys & Emotion", },
  { unicode: "\u{1F632}", emoji: "😲", name: "astonished face", key: ":astonished_face:", group: "Smileys & Emotion", },
  { unicode: "\u{1F633}", emoji: "😳", name: "flushed face", key: ":flushed_face:", group: "Smileys & Emotion", },
  // { unicode: "\u{1F97A}", emoji: "🥺", name: "pleading face", key: ":pleading_face:", group: "Smileys & Emotion", },
  { unicode: "\u{1F626}", emoji: "😦", name: "frowning face with open mouth", key: ":frowning_face_with_open_mouth:", group: "Smileys & Emotion", },
  { unicode: "\u{1F627}", emoji: "😧", name: "anguished face", key: ":anguished_face:", group: "Smileys & Emotion", },
  { unicode: "\u{1F628}", emoji: "😨", name: "fearful face", key: ":fearful_face:", group: "Smileys & Emotion", },
  { unicode: "\u{1F630}", emoji: "😰", name: "anxious face with sweat", key: ":anxious_face_with_sweat:", group: "Smileys & Emotion", },
  { unicode: "\u{1F625}", emoji: "😥", name: "sad but relieved face", key: ":sad_but_relieved_face:", group: "Smileys & Emotion", },
  { unicode: "\u{1F622}", emoji: "😢", name: "crying face", key: ":crying_face:", group: "Smileys & Emotion", },
  { unicode: "\u{1F62D}", emoji: "😭", name: "loudly crying face", key: ":loudly_crying_face:", group: "Smileys & Emotion", },
  { unicode: "\u{1F631}", emoji: "😱", name: "face screaming in fear", key: ":face_screaming_in_fear:", group: "Smileys & Emotion", },
  { unicode: "\u{1F616}", emoji: "😖", name: "confounded face", key: ":confounded_face:", group: "Smileys & Emotion", },
  { unicode: "\u{1F623}", emoji: "😣", name: "persevering face", key: ":persevering_face:", group: "Smileys & Emotion", },
  { unicode: "\u{1F61E}", emoji: "😞", name: "disappointed face", key: ":disappointed_face:", group: "Smileys & Emotion", },
  { unicode: "\u{1F613}", emoji: "😓", name: "downcast face with sweat", key: ":downcast_face_with_sweat:", group: "Smileys & Emotion", },
  { unicode: "\u{1F629}", emoji: "😩", name: "weary face", key: ":weary_face:", group: "Smileys & Emotion", },
  { unicode: "\u{1F62B}", emoji: "😫", name: "tired face", key: ":tired_face:", group: "Smileys & Emotion", },
  // { unicode: "\u{1F971}", emoji: "🥱", name: "yawning face", key: ":yawning_face:", group: "Smileys & Emotion", },
  { unicode: "\u{1F624}", emoji: "😤", name: "face with steam from nose", key: ":face_with_steam_from_nose:", group: "Smileys & Emotion", },
  { unicode: "\u{1F621}", emoji: "😡", name: "pouting face", key: ":pouting_face:", group: "Smileys & Emotion", },
  { unicode: "\u{1F620}", emoji: "😠", name: "angry face", key: ":angry_face:", group: "Smileys & Emotion", },
  { unicode: "\u{1F92C}", emoji: "🤬", name: "face with symbols on mouth", key: ":face_with_symbols_on_mouth:", group: "Smileys & Emotion", },
  { unicode: "\u{1F608}", emoji: "😈", name: "smiling face with horns", key: ":smiling_face_with_horns:", group: "Smileys & Emotion", },
  { unicode: "\u{1F47F}", emoji: "👿", name: "angry face with horns", key: ":angry_face_with_horns:", group: "Smileys & Emotion", },
  { unicode: "\u{1F480}", emoji: "💀", name: "skull", key: ":skull:", group: "Smileys & Emotion", },
  { unicode: "\u{2620}\u{FE0F}", emoji: "☠️", name: "skull and crossbones", key: ":skull_and_crossbones:", group: "Smileys & Emotion", },
  { unicode: "\u{1F4A9}", emoji: "💩", name: "pile of poo", key: ":pile_of_poo:", group: "Smileys & Emotion", },
  { unicode: "\u{1F921}", emoji: "🤡", name: "clown face", key: ":clown_face:", group: "Smileys & Emotion", },
  { unicode: "\u{1F479}", emoji: "👹", name: "ogre", key: ":ogre:", group: "Smileys & Emotion", },
  { unicode: "\u{1F47A}", emoji: "👺", name: "goblin", key: ":goblin:", group: "Smileys & Emotion", },
  { unicode: "\u{1F47B}", emoji: "👻", name: "ghost", key: ":ghost:", group: "Smileys & Emotion", },
  { unicode: "\u{1F47D}", emoji: "👽", name: "alien", key: ":alien:", group: "Smileys & Emotion", },
  { unicode: "\u{1F47E}", emoji: "👾", name: "alien monster", key: ":alien_monster:", group: "Smileys & Emotion", },
  { unicode: "\u{1F916}", emoji: "🤖", name: "robot", key: ":robot:", group: "Smileys & Emotion", },
  { unicode: "\u{1F63A}", emoji: "😺", name: "grinning cat", key: ":grinning_cat:", group: "Smileys & Emotion", },
  { unicode: "\u{1F638}", emoji: "😸", name: "grinning cat with smiling eyes", key: ":grinning_cat_with_smiling_eyes:", group: "Smileys & Emotion", },
  { unicode: "\u{1F639}", emoji: "😹", name: "cat with tears of joy", key: ":cat_with_tears_of_joy:", group: "Smileys & Emotion", },
  { unicode: "\u{1F63B}", emoji: "😻", name: "smiling cat with heart-eyes", key: ":smiling_cat_with_heart_eyes:", group: "Smileys & Emotion", },
  { unicode: "\u{1F63C}", emoji: "😼", name: "cat with wry smile", key: ":cat_with_wry_smile:", group: "Smileys & Emotion", },
  { unicode: "\u{1F63D}", emoji: "😽", name: "kissing cat", key: ":kissing_cat:", group: "Smileys & Emotion", },
  { unicode: "\u{1F640}", emoji: "🙀", name: "weary cat", key: ":weary_cat:", group: "Smileys & Emotion", },
  { unicode: "\u{1F63F}", emoji: "😿", name: "crying cat", key: ":crying_cat:", group: "Smileys & Emotion", },
  { unicode: "\u{1F63E}", emoji: "😾", name: "pouting cat", key: ":pouting_cat:", group: "Smileys & Emotion", },
  { unicode: "\u{1F648}", emoji: "🙈", name: "see-no-evil monkey", key: ":see_no_evil_monkey:", group: "Smileys & Emotion", },
  { unicode: "\u{1F649}", emoji: "🙉", name: "hear-no-evil monkey", key: ":hear_no_evil_monkey:", group: "Smileys & Emotion", },
  { unicode: "\u{1F64A}", emoji: "🙊", name: "speak-no-evil monkey", key: ":speak_no_evil_monkey:", group: "Smileys & Emotion", },
  { unicode: "\u{1F48B}", emoji: "💋", name: "kiss mark", key: ":kiss_mark:", group: "Smileys & Emotion", },
  { unicode: "\u{1F48C}", emoji: "💌", name: "love letter", key: ":love_letter:", group: "Smileys & Emotion", },
  { unicode: "\u{1F498}", emoji: "💘", name: "heart with arrow", key: ":heart_with_arrow:", group: "Smileys & Emotion", },
  { unicode: "\u{1F49D}", emoji: "💝", name: "heart with ribbon", key: ":heart_with_ribbon:", group: "Smileys & Emotion", },
  { unicode: "\u{1F496}", emoji: "💖", name: "sparkling heart", key: ":sparkling_heart:", group: "Smileys & Emotion", },
  { unicode: "\u{1F497}", emoji: "💗", name: "growing heart", key: ":growing_heart:", group: "Smileys & Emotion", },
  { unicode: "\u{1F493}", emoji: "💓", name: "beating heart", key: ":beating_heart:", group: "Smileys & Emotion", },
  { unicode: "\u{1F49E}", emoji: "💞", name: "revolving hearts", key: ":revolving_hearts:", group: "Smileys & Emotion", },
  { unicode: "\u{1F495}", emoji: "💕", name: "two hearts", key: ":two_hearts:", group: "Smileys & Emotion", },
  { unicode: "\u{1F49F}", emoji: "💟", name: "heart decoration", key: ":heart_decoration:", group: "Smileys & Emotion", },
  { unicode: "\u{2763}\u{FE0F}", emoji: "❣️", name: "heart exclamation", key: ":heart_exclamation:", group: "Smileys & Emotion", },
  { unicode: "\u{1F494}", emoji: "💔", name: "broken heart", key: ":broken_heart:", group: "Smileys & Emotion", },
  { unicode: "\u{2764}\u{FE0F}", emoji: "❤️", name: "red heart", key: ":red_heart:", group: "Smileys & Emotion", },
  { unicode: "\u{1F9E1}", emoji: "🧡", name: "orange heart", key: ":orange_heart:", group: "Smileys & Emotion", },
  { unicode: "\u{1F49B}", emoji: "💛", name: "yellow heart", key: ":yellow_heart:", group: "Smileys & Emotion", },
  { unicode: "\u{1F49A}", emoji: "💚", name: "green heart", key: ":green_heart:", group: "Smileys & Emotion", },
  { unicode: "\u{1F499}", emoji: "💙", name: "blue heart", key: ":blue_heart:", group: "Smileys & Emotion", },
  { unicode: "\u{1F49C}", emoji: "💜", name: "purple heart", key: ":purple_heart:", group: "Smileys & Emotion", },
  // { unicode: "\u{1F90E}", emoji: "🤎", name: "brown heart", key: ":brown_heart:", group: "Smileys & Emotion", },
  { unicode: "\u{1F5A4}", emoji: "🖤", name: "black heart", key: ":black_heart:", group: "Smileys & Emotion", },
  // { unicode: "\u{1F90D}", emoji: "🤍", name: "white heart", key: ":white_heart:", group: "Smileys & Emotion", },
  { unicode: "\u{1F4AF}", emoji: "💯", name: "hundred points", key: ":hundred_points:", group: "Smileys & Emotion", },
  { unicode: "\u{1F4A2}", emoji: "💢", name: "anger symbol", key: ":anger_symbol:", group: "Smileys & Emotion", },
  { unicode: "\u{1F4A5}", emoji: "💥", name: "collision", key: ":collision:", group: "Smileys & Emotion", },
  { unicode: "\u{1F4AB}", emoji: "💫", name: "dizzy", key: ":dizzy:", group: "Smileys & Emotion", },
  { unicode: "\u{1F4A6}", emoji: "💦", name: "sweat droplets", key: ":sweat_droplets:", group: "Smileys & Emotion", },
  { unicode: "\u{1F4A8}", emoji: "💨", name: "dashing away", key: ":dashing_away:", group: "Smileys & Emotion", },
  { unicode: "\u{1F573}\u{FE0F}", emoji: "🕳️", name: "hole", key: ":hole:", group: "Smileys & Emotion", },
  { unicode: "\u{1F4A3}", emoji: "💣", name: "bomb", key: ":bomb:", group: "Smileys & Emotion", },
  { unicode: "\u{1F4AC}", emoji: "💬", name: "speech balloon", key: ":speech_balloon:", group: "Smileys & Emotion", },
  { unicode: "\u{1F5E8}\u{FE0F}", emoji: "🗨️", name: "left speech bubble", key: ":left_speech_bubble:", group: "Smileys & Emotion", },
  { unicode: "\u{1F5EF}\u{FE0F}", emoji: "🗯️", name: "right anger bubble", key: ":right_anger_bubble:", group: "Smileys & Emotion", },
  { unicode: "\u{1F4AD}", emoji: "💭", name: "thought balloon", key: ":thought_balloon:", group: "Smileys & Emotion", },
  { unicode: "\u{1F4A4}", emoji: "💤", name: "zzz", key: ":zzz:", group: "Smileys & Emotion", },
  { unicode: "\u{1F44B}", emoji: "👋", name: "waving hand", key: ":waving_hand:", group: "People & Body", },
  { unicode: "\u{1F91A}", emoji: "🤚", name: "raised back of hand", key: ":raised_back_of_hand:", group: "People & Body", },
  { unicode: "\u{1F590}", emoji: "🖐", name: "hand with fingers splayed", key: ":hand_with_fingers_splayed:", group: "People & Body" },
  { unicode: "\u{1F590}\u{1F3FB}", emoji: "🖐🏻", name: "hand with fingers splayed: light skin tone", key: ":hand_with_fingers_splayed_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F590}\u{1F3FC}", emoji: "🖐🏼", name: "hand with fingers splayed: medium-light skin tone", key: ":hand_with_fingers_splayed_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F590}\u{1F3FD}", emoji: "🖐🏽", name: "hand with fingers splayed: medium skin tone", key: ":hand_with_fingers_splayed_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F590}\u{1F3FE}", emoji: "🖐🏾", name: "hand with fingers splayed: medium-dark skin tone", key: ":hand_with_fingers_splayed_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F590}\u{1F3FF}", emoji: "🖐🏿", name: "hand with fingers splayed: dark skin tone", key: ":hand_with_fingers_splayed_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{270B}", emoji: "✋", name: "raised hand", key: ":raised_hand:", group: "People & Body", },
  { unicode: "\u{270B}\u{1F3FB}", emoji: "✋🏻", name: "raised hand light skin tone", key: ":raised_hand_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{270B}\u{1F3FC}", emoji: "✋🏼", name: "raised hand: medium-light skin tone", key: ":raised_hand_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{270B}\u{1F3FD}", emoji: "✋🏽", name: "raised hand: medium skin tone", key: ":raised_hand_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{270B}\u{1F3FE}", emoji: "✋🏾", name: "raised hand: medium-dark skin tone", key: ":raised_hand_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{270B}\u{1F3FF}", emoji: "✋🏿", name: "raised hand: dark skin tone", key: ":raised_hand_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F596}", emoji: "🖖", name: "vulcan salute", key: ":vulcan_salute:", group: "People & Body" },
  { unicode: "\u{1F596}\u{1F3FB}", emoji: "🖖🏻", name: "vulcan salute: light skin tone", key: ":vulcan_salute_light_skin_tone:", group: "People & Body" },
  { unicode: "\u{1F596}\u{1F3FC}", emoji: "🖖🏼", name: "vulcan salute: medium-light skin tone", key: ":vulcan_salute_medium_light_skin_tone:", group: "People & Body" },
  { unicode: "\u{1F596}\u{1F3FD}", emoji: "🖖🏽", name: "vulcan salute: medium skin tone", key: ":vulcan_salute_medium_skin_tone:", group: "People & Body" },
  { unicode: "\u{1F596}\u{1F3FE}", emoji: "🖖🏾", name: "vulcan salute: medium-dark skin tone", key: ":vulcan_salute_medium_dark_skin_tone:", group: "People & Body" },
  { unicode: "\u{1F596}\u{1F3FF}", emoji: "🖖🏿", name: "vulcan salute: dark skin tone", key: ":vulcan_salute_dark_skin_tone:", group: "People & Body" },
  { unicode: "\u{1F44C}", emoji: "👌", name: "ok hand", key: ":ok_hand:", group: "People & Body" },
  { unicode: "\u{1F44C}\u{1F3FB}", emoji: "👌🏻", name: "ok hand: light skin tone", key: ":ok_hand_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F44C}\u{1F3FC}", emoji: "👌🏼", name: "ok hand: medium-light skin tone", key: ":ok_hand_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F44C}\u{1F3FD}", emoji: "👌🏽", name: "ok hand: medium skin tone", key: ":ok_hand_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F44C}\u{1F3F3}", emoji: "👌🏾", name: "ok hand: medium-dark skin tone", key: ":ok_hand_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F44C}\u{1F3FF}", emoji: "👌🏿", name: "ok hand: dark skin tone", key: ":ok_hand_dark_skin_tone:", group: "People & Body", },
  // { unicode: "\u{1F90F}", emoji: "🤏", name: "pinching hand", key: ":pinching_hand:", group: "People & Body", },
  // { unicode: "\u{1F90F}\u{1F3FB}", emoji: "🤏🏻", name: "pinching hand: light skin tone", key: ":pinching_hand_light_skin_tone:", group: "People & Body", },
  // { unicode: "\u{1F90F}\u{1F3FC}", emoji: "🤏🏼", name: "pinching hand: medium-light skin tone", key: ":pinching_hand_medium_light_skin_tone:", group: "People & Body", },
  // { unicode: "\u{1F90F}\u{1F3FD}", emoji: "🤏🏽", name: "pinching hand: medium skin tone", key: ":pinching_hand_medium_skin_tone:", group: "People & Body", },
  // { unicode: "\u{1F90F}\u{1F3FE}", emoji: "🤏🏾", name: "pinching hand: medium-dark skin tone", key: ":pinching_hand_medium_dark_skin_tone:", group: "People & Body", },
  // { unicode: "\u{1F90F}\u{1F3FF}", emoji: "🤏🏿", name: "pinching hand: dark skin tone", key: ":pinching_hand_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{270C}\u{FE0F}", emoji: "✌️", name: "victory hand", key: ":victory_hand:", group: "People & Body" },
  { unicode: "\u{270C}\u{1F3FB}", emoji: "✌🏻", name: "victory hand: light skin tone", key: ":victory_hand_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{270C}\u{1F3FC}", emoji: "✌🏼", name: "victory hand: medium-light skin tone", key: ":victory_hand_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{270C}\u{1F3FD}", emoji: "✌🏽", name: "victory hand: medium skin tone", key: ":victory_hand_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{270C}\u{1F3FE}", emoji: "✌🏾", name: "victory hand: medium-dark skin tone", key: ":victory_hand_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{270C}\u{1F3FF}", emoji: "✌🏿", name: "victory hand: dark skin tone", key: ":victory_hand_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F91E}", emoji: "🤞", name: "crossed fingers", key: ":crossed_fingers:", group: "People & Body" },
  { unicode: "\u{1F91E}\u{1F3FB}", emoji: "🤞🏻", name: "crossed fingers: light skin tone", key: ":crossed_fingers_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F91E}\u{1F3FC}", emoji: "🤞🏼", name: "crossed fingers: medium-light skin tone", key: ":crossed_fingers_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F91E}\u{1F3FD}", emoji: "🤞🏽", name: "crossed fingers: medium skin tone", key: ":crossed_fingers_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F91E}\u{1F3FE}", emoji: "🤞🏾", name: "crossed fingers: medium-dark skin tone", key: ":crossed_fingers_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F91E}\u{1F3FF}", emoji: "🤞🏿", name: "crossed fingers: dark skin tone", key: ":crossed_fingers_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F91F}", emoji: "🤟", name: "love-you gesture", key: ":love_you_gesture:", group: "People & Body" },
  { unicode: "\u{1F91F}\u{1F3FB}", emoji: "🤟🏻", name: "love-you gesture: light skin tone", key: ":love_you_gesture_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F91F}\u{1F3FC}", emoji: "🤟🏼", name: "love-you gesture: medium-light skin tone", key: ":love_you_gesture_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F91F}\u{1F3FD}", emoji: "🤟🏽", name: "love-you gesture: medium skin tone", key: ":love_you_gesture_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F91F}\u{1F3FE}", emoji: "🤟🏾", name: "love-you gesture: medium-dark skin tone", key: ":love_you_gesture_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F91F}\u{1F3FF}", emoji: "🤟🏿", name: "love-you gesture: dark skin tone", key: ":love_you_gesture_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F918}", emoji: "🤘", name: "sign of the horns", key: ":sign_of_the_horns:", group: "People & Body", },
  { unicode: "\u{1F918}\u{1F3FB}", emoji: "🤘🏻", name: "sign of the horns: light skin tone", key: ":sign_of_the_horns_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F918}\u{1F3FC}", emoji: "🤘🏼", name: "sign of the horns: medium-light skin tone", key: ":sign_of_the_horns_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F918}\u{1F3FD}", emoji: "🤘🏽", name: "sign of the horns: medium skin tone", key: ":sign_of_the_horns_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F918}\u{1F3FE}", emoji: "🤘🏾", name: "sign of the horns: medium-dark skin tone", key: ":sign_of_the_horns_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F918}\u{1F3FF}", emoji: "🤘🏿", name: "sign of the horns: dark skin tone", key: ":sign_of_the_horns_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F919}", emoji: "🤙", name: "call me hand", key: ":call_me_hand:", group: "People & Body", },
  { unicode: "\u{1F919}\u{1F3FB}", emoji: "🤙🏻", name: "call me hand: light skin tone", key: ":call_me_hand_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F919}\u{1F3FC}", emoji: "🤙🏼", name: "call me hand: medium-light skin tone", key: ":call_me_hand_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F919}\u{1F3FD}", emoji: "🤙🏽", name: "call me hand: medium skin tone", key: ":call_me_hand_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F919}\u{1F3FE}", emoji: "🤙🏾", name: "call me hand: medium-dark skin tone", key: ":call_me_hand_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F919}\u{1F3FF}", emoji: "🤙🏿", name: "call me hand: dark skin tone", key: ":call_me_hand_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F448}", emoji: "👈", name: "backhand index pointing left", key: ":backhand_index_pointing_left:", group: "People & Body", },
  { unicode: "\u{1F448}\u{1F3FB}", emoji: "👈🏻", name: "backhand index pointing left: light skin tone", key: ":backhand_index_pointing_left_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F448}\u{1F3FC}", emoji: "👈🏼", name: "backhand index pointing left: medium-light skin tone", key: ":backhand_index_pointing_left_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F448}\u{1F3FD}", emoji: "👈🏽", name: "backhand index pointing left: medium skin tone", key: ":backhand_index_pointing_left_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F448}\u{1F3F3}", emoji: "👈🏾", name: "backhand index pointing left: medium-dark skin tone", key: ":backhand_index_pointing_left_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F448}\u{1F3FF}", emoji: "👈🏿", name: "backhand index pointing left: dark skin tone", key: ":backhand_index_pointing_left_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F449}", emoji: "👉", name: "backhand index pointing right", key: ":backhand_index_pointing_right:", group: "People & Body", },
  { unicode: "\u{1F449}\u{1F3FB}", emoji: "👉🏻", name: "backhand index pointing right: light skin tone", key: ":backhand_index_pointing_right_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F449}\u{1F3FC}", emoji: "👉🏼", name: "backhand index pointing right: medium-light skin tone", key: ":backhand_index_pointing_right_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F449}\u{1F3FD}", emoji: "👉🏽", name: "backhand index pointing right: medium skin tone", key: ":backhand_index_pointing_right_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F449}\u{1F3F3}", emoji: "👉🏾", name: "backhand index pointing right: medium-dark skin tone", key: ":backhand_index_pointing_right_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F449}\u{1F3FF}", emoji: "👉🏿", name: "backhand index pointing right: dark skin tone", key: ":backhand_index_pointing_right_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F446}", emoji: "👆", name: "backhand index pointing up", key: ":backhand_index_pointing_up:", group: "People & Body", },
  { unicode: "\u{1F446}\u{1F3FB}", emoji: "👆🏻", name: "backhand index pointing up: light skin tone", key: ":backhand_index_pointing_up_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F446}\u{1F3FC}", emoji: "👆🏼", name: "backhand index pointing up: medium-light skin tone", key: ":backhand_index_pointing_up_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F446}\u{1F3FD}", emoji: "👆🏽", name: "backhand index pointing up: medium skin tone", key: ":backhand_index_pointing_up_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F446}\u{1F3F3}", emoji: "👆🏾", name: "backhand index pointing up: medium-dark skin tone", key: ":backhand_index_pointing_up_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F446}\u{1F3FF}", emoji: "👆🏿", name: "backhand index pointing up: dark skin tone", key: ":backhand_index_pointing_up_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F595}", emoji: "🖕", name: "middle finger", key: ":middle_finger:", group: "People & Body", },
  { unicode: "\u{1F595}\u{1F3FB}", emoji: "🖕🏻", name: "middle finger: light skin tone", key: ":middle_finger_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F595}\u{1F3FC}", emoji: "🖕🏼", name: "middle finger: medium-light skin tone", key: ":middle_finger_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F595}\u{1F3FD}", emoji: "🖕🏽", name: "middle finger: medium skin tone", key: ":middle_finger_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F595}\u{1F3F3}", emoji: "🖕🏾", name: "middle finger: medium-dark skin tone", key: ":middle_finger_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F595}\u{1F3FF}", emoji: "🖕🏿", name: "middle finger: dark skin tone", key: ":middle_finger_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F447}", emoji: "👇", name: "backhand index pointing down", key: ":backhand_index_pointing_down:", group: "People & Body", },
  { unicode: "\u{1F447}\u{1F3FB}", emoji: "👇🏻", name: "backhand index pointing down: light skin tone", key: ":backhand_index_pointing_down_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F447}\u{1F3FC}", emoji: "👇🏼", name: "backhand index pointing down: medium-light skin tone", key: ":backhand_index_pointing_down_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F447}\u{1F3FD}", emoji: "👇🏽", name: "backhand index pointing down: medium skin tone", key: ":backhand_index_pointing_down_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F447}\u{1F3F3}", emoji: "👇🏾", name: "backhand index pointing down: medium-dark skin tone", key: ":backhand_index_pointing_down_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F447}\u{1F3FF}", emoji: "👇🏿", name: "backhand index pointing down: dark skin tone", key: ":backhand_index_pointing_down_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{261D}\u{FE0F}", emoji: "☝️", name: "index pointing up", key: ":index_pointing_up:", group: "People & Body", },
  { unicode: "\u{261D}\u{1F3FB}", emoji: "☝🏻", name: "index pointing up: light skin tone", key: ":index_pointing_up_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{261D}\u{1F3FC}", emoji: "☝🏼", name: "index pointing up: medium-light skin tone", key: ":index_pointing_up_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{261D}\u{1F3FD}", emoji: "☝🏽", name: "index pointing up: medium skin tone", key: ":index_pointing_up_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{261D}\u{1F3F3}", emoji: "☝🏾", name: "index pointing up: medium-dark skin tone", key: ":index_pointing_up_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{261D}\u{1F3FF}", emoji: "☝🏿", name: "index pointing up: dark skin tone", key: ":index_pointing_up_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F44D}", emoji: "👍", name: "thumbs up", key: ":thumbs_up:", group: "People & Body", },
  { unicode: "\u{1F44D}\u{1F3FB}", emoji: "👍🏻", name: "thumbs up: light skin tone", key: ":thumbs_up_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F44D}\u{1F3FC}", emoji: "👍🏼", name: "thumbs up: medium-light skin tone", key: ":thumbs_up_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F44D}\u{1F3FD}", emoji: "👍🏽", name: "thumbs up: medium skin tone", key: ":thumbs_up_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F44D}\u{1F3FE}", emoji: "👍🏾", name: "thumbs up: medium-dark skin tone", key: ":thumbs_up_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F44D}\u{1F3FF}", emoji: "👍🏿", name: "thumbs up: dark skin tone", key: ":thumbs_up_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F44E}", emoji: "👎", name: "thumbs down", key: ":thumbs_down:", group: "People & Body", },
  { unicode: "\u{1F44E}\u{1F3FB}", emoji: "👎🏻", name: "thumbs down: light skin tone", key: ":thumbs_down_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F44E}\u{1F3FC}", emoji: "👎🏼", name: "thumbs down: medium-light skin tone", key: ":thumbs_down_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F44E}\u{1F3FD}", emoji: "👎🏽", name: "thumbs down: medium skin tone", key: ":thumbs_down_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F44E}\u{1F3FE}", emoji: "👎🏾", name: "thumbs down: medium-dark skin tone", key: ":thumbs_down_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F44E}\u{1F3FF}", emoji: "👎🏿", name: "thumbs down: dark skin tone", key: ":thumbs_down_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{270A}", emoji: "✊", name: "raised fist", key: ":raised_fist:", group: "People & Body", },
  { unicode: "\u{270A}\u{1F3FB}", emoji: "✊🏻", name: "raised fist: light skin tone", key: ":raised_fist_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{270A}\u{1F3FC}", emoji: "✊🏼", name: "raised fist: medium-light skin tone", key: ":raised_fist_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{270A}\u{1F3FD}", emoji: "✊🏽", name: "raised fist: medium skin tone", key: ":raised_fist_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{270A}\u{1F3FE}", emoji: "✊🏾", name: "raised fist: medium-dark skin tone", key: ":raised_fist_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{270A}\u{1F3FF}", emoji: "✊🏿", name: "raised fist: dark skin tone", key: ":raised_fist_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F44A}", emoji: "👊", name: "oncoming fist", key: ":oncoming_fist:", group: "People & Body", },
  { unicode: "\u{1F44A}\u{1F3FB}", emoji: "👊🏻", name: "oncoming fist: light skin tone", key: ":oncoming_fist_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F44A}\u{1F3FC}", emoji: "👊🏼", name: "oncoming fist: medium-light skin tone", key: ":oncoming_fist_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F44A}\u{1F3FD}", emoji: "👊🏽", name: "oncoming fist: medium skin tone", key: ":oncoming_fist_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F44A}\u{1F3FE}", emoji: "👊🏾", name: "oncoming fist: medium-dark skin tone", key: ":oncoming_fist_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F44A}\u{1F3FF}", emoji: "👊🏿", name: "oncoming fist: dark skin tone", key: ":oncoming_fist_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F91B}", emoji: "🤛", name: "left-facing fist", key: "left_facing_fist:", group: "People & Body", },
  { unicode: "\u{1F91B}\u{1F3FB}", emoji: "🤛🏻", name: "left-facing fist: light skin tone", key: ":left_facing_fist_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F91B}\u{1F3FC}", emoji: "🤛🏼", name: "left-facing fist: medium-light skin tone", key: ":left_facing_fist_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F91B}\u{1F3FD}", emoji: "🤛🏽", name: "left-facing fist: medium skin tone", key: ":left_facing_fist_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F91B}\u{1F3FE}", emoji: "🤛🏾", name: "left-facing fist: medium-dark skin tone", key: ":left_facing_fist_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F91B}\u{1F3FF}", emoji: "🤛🏿", name: "left-facing fist: dark skin tone", key: ":left_facing_fist_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F91C}", emoji: "🤜", name: "right-facing fist", key: ":right_facing_fist:", group: "People & Body", },
  { unicode: "\u{1F91C}\u{1F3FB}", emoji: "🤜🏻", name: "right-facing fist: light skin tone", key: ":right_facing_fist_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F91C}\u{1F3FC}", emoji: "🤜🏼", name: "right-facing fist: medium-light skin tone", key: ":right_facing_fist_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F91C}\u{1F3FD}", emoji: "🤜🏽", name: "right-facing fist: medium skin tone", key: ":right_facing_fist_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F91C}\u{1F3FE}", emoji: "🤜🏾", name: "right-facing fist: medium-dark skin tone", key: ":right_facing_fist_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F91C}\u{1F3FF}", emoji: "🤜🏿", name: "right-facing fist: dark skin tone", key: ":right_facing_fist_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F44F}", emoji: "👏", name: "clapping hands", key: ":clapping_hands:", group: "People & Body", },
  { unicode: "\u{1F44F}\u{1F3FB}", emoji: "👏🏻", name: "clapping hands: light skin tone", key: ":clapping_hands_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F44F}\u{1F3FC}", emoji: "👏🏼", name: "clapping hands: medium-light skin tone", key: ":clapping_hands_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F44F}\u{1F3FD}", emoji: "👏🏽", name: "clapping hands: medium skin tone", key: ":clapping_hands_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F44F}\u{1F3FE}", emoji: "👏🏾", name: "clapping hands: medium-dark skin tone", key: ":clapping_hands_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F44F}\u{1F3FF}", emoji: "👏🏿", name: "clapping hands: dark skin tone", key: ":clapping_hands_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64C}", emoji: "🙌", name: "raising hands", key: ":raising_hands:", group: "People & Body", },
  { unicode: "\u{1F64C}\u{1F3FB}", emoji: "🙌🏻", name: "raising hands: light skin tone", key: ":raising_hands_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64C}\u{1F3FC}", emoji: "🙌🏼", name: "raising hands: medium-light skin tone", key: ":raising_hands_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64C}\u{1F3FD}", emoji: "🙌🏽", name: "raising hands: medium skin tone", key: ":raising_hands_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64C}\u{1F3FE}", emoji: "🙌🏾", name: "raising hands: medium-dark skin tone", key: ":raising_hands_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64C}\u{1F3FF}", emoji: "🙌🏿", name: "raising hands: dark skin tone", key: ":raising_hands_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F450}", emoji: "👐", name: "open hands", key: ":open_hands:", group: "People & Body", },
  { unicode: "\u{1F450}\u{1F3FB}", emoji: "👐🏻", name: "open hands: light skin tone", key: ":open_hands_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F450}\u{1F3FC}", emoji: "👐🏼", name: "open hands: medium-light skin tone", key: ":open_hands_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F450}\u{1F3FD}", emoji: "👐🏽", name: "open hands: medium skin tone", key: ":open_hands_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F450}\u{1F3FE}", emoji: "👐🏾", name: "open hands: medium-dark skin tone", key: ":open_hands_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F450}\u{1F3FF}", emoji: "👐🏿", name: "open hands: dark skin tone", key: ":open_hands_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F932}", emoji: "🤲", name: "palms up together", key: ":palms_up_together:", group: "People & Body", },
  { unicode: "\u{1F932}\u{1F3FB}", emoji: "🤲🏻", name: "palms up together: light skin tone", key: ":palms_up_together_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F932}\u{1F3FC}", emoji: "🤲🏼", name: "palms up together: medium-light skin tone", key: ":palms_up_together_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F932}\u{1F3FD}", emoji: "🤲🏽", name: "palms up together: medium skin tone", key: ":palms_up_together_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F932}\u{1F3FE}", emoji: "🤲🏾", name: "palms up together: medium-dark skin tone", key: ":palms_up_together_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F932}\u{1F3FF}", emoji: "🤲🏿", name: "palms up together: dark skin tone", key: ":palms_up_together_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F91D}", emoji: "🤝", name: "handshake", key: ":handshake:", group: "People & Body", },
  { unicode: "\u{1F64F}", emoji: "🙏", name: "folded hands", key: ":folded_hands:", group: "People & Body", },
  { unicode: "\u{1F64F}\u{1F3FB}", emoji: "🙏🏻", name: "folded hands: light skin tone", key: ":folded_hands_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64F}\u{1F3FC}", emoji: "🙏🏼", name: "folded hands: medium-light skin tone", key: ":folded_hands_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64F}\u{1F3FD}", emoji: "🙏🏽", name: "folded hands: medium skin tone", key: ":folded_hands_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64F}\u{1F3FE}", emoji: "🙏🏾", name: "folded hands: medium-dark skin tone", key: ":folded_hands_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64F}\u{1F3FF}", emoji: "🙏🏿", name: "folded hands: dark skin tone", key: ":folded_hands_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{270D}", emoji: "✍", name: "writing hand", key: ":writing_hand:", group: "People & Body", },
  { unicode: "\u{270D}\u{1F3FB}", emoji: "✍🏻", name: "writing hand: light skin tone", key: ":writing_hand_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{270D}{1F3FC}", emoji: "✍🏼", name: "writing hand: medium-light skin tone", key: ":writing_hand_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{270D}\u{1F3FD}", emoji: "✍🏽", name: "writing hand: medium skin tone", key: ":writing_hand_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{270D}\u{1F3FE}", emoji: "✍🏾", name: "writing hand: medium-dark skin tone", key: ":writing_hand_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{270D}\u{1F3FF}", emoji: "✍🏿", name: "writing hand: dark skin tone", key: ":writing_hand_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F485}", emoji: "💅", name: "nail polish", key: ":nail_polish:", group: "People & Body", },
  { unicode: "\u{1F485}\u{1F3FB}", emoji: "💅🏻", name: "nail polish: light skin tone", key: ":nail_polish_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F485}\u{1F3FC}", emoji: "💅🏼", name: "nail polish: medium-light skin tone", key: ":nail_polish_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F485}\u{1F3FD}", emoji: "💅🏽", name: "nail polish: medium skin tone", key: ":nail_polish_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F485}\u{1F3FE}", emoji: "💅🏾", name: "nail polish: medium-dark skin tone", key: ":nail_polish_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F485}\u{1F3FF}", emoji: "💅🏿", name: "nail polish: dark skin tone", key: ":nail_polish_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F933}", emoji: "🤳", name: "selfie", key: ":selfie:", group: "People & Body", },
  { unicode: "\u{1F933}\u{1F3FB}", emoji: "🤳🏻", name: "selfie: light skin tone", key: ":selfie_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F933}\u{1F3FC}", emoji: "🤳🏼", name: "selfie: medium-light skin tone", key: ":selfie_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F933}\u{1F3FD}", emoji: "🤳🏽", name: "selfie: medium skin tone", key: ":selfie_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F933}\u{1F3FE}", emoji: "🤳🏾", name: "selfie: medium-dark skin tone", key: ":selfie_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F933}\u{1F3FF}", emoji: "🤳🏿", name: "selfie: dark skin tone", key: ":selfie_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F4AA}", emoji: "💪", name: "flexed biceps", key: ":flexed_biceps:", group: "People & Body", },
  { unicode: "\u{1F4AA}\u{1F3FB}", emoji: "💪🏻", name: "flexed biceps: light skin tone", key: ":flexed_biceps_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F4AA}\u{1F3FC}", emoji: "💪🏼", name: "flexed biceps: medium-light skin tone", key: ":flexed_biceps_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F4AA}\u{1F3FD}", emoji: "💪🏽", name: "flexed biceps: medium skin tone", key: ":flexed_biceps_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F4AA}\u{1F3FE}", emoji: "💪🏾", name: "flexed biceps: medium-dark skin tone", key: ":flexed_biceps_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F4AA}\u{1F3FF}", emoji: "💪🏿", name: "flexed biceps: dark skin tone", key: ":flexed_biceps_dark_skin_tone:", group: "People & Body", },
  // { unicode: "\u{1F9BE}", emoji: "🦾", name: "mechanical arm", key: ":mechanical_arm:", group: "People & Body", },
  // { unicode: "\u{1F9BF}", emoji: "🦿", name: "mechanical leg", key: ":mechanical_leg:", group: "People & Body", },
  // { unicode: "\u{1F9B5}", emoji: "🦵", name: "leg", key: ":leg:", group: "People & Body", },
  // { unicode: "\u{1F9B5}\u{1F3FB}", emoji: "🦵🏻", name: "leg: light skin tone", key: ":leg_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9B5}\u{1F3FC}", emoji: "🏼", name: "leg: medium-light skin tone", key: ":leg_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9B5}\u{1F3FD}", emoji: "🏽", name: "leg: medium skin tone", key: ":leg_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9B5}\u{1F3FE}", emoji: "🏾", name: "leg: medium-dark skin tone", key: ":leg_medium_dark_skin_tone:", group: "People & Body", },
  // { unicode: "\u{1F9B5}\u{1F3FF}", emoji: "🦵🏿", name: "leg: dark skin tone", key: ":leg_dark_skin_tone:", group: "People & Body", },
  // { unicode: "\u{1F9B6}", emoji: "🦶", name: "foot", key: ":foot:", group: "People & Body", },
  // { unicode: "\u{1F9B6}\u{1F3FB}", emoji: "🦶🏻", name: "foot: light skin tone", key: ":foot_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9B6}\u{1F3FC}", emoji: "🏼", name: "foot: medium-light skin tone", key: ":foot_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9B6}\u{1F3FD}", emoji: "🏽", name: "foot: medium skin tone", key: ":foot_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9B6}\u{1F3FE}", emoji: "🏾", name: "foot: medium-dark skin tone", key: ":foot_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9B6}\u{1F3FF}", emoji: "🏿", name: "foot: dark skin tone", key: ":foot_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F442}", emoji: "👂", name: "ear", key: ":ear:", group: "People & Body", },
  { unicode: "\u{1F442}\u{1F3FB}", emoji: "👂🏻", name: "ear: light skin tone", key: ":ear_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F442}\u{1F3FC}", emoji: "👂🏼", name: "ear: medium-light skin tone", key: ":ear_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F442}\u{1F3FD}", emoji: "👂🏽", name: "ear: medium skin tone", key: ":ear_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F442}\u{1F3FE}", emoji: "👂🏾", name: "ear: medium-dark skin tone", key: ":ear_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F442}\u{1F3FF}", emoji: "👂🏿", name: "ear: dark skin tone", key: ":ear_dark_skin_tone:", group: "People & Body", },
  // { unicode: "\u{1F9BB}", emoji: "🦻", name: "ear with hearing aid", key: ":ear_with_hearing_aid:", group: "People & Body", },
  // { unicode: "\u{1F9BB}\u{1F3FB}", emoji: "🦻🏻", name: "ear with hearing aid: light skin tone", key: ":ear_with_hearing_aid_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9BB}\u{1F3FC}", emoji: "🏼", name: "ear with hearing aid: medium-light skin tone", key: ":ear_with_hearing_aid_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9BB}\u{1F3FD}", emoji: "🏽", name: "ear with hearing aid: medium skin tone", key: ":ear_with_hearing_aid_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9BB}\u{1F3FE}", emoji: "🏾", name: "ear with hearing aid: medium-dark skin tone", key: ":ear_with_hearing_aid_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9BB}\u{1F3FF}", emoji: "🏿", name: "ear with hearing aid: dark skin tone", key: ":ear_with_hearing_aid_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F443}", emoji: "👃", name: "nose", key: ":nose:", group: "People & Body", },
  { unicode: "\u{1F443}\u{1F3FB}", emoji: "👃🏻", name: "nose: light skin tone", key: ":nose_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F443}\u{1F3FC}", emoji: "👃🏼", name: "nose: medium-light skin tone", key: ":nose_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F443}\u{1F3FD}", emoji: "👃🏽", name: "nose: medium skin tone", key: ":nose_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F443}\u{1F3FE}", emoji: "👃🏾", name: "nose: medium-dark skin tone", key: ":nose_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F443}\u{1F3FF}", emoji: "👃🏿", name: "nose: dark skin tone", key: ":nose_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9E0}", emoji: "🧠", name: "brain", key: ":brain:", group: "People & Body", },
  // { unicode: "\u{1F9B7}", emoji: "🦷", name: "tooth", key: ":tooth:", group: "People & Body", },
  // { unicode: "\u{1F9B4}", emoji: "🦴", name: "bone", key: ":bone:", group: "People & Body", },
  { unicode: "\u{1F440}", emoji: "👀", name: "eyes", key: ":eyes:", group: "People & Body", },
  { unicode: "\u{1F441}", emoji: "👁", name: "eye", key: ":eye:", group: "People & Body", },
  { unicode: "\u{1F445}", emoji: "👅", name: "tongue", key: ":tongue:", group: "People & Body", },
  { unicode: "\u{1F444}", emoji: "👄", name: "mouth", key: ":mouth:", group: "People & Body", },
  { unicode: "\u{1F476}", emoji: "👶", name: "baby", key: ":baby:", group: "People & Body", },
  { unicode: "\u{1F476}\u{1F3FB}", emoji: "👶🏻", name: "baby: light skin tone", key: ":baby_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F476}\u{1F3FC}", emoji: "👶🏼", name: "baby: medium-light skin tone", key: ":baby_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F476}\u{1F3FD}", emoji: "👶🏽", name: "baby: medium skin tone", key: ":baby_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F476}\u{1F3FE}", emoji: "👶🏾", name: "baby: medium-dark skin tone", key: ":baby_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F476}\u{1F3FF}", emoji: "👶🏿", name: "baby: dark skin tone", key: ":baby_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D2}", emoji: "🧒", name: "child", key: ":child:", group: "People & Body", },
  { unicode: "\u{1F9D2}\u{1F3FB}", emoji: "🧒🏻", name: "child: light skin tone", key: ":child_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D2}\u{1F3FC}", emoji: "🧒🏼", name: "child: medium-light skin tone", key: ":child_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D2}\u{1F3FD}", emoji: "🧒🏽", name: "child: medium skin tone", key: ":child_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D2}\u{1F3FE}", emoji: "🧒🏾", name: "child: medium-dark skin tone", key: ":child_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D2}\u{1F3FF}", emoji: "🧒🏿", name: "child: dark skin tone", key: ":child_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F466}", emoji: "👦", name: "boy", key: ":boy:", group: "People & Body", },
  { unicode: "\u{1F466}\u{1F3FB}", emoji: "👦🏻", name: "boy: light skin tone", key: ":boy_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F466}\u{1F3FC}", emoji: "👦🏼", name: "boy: medium-light skin tone", key: ":boy_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F466}\u{1F3FD}", emoji: "👦🏽", name: "boy: medium skin tone", key: ":boy_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F466}\u{1F3FE}", emoji: "👦🏾", name: "boy: medium-dark skin tone", key: ":boy_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F466}\u{1F3FF}", emoji: "👦🏿", name: "boy: dark skin tone", key: ":boy_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F467}", emoji: "👧", name: "girl", key: ":girl:", group: "People & Body", },
  { unicode: "\u{1F467}\u{1F3FB}", emoji: "👧🏻", name: "girl: light skin tone", key: ":girl_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F467}\u{1F3FC}", emoji: "👧🏼", name: "girl: medium-light skin tone", key: ":girl_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F467}\u{1F3FD}", emoji: "👧🏽", name: "girl: medium skin tone", key: ":girl_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F467}\u{1F3FE}", emoji: "👧🏾", name: "girl: medium-dark skin tone", key: ":girl_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F467}\u{1F3FF}", emoji: "👧🏿", name: "girl: dark skin tone", key: ":girl_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}", emoji: "🧑", name: "person", key: ":person:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FB}", emoji: "🧑🏻", name: "person: light skin tone", key: ":person_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FC}", emoji: "🧑🏼", name: "person: medium-light skin tone", key: ":person_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FD}", emoji: "🧑🏽", name: "person: medium skin tone", key: ":person_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FE}", emoji: "🧑🏾", name: "person: medium-dark skin tone", key: ":person_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FF}", emoji: "🧑🏿", name: "person: dark skin tone", key: ":person_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F471}", emoji: "👱", name: "person: blond hair", key: ":person_blond_hair:", group: "People & Body", },
  { unicode: "\u{1F471}\u{1F3FB}", emoji: "👱🏻", name: "person: light skin tone, blond hair", key: ":person_light_skin_tone_blond_hair:", group: "People & Body", },
  { unicode: "\u{1F471}\u{1F3FC}", emoji: "👱🏼", name: "person: medium-light skin tone, blond hair", key: ":person_medium_light_skin_tone_blond_hair:", group: "People & Body", },
  { unicode: "\u{1F471}\u{1F3FD}", emoji: "👱🏽", name: "person: medium skin tone, blond hair", key: ":person_medium_skin_tone_blond_hair:", group: "People & Body", },
  { unicode: "\u{1F471}\u{1F3FE}", emoji: "👱🏾", name: "person: medium-dark skin tone, blond hair", key: ":person_medium_dark_skin_tone_blond_hair:", group: "People & Body", },
  { unicode: "\u{1F471}\u{1F3FF}", emoji: "👱🏿", name: "person: dark skin tone, blond hair", key: ":person_dark_skin_tone_blond_hair:", group: "People & Body", },
  { unicode: "\u{1F468}", emoji: "👨", name: "man", key: ":man:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FB}", emoji: "👨🏻", name: "man: light skin tone", key: ":man_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FC}", emoji: "👨🏼", name: "man: medium-light skin tone", key: ":man_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FD}", emoji: "👨🏽", name: "man: medium skin tone", key: ":man_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FE}", emoji: "👨🏾", name: "man: medium-dark skin tone", key: ":man_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FF}", emoji: "👨🏿", name: "man: dark skin tone", key: ":man_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D4}", emoji: "🧔", name: "man: beard", key: ":man_beard:", group: "People & Body", },
  { unicode: "\u{1F9D4}\u{1F3FB}", emoji: "🧔🏻", name: "man: light skin tone, beard", key: ":man_light_skin_tone_beard:", group: "People & Body", },
  { unicode: "\u{1F9D4}\u{1F3FC}", emoji: "🧔🏼", name: "man: medium-light skin tone, beard", key: ":man_medium_light_skin_tone_beard:", group: "People & Body", },
  { unicode: "\u{1F9D4}\u{1F3FD}", emoji: "🧔🏽", name: "man: medium skin tone, beard", key: ":man_medium_skin_tone_beard:", group: "People & Body", },
  { unicode: "\u{1F9D4}\u{1F3FE}", emoji: "🧔🏾", name: "man: medium-dark skin tone, beard", key: ":man_medium_dark_skin_tone_beard:", group: "People & Body", },
  { unicode: "\u{1F9D4}\u{1F3FF}", emoji: "🧔🏿", name: "man: dark skin tone, beard", key: ":man_dark_skin_tone_beard:", group: "People & Body", },
  { unicode: "\u{1F468}\u{200D}\u{1F9B0}", emoji: "👨‍", name: "man: red hair", key: ":man_red_hair:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FB}\u{200D}\u{1F9B0}", emoji: "👨🏻‍", name: "man: light skin tone, red hair", key: ":man_light_skin_tone_red_hair:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FC}\u{200D}\u{1F9B0}", emoji: "👨🏼‍", name: "man: medium-light skin tone, red hair", key: ":man_medium_light_skin_tone_red_hair:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FD}\u{200D}\u{1F9B0}", emoji: "👨🏽‍", name: "man: medium skin tone, red hair", key: ":man_medium_skin_tone_red_hair:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FE}\u{200D}\u{1F9B0}", emoji: "👨🏾‍", name: "man: medium-dark skin tone, red hair", key: ":man_medium_dark_skin_tone_red_hair:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FF}\u{200D}\u{1F9B0}", emoji: "👨🏿‍", name: "man: dark skin tone, red hair", key: ":man_dark_skin_tone_red_hair:", group: "People & Body", },
  { unicode: "\u{1F468}\u{200D}\u{1F9B1}", emoji: "👨‍", name: "man: curly hair", key: ":man_curly_hair:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FB}\u{200D}\u{1F9B1}", emoji: "👨🏻‍", name: "man: light skin tone, curly hair", key: ":man_light_skin_tone_curly_hair:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FC}\u{200D}\u{1F9B1}", emoji: "👨🏼‍", name: "man: medium-light skin tone, curly hair", key: ":man_medium_light_skin_tone_curly_hair:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FD}\u{200D}\u{1F9B1}", emoji: "👨🏽‍", name: "man: medium skin tone, curly hair", key: ":man_medium_skin_tone_curly_hair:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FE}\u{200D}\u{1F9B1}", emoji: "👨🏾‍", name: "man: medium-dark skin tone, curly hair", key: ":man_medium_dark_skin_tone_curly_hair:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FF}\u{200D}\u{1F9B1}", emoji: "👨🏿‍", name: "man: dark skin tone, curly hair", key: ":man_dark_skin_tone_curly_hair:", group: "People & Body", },
  { unicode: "\u{1F468}\u{200D}\u{1F9B3}", emoji: "👨‍", name: "man: white hair", key: ":man_white_hair:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FB}\u{200D}\u{1F9B3}", emoji: "👨🏻", name: "man: light skin tone, white hair", key: ":man_light_skin_tone_white_hair:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FC}\u{200D}\u{1F9B3}", emoji: "👨🏼", name: "man: medium-light skin tone, white hair", key: ":man_medium_light_skin_tone_white_hair:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FD}\u{200D}\u{1F9B3}", emoji: "👨🏽", name: "man: medium skin tone, white hair", key: ":man_medium_skin_tone_white_hair:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FE}\u{200D}\u{1F9B3}", emoji: "👨🏾", name: "man: medium-dark skin tone, white hair", key: ":man_medium_dark_skin_tone_white_hair:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FF}\u{200D}\u{1F9B3}", emoji: "👨🏿", name: "man: dark skin tone, white hair", key: ":man_dark_skin_tone_white_hair:", group: "People & Body", },
  { unicode: "\u{1F468}\u{200D}\u{1F9B2}", emoji: "👨‍", name: "man: bald", key: ":man_bald:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FB}\u{200D}\u{1F9B2}", emoji: "👨🏻", name: "man: light skin tone, bald", key: ":man_light_skin_tone_bald:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FC}\u{200D}\u{1F9B2}", emoji: "👨🏼", name: "man: medium-light skin tone, bald", key: ":man_medium_light_skin_tone_bald:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FD}\u{200D}\u{1F9B2}", emoji: "👨🏽", name: "man: medium skin tone, bald", key: ":man_medium_skin_tone_bald:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FE}\u{200D}\u{1F9B2}", emoji: "👨🏾", name: "man: medium-dark skin tone, bald", key: ":man_medium_dark_skin_tone_bald:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FF}\u{200D}\u{1F9B2}", emoji: "👨🏿", name: "man: dark skin tone, bald", key: ":man_dark_skin_tone_bald:", group: "People & Body", },
  { unicode: "\u{1F469}", emoji: "👩", name: "woman", key: ":woman:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FB}", emoji: "👩🏻", name: "woman: light skin tone", key: ":woman_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FC}", emoji: "👩🏼", name: "woman: medium-light skin tone", key: ":woman_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FD}", emoji: "👩🏽", name: "woman: medium skin tone", key: ":woman_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FE}", emoji: "👩🏾", name: "woman: medium-dark skin tone", key: ":woman_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FF}", emoji: "👩🏿", name: "woman: dark skin tone", key: ":woman_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{200D}\u{1F9B0}", emoji: "👩‍", name: "woman: red hair", key: ":woman_red_hair:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FB}\u{200D}\u{1F9B0}", emoji: "👩🏻", name: "woman: light skin tone, red hair", key: ":woman_light_skin_tone_red_hair:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FC}\u{200D}\u{1F9B0}", emoji: "👩🏼", name: "woman: medium-light skin tone, red hair", key: ":woman_medium_light_skin_tone_red_hair:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FD}\u{200D}\u{1F9B0}", emoji: "👩🏽", name: "woman: medium skin tone, red hair", key: ":woman_medium_skin_tone_red_hair:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FE}\u{200D}\u{1F9B0}", emoji: "👩🏾", name: "woman: medium-dark skin tone, red hair", key: ":woman_medium_dark_skin_tone_red_hair:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FF}\u{200D}\u{1F9B0}", emoji: "👩🏿", name: "woman: dark skin tone, red hair", key: ":woman_dark_skin_tone_red_hair:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{200D}\u{1F9B0}", emoji: "🧑‍", name: "person: red hair", key: ":person_red_hair:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FB}\u{200D}\u{1F9B0}", emoji: "🧑🏻", name: "person: light skin tone, red hair", key: ":person_light_skin_tone_red_hair:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FC}\u{200D}\u{1F9B0}", emoji: "🧑🏼", name: "person: medium-light skin tone, red hair", key: ":person_medium_light_skin_tone_red_hair:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FD}\u{200D}\u{1F9B0}", emoji: "🧑🏽", name: "person: medium skin tone, red hair", key: ":person_medium_skin_tone_red_hair:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FE}\u{200D}\u{1F9B0}", emoji: "🧑🏾", name: "person: medium-dark skin tone, red hair", key: ":person_medium_dark_skin_tone_red_hair:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FF}\u{200D}\u{1F9B0}", emoji: "🧑🏿", name: "person: dark skin tone, red hair", key: ":person_dark_skin_tone_red_hair:", group: "People & Body", },
  { unicode: "\u{1F469}\u{200D}\u{1F9B1}", emoji: "👩‍", name: "woman: curly hair", key: ":woman_curly_hair:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FB}\u{200D}\u{1F9B1}", emoji: "👩🏻", name: "woman: light skin tone, curly hair", key: ":woman_light_skin_tone_curly_hair:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FC}\u{200D}\u{1F9B1}", emoji: "👩🏼", name: "woman: medium-light skin tone, curly hair", key: ":woman_medium_light_skin_tone_curly_hair:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FD}\u{200D}\u{1F9B1}", emoji: "👩🏽", name: "woman: medium skin tone, curly hair", key: ":woman_medium_skin_tone_curly_hair:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FE}\u{200D}\u{1F9B1}", emoji: "👩🏾", name: "woman: medium-dark skin tone, curly hair", key: ":woman_medium_dark_skin_tone_curly_hair:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FF}\u{200D}\u{1F9B1}", emoji: "👩🏿", name: "woman: dark skin tone, curly hair", key: ":woman_dark_skin_tone_curly_hair:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{200D}\u{1F9B1}", emoji: "🧑‍", name: "person: curly hair", key: ":person_curly_hair:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FB}\u{200D}\u{1F9B1}", emoji: "🧑🏻", name: "person: light skin tone, curly hair", key: ":person_light_skin_tone_curly_hair:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FC}\u{200D}\u{1F9B1}", emoji: "🧑🏼", name: "person: medium-light skin tone, curly hair", key: ":person_medium_light_skin_tone_curly_hair:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FD}\u{200D}\u{1F9B1}", emoji: "🧑🏽", name: "person: medium skin tone, curly hair", key: ":person_medium_skin_tone_curly_hair:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FE}\u{200D}\u{1F9B1}", emoji: "🧑🏾", name: "person: medium-dark skin tone, curly hair", key: ":person_medium_dark_skin_tone_curly_hair:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FF}\u{200D}\u{1F9B1}", emoji: "🧑🏿", name: "person: dark skin tone, curly hair", key: ":person_dark_skin_tone_curly_hair:", group: "People & Body", },
  { unicode: "\u{1F469}\u{200D}\u{1F9B3}", emoji: "👩‍", name: "woman: white hair", key: ":woman_white_hair:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FB}\u{200D}\u{1F9B3}", emoji: "👩🏻", name: "woman: light skin tone, white hair", key: ":woman_light_skin_tone_white_hair:", group: "People & Body" },
  { unicode: "\u{1F469}\u{1F3FC}\u{200D}\u{1F9B3}", emoji: "👩🏼", name: "woman: medium-light skin tone, white hair", key: ":woman_medium_light_skin_tone_white_hair:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FD}\u{200D}\u{1F9B3}", emoji: "👩🏽", name: "woman: medium skin tone, white hair", key: ":woman_medium_skin_tone_white_hair:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FE}\u{200D}\u{1F9B3}", emoji: "👩🏾", name: "woman: medium-dark skin tone, white hair", key: "woman_medium_dark_skin_tone_white_hair", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FF}\u{200D}\u{1F9B3}", emoji: "👩🏿", name: "woman: dark skin tone, white hair", key: ":woman_dark_skin_tone_white_hair:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{200D}\u{1F9B3}", emoji: "🧑‍", name: "person: white hair", key: ":person_white_hair:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FB}\u{200D}\u{1F9B3}", emoji: "🧑🏻", name: "person: light skin tone, white hair", key: ":person_light_skin_tone_white_hair:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FC}\u{200D}\u{1F9B3}", emoji: "🧑🏼", name: "person: medium-light skin tone, white hair", key: ":person_medium_light_skin_tone_white_hair:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FD}\u{200D}\u{1F9B3}", emoji: "🧑🏽", name: "person: medium skin tone, white hair", key: ":person_medium_skin_tone_white_hair:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FE}\u{200D}\u{1F9B3}", emoji: "🧑🏾", name: "person: medium-dark skin tone, white hair", key: ":person_medium_dark_skin_tone_white_hair:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FF}\u{200D}\u{1F9B3}", emoji: "🧑🏿", name: "person: dark skin tone, white hair", key: ":person_dark_skin_tone_white_hair:", group: "People & Body", },
  { unicode: "\u{1F469}\u{200D}\u{1F9B2}", emoji: "👩", name: "woman: bald", key: ":woman_bald:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FB}\u{200D}\u{1F9B2}", emoji: "👩🏻", name: "woman: light skin tone, bald", key: ":woman_light_skin_tone_bald:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FC}\u{200D}\u{1F9B2}", emoji: "👩🏼", name: "woman: medium-light skin tone, bald", key: ":woman_medium_light_skin_tone_bald:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FD}\u{200D}\u{1F9B2}", emoji: "👩🏽", name: "woman: medium skin tone, bald", key: ":woman_medium_skin_tone_bald:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FE}\u{200D}\u{1F9B2}", emoji: "👩🏾", name: "woman: medium-dark skin tone, bald", key: ":woman_medium_dark_skin_tone_bald:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FF}\u{200D}\u{1F9B2}", emoji: "👩🏿", name: "woman: dark skin tone, bald", key: ":woman_dark_skin_tone_bald:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{200D}\u{1F9B2}", emoji: "🧑‍", name: "person: bald", key: ":person_bald:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FB}\u{200D}\u{1F9B2}", emoji: "🧑🏻", name: "person: light skin tone, bald", key: ":person_light_skin_tone_bald:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FC}\u{200D}\u{1F9B2}", emoji: "🧑🏼", name: "person: medium-light skin tone, bald", key: ":person_medium_light_skin_tone_bald:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FD}\u{200D}\u{1F9B2}", emoji: "🧑🏽", name: "person: medium skin tone, bald", key: ":person_medium_skin_tone_bald:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FE}\u{200D}\u{1F9B2}", emoji: "🧑🏾", name: "person: medium-dark skin tone, bald", key: ":person_medium_dark_skin_tone_bald:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FF}\u{200D}\u{1F9B2}", emoji: "🧑🏿", name: "person: dark skin tone, bald", key: ":person_dark_skin_tone_bald:", group: "People & Body", },
  { unicode: "\u{1F471}\u{200D}\u{2640}\u{FE0F}", emoji: "👱‍♀️", name: "woman: blond hair", key: ":woman_blond_hair:", group: "People & Body", },
  { unicode: "\u{1F471}\u{1F3FB}\u{200D}\u{2640}", emoji: "👱🏻‍♀", name: "woman: light skin tone, blond hair", key: ":woman_light_skin_tone_blond_hair:", group: "People & Body", },
  { unicode: "\u{1F471}\u{1F3FC}\u{200D}\u{2640}", emoji: "👱🏼‍♀", name: "woman: medium-light skin tone, blond hair", key: ":woman_medium_light_skin_tone_blond_hair:", group: "People & Body", },
  { unicode: "\u{1F471}\u{1F3FD}\u{200D}\u{2640}", emoji: "👱🏽‍♀", name: "woman: medium skin tone, blond hair", key: ":woman_medium_skin_tone_blond_hair:", group: "People & Body", },
  { unicode: "\u{1F471}\u{1F3FE}\u{200D}\u{2640}", emoji: "👱🏾‍♀️", name: "woman: medium-dark skin tone, blond hair", key: ":woman_medium_dark_skin_tone_blond_hair:", group: "People & Body", },
  { unicode: "\u{1F471}\u{1F3FF}\u{200D}\u{2640}", emoji: "👱🏿‍♀", name: "woman: dark skin tone, blond hair", key: ":woman_dark_skin_tone_blond_hair:", group: "People & Body", },
  { unicode: "\u{1F471}\u{200D}\u{2642}", emoji: "👱‍♂", name: "man: blond hair", key: ":man_blond_hair:", group: "People & Body", },
  { unicode: "\u{1F471}\u{1F3FB}\u{200D}\u{2642}", emoji: "👱🏻‍♂", name: "man: light skin tone, blond hair", key: ":man_light_skin_tone_blond_hair:", group: "People & Body", },
  { unicode: "\u{1F471}\u{1F3FC}\u{200D}\u{2642}", emoji: "👱🏼‍♂", name: "man: medium-light skin tone, blond hair", key: ":man_medium_light_skin_tone_blond_hair:", group: "People & Body", },
  { unicode: "\u{1F471}\u{1F3FD}\u{200D}\u{2642}", emoji: "👱🏽‍♂", name: "man: medium skin tone, blond hair", key: ":man_medium_skin_tone_blond_hair:", group: "People & Body", },
  { unicode: "\u{1F471}\u{1F3FE}\u{200D}\u{2642}", emoji: "👱🏾‍♂", name: "man: medium-dark skin tone, blond hair", key: ":man_medium_dark_skin_tone_blond_hair:", group: "People & Body", },
  { unicode: "\u{1F471}\u{1F3FF}\u{200D}\u{2642}", emoji: "👱🏿‍♂", name: "man: dark skin tone, blond hair", key: ":man_dark_skin_tone_blond_hair:", group: "People & Body", },
  { unicode: "\u{1F9D3}", emoji: "🧓", name: "older person", key: ":older_person:", group: "People & Body", },
  { unicode: "\u{1F9D3}\u{1F3FB}", emoji: "🧓🏻", name: "older person: light skin tone", key: ":older_person_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D3}\u{1F3FC}", emoji: "🧓🏼", name: "older person: medium-light skin tone", key: ":older_person_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D3}\u{1F3FD}", emoji: "🧓🏽", name: "older person: medium skin tone", key: ":older_person_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D3}\u{1F3FE}", emoji: "🧓🏾", name: "older person: medium-dark skin tone", key: ":older_person_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D3}\u{1F3FF}", emoji: "🧓🏿", name: "older person: dark skin tone", key: ":older_person_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F474}", emoji: "👴", name: "old man", key: ":old_man:", group: "People & Body", },
  { unicode: "\u{1F474}\u{1F3FB}", emoji: "👴🏻", name: "old man: light skin tone", key: ":old_man_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F474}\u{1F3FC}", emoji: "👴🏼", name: "old man: medium-light skin tone", key: ":old_man_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F474}\u{1F3FD}", emoji: "👴🏽", name: "old man: medium skin tone", key: ":old_man_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F474}\u{1F3FE}", emoji: "👴🏾", name: "old man: medium-dark skin tone", key: ":old_man_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F474}\u{1F3FF}", emoji: "👴🏿", name: "old man: dark skin tone", key: ":old_man_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F475}", emoji: "👵", name: "old woman", key: ":old_woman:", group: "People & Body", },
  { unicode: "\u{1F475}\u{1F3FB}", emoji: "👵🏻", name: "old woman: light skin tone", key: ":old_woman_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F475}\u{1F3FC}", emoji: "👵🏼", name: "old woman: medium-light skin tone", key: ":old_woman_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F475}\u{1F3FD}", emoji: "👵🏽", name: "old woman: medium skin tone", key: ":old_woman_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F475}\u{1F3FE}", emoji: "👵🏾", name: "old woman: medium-dark skin tone", key: ":old_woman_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F475}\u{1F3FF}", emoji: "👵🏿", name: "old woman: dark skin tone", key: ":old_woman_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64D}", emoji: "🙍", name: "person frowning", key: ":person_frowning:", group: "People & Body", },
  { unicode: "\u{1F64D}\u{1F3FB}", emoji: "🙍🏻", name: "person frowning: light skin tone", key: ":person_frowning_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64D}\u{1F3FC}", emoji: "🙍🏼", name: "person frowning: medium-light skin tone", key: ":person_frowning_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64D}\u{1F3FD}", emoji: "🙍🏽", name: "person frowning: medium skin tone", key: ":person_frowning_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64D}\u{1F3FE}", emoji: "🙍🏾", name: "person frowning: medium-dark skin tone", key: ":person_frowning_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64D}\u{1F3FF}", emoji: "🙍🏿", name: "person frowning: dark skin tone", key: ":person_frowning_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64D}\u{200D}\u{2642}", emoji: "🙍‍♂", name: "man frowning", key: ":man_frowning:", group: "People & Body", },
  { unicode: "\u{1F64D}\u{1F3FB}\u{200D}\u{2642}", emoji: "🙍🏻‍♂", name: "man frowning: light skin tone", key: ":man_frowning_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64D}\u{1F3FC}\u{200D}\u{2642}", emoji: "🙍🏼‍♂", name: "man frowning: medium-light skin tone", key: ":man_frowning_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64D}\u{1F3FD}\u{200D}\u{2642}", emoji: "🙍🏽‍♂", name: "man frowning: medium skin tone", key: ":man_frowning_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64D}\u{1F3FE}\u{200D}\u{2642}", emoji: "🙍🏾‍♂", name: "man frowning: medium-dark skin tone", key: ":man_frowning_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64D}\u{1F3FF}\u{200D}\u{2642}", emoji: "🙍🏿‍♂", name: "man frowning: dark skin tone", key: ":man_frowning_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64D}\u{200D}\u{2640}", emoji: "🙍‍♀", name: "woman frowning", key: ":woman_frowning:", group: "People & Body", },
  { unicode: "\u{1F64D}\u{1F3FB}\u{200D}\u{2640}", emoji: "🙍🏻‍♀", name: "woman frowning: light skin tone", key: ":woman_frowning_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64D}\u{1F3FC}\u{200D}\u{2640}", emoji: "🙍🏼‍♀", name: "woman frowning: medium-light skin tone", key: ":woman_frowning_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64D}\u{1F3FD}\u{200D}\u{2640}", emoji: "🙍🏽‍♀", name: "woman frowning: medium skin tone", key: ":woman_frowning_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64D}\u{1F3FE}\u{200D}\u{2640}", emoji: "🙍🏾‍♀", name: "woman frowning: medium-dark skin tone", key: ":woman_frowning_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64D}\u{1F3FF}\u{200D}\u{2640}", emoji: "🙍🏿‍♀", name: "woman frowning: dark skin tone", key: ":woman_frowning_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64E}", emoji: "🙎", name: "person pouting", key: ":person_pouting:", group: "People & Body", },
  { unicode: "\u{1F64E}\u{1F3FB}", emoji: "🙎🏻", name: "person pouting: light skin tone", key: ":person_pouting_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64E}\u{1F3FC}", emoji: "🙎🏼", name: "person pouting: medium-light skin tone", key: ":person_pouting_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64E}\u{1F3FD}", emoji: "🙎🏽", name: "person pouting: medium skin tone", key: ":person_pouting_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64E}\u{1F3FE}", emoji: "🙎🏾", name: "person pouting: medium-dark skin tone", key: ":person_pouting_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64E}\u{1F3FF}", emoji: "🙎🏿", name: "person pouting: dark skin tone", key: ":person_pouting_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64E}\u{200D}\u{2642}", emoji: "🙎‍♂", name: "man pouting", key: ":man_pouting:", group: "People & Body", },
  { unicode: "\u{1F64E}\u{1F3FB}\u{200D}\u{2642}", emoji: "🙎🏻‍♂", name: "man pouting: light skin tone", key: ":man_pouting_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64E}\u{1F3FC}\u{200D}\u{2642}", emoji: "🙎🏼‍♂", name: "man pouting: medium-light skin tone", key: ":man_pouting_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64E}\u{1F3FD}\u{200D}\u{2642}", emoji: "🙎🏽‍♂", name: "man pouting: medium skin tone", key: ":man_pouting_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64E}\u{1F3FE}\u{200D}\u{2642}", emoji: "🙎🏾‍♂", name: "man pouting: medium-dark skin tone", key: ":man_pouting_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64E}\u{1F3FF}\u{200D}\u{2642}", emoji: "🙎🏿‍♂", name: "man pouting: dark skin tone", key: ":man_pouting_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64E}\u{200D}\u{2640}", emoji: "🙎‍♀", name: "woman pouting", key: ":woman_pouting:", group: "People & Body", },
  { unicode: "\u{1F64E}\u{1F3FB}\u{200D}\u{2640}", emoji: "🙎🏻‍♀", name: "woman pouting: light skin tone", key: ":woman_pouting_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64E}\u{1F3FC}\u{200D}\u{2640}", emoji: "🙎🏼‍♀", name: "woman pouting: medium-light skin tone", key: ":woman_pouting_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64E}\u{1F3FD}\u{200D}\u{2640}", emoji: "🙎🏽‍♀", name: "woman pouting: medium skin tone", key: ":woman_pouting_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64E}\u{1F3FE}\u{200D}\u{2640}", emoji: "🙎🏾‍♀", name: "woman pouting: medium-dark skin tone", key: ":woman_pouting_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64E}\u{1F3FF}\u{200D}\u{2640}", emoji: "🙎🏿‍♀", name: "woman pouting: dark skin tone", key: ":woman_pouting_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F645}", emoji: "🙅", name: "person gesturing no", key: ":person_gesturing_no:", group: "People & Body", },
  { unicode: "\u{1F645}\u{1F3FB}", emoji: "🙅🏻", name: "person gesturing no: light skin tone", key: ":person_gesturing_no_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F645}\u{1F3FC}", emoji: "🙅🏼", name: "person gesturing no: medium-light skin tone", key: ":person_gesturing_no_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F645}\u{1F3FD}", emoji: "🙅🏽", name: "person gesturing no: medium skin tone", key: ":person_gesturing_no_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F645}\u{1F3FE}", emoji: "🙅🏾", name: "person gesturing no: medium-dark skin tone", key: ":person_gesturing_no_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F645}\u{1F3FF}", emoji: "🙅🏿", name: "person gesturing no: dark skin tone", key: ":person_gesturing_no_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F645}\u{200D}\u{2642}", emoji: "🙅‍♂", name: "man gesturing no", key: ":man_gesturing_no:", group: "People & Body", },
  { unicode: "\u{1F645}\u{1F3FB}\u{200D}\u{2642}", emoji: "🙅🏻‍♂", name: "man gesturing no: light skin tone", key: ":man_gesturing_no_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F645}\u{1F3FC}\u{200D}\u{2642}", emoji: "🙅🏼‍♂", name: "man gesturing no: medium-light skin tone", key: ":man_gesturing_no_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F645}\u{1F3FD}\u{200D}\u{2642}", emoji: "🙅🏽‍♂", name: "man gesturing no: medium skin tone", key: ":man_gesturing_no_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F645}\u{1F3FE}\u{200D}\u{2642}", emoji: "🙅🏾‍♂", name: "man gesturing no: medium-dark skin tone", key: ":man_gesturing_no_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F645}\u{1F3FF}\u{200D}\u{2642}", emoji: "🙅🏿‍♂", name: "man gesturing no: dark skin tone", key: ":man_gesturing_no_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F645}\u{200D}\u{2640}", emoji: "🙅‍♀", name: "woman gesturing no", key: ":woman_gesturing_no:", group: "People & Body", },
  { unicode: "\u{1F645}\u{1F3FB}\u{200D}\u{2640}", emoji: "🙅🏻‍♀", name: "woman gesturing no: light skin tone", key: ":woman_gesturing_no_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F645}\u{1F3FC}\u{200D}\u{2640}", emoji: "🙅🏼‍♀", name: "woman gesturing no: medium-light skin tone", key: ":woman_gesturing_no_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F645}\u{1F3FD}\u{200D}\u{2640}", emoji: "🙅🏽‍♀", name: "woman gesturing no: medium skin tone", key: ":woman_gesturing_no_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F645}\u{1F3FE}\u{200D}\u{2640}", emoji: "🙅🏾‍♀", name: "woman gesturing no: medium-dark skin tone", key: ":woman_gesturing_no_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F645}\u{1F3FF}\u{200D}\u{2640}", emoji: "🙅🏿‍♀", name: "woman gesturing no: dark skin tone", key: ":woman_gesturing_no_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F646}", emoji: "🙆", name: "person gesturing ok", key: ":person_gesturing_ok:", group: "People & Body", },
  { unicode: "\u{1F646}\u{1F3FB}", emoji: "🙆🏻", name: "person gesturing ok: light skin tone", key: ":person_gesturing_ok_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F646}\u{1F3FC}", emoji: "🙆🏼", name: "person gesturing ok: medium-light skin tone", key: ":person_gesturing_ok_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F646}\u{1F3FD}", emoji: "🙆🏽", name: "person gesturing ok: medium skin tone", key: ":person_gesturing_ok_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F646}\u{1F3FE}", emoji: "🙆🏾", name: "person gesturing ok: medium-dark skin tone", key: ":person_gesturing_ok_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F646}\u{1F3FF}", emoji: "🙆🏿", name: "person gesturing ok: dark skin tone", key: ":person_gesturing_ok_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F646}\u{200D}\u{2642}", emoji: "🙆‍♂", name: "man gesturing ok", key: ":man_gesturing_ok:", group: "People & Body", },
  { unicode: "\u{1F646}\u{1F3FB}\u{200D}\u{2642}", emoji: "🙆🏻‍♂", name: "man gesturing ok: light skin tone", key: ":man_gesturing_ok_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F646}\u{1F3FC}\u{200D}\u{2642}", emoji: "🙆🏼‍♂", name: "man gesturing ok: medium-light skin tone", key: ":man_gesturing_ok_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F646}\u{1F3FD}\u{200D}\u{2642}", emoji: "🙆🏽‍♂", name: "man gesturing ok: medium skin tone", key: ":man_gesturing_ok_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F646}\u{1F3FE}\u{200D}\u{2642}", emoji: "🙆🏾‍♂", name: "man gesturing ok: medium-dark skin tone", key: ":man_gesturing_ok_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F646}\u{1F3FF}\u{200D}\u{2642}", emoji: "🙆🏿‍♂", name: "man gesturing ok: dark skin tone", key: ":man_gesturing_ok_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F646}\u{200D}\u{2640}", emoji: "🙆‍♀", name: "woman gesturing ok", key: ":woman_gesturing_ok:", group: "People & Body", },
  { unicode: "\u{1F646}\u{1F3FB}\u{200D}\u{2640}", emoji: "🙆🏻‍♀", name: "woman gesturing ok: light skin tone", key: ":woman_gesturing_ok_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F646}\u{1F3FC}\u{200D}\u{2640}", emoji: "🙆🏼‍♀", name: "woman gesturing ok: medium-light skin tone", key: ":woman_gesturing_ok_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F646}\u{1F3FD}\u{200D}\u{2640}", emoji: "🙆🏽‍♀", name: "woman gesturing ok: medium skin tone", key: ":woman_gesturing_ok_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F646}\u{1F3FE}\u{200D}\u{2640}", emoji: "🙆🏾‍♀", name: "woman gesturing ok: medium-dark skin tone", key: ":woman_gesturing_ok_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F646}\u{1F3FF}\u{200D}\u{2640}", emoji: "🙆🏿‍♀", name: "woman gesturing ok: dark skin tone", key: ":woman_gesturing_ok_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F481}", emoji: "💁", name: "person tipping hand", key: ":person_tipping_hand:", group: "People & Body", },
  { unicode: "\u{1F481}\u{1F3FB}", emoji: "💁🏻", name: "person tipping hand: light skin tone", key: ":person_tipping_hand_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F481}\u{1F3FC}", emoji: "💁🏼", name: "person tipping hand: medium-light skin tone", key: ":person_tipping_hand_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F481}\u{1F3FD}", emoji: "💁🏽", name: "person tipping hand: medium skin tone", key: ":person_tipping_hand_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F481}\u{1F3FE}", emoji: "💁🏾", name: "person tipping hand: medium-dark skin tone", key: ":person_tipping_hand_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F481}\u{1F3FF}", emoji: "💁🏿", name: "person tipping hand: dark skin tone", key: ":person_tipping_hand_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F481}\u{200D}\u{2642}", emoji: "💁‍♂", name: "man tipping hand", key: ":man_tipping_hand:", group: "People & Body", },
  { unicode: "\u{1F481}\u{1F3FB}\u{200D}\u{2642}", emoji: "💁🏻‍♂", name: "man tipping hand: light skin tone", key: ":man_tipping_hand_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F481}\u{1F3FC}\u{200D}\u{2642}", emoji: "💁🏼‍♂", name: "man tipping hand: medium-light skin tone", key: ":man_tipping_hand_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F481}\u{1F3FD}\u{200D}\u{2642}", emoji: "💁🏽‍♂", name: "man tipping hand: medium skin tone", key: ":man_tipping_hand_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F481}\u{1F3FE}\u{200D}\u{2642}", emoji: "💁🏾‍♂", name: "man tipping hand: medium-dark skin tone", key: ":man_tipping_hand_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F481}\u{1F3FF}\u{200D}\u{2642}", emoji: "💁🏿‍♂", name: "man tipping hand: dark skin tone", key: ":man_tipping_hand_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F481}\u{200D}\u{2640}", emoji: "💁‍♀", name: "woman tipping hand", key: ":woman_tipping_hand:", group: "People & Body", },
  { unicode: "\u{1F481}\u{1F3FB}\u{200D}\u{2640}", emoji: "💁🏻‍♀", name: "woman tipping hand: light skin tone", key: ":woman_tipping_hand_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F481}\u{1F3FC}\u{200D}\u{2640}", emoji: "💁🏼‍♀", name: "woman tipping hand: medium-light skin tone", key: ":woman_tipping_hand_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F481}\u{1F3FD}\u{200D}\u{2640}", emoji: "💁🏽‍♀", name: "woman tipping hand: medium skin tone", key: ":woman_tipping_hand_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F481}\u{1F3FE}\u{200D}\u{2640}", emoji: "💁🏾‍♀", name: "woman tipping hand: medium-dark skin tone", key: ":woman_tipping_hand_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F481}\u{1F3FF}\u{200D}\u{2640}", emoji: "💁🏿‍♀", name: "woman tipping hand: dark skin tone", key: ":woman_tipping_hand_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64B}", emoji: "🙋", name: "person raising hand", key: ":person_raising_hand:", group: "People & Body", },
  { unicode: "\u{1F64B}\u{1F3FB}", emoji: "🙋🏻", name: "person raising hand: light skin tone", key: ":person_raising_hand_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64B}\u{1F3FC}", emoji: "🙋🏼", name: "person raising hand: medium-light skin tone", key: ":person_raising_hand_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64B}\u{1F3FD}", emoji: "🙋🏽", name: "person raising hand: medium skin tone", key: ":person_raising_hand_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64B}\u{1F3FE}", emoji: "🙋🏾", name: "person raising hand: medium-dark skin tone", key: ":person_raising_hand_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64B}\u{1F3FF}", emoji: "🙋🏿", name: "person raising hand: dark skin tone", key: ":person_raising_hand_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64B}\u{200D}\u{2642}", emoji: "🙋‍♂", name: "man raising hand", key: ":man_raising_hand:", group: "People & Body", },
  { unicode: "\u{1F64B}\u{1F3FB}\u{200D}\u{2642}", emoji: "🙋🏻‍♂", name: "man raising hand: light skin tone", key: ":man_raising_hand_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64B}\u{1F3FC}\u{200D}\u{2642}", emoji: "🙋🏼‍♂", name: "man raising hand: medium-light skin tone", key: ":man_raising_hand_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64B}\u{1F3FD}\u{200D}\u{2642}", emoji: "🙋🏽‍♂", name: "man raising hand: medium skin tone", key: ":man_raising_hand_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64B}\u{1F3FE}\u{200D}\u{2642}", emoji: "🙋🏾‍♂", name: "man raising hand: medium-dark skin tone", key: ":man_raising_hand_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64B}\u{1F3FF}\u{200D}\u{2642}", emoji: "🙋🏿‍♂", name: "man raising hand: dark skin tone", key: ":man_raising_hand_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64B}\u{200D}\u{2640}", emoji: "🙋‍♀", name: "woman raising hand", key: ":woman_raising_hand:", group: "People & Body", },
  { unicode: "\u{1F64B}\u{1F3FB}\u{200D}\u{2640}", emoji: "🙋🏻‍♀", name: "woman raising hand: light skin tone", key: ":woman_raising_hand_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64B}\u{1F3FC}\u{200D}\u{2640}", emoji: "🙋🏼‍♀", name: "woman raising hand: medium-light skin tone", key: ":woman_raising_hand_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64B}\u{1F3FD}\u{200D}\u{2640}", emoji: "🙋🏽‍♀", name: "woman raising hand: medium skin tone", key: ":woman_raising_hand_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64B}\u{1F3FE}\u{200D}\u{2640}", emoji: "🙋🏾‍♀", name: "woman raising hand: medium-dark skin tone", key: ":woman_raising_hand_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F64B}\u{1F3FF}\u{200D}\u{2640}", emoji: "🙋🏿‍♀", name: "woman raising hand: dark skin tone", key: ":woman_raising_hand_dark_skin_tone:", group: "People & Body", },
  // { unicode: "\u{1F9CF}", emoji: "🧏", name: "deaf person", key: ":deaf_person:", group: "People & Body", },
  { unicode: "\u{1F9CF}\u{1F3FB}", emoji: "🏻", name: "deaf person: light skin tone", key: ":deaf_person_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9CF}\u{1F3FC}", emoji: "🏼", name: "deaf person: medium-light skin tone", key: ":deaf_person_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9CF}\u{1F3FD}", emoji: "🏽", name: "deaf person: medium skin tone", key: ":deaf_person_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9CF}\u{1F3FE}", emoji: "🏾", name: "deaf person: medium-dark skin tone", key: ":deaf_person_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9CF}\u{1F3FF}", emoji: "🏿", name: "deaf person: dark skin tone", key: ":deaf_person_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9CF}\u{200D}\u{2642}", emoji: "‍♂", name: "deaf man", key: ":deaf_man:", group: "People & Body", },
  { unicode: "\u{1F9CF}\u{1F3F}\u{200D}\u{2642}", emoji: "🏻‍♂", name: "deaf man: light skin tone", key: ":deaf_man_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9CF}\u{1F3F}\u{200D}\u{2642}", emoji: "🏼‍♂", name: "deaf man: medium-light skin tone", key: ":deaf_man_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9CF}\u{1F3F}\u{200D}\u{2642}", emoji: "🏽‍♂", name: "deaf man: medium skin tone", key: ":deaf_man_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9CF}\u{1F3F}\u{200D}\u{2642}", emoji: "🏾‍♂", name: "deaf man: medium-dark skin tone", key: ":deaf_man_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9CF}\u{1F3F}\u{200D}\u{2642}", emoji: "🏿‍♂", name: "deaf man: dark skin tone", key: ":deaf_man_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9CF}\u{200D}\u{2640}", emoji: "‍♀", name: "deaf woman", key: ":deaf_woman:", group: "People & Body", },
  { unicode: "\u{1F9CF}\u{1F3FB}\u{200D}\u{2640}", emoji: "🏻‍♀", name: "deaf woman: light skin tone", key: ":deaf_woman_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9CF}\u{1F3FC}\u{200D}\u{2640}", emoji: "🏼‍♀", name: "deaf woman: medium-light skin tone", key: ":deaf_woman_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9CF}\u{1F3FD}\u{200D}\u{2640}", emoji: "🏽‍♀", name: "deaf woman: medium skin tone", key: ":deaf_woman_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9CF}\u{1F3FE}\u{200D}\u{2640}", emoji: "🏾‍♀", name: "deaf woman: medium-dark skin tone", key: ":deaf_woman_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9CF}\u{1F3FF}\u{200D}\u{2640}", emoji: "🏿‍♀", name: "deaf woman: dark skin tone", key: ":deaf_woman_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F647}", emoji: "🙇", name: "person bowing", key: ":person_bowing:", group: "People & Body", },
  { unicode: "\u{1F647}\u{1F3FB}", emoji: "🙇🏻", name: "person bowing: light skin tone", key: ":person_bowing_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F647}\u{1F3FC}", emoji: "🙇🏼", name: "person bowing: medium-light skin tone", key: ":person_bowing_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F647}\u{1F3FD}", emoji: "🙇🏽", name: "person bowing: medium skin tone", key: ":person_bowing_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F647}\u{1F3FE}", emoji: "🙇🏾", name: "person bowing: medium-dark skin tone", key: ":person_bowing_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F647}\u{1F3FF}", emoji: "🙇🏿", name: "person bowing: dark skin tone", key: ":person_bowing_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F647}\u{200D}\u{2642}", emoji: "🙇‍♂", name: "man bowing", key: ":man_bowing:", group: "People & Body", },
  { unicode: "\u{1F647}\u{1F3FB}\u{200D}\u{2642}", emoji: "🙇🏻‍♂", name: "man bowing: light skin tone", key: ":man_bowing_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F647}\u{1F3FC}\u{200D}\u{2642}", emoji: "🙇🏼‍♂", name: "man bowing: medium-light skin tone", key: ":man_bowing_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F647}\u{1F3FD}\u{200D}\u{2642}", emoji: "🙇🏽‍♂", name: "man bowing: medium skin tone", key: ":man_bowing_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F647}\u{1F3FE}\u{200D}\u{2642}", emoji: "🙇🏾‍♂", name: "man bowing: medium-dark skin tone", key: ":man_bowing_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F647}\u{1F3FF}\u{200D}\u{2642}", emoji: "🙇🏿‍♂", name: "man bowing: dark skin tone", key: ":man_bowing_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F647}\u{200D}\u{2640}", emoji: "🙇‍♀", name: "woman bowing", key: ":woman_bowing:", group: "People & Body", },
  { unicode: "\u{1F647}\u{1F3FB}\u{200D}\u{2640}", emoji: "🙇🏻‍♀", name: "woman bowing: light skin tone", key: ":woman_bowing_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F647}\u{1F3FC}\u{200D}\u{2640}", emoji: "🙇🏼‍♀", name: "woman bowing: medium-light skin tone", key: ":woman_bowing_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F647}\u{1F3FD}\u{200D}\u{2640}", emoji: "🙇🏽‍♀", name: "woman bowing: medium skin tone", key: ":woman_bowing_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F647}\u{1F3FE}\u{200D}\u{2640}", emoji: "🙇🏾‍♀", name: "woman bowing: medium-dark skin tone", key: ":woman_bowing_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F647}\u{1F3FF}\u{200D}\u{2640}", emoji: "🙇🏿‍♀", name: "woman bowing: dark skin tone", key: ":woman_bowing_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F926}", emoji: "🤦", name: "person facepalming", key: ":person_facepalming:", group: "People & Body", },
  { unicode: "\u{1F926}\u{1F3FB}", emoji: "🤦🏻", name: "person facepalming: light skin tone", key: ":person_facepalming_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F926}\u{1F3FC}", emoji: "🤦🏼", name: "person facepalming: medium-light skin tone", key: ":person_facepalming_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F926}\u{1F3FD}", emoji: "🤦🏽", name: "person facepalming: medium skin tone", key: ":person_facepalming_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F926}\u{1F3FE}", emoji: "🤦🏾", name: "person facepalming: medium-dark skin tone", key: ":person_facepalming_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F926}\u{1F3FF}", emoji: "🤦🏿", name: "person facepalming: dark skin tone", key: ":person_facepalming_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F926}\u{200D}\u{2642}", emoji: "🤦‍♂", name: "man facepalming", key: ":man_facepalming:", group: "People & Body", },
  { unicode: "\u{1F926}\u{1F3FB}\u{200D}\u{2642}", emoji: "🤦🏻‍♂", name: "man facepalming: light skin tone", key: ":man_facepalming_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F926}\u{1F3FC}\u{200D}\u{2642}", emoji: "🤦🏼‍♂", name: "man facepalming: medium-light skin tone", key: ":man_facepalming_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F926}\u{1F3FD}\u{200D}\u{2642}", emoji: "🤦🏽‍♂", name: "man facepalming: medium skin tone", key: ":man_facepalming_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F926}\u{1F3FE}\u{200D}\u{2642}", emoji: "🤦🏾‍♂", name: "man facepalming: medium-dark skin tone", key: ":man_facepalming_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F926}\u{1F3FF}\u{200D}\u{2642}", emoji: "🤦🏿‍♂", name: "man facepalming: dark skin tone", key: ":man_facepalming_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F926}\u{200D}\u{2640}", emoji: "🤦‍♀", name: "woman facepalming", key: ":woman_facepalming:", group: "People & Body", },
  { unicode: "\u{1F926}\u{1F3FB}\u{200D}\u{2640}", emoji: "🤦🏻‍♀", name: "woman facepalming: light skin tone", key: ":woman_facepalming_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F926}\u{1F3FC}\u{200D}\u{2640}", emoji: "🤦🏼‍♀", name: "woman facepalming: medium-light skin tone", key: ":woman_facepalming_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F926}\u{1F3FD}\u{200D}\u{2640}", emoji: "🤦🏽‍♀", name: "woman facepalming: medium skin tone", key: ":woman_facepalming_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F926}\u{1F3FE}\u{200D}\u{2640}", emoji: "🤦🏾‍♀", name: "woman facepalming: medium-dark skin tone", key: ":woman_facepalming_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F926}\u{1F3FF}\u{200D}\u{2640}", emoji: "🤦🏿‍♀", name: "woman facepalming: dark skin tone", key: ":woman_facepalming_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F937}", emoji: "🤷", name: "person shrugging", key: ":person_shrugging:", group: "People & Body", },
  { unicode: "\u{1F937}\u{1F3FB}", emoji: "🤷🏻", name: "person shrugging: light skin tone", key: ":person_shrugging_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F937}\u{1F3FC}", emoji: "🤷🏼", name: "person shrugging: medium-light skin tone", key: ":person_shrugging_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F937}\u{1F3FD}", emoji: "🤷🏽", name: "person shrugging: medium skin tone", key: ":person_shrugging_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F937}\u{1F3FE}", emoji: "🤷🏾", name: "person shrugging: medium-dark skin tone", key: ":person_shrugging_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F937}\u{1F3FF}", emoji: "🤷🏿", name: "person shrugging: dark skin tone", key: ":person_shrugging_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F937}\u{200D}\u{2642}", emoji: "🤷‍♂️", name: "man shrugging", key: ":man_shrugging:", group: "People & Body", },
  { unicode: "\u{1F937}\u{1F3FB}\u{200D}\u{2642}", emoji: "🤷🏻‍♂", name: "man shrugging: light skin tone", key: ":man_shrugging_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F937}\u{1F3FC}\u{200D}\u{2642}", emoji: "🤷🏼‍♂", name: "man shrugging: medium-light skin tone", key: ":man_shrugging_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F937}\u{1F3FD}\u{200D}\u{2642}", emoji: "🤷🏽‍♂", name: "man shrugging: medium skin tone", key: ":man_shrugging_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F937}\u{1F3FE}\u{200D}\u{2642}", emoji: "🤷🏾‍♂", name: "man shrugging: medium-dark skin tone", key: ":man_shrugging_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F937}\u{1F3FF}\u{200D}\u{2642}", emoji: "🤷🏿‍♂", name: "man shrugging: dark skin tone", key: ":man_shrugging_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F937}\u{200D}\u{2640}", emoji: "🤷‍♀", name: "woman shrugging", key: ":woman_shrugging:", group: "People & Body", },
  { unicode: "\u{1F937}\u{1F3FB}\u{200D}\u{2640}", emoji: "🤷🏻‍♀", name: "woman shrugging: light skin tone", key: ":woman_shrugging_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F937}\u{1F3FC}\u{200D}\u{2640}", emoji: "🤷🏼‍♀", name: "woman shrugging: medium-light skin tone", key: ":woman_shrugging_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F937}\u{1F3FD}\u{200D}\u{2640}", emoji: "🤷🏽‍♀", name: "woman shrugging: medium skin tone", key: ":woman_shrugging_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F937}\u{1F3FE}\u{200D}\u{2640}", emoji: "🤷🏾‍♀", name: "woman shrugging: medium-dark skin tone", key: ":woman_shrugging_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F937}\u{1F3FF}\u{200D}\u{2640}", emoji: "🤷🏿‍♀", name: "woman shrugging: dark skin tone", key: ":woman_shrugging_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{200D}\u{2695}", emoji: "🧑‍⚕", name: "health worker", key: ":health_worker:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FB}\u{200D}\u{2695}", emoji: "🏻‍⚕", name: "health worker: light skin tone", key: ":health_worker_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FC}\u{200D}\u{2695}", emoji: "🏼‍⚕", name: "health worker: medium-light skin tone", key: ":health_worker_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FD}\u{200D}\u{2695}", emoji: "🏽‍⚕", name: "health worker: medium skin tone", key: ":health_worker_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FE}\u{200D}\u{2695}", emoji: "🏾‍⚕", name: "health worker: medium-dark skin tone", key: ":health_worker_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FF}\u{200D}\u{2695}", emoji: "🏿‍⚕", name: "health worker: dark skin tone", key: ":health_worker_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{200D}\u{2695}", emoji: "👨‍⚕", name: "man health worker", key: ":man_health_worker:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FB}\u{200D}\u{2695}", emoji: "👨🏻‍⚕", name: "man health worker: light skin tone", key: ":man_health_worker_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FC}\u{200D}\u{2695}", emoji: "👨🏼‍⚕", name: "man health worker: medium-light skin tone", key: ":man_health_worker_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FD}\u{200D}\u{2695}", emoji: "👨🏽‍⚕", name: "man health worker: medium skin tone", key: ":man_health_worker_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FE}\u{200D}\u{2695}", emoji: "👨🏾‍⚕", name: "man health worker: medium-dark skin tone", key: ":man_health_worker_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FF}\u{200D}\u{2695}", emoji: "👨🏿‍⚕", name: "man health worker: dark skin tone", key: ":man_health_worker_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{200D}\u{2695}", emoji: "👩‍⚕", name: "woman health worker", key: ":woman_health_worker:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FB}\u{200D}\u{2695}", emoji: "👩🏻‍⚕", name: "woman health worker: light skin tone", key: ":woman_health_worker_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FC}\u{200D}\u{2695}", emoji: "👩🏼‍⚕", name: "woman health worker: medium-light skin tone", key: ":woman_health_worker_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FD}\u{200D}\u{2695}", emoji: "👩🏽‍⚕", name: "woman health worker: medium skin tone", key: ":woman_health_worker_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FE}\u{200D}\u{2695}", emoji: "👩🏾‍⚕", name: "woman health worker: medium-dark skin tone", key: ":woman_health_worker_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FF}\u{200D}\u{2695}", emoji: "👩🏿‍⚕", name: "woman health worker: dark skin tone", key: ":woman_health_worker_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{200D}\u{1F393}", emoji: "🧑‍🎓", name: "student", key: ":student:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FB}\u{200D}\u{1F393}", emoji: "🏻‍🎓", name: "student: light skin tone", key: ":student_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FC}\u{200D}\u{1F393}", emoji: "🏼‍🎓", name: "student: medium-light skin tone", key: ":student_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FD}\u{200D}\u{1F393}", emoji: "🏽‍🎓", name: "student: medium skin tone", key: ":student_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FE}\u{200D}\u{1F393}", emoji: "🏾‍🎓", name: "student: medium-dark skin tone", key: ":student_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FF}\u{200D}\u{1F393}", emoji: "🏿‍🎓", name: "student: dark skin tone", key: ":student_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{200D}\u{1F393}", emoji: "👨‍🎓", name: "man student", key: ":man_student:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FB}\u{200D}\u{1F393}", emoji: "👨🏻‍🎓", name: "man student: light skin tone", key: ":man_student_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FC}\u{200D}\u{1F393}", emoji: "👨🏼‍🎓", name: "man student: medium-light skin tone", key: ":man_student_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FD}\u{200D}\u{1F393}", emoji: "👨🏽‍🎓", name: "man student: medium skin tone", key: ":man_student_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FE}\u{200D}\u{1F393}", emoji: "👨🏾‍🎓", name: "man student: medium-dark skin tone", key: ":man_student_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FF}\u{200D}\u{1F393}", emoji: "👨🏿‍🎓", name: "man student: dark skin tone", key: ":man_student_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{200D}\u{1F393}", emoji: "👩‍🎓", name: "woman student", key: ":woman_student:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FB}\u{200D}\u{1F393}", emoji: "👩🏻‍🎓", name: "woman student: light skin tone", key: ":woman_student_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FC}\u{200D}\u{1F393}", emoji: "👩🏼‍🎓", name: "woman student: medium-light skin tone", key: ":woman_student_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FD}\u{200D}\u{1F393}", emoji: "👩🏽‍🎓", name: "woman student: medium skin tone", key: ":woman_student_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FE}\u{200D}\u{1F393}", emoji: "👩🏾‍🎓", name: "woman student: medium-dark skin tone", key: ":woman_student_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FF}\u{200D}\u{1F393}", emoji: "👩🏿‍🎓", name: "woman student: dark skin tone", key: ":woman_student_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{200D}\u{1F3EB}", emoji: "🧑‍🏫", name: "teacher", key: ":teacher:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FB}\u{200D}\u{1F3EB}", emoji: "🧑🏻‍🏫", name: "teacher: light skin tone", key: ":teacher_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FC}\u{200D}\u{1F3EB}", emoji: "🧑🏼‍🏫", name: "teacher: medium-light skin tone", key: ":teacher_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FD}\u{200D}\u{1F3EB}", emoji: "🧑🏽‍🏫", name: "teacher: medium skin tone", key: ":teacher_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FE}\u{200D}\u{1F3EB}", emoji: "🧑🏾‍🏫", name: "teacher: medium-dark skin tone", key: ":teacher_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FF}\u{200D}\u{1F3EB}", emoji: "🧑🏿‍🏫", name: "teacher: dark skin tone", key: ":teacher_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{200D}\u{1F3EB}", emoji: "👨‍🏫", name: "man teacher", key: ":man_teacher:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FB}\u{200D}\u{1F3EB}", emoji: "👨🏻‍🏫", name: "man teacher: light skin tone", key: ":man_teacher_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FC}\u{200D}\u{1F3EB}", emoji: "👨🏼‍🏫", name: "man teacher: medium-light skin tone", key: ":man_teacher_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FD}\u{200D}\u{1F3EB}", emoji: "👨🏽‍🏫", name: "man teacher: medium skin tone", key: ":man_teacher_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FE}\u{200D}\u{1F3EB}", emoji: "👨🏾‍🏫", name: "man teacher: medium-dark skin tone", key: ":man_teacher_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FF}\u{200D}\u{1F3EB}", emoji: "👨🏿‍🏫", name: "man teacher: dark skin tone", key: ":man_teacher_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{200D}\u{1F3EB}", emoji: "👩‍🏫", name: "woman teacher", key: ":woman_teacher:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FB}\u{200D}\u{1F3EB}", emoji: "👩🏻‍🏫", name: "woman teacher: light skin tone", key: ":woman_teacher_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FC}\u{200D}\u{1F3EB}", emoji: "👩🏼‍🏫", name: "woman teacher: medium-light skin tone", key: ":woman_teacher_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FD}\u{200D}\u{1F3EB}", emoji: "👩🏽‍🏫", name: "woman teacher: medium skin tone", key: ":woman_teacher_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FE}\u{200D}\u{1F3EB}", emoji: "👩🏾‍🏫", name: "woman teacher: medium-dark skin tone", key: ":woman_teacher_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FF}\u{200D}\u{1F3EB}", emoji: "👩🏿‍🏫", name: "woman teacher: dark skin tone", key: ":woman_teacher_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{200D}\u{2696}", emoji: "🧑‍⚖", name: "judge", key: ":judge:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FB}\u{200D}\u{2696}", emoji: "🧑🏻‍⚖", name: "judge: light skin tone", key: ":judge_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FC}\u{200D}\u{2696}", emoji: "🧑🏼‍⚖", name: "judge: medium-light skin tone", key: ":judge_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FD}\u{200D}\u{2696}", emoji: "🧑🏽‍⚖", name: "judge: medium skin tone", key: ":judge_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FE}\u{200D}\u{2696}", emoji: "🧑🏾‍⚖", name: "judge: medium-dark skin tone", key: ":judge_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FF}\u{200D}\u{2696}", emoji: "🧑🏿‍⚖", name: "judge: dark skin tone", key: ":judge_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{200D}\u{2696}", emoji: "👨‍⚖", name: "man judge", key: ":man_judge:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FB}\u{200D}\u{2696}", emoji: "👨🏻‍⚖", name: "man judge: light skin tone", key: ":man_judge_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FC}\u{200D}\u{2696}", emoji: "👨🏼‍⚖", name: "man judge: medium-light skin tone", key: ":man_judge_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FD}\u{200D}\u{2696}", emoji: "👨🏽‍⚖", name: "man judge: medium skin tone", key: ":man_judge_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FE}\u{200D}\u{2696}", emoji: "👨🏾‍⚖", name: "man judge: medium-dark skin tone", key: ":man_judge_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FF}\u{200D}\u{2696}", emoji: "👨🏿‍⚖", name: "man judge: dark skin tone", key: ":man_judge_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{200D}\u{2696}", emoji: "👩‍⚖", name: "woman judge", key: ":woman_judge:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FB}\u{200D}\u{2696}", emoji: "👩🏻‍⚖", name: "woman judge: light skin tone", key: ":woman_judge_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FC}\u{200D}\u{2696}", emoji: "👩🏼‍⚖", name: "woman judge: medium-light skin tone", key: ":woman_judge_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FD}\u{200D}\u{2696}", emoji: "👩🏽‍⚖", name: "woman judge: medium skin tone", key: ":woman_judge_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FE}\u{200D}\u{2696}", emoji: "👩🏾‍⚖", name: "woman judge: medium-dark skin tone", key: ":woman_judge_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FF}\u{200D}\u{2696}", emoji: "👩🏿‍⚖", name: "woman judge: dark skin tone", key: ":woman_judge_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{200D}\u{1F33E}", emoji: "🧑‍🌾", name: "farmer", key: ":farmer:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FB}\u{200D}\u{1F33E}", emoji: "🧑🏻‍🌾", name: "farmer: light skin tone", key: ":farmer_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FC}\u{200D}\u{1F33E}", emoji: "🧑🏼‍🌾", name: "farmer: medium-light skin tone", key: ":farmer_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FD}\u{200D}\u{1F33E}", emoji: "🧑🏽‍🌾", name: "farmer: medium skin tone", key: ":farmer_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FE}\u{200D}\u{1F33E}", emoji: "🧑🏾‍🌾", name: "farmer: medium-dark skin tone", key: ":farmer_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FF}\u{200D}\u{1F33E}", emoji: "🧑🏿‍🌾", name: "farmer: dark skin tone", key: ":farmer_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{200D}\u{1F33E}", emoji: "👨‍🌾", name: "man farmer", key: ":man_farmer:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FB}\u{200D}\u{1F33E}", emoji: "👨🏻‍🌾", name: "man farmer: light skin tone", key: ":man_farmer_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FC}\u{200D}\u{1F33E}", emoji: "👨🏼‍🌾", name: "man farmer: medium-light skin tone", key: ":man_farmer_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FD}\u{200D}\u{1F33E}", emoji: "👨🏽‍🌾", name: "man farmer: medium skin tone", key: ":man_farmer_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FE}\u{200D}\u{1F33E}", emoji: "👨🏾‍🌾", name: "man farmer: medium-dark skin tone", key: ":man_farmer_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FF}\u{200D}\u{1F33E}", emoji: "👨🏿‍🌾", name: "man farmer: dark skin tone", key: ":man_farmer_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{200D}\u{1F33E}", emoji: "👩‍🌾", name: "woman farmer", key: ":woman_farmer:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FB}\u{200D}\u{1F33E}", emoji: "👩🏻‍🌾", name: "woman farmer: light skin tone", key: ":woman_farmer_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FC}\u{200D}\u{1F33E}", emoji: "👩🏼‍🌾", name: "woman farmer: medium-light skin tone", key: ":woman_farmer_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FD}\u{200D}\u{1F33E}", emoji: "👩🏽‍🌾", name: "woman farmer: medium skin tone", key: ":woman_farmer_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FE}\u{200D}\u{1F33E}", emoji: "👩🏾‍🌾", name: "woman farmer: medium-dark skin tone", key: ":woman_farmer_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FF}\u{200D}\u{1F33E}", emoji: "👩🏿‍🌾", name: "woman farmer: dark skin tone", key: ":woman_farmer_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{200D}\u{1F373}", emoji: "🧑‍🍳", name: "cook", key: ":cook:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FB}\u{200D}\u{1F373}", emoji: "🧑🏻‍🍳", name: "cook: light skin tone", key: ":cook_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FC}\u{200D}\u{1F373}", emoji: "🧑🏼‍🍳", name: "cook: medium-light skin tone", key: ":cook_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FD}\u{200D}\u{1F373}", emoji: "🧑🏽‍🍳", name: "cook: medium skin tone", key: ":cook_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FE}\u{200D}\u{1F373}", emoji: "🧑🏾‍🍳", name: "cook: medium-dark skin tone", key: ":cook_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FF}\u{200D}\u{1F373}", emoji: "🧑🏿‍🍳", name: "cook: dark skin tone", key: ":cook_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{200D}\u{1F373}", emoji: "👨‍🍳", name: "man cook", key: ":man_cook:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FB}\u{200D}\u{1F373}", emoji: "👨🏻‍🍳", name: "man cook: light skin tone", key: ":man_cook_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FC}\u{200D}\u{1F373}", emoji: "👨🏼‍🍳", name: "man cook: medium-light skin tone", key: ":man_cook_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FD}\u{200D}\u{1F373}", emoji: "👨🏽‍🍳", name: "man cook: medium skin tone", key: ":man_cook_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FE}\u{200D}\u{1F373}", emoji: "👨🏾‍🍳", name: "man cook: medium-dark skin tone", key: ":man_cook_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FF}\u{200D}\u{1F373}", emoji: "👨🏿‍🍳", name: "man cook: dark skin tone", key: ":man_cook_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{200D}\u{1F373}", emoji: "👩‍🍳", name: "woman cook", key: ":woman_cook:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FB}\u{200D}\u{1F373}", emoji: "👩🏻‍🍳", name: "woman cook: light skin tone", key: ":woman_cook_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FC}\u{200D}\u{1F373}", emoji: "👩🏼‍🍳", name: "woman cook: medium-light skin tone", key: ":woman_cook_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FD}\u{200D}\u{1F373}", emoji: "👩🏽‍🍳", name: "woman cook: medium skin tone", key: ":woman_cook_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FE}\u{200D}\u{1F373}", emoji: "👩🏾‍🍳", name: "woman cook: medium-dark skin tone", key: ":woman_cook_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FF}\u{200D}\u{1F373}", emoji: "👩🏿‍🍳", name: "woman cook: dark skin tone", key: ":woman_cook_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{200D}\u{1F527}", emoji: "🧑‍🔧", name: "mechanic", key: ":mechanic:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FB}\u{200D}\u{1F527}", emoji: "🧑🏻‍🔧", name: "mechanic: light skin tone", key: ":mechanic_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FC}\u{200D}\u{1F527}", emoji: "🧑🏼‍🔧", name: "mechanic: medium-light skin tone", key: ":mechanic_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FD}\u{200D}\u{1F527}", emoji: "🧑🏽‍🔧", name: "mechanic: medium skin tone", key: ":mechanic_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FE}\u{200D}\u{1F527}", emoji: "🧑🏾‍🔧", name: "mechanic: medium-dark skin tone", key: ":mechanic_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FF}\u{200D}\u{1F527}", emoji: "🧑🏿‍🔧", name: "mechanic: dark skin tone", key: ":mechanic_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{200D}\u{1F527}", emoji: "👨‍🔧", name: "man mechanic", key: ":man_mechanic:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FB}\u{200D}\u{1F527}", emoji: "👨🏻‍🔧", name: "man mechanic: light skin tone", key: ":man_mechanic_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FC}\u{200D}\u{1F527}", emoji: "👨🏼‍🔧", name: "man mechanic: medium-light skin tone", key: ":man_mechanic_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FD}\u{200D}\u{1F527}", emoji: "👨🏽‍🔧", name: "man mechanic: medium skin tone", key: ":man_mechanic_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FE}\u{200D}\u{1F527}", emoji: "👨🏾‍🔧", name: "man mechanic: medium-dark skin tone", key: ":man_mechanic_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FF}\u{200D}\u{1F527}", emoji: "👨🏿‍🔧", name: "man mechanic: dark skin tone", key: ":man_mechanic_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{200D}\u{1F527}", emoji: "👩‍🔧", name: "woman mechanic", key: ":woman_mechanic:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FB}\u{200D}\u{1F527}", emoji: "👩🏻‍🔧", name: "woman mechanic: light skin tone", key: ":woman_mechanic_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FC}\u{200D}\u{1F527}", emoji: "👩🏼‍🔧", name: "woman mechanic: medium-light skin tone", key: ":woman_mechanic_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FD}\u{200D}\u{1F527}", emoji: "👩🏽‍🔧", name: "woman mechanic: medium skin tone", key: ":woman_mechanic_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FE}\u{200D}\u{1F527}", emoji: "👩🏾‍🔧", name: "woman mechanic: medium-dark skin tone", key: ":woman_mechanic_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FF}\u{200D}\u{1F527}", emoji: "👩🏿‍🔧", name: "woman mechanic: dark skin tone", key: ":woman_mechanic_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{200D}\u{1F3ED}", emoji: "🧑‍🏭", name: "factory worker", key: ":factory_worker:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FB}\u{200D}\u{1F3ED}", emoji: "🧑🏻‍🏭", name: "factory worker: light skin tone", key: ":factory_worker_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FC}\u{200D}\u{1F3ED}", emoji: "🧑🏼‍🏭", name: "factory worker: medium-light skin tone", key: ":factory_worker_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FD}\u{200D}\u{1F3ED}", emoji: "🧑🏽‍🏭", name: "factory worker: medium skin tone", key: ":factory_worker_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FE}\u{200D}\u{1F3ED}", emoji: "🧑🏾‍🏭", name: "factory worker: medium-dark skin tone", key: ":factory_worker_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FF}\u{200D}\u{1F3ED}", emoji: "🧑🏿‍🏭", name: "factory worker: dark skin tone", key: ":factory_worker_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{200D}\u{1F3ED}", emoji: "👨‍🏭", name: "man factory worker", key: ":man_factory_worker:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FB}\u{200D}\u{1F3ED}", emoji: "👨🏻‍🏭", name: "man factory worker: light skin tone", key: ":man_factory_worker_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FC}\u{200D}\u{1F3ED}", emoji: "👨🏼‍🏭", name: "man factory worker: medium-light skin tone", key: ":man_factory_worker_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FD}\u{200D}\u{1F3ED}", emoji: "👨🏽‍🏭", name: "man factory worker: medium skin tone", key: ":man_factory_worker_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FE}\u{200D}\u{1F3ED}", emoji: "👨🏾‍🏭", name: "man factory worker: medium-dark skin tone", key: ":man_factory_worker_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FF}\u{200D}\u{1F3ED}", emoji: "👨🏿‍🏭", name: "man factory worker: dark skin tone", key: ":man_factory_worker_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{200D}\u{1F3ED}", emoji: "👩‍🏭", name: "woman factory worker", key: ":woman_factory_worker:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FB}\u{200D}\u{1F3ED}", emoji: "👩🏻‍🏭", name: "woman factory worker: light skin tone", key: ":woman_factory_worker_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FC}\u{200D}\u{1F3ED}", emoji: "👩🏼‍🏭", name: "woman factory worker: medium-light skin tone", key: ":woman_factory_worker_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FD}\u{200D}\u{1F3ED}", emoji: "👩🏽‍🏭", name: "woman factory worker: medium skin tone", key: ":woman_factory_worker_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FE}\u{200D}\u{1F3ED}", emoji: "👩🏾‍🏭", name: "woman factory worker: medium-dark skin tone", key: ":woman_factory_worker_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FF}\u{200D}\u{1F3ED}", emoji: "👩🏿‍🏭", name: "woman factory worker: dark skin tone", key: ":woman_factory_worker_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{200D}\u{1F4BC}", emoji: "🧑‍💼", name: "office worker", key: ":office_worker:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FB}\u{200D}\u{1F4BC}", emoji: "🧑🏻‍💼", name: "office worker: light skin tone", key: ":office_worker_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FC}\u{200D}\u{1F4BC}", emoji: "🧑🏼‍💼", name: "office worker: medium-light skin tone", key: ":office_worker_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FD}\u{200D}\u{1F4BC}", emoji: "🧑🏽‍💼", name: "office worker: medium skin tone", key: ":office_worker_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FE}\u{200D}\u{1F4BC}", emoji: "🧑🏾‍💼", name: "office worker: medium-dark skin tone", key: ":office_worker_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FF}\u{200D}\u{1F4BC}", emoji: "🧑🏿‍💼", name: "office worker: dark skin tone", key: ":office_worker_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{200D}\u{1F4BC}", emoji: "👨‍💼", name: "man office worker", key: ":man_office_worker:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FB}\u{200D}\u{1F4BC}", emoji: "👨🏻‍💼", name: "man office worker: light skin tone", key: ":man_office_worker_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FC}\u{200D}\u{1F4BC}", emoji: "👨🏼‍💼", name: "man office worker: medium-light skin tone", key: ":man_office_worker_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FD}\u{200D}\u{1F4BC}", emoji: "👨🏽‍💼", name: "man office worker: medium skin tone", key: ":man_office_worker_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FE}\u{200D}\u{1F4BC}", emoji: "👨🏾‍💼", name: "man office worker: medium-dark skin tone", key: ":man_office_worker_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FF}\u{200D}\u{1F4BC}", emoji: "👨🏿‍💼", name: "man office worker: dark skin tone", key: ":man_office_worker_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{200D}\u{1F4BC}", emoji: "👩‍💼", name: "woman office worker", key: ":woman_office_worker:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FB}\u{200D}\u{1F4BC}", emoji: "👩🏻‍💼", name: "woman office worker: light skin tone", key: ":woman_office_worker_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FC}\u{200D}\u{1F4BC}", emoji: "👩🏼‍💼", name: "woman office worker: medium-light skin tone", key: ":woman_office_worker_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FD}\u{200D}\u{1F4BC}", emoji: "👩🏽‍💼", name: "woman office worker: medium skin tone", key: ":woman_office_worker_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FE}\u{200D}\u{1F4BC}", emoji: "👩🏾‍💼", name: "woman office worker: medium-dark skin tone", key: ":woman_office_worker_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FF}\u{200D}\u{1F4BC}", emoji: "👩🏿‍💼", name: "woman office worker: dark skin tone", key: ":woman_office_worker_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{200D}\u{1F52C}", emoji: "🧑‍🔬", name: "scientist", key: ":scientist:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FB}\u{200D}\u{1F52C}", emoji: "🧑🏻‍🔬", name: "scientist: light skin tone", key: ":scientist_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FC}\u{200D}\u{1F52C}", emoji: "🧑🏼‍🔬", name: "scientist: medium-light skin tone", key: ":scientist_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FD}\u{200D}\u{1F52C}", emoji: "🧑🏽‍🔬", name: "scientist: medium skin tone", key: ":scientist_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FE}\u{200D}\u{1F52C}", emoji: "🧑🏾‍🔬", name: "scientist: medium-dark skin tone", key: ":scientist_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FF}\u{200D}\u{1F52C}", emoji: "🧑🏿‍🔬", name: "scientist: dark skin tone", key: ":scientist_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{200D}\u{1F52C}", emoji: "👨‍🔬", name: "man scientist", key: ":man_scientist:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FB}\u{200D}\u{1F52C}", emoji: "👨🏻‍🔬", name: "man scientist: light skin tone", key: ":man_scientist_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FC}\u{200D}\u{1F52C}", emoji: "👨🏼‍🔬", name: "man scientist: medium-light skin tone", key: ":man_scientist_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FD}\u{200D}\u{1F52C}", emoji: "👨🏽‍🔬", name: "man scientist: medium skin tone", key: ":man_scientist_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FE}\u{200D}\u{1F52C}", emoji: "👨🏾‍🔬", name: "man scientist: medium-dark skin tone", key: ":man_scientist_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FF}\u{200D}\u{1F52C}", emoji: "👨🏿‍🔬", name: "man scientist: dark skin tone", key: ":man_scientist_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{200D}\u{1F52C}", emoji: "👩‍🔬", name: "woman scientist", key: ":woman_scientist:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FB}\u{200D}\u{1F52C}", emoji: "👩🏻‍🔬", name: "woman scientist: light skin tone", key: ":woman_scientist_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FC}\u{200D}\u{1F52C}", emoji: "👩🏼‍🔬", name: "woman scientist: medium-light skin tone", key: ":woman_scientist_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FD}\u{200D}\u{1F52C}", emoji: "👩🏽‍🔬", name: "woman scientist: medium skin tone", key: ":woman_scientist_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FE}\u{200D}\u{1F52C}", emoji: "👩🏾‍🔬", name: "woman scientist: medium-dark skin tone", key: ":woman_scientist_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FF}\u{200D}\u{1F52C}", emoji: "👩🏿‍🔬", name: "woman scientist: dark skin tone", key: ":woman_scientist_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{200D}\u{1F4BB}", emoji: "🧑‍💻", name: "technologist", key: ":technologist:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FB}\u{200D}\u{1F4BB}", emoji: "🧑🏻‍💻", name: "technologist: light skin tone", key: ":technologist_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FC}\u{200D}\u{1F4BB}", emoji: "🧑🏼‍💻", name: "technologist: medium-light skin tone", key: ":technologist_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FD}\u{200D}\u{1F4BB}", emoji: "🧑🏽‍💻", name: "technologist: medium skin tone", key: ":technologist_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FE}\u{200D}\u{1F4BB}", emoji: "🧑🏾‍💻", name: "technologist: medium-dark skin tone", key: ":technologist_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FF}\u{200D}\u{1F4BB}", emoji: "🧑🏿‍💻", name: "technologist: dark skin tone", key: ":technologist_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{200D}\u{1F4BB}", emoji: "👨‍💻", name: "man technologist", key: ":man_technologist:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FB}\u{200D}\u{1F4BB}", emoji: "👨🏻‍💻", name: "man technologist: light skin tone", key: ":man_technologist_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FC}\u{200D}\u{1F4BB}", emoji: "👨🏼‍💻", name: "man technologist: medium-light skin tone", key: ":man_technologist_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FD}\u{200D}\u{1F4BB}", emoji: "👨🏽‍💻", name: "man technologist: medium skin tone", key: ":man_technologist_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FE}\u{200D}\u{1F4BB}", emoji: "👨🏾‍💻", name: "man technologist: medium-dark skin tone", key: ":man_technologist_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FF}\u{200D}\u{1F4BB}", emoji: "👨🏿‍💻", name: "man technologist: dark skin tone", key: ":man_technologist_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{200D}\u{1F4BB}", emoji: "👩‍💻", name: "woman technologist", key: ":woman_technologist:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FB}\u{200D}\u{1F4BB}", emoji: "👩🏻‍💻", name: "woman technologist: light skin tone", key: ":woman_technologist_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FC}\u{200D}\u{1F4BB}", emoji: "👩🏼‍💻", name: "woman technologist: medium-light skin tone", key: ":woman_technologist_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FD}\u{200D}\u{1F4BB}", emoji: "👩🏽‍💻", name: "woman technologist: medium skin tone", key: ":woman_technologist_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FE}\u{200D}\u{1F4BB}", emoji: "👩🏾‍💻", name: "woman technologist: medium-dark skin tone", key: ":woman_technologist_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FF}\u{200D}\u{1F4BB}", emoji: "👩🏿‍💻", name: "woman technologist: dark skin tone", key: ":woman_technologist_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{200D}\u{1F3A4}", emoji: "🧑‍🎤", name: "singer", key: ":singer:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FB}\u{200D}\u{1F3A4}", emoji: "🧑🏻‍🎤", name: "singer: light skin tone", key: ":singer_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FC}\u{200D}\u{1F3A4}", emoji: "🧑🏼‍🎤", name: "singer: medium-light skin tone", key: ":singer_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FD}\u{200D}\u{1F3A4}", emoji: "🧑🏽‍🎤", name: "singer: medium skin tone", key: ":singer_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FE}\u{200D}\u{1F3A4}", emoji: "🧑🏾‍🎤", name: "singer: medium-dark skin tone", key: ":singer_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FF}\u{200D}\u{1F3A4}", emoji: "🧑🏿‍🎤", name: "singer: dark skin tone", key: ":singer_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{200D}\u{1F3A4}", emoji: "👨‍🎤", name: "man singer", key: ":man_singer:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FB}\u{200D}\u{1F3A4}", emoji: "👨🏻‍🎤", name: "man singer: light skin tone", key: ":man_singer_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FC}\u{200D}\u{1F3A4}", emoji: "👨🏼‍🎤", name: "man singer: medium-light skin tone", key: ":man_singer_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FD}\u{200D}\u{1F3A4}", emoji: "👨🏽‍🎤", name: "man singer: medium skin tone", key: ":man_singer_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FE}\u{200D}\u{1F3A4}", emoji: "👨🏾‍🎤", name: "man singer: medium-dark skin tone", key: ":man_singer_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FF}\u{200D}\u{1F3A4}", emoji: "👨🏿‍🎤", name: "man singer: dark skin tone", key: ":man_singer_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{200D}\u{1F3A4}", emoji: "👩‍🎤", name: "woman singer", key: ":woman_singer:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FB}\u{200D}\u{1F3A4}", emoji: "👩🏻‍🎤", name: "woman singer: light skin tone", key: ":woman_singer_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FC}\u{200D}\u{1F3A4}", emoji: "👩🏼‍🎤", name: "woman singer: medium-light skin tone", key: ":woman_singer_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FD}\u{200D}\u{1F3A4}", emoji: "👩🏽‍🎤", name: "woman singer: medium skin tone", key: ":woman_singer_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FE}\u{200D}\u{1F3A4}", emoji: "👩🏾‍🎤", name: "woman singer: medium-dark skin tone", key: ":woman_singer_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FF}\u{200D}\u{1F3A4}", emoji: "👩🏿‍🎤", name: "woman singer: dark skin tone", key: ":woman_singer_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{200D}\u{1F3A8}", emoji: "🧑‍🎨", name: "artist", key: ":artist:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FB}\u{200D}\u{1F3A8}", emoji: "🧑🏻‍🎨", name: "artist: light skin tone", key: ":artist_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FC}\u{200D}\u{1F3A8}", emoji: "🧑🏼‍🎨", name: "artist: medium-light skin tone", key: ":artist_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FD}\u{200D}\u{1F3A8}", emoji: "🧑🏽‍🎨", name: "artist: medium skin tone", key: ":artist_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FE}\u{200D}\u{1F3A8}", emoji: "🧑🏾‍🎨", name: "artist: medium-dark skin tone", key: ":artist_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FF}\u{200D}\u{1F3A8}", emoji: "🧑🏿‍🎨", name: "artist: dark skin tone", key: ":artist_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{200D}\u{1F3A8}", emoji: "👨‍🎨", name: "man artist", key: ":man_artist:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FB}\u{200D}\u{1F3A8}", emoji: "👨🏻‍🎨", name: "man artist: light skin tone", key: ":man_artist_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FC}\u{200D}\u{1F3A8}", emoji: "👨🏼‍🎨", name: "man artist: medium-light skin tone", key: ":man_artist_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FD}\u{200D}\u{1F3A8}", emoji: "👨🏽‍🎨", name: "man artist: medium skin tone", key: ":man_artist_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FE}\u{200D}\u{1F3A8}", emoji: "👨🏾‍🎨", name: "man artist: medium-dark skin tone", key: ":man_artist_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FF}\u{200D}\u{1F3A8}", emoji: "👨🏿‍🎨", name: "man artist: dark skin tone", key: ":man_artist_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{200D}\u{1F3A8}", emoji: "👩‍🎨", name: "woman artist", key: ":woman_artist:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FB}\u{200D}\u{1F3A8}", emoji: "👩🏻‍🎨", name: "woman artist: light skin tone", key: ":woman_artist_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FC}\u{200D}\u{1F3A8}", emoji: "👩🏼‍🎨", name: "woman artist: medium-light skin tone", key: ":woman_artist_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FD}\u{200D}\u{1F3A8}", emoji: "👩🏽‍🎨", name: "woman artist: medium skin tone", key: ":woman_artist_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FE}\u{200D}\u{1F3A8}", emoji: "👩🏾‍🎨", name: "woman artist: medium-dark skin tone", key: ":woman_artist_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FF}\u{200D}\u{1F3A8}", emoji: "👩🏿‍🎨", name: "woman artist: dark skin tone", key: ":woman_artist_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{200D}\u{2708}", emoji: "🧑‍✈", name: "pilot", key: ":pilot:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FB}\u{200D}\u{2708}", emoji: "🧑🏻‍✈", name: "pilot: light skin tone", key: ":pilot_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FC}\u{200D}\u{2708}", emoji: "🧑🏼‍✈", name: "pilot: medium-light skin tone", key: ":pilot_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FD}\u{200D}\u{2708}", emoji: "🧑🏽‍✈", name: "pilot: medium skin tone", key: ":pilot_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FE}\u{200D}\u{2708}", emoji: "🧑🏾‍✈", name: "pilot: medium-dark skin tone", key: ":pilot_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FF}\u{200D}\u{2708}", emoji: "🧑🏿‍✈", name: "pilot: dark skin tone", key: ":pilot_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{200D}\u{2708}", emoji: "👨‍✈", name: "man pilot", key: ":man_pilot:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FB}\u{200D}\u{2708}", emoji: "👨🏻‍✈", name: "man pilot: light skin tone", key: ":man_pilot_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FC}\u{200D}\u{2708}", emoji: "👨🏼‍✈", name: "man pilot: medium-light skin tone", key: ":man_pilot_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FD}\u{200D}\u{2708}", emoji: "👨🏽‍✈", name: "man pilot: medium skin tone", key: ":man_pilot_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FE}\u{200D}\u{2708}", emoji: "👨🏾‍✈", name: "man pilot: medium-dark skin tone", key: ":man_pilot_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FF}\u{200D}\u{2708}", emoji: "👨🏿‍✈", name: "man pilot: dark skin tone", key: ":man_pilot_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{200D}\u{2708}", emoji: "👩‍✈", name: "woman pilot", key: ":woman_pilot:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FB}\u{200D}\u{2708}", emoji: "👩🏻‍✈", name: "woman pilot: light skin tone", key: ":woman_pilot_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FC}\u{200D}\u{2708}", emoji: "👩🏼‍✈", name: "woman pilot: medium-light skin tone", key: ":woman_pilot_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FD}\u{200D}\u{2708}", emoji: "👩🏽‍✈", name: "woman pilot: medium skin tone", key: ":woman_pilot_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FE}\u{200D}\u{2708}", emoji: "👩🏾‍✈", name: "woman pilot: medium-dark skin tone", key: ":woman_pilot_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FF}\u{200D}\u{2708}", emoji: "👩🏿‍✈", name: "woman pilot: dark skin tone", key: ":woman_pilot_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{200D}\u{1F680}", emoji: "🧑‍🚀", name: "astronaut", key: ":astronaut:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FB}\u{200D}\u{1F680}", emoji: "🧑🏻‍🚀", name: "astronaut: light skin tone", key: ":astronaut_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FC}\u{200D}\u{1F680}", emoji: "🧑🏼‍🚀", name: "astronaut: medium-light skin tone", key: ":astronaut_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FD}\u{200D}\u{1F680}", emoji: "🧑🏽‍🚀", name: "astronaut: medium skin tone", key: ":astronaut_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FE}\u{200D}\u{1F680}", emoji: "🧑🏾‍🚀", name: "astronaut: medium-dark skin tone", key: ":astronaut_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FF}\u{200D}\u{1F680}", emoji: "🧑🏿‍🚀", name: "astronaut: dark skin tone", key: ":astronaut_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{200D}\u{1F680}", emoji: "👨‍🚀", name: "man astronaut", key: ":man_astronaut:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FB}\u{200D}\u{1F680}", emoji: "👨🏻‍🚀", name: "man astronaut: light skin tone", key: ":man_astronaut_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FC}\u{200D}\u{1F680}", emoji: "👨🏼‍🚀", name: "man astronaut: medium-light skin tone", key: ":man_astronaut_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FD}\u{200D}\u{1F680}", emoji: "👨🏽‍🚀", name: "man astronaut: medium skin tone", key: ":man_astronaut_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FE}\u{200D}\u{1F680}", emoji: "👨🏾‍🚀", name: "man astronaut: medium-dark skin tone", key: ":man_astronaut_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FF}\u{200D}\u{1F680}", emoji: "👨🏿‍🚀", name: "man astronaut: dark skin tone", key: ":man_astronaut_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{200D}\u{1F680}", emoji: "👩‍🚀", name: "woman astronaut", key: ":woman_astronaut:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FB}\u{200D}\u{1F680}", emoji: "👩🏻‍🚀", name: "woman astronaut: light skin tone", key: ":woman_astronaut_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FC}\u{200D}\u{1F680}", emoji: "👩🏼‍🚀", name: "woman astronaut: medium-light skin tone", key: ":woman_astronaut_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FD}\u{200D}\u{1F680}", emoji: "👩🏽‍🚀", name: "woman astronaut: medium skin tone", key: ":woman_astronaut_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FE}\u{200D}\u{1F680}", emoji: "👩🏾‍🚀", name: "woman astronaut: medium-dark skin tone", key: ":woman_astronaut_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FF}\u{200D}\u{1F680}", emoji: "👩🏿‍🚀", name: "woman astronaut: dark skin tone", key: ":woman_astronaut_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{200D}\u{1F692}", emoji: "🧑‍🚒", name: "firefighter", key: ":firefighter:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FB}\u{200D}\u{1F692}", emoji: "🧑🏻‍🚒", name: "firefighter: light skin tone", key: ":firefighter_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FC}\u{200D}\u{1F692}", emoji: "🧑🏼‍🚒", name: "firefighter: medium-light skin tone", key: ":firefighter_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FD}\u{200D}\u{1F692}", emoji: "🧑🏽‍🚒", name: "firefighter: medium skin tone", key: ":firefighter_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FE}\u{200D}\u{1F692}", emoji: "🧑🏾‍🚒", name: "firefighter: medium-dark skin tone", key: ":firefighter_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FF}\u{200D}\u{1F692}", emoji: "🧑🏿‍🚒", name: "firefighter: dark skin tone", key: ":firefighter_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{200D}\u{1F692}", emoji: "👨‍🚒", name: "man firefighter", key: ":man_firefighter:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FB}\u{200D}\u{1F692}", emoji: "👨🏻‍🚒", name: "man firefighter: light skin tone", key: ":man_firefighter_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FC}\u{200D}\u{1F692}", emoji: "👨🏼‍🚒", name: "man firefighter: medium-light skin tone", key: ":man_firefighter_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FD}\u{200D}\u{1F692}", emoji: "👨🏽‍🚒", name: "man firefighter: medium skin tone", key: ":man_firefighter_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FE}\u{200D}\u{1F692}", emoji: "👨🏾‍🚒", name: "man firefighter: medium-dark skin tone", key: ":man_firefighter_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FF}\u{200D}\u{1F692}", emoji: "👨🏿‍🚒", name: "man firefighter: dark skin tone", key: ":man_firefighter_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{200D}\u{1F692}", emoji: "👩‍🚒", name: "woman firefighter", key: ":woman_firefighter:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FB}\u{200D}\u{1F692}", emoji: "👩🏻‍🚒", name: "woman firefighter: light skin tone", key: ":woman_firefighter_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FC}\u{200D}\u{1F692}", emoji: "👩🏼‍🚒", name: "woman firefighter: medium-light skin tone", key: ":woman_firefighter_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FD}\u{200D}\u{1F692}", emoji: "👩🏽‍🚒", name: "woman firefighter: medium skin tone", key: ":woman_firefighter_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FE}\u{200D}\u{1F692}", emoji: "👩🏾‍🚒", name: "woman firefighter: medium-dark skin tone", key: ":woman_firefighter_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FF}\u{200D}\u{1F692}", emoji: "👩🏿‍🚒", name: "woman firefighter: dark skin tone", key: ":woman_firefighter_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F46E}", emoji: "👮", name: "police officer", key: ":police_officer:", group: "People & Body", },
  { unicode: "\u{1F46E}\u{1F3FB}", emoji: "👮🏻", name: "police officer: light skin tone", key: ":police_officer_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F46E}\u{1F3FC}", emoji: "👮🏼", name: "police officer: medium-light skin tone", key: ":police_officer_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F46E}\u{1F3FD}", emoji: "👮🏽", name: "police officer: medium skin tone", key: ":police_officer_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F46E}\u{1F3FE}", emoji: "👮🏾", name: "police officer: medium-dark skin tone", key: ":police_officer_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F46E}\u{1F3FF}", emoji: "👮🏿", name: "police officer: dark skin tone", key: ":police_officer_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F46E}\u{200D}\u{2642}", emoji: "👮‍♂", name: "man police officer", key: ":man_police_officer:", group: "People & Body", },
  { unicode: "\u{1F46E}\u{1F3FB}\u{200D}\u{2642}", emoji: "👮🏻‍♂", name: "man police officer: light skin tone", key: ":man_police_officer_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F46E}\u{1F3FC}\u{200D}\u{2642}", emoji: "👮🏼‍♂", name: "man police officer: medium-light skin tone", key: ":man_police_officer_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F46E}\u{1F3FD}\u{200D}\u{2642}", emoji: "👮🏽‍♂", name: "man police officer: medium skin tone", key: ":man_police_officer_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F46E}\u{1F3FE}\u{200D}\u{2642}", emoji: "👮🏾‍♂", name: "man police officer: medium-dark skin tone", key: ":man_police_officer_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F46E}\u{1F3FF}\u{200D}\u{2642}", emoji: "👮🏿‍♂", name: "man police officer: dark skin tone", key: ":man_police_officer_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F46E}\u{200D}\u{2640}", emoji: "👮‍♀", name: "woman police officer", key: ":woman_police_officer:", group: "People & Body", },
  { unicode: "\u{1F46E}\u{1F3FB}\u{200D}\u{2640}", emoji: "👮🏻‍♀", name: "woman police officer: light skin tone", key: ":woman_police_officer_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F46E}\u{1F3FC}\u{200D}\u{2640}", emoji: "👮🏼‍♀", name: "woman police officer: medium-light skin tone", key: ":woman_police_officer_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F46E}\u{1F3FD}\u{200D}\u{2640}", emoji: "👮🏽‍♀", name: "woman police officer: medium skin tone", key: ":woman_police_officer_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F46E}\u{1F3FE}\u{200D}\u{2640}", emoji: "👮🏾‍♀", name: "woman police officer: medium-dark skin tone", key: ":woman_police_officer_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F46E}\u{1F3FF}\u{200D}\u{2640}", emoji: "👮🏿‍♀", name: "woman police officer: dark skin tone", key: ":woman_police_officer_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F575}\u{FE0F}", emoji: "🕵️", name: "detective", key: ":detective:", group: "People & Body", },
  { unicode: "\u{1F575}\u{1F3FB}", emoji: "🕵🏻", name: "detective: light skin tone", key: ":detective_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F575}\u{1F3FC}", emoji: "🕵🏼", name: "detective: medium-light skin tone", key: ":detective_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F575}\u{1F3FD}", emoji: "🕵🏽", name: "detective: medium skin tone", key: ":detective_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F575}\u{1F3FE}", emoji: "🕵🏾", name: "detective: medium-dark skin tone", key: ":detective_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F575}\u{1F3FF}", emoji: "🕵🏿", name: "detective: dark skin tone", key: ":detective_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F575}\u{200D}\u{2642}", emoji: "🕵‍♂", name: "man detective", key: ":man_detective:", group: "People & Body", },
  { unicode: "\u{1F575}\u{1F3FB}\u{200D}\u{2642}", emoji: "🕵🏻‍♂", name: "man detective: light skin tone", key: ":man_detective_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F575}\u{1F3FC}\u{200D}\u{2642}", emoji: "🕵🏼‍♂", name: "man detective: medium-light skin tone", key: ":man_detective_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F575}\u{1F3FD}\u{200D}\u{2642}", emoji: "🕵🏽‍♂", name: "man detective: medium skin tone", key: ":man_detective_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F575}\u{1F3FE}\u{200D}\u{2642}", emoji: "🕵🏾‍♂", name: "man detective: medium-dark skin tone", key: ":man_detective_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F575}\u{1F3FF}\u{200D}\u{2642}", emoji: "🕵🏿‍♂", name: "man detective: dark skin tone", key: ":man_detective_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F575}\u{200D}\u{2640}", emoji: "🕵‍♀", name: "woman detective", key: ":woman_detective:", group: "People & Body", },
  { unicode: "\u{1F575}\u{1F3FB}\u{200D}\u{2640}", emoji: "🕵🏻‍♀", name: "woman detective: light skin tone", key: ":woman_detective_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F575}\u{1F3FC}\u{200D}\u{2640}", emoji: "🕵🏼‍♀", name: "woman detective: medium-light skin tone", key: ":woman_detective_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F575}\u{1F3FD}\u{200D}\u{2640}", emoji: "🕵🏽‍♀", name: "woman detective: medium skin tone", key: ":woman_detective_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F575}\u{1F3FE}\u{200D}\u{2640}", emoji: "🕵🏾‍♀", name: "woman detective: medium-dark skin tone", key: ":woman_detective_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F575}\u{1F3FF}\u{200D}\u{2640}", emoji: "🕵🏿‍♀", name: "woman detective: dark skin tone", key: ":woman_detective_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F482}", emoji: "💂", name: "guard", key: ":guard:", group: "People & Body", },
  { unicode: "\u{1F482}\u{1F3FB}", emoji: "💂🏻", name: "guard: light skin tone", key: ":guard_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F482}\u{1F3FC}", emoji: "💂🏼", name: "guard: medium-light skin tone", key: ":guard_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F482}\u{1F3FD}", emoji: "💂🏽", name: "guard: medium skin tone", key: ":guard_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F482}\u{1F3FE}", emoji: "💂🏾", name: "guard: medium-dark skin tone", key: ":guard_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F482}\u{1F3FF}", emoji: "💂🏿", name: "guard: dark skin tone", key: ":guard_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F482}\u{200D}\u{2642}", emoji: "💂‍♂", name: "man guard", key: ":man_guard:", group: "People & Body", },
  { unicode: "\u{1F482}\u{1F3FB}\u{200D}\u{2642}", emoji: "💂🏻‍♂", name: "man guard: light skin tone", key: ":man_guard_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F482}\u{1F3FC}\u{200D}\u{2642}", emoji: "💂🏼‍♂", name: "man guard: medium-light skin tone", key: ":man_guard_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F482}\u{1F3FD}\u{200D}\u{2642}", emoji: "💂🏽‍♂", name: "man guard: medium skin tone", key: ":man_guard_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F482}\u{1F3FE}\u{200D}\u{2642}", emoji: "💂🏾‍♂", name: "man guard: medium-dark skin tone", key: ":man_guard_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F482}\u{1F3FF}\u{200D}\u{2642}", emoji: "💂🏿‍♂", name: "man guard: dark skin tone", key: ":man_guard_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F482}\u{200D}\u{2640}", emoji: "💂‍♀", name: "woman guard", key: ":woman_guard:", group: "People & Body", },
  { unicode: "\u{1F482}\u{1F3FB}\u{200D}\u{2640}", emoji: "💂🏻‍♀", name: "woman guard: light skin tone", key: ":woman_guard_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F482}\u{1F3FC}\u{200D}\u{2640}", emoji: "💂🏼‍♀", name: "woman guard: medium-light skin tone", key: ":woman_guard_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F482}\u{1F3FD}\u{200D}\u{2640}", emoji: "💂🏽‍♀", name: "woman guard: medium skin tone", key: ":woman_guard_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F482}\u{1F3FE}\u{200D}\u{2640}", emoji: "💂🏾‍♀", name: "woman guard: medium-dark skin tone", key: ":woman_guard_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F482}\u{1F3FF}\u{200D}\u{2640}", emoji: "💂🏿‍♀", name: "woman guard: dark skin tone", key: ":woman_guard_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F477}", emoji: "👷", name: "construction worker", key: ":construction_worker:", group: "People & Body", },
  { unicode: "\u{1F477}\u{1F3FB}", emoji: "👷🏻", name: "construction worker: light skin tone", key: ":construction_worker_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F477}\u{1F3FC}", emoji: "👷🏼", name: "construction worker: medium-light skin tone", key: ":construction_worker_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F477}\u{1F3FD}", emoji: "👷🏽", name: "construction worker: medium skin tone", key: ":construction_worker_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F477}\u{1F3FE}", emoji: "👷🏾", name: "construction worker: medium-dark skin tone", key: ":construction_worker_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F477}\u{1F3FF}", emoji: "👷🏿", name: "construction worker: dark skin tone", key: ":construction_worker_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F477}\u{200D}\u{2642}", emoji: "👷‍♂", name: "man construction worker", key: ":man_construction_worker:", group: "People & Body", },
  { unicode: "\u{1F477}\u{1F3FB}\u{200D}\u{2642}", emoji: "👷🏻‍♂", name: "man construction worker: light skin tone", key: ":man_construction_worker_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F477}\u{1F3FC}\u{200D}\u{2642}", emoji: "👷🏼‍♂", name: "man construction worker: medium-light skin tone", key: ":man_construction_worker_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F477}\u{1F3FD}\u{200D}\u{2642}", emoji: "👷🏽‍♂", name: "man construction worker: medium skin tone", key: ":man_construction_worker_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F477}\u{1F3FE}\u{200D}\u{2642}", emoji: "👷🏾‍♂", name: "man construction worker: medium-dark skin tone", key: ":man_construction_worker_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F477}\u{1F3FF}\u{200D}\u{2642}", emoji: "👷🏿‍♂", name: "man construction worker: dark skin tone", key: ":man_construction_worker_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F477}\u{200D}\u{2640}", emoji: "👷‍♀", name: "woman construction worker", key: ":woman_construction_worker:", group: "People & Body", },
  { unicode: "\u{1F477}\u{1F3FB}\u{200D}\u{2640}", emoji: "👷🏻‍♀", name: "woman construction worker: light skin tone", key: ":woman_construction_worker_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F477}\u{1F3FC}\u{200D}\u{2640}", emoji: "👷🏼‍♀", name: "woman construction worker: medium-light skin tone", key: ":woman_construction_worker_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F477}\u{1F3FD}\u{200D}\u{2640}", emoji: "👷🏽‍♀", name: "woman construction worker: medium skin tone", key: ":woman_construction_worker_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F477}\u{1F3FE}\u{200D}\u{2640}", emoji: "👷🏾‍♀", name: "woman construction worker: medium-dark skin tone", key: ":woman_construction_worker_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F477}\u{1F3FF}\u{200D}\u{2640}", emoji: "👷🏿‍♀", name: "woman construction worker: dark skin tone", key: ":woman_construction_worker_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F934}", emoji: "🤴", name: "prince", key: ":prince:", group: "People & Body", },
  { unicode: "\u{1F934}\u{1F3FB}", emoji: "🤴🏻", name: "prince: light skin tone", key: ":prince_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F934}\u{1F3FC}", emoji: "🤴🏼", name: "prince: medium-light skin tone", key: ":prince_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F934}\u{1F3FD}", emoji: "🤴🏽", name: "prince: medium skin tone", key: ":prince_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F934}\u{1F3FE}", emoji: "🤴🏾", name: "prince: medium-dark skin tone", key: ":prince_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F934}\u{1F3FF}", emoji: "🤴🏿", name: "prince: dark skin tone", key: ":prince_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F478}", emoji: "👸", name: "princess", key: ":princess:", group: "People & Body", },
  { unicode: "\u{1F478}\u{1F3FB}", emoji: "👸🏻", name: "princess: light skin tone", key: ":princess_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F478}\u{1F3FC}", emoji: "👸🏼", name: "princess: medium-light skin tone", key: ":princess_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F478}\u{1F3FD}", emoji: "👸🏽", name: "princess: medium skin tone", key: ":princess_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F478}\u{1F3FE}", emoji: "👸🏾", name: "princess: medium-dark skin tone", key: ":princess_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F478}\u{1F3FF}", emoji: "👸🏿", name: "princess: dark skin tone", key: ":princess_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F473}", emoji: "👳", name: "person wearing turban", key: ":person_wearing_turban:", group: "People & Body", },
  { unicode: "\u{1F473}\u{1F3FB}", emoji: "👳🏻", name: "person wearing turban: light skin tone", key: ":person_wearing_turban_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F473}\u{1F3FC}", emoji: "👳🏼", name: "person wearing turban: medium-light skin tone", key: ":person_wearing_turban_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F473}\u{1F3FD}", emoji: "👳🏽", name: "person wearing turban: medium skin tone", key: ":person_wearing_turban_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F473}\u{1F3FE}", emoji: "👳🏾", name: "person wearing turban: medium-dark skin tone", key: ":person_wearing_turban_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F473}\u{1F3FF}", emoji: "👳🏿", name: "person wearing turban: dark skin tone", key: ":person_wearing_turban_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F473}\u{200D}\u{2642}", emoji: "👳‍♂", name: "man wearing turban", key: ":man_wearing_turban:", group: "People & Body", },
  { unicode: "\u{1F473}\u{1F3FB}\u{200D}\u{2642}", emoji: "👳🏻‍♂", name: "man wearing turban: light skin tone", key: ":man_wearing_turban_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F473}\u{1F3FC}\u{200D}\u{2642}", emoji: "👳🏼‍♂", name: "man wearing turban: medium-light skin tone", key: ":man_wearing_turban_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F473}\u{1F3FD}\u{200D}\u{2642}", emoji: "👳🏽‍♂", name: "man wearing turban: medium skin tone", key: ":man_wearing_turban_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F473}\u{1F3FE}\u{200D}\u{2642}", emoji: "👳🏾‍♂", name: "man wearing turban: medium-dark skin tone", key: ":man_wearing_turban_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F473}\u{1F3FF}\u{200D}\u{2642}", emoji: "👳🏿‍♂", name: "man wearing turban: dark skin tone", key: ":man_wearing_turban_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F473}\u{200D}\u{2640}", emoji: "👳‍♀", name: "woman wearing turban", key: ":woman_wearing_turban:", group: "People & Body", },
  { unicode: "\u{1F473}\u{1F3FB}\u{200D}\u{2640}", emoji: "👳🏻‍♀", name: "woman wearing turban: light skin tone", key: ":woman_wearing_turban_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F473}\u{1F3FC}\u{200D}\u{2640}", emoji: "👳🏼‍♀", name: "woman wearing turban: medium-light skin tone", key: ":woman_wearing_turban_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F473}\u{1F3FD}\u{200D}\u{2640}", emoji: "👳🏽‍♀", name: "woman wearing turban: medium skin tone", key: ":woman_wearing_turban_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F473}\u{1F3FE}\u{200D}\u{2640}", emoji: "👳🏾‍♀", name: "woman wearing turban: medium-dark skin tone", key: ":woman_wearing_turban_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F473}\u{1F3FF}\u{200D}\u{2640}", emoji: "👳🏿‍♀", name: "woman wearing turban: dark skin tone", key: ":woman_wearing_turban_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F472}", emoji: "👲", name: "man with skullcap", key: ":man_with_skullcap:", group: "People & Body", },
  { unicode: "\u{1F472}\u{1F3FB}", emoji: "👲🏻", name: "man with skullcap: light skin tone", key: ":man_with_skullcap_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F472}\u{1F3FC}", emoji: "👲🏼", name: "man with skullcap: medium-light skin tone", key: ":man_with_skullcap_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F472}\u{1F3FD}", emoji: "👲🏽", name: "man with skullcap: medium skin tone", key: ":man_with_skullcap_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F472}\u{1F3FE}", emoji: "👲🏾", name: "man with skullcap: medium-dark skin tone", key: ":man_with_skullcap_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F472}\u{1F3FF}", emoji: "👲🏿", name: "man with skullcap: dark skin tone", key: ":man_with_skullcap_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D5}", emoji: "🧕", name: "woman with headscarf", key: ":woman_with_headscarf:", group: "People & Body", },
  { unicode: "\u{1F9D5}\u{1F3FB}", emoji: "🧕🏻", name: "woman with headscarf: light skin tone", key: ":woman_with_headscarf_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D5}\u{1F3FC}", emoji: "🧕🏼", name: "woman with headscarf: medium-light skin tone", key: ":woman_with_headscarf_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D5}\u{1F3FD}", emoji: "🧕🏽", name: "woman with headscarf: medium skin tone", key: ":woman_with_headscarf_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D5}\u{1F3FE}", emoji: "🧕🏾", name: "woman with headscarf: medium-dark skin tone", key: ":woman_with_headscarf_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D5}\u{1F3FF}", emoji: "🧕🏿", name: "woman with headscarf: dark skin tone", key: ":woman_with_headscarf_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F935}", emoji: "🤵", name: "man in tuxedo", key: ":man_in_tuxedo:", group: "People & Body", },
  { unicode: "\u{1F935}\u{1F3FB}", emoji: "🤵🏻", name: "man in tuxedo: light skin tone", key: ":man_in_tuxedo_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F935}\u{1F3FC}", emoji: "🤵🏼", name: "man in tuxedo: medium-light skin tone", key: ":man_in_tuxedo_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F935}\u{1F3FD}", emoji: "🤵🏽", name: "man in tuxedo: medium skin tone", key: ":man_in_tuxedo_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F935}\u{1F3FE}", emoji: "🤵🏾", name: "man in tuxedo: medium-dark skin tone", key: ":man_in_tuxedo_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F935}\u{1F3FF}", emoji: "🤵🏿", name: "man in tuxedo: dark skin tone", key: ":man_in_tuxedo_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F470}", emoji: "👰", name: "bride with veil", key: ":bride_with_veil:", group: "People & Body", },
  { unicode: "\u{1F470}\u{1F3FB}", emoji: "👰🏻", name: "bride with veil: light skin tone", key: ":bride_with_veil_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F470}\u{1F3FC}", emoji: "👰🏼", name: "bride with veil: medium-light skin tone", key: ":bride_with_veil_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F470}\u{1F3FD}", emoji: "👰🏽", name: "bride with veil: medium skin tone", key: ":bride_with_veil_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F470}\u{1F3FE}", emoji: "👰🏾", name: "bride with veil: medium-dark skin tone", key: ":bride_with_veil_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F470}\u{1F3FF}", emoji: "👰🏿", name: "bride with veil: dark skin tone", key: ":bride_with_veil_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F930}", emoji: "🤰", name: "pregnant woman", key: ":pregnant_woman:", group: "People & Body", },
  { unicode: "\u{1F930}\u{1F3FB}", emoji: "🤰🏻", name: "pregnant woman: light skin tone", key: ":pregnant_woman_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F930}\u{1F3FC}", emoji: "🤰🏼", name: "pregnant woman: medium-light skin tone", key: ":pregnant_woman_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F930}\u{1F3FD}", emoji: "🤰🏽", name: "pregnant woman: medium skin tone", key: ":pregnant_woman_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F930}\u{1F3FE}", emoji: "🤰🏾", name: "pregnant woman: medium-dark skin tone", key: ":pregnant_woman_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F930}\u{1F3FF}", emoji: "🤰🏿", name: "pregnant woman: dark skin tone", key: ":pregnant_woman_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F931}", emoji: "🤱", name: "breast-feeding", key: ":breast_feeding:", group: "People & Body", },
  { unicode: "\u{1F931}\u{1F3FB}", emoji: "🤱🏻", name: "breast-feeding: light skin tone", key: ":breast_feeding_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F931}\u{1F3FC}", emoji: "🤱🏼", name: "breast-feeding: medium-light skin tone", key: ":breast_feeding_medium-light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F931}\u{1F3FD}", emoji: "🤱🏽", name: "breast-feeding: medium skin tone", key: ":breast_feeding_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F931}\u{1F3FE}", emoji: "🤱🏾", name: "breast-feeding: medium-dark skin tone", key: ":breast_feeding_medium-dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F931}\u{1F3FF}", emoji: "🤱🏿", name: "breast-feeding: dark skin tone", key: ":breast_feeding_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F47C}", emoji: "👼", name: "baby angel", key: ":baby_angel:", group: "People & Body", },
  { unicode: "\u{1F47C}\u{1F3FB}", emoji: "👼🏻", name: "baby angel: light skin tone", key: ":baby_angel_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F47C}\u{1F3FC}", emoji: "👼🏼", name: "baby angel: medium-light skin tone", key: ":baby_angel_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F47C}\u{1F3FD}", emoji: "👼🏽", name: "baby angel: medium skin tone", key: ":baby_angel_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F47C}\u{1F3FE}", emoji: "👼🏾", name: "baby angel: medium-dark skin tone", key: ":baby_angel_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F47C}\u{1F3FF}", emoji: "👼🏿", name: "baby angel: dark skin tone", key: ":baby_angel_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F385}", emoji: "🎅", name: "Santa Claus", key: ":Santa_Claus:", group: "People & Body", },
  { unicode: "\u{1F385}\u{1F3FB}", emoji: "🎅🏻", name: "Santa Claus: light skin tone", key: ":Santa_Claus_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F385}\u{1F3FC}", emoji: "🎅🏼", name: "Santa Claus: medium-light skin tone", key: ":Santa_Claus_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F385}\u{1F3FD}", emoji: "🎅🏽", name: "Santa Claus: medium skin tone", key: ":Santa_Claus_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F385}\u{1F3FE}", emoji: "🎅🏾", name: "Santa Claus: medium-dark skin tone", key: ":Santa_Claus_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F385}\u{1F3FF}", emoji: "🎅🏿", name: "Santa Claus: dark skin tone", key: ":Santa_Claus_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F936}", emoji: "🤶", name: "Mrs. Claus", key: ":Mrs._Claus:", group: "People & Body", },
  { unicode: "\u{1F936}\u{1F3FB}", emoji: "🤶🏻", name: "Mrs. Claus: light skin tone", key: ":Mrs._Claus_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F936}\u{1F3FC}", emoji: "🤶🏼", name: "Mrs. Claus: medium-light skin tone", key: ":Mrs._Claus_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F936}\u{1F3FD}", emoji: "🤶🏽", name: "Mrs. Claus: medium skin tone", key: ":Mrs._Claus_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F936}\u{1F3FE}", emoji: "🤶🏾", name: "Mrs. Claus: medium-dark skin tone", key: ":Mrs._Claus_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F936}\u{1F3FF}", emoji: "🤶🏿", name: "Mrs. Claus: dark skin tone", key: ":Mrs._Claus_dark_skin_tone:", group: "People & Body", },
  // { unicode: "\u{1F9B8}", emoji: "🦸", name: "superhero", key: ":superhero:", group: "People & Body", },
  { unicode: "\u{1F9B8}\u{1F3FB}", emoji: "🏻", name: "superhero: light skin tone", key: ":superhero_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9B8}\u{1F3FC}", emoji: "🏼", name: "superhero: medium-light skin tone", key: ":superhero_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9B8}\u{1F3FD}", emoji: "🏽", name: "superhero: medium skin tone", key: ":superhero_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9B8}\u{1F3FE}", emoji: "🏾", name: "superhero: medium-dark skin tone", key: ":superhero_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9B8}\u{1F3FF}", emoji: "🏿", name: "superhero: dark skin tone", key: ":superhero_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9B8}\u{200D}\u{2642}", emoji: "‍♂️", name: "man superhero", key: ":man_superhero:", group: "People & Body", },
  { unicode: "\u{1F9B8}\u{1F3FB}\u{200D}\u{2642}", emoji: "🏻‍♂", name: "man superhero: light skin tone", key: ":man_superhero_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9B8}\u{1F3FC}\u{200D}\u{2642}", emoji: "🏼‍♂", name: "man superhero: medium-light skin tone", key: ":man_superhero_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9B8}\u{1F3FD}\u{200D}\u{2642}", emoji: "🏽‍♂", name: "man superhero: medium skin tone", key: ":man_superhero_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9B8}\u{1F3FE}\u{200D}\u{2642}", emoji: "🏾‍♂", name: "man superhero: medium-dark skin tone", key: ":man_superhero_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9B8}\u{1F3FF}\u{200D}\u{2642}", emoji: "🏿‍♂", name: "man superhero: dark skin tone", key: ":man_superhero_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9B8}\u{200D}\u{2640}", emoji: "‍♀", name: "woman superhero", key: ":woman_superhero:", group: "People & Body", },
  { unicode: "\u{1F9B8}\u{1F3FB}\u{200D}\u{2640}", emoji: "🏻‍♀", name: "woman superhero: light skin tone", key: ":woman_superhero_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9B8}\u{1F3FC}\u{200D}\u{2640}", emoji: "🏼‍♀", name: "woman superhero: medium-light skin tone", key: ":woman_superhero_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9B8}\u{1F3FD}\u{200D}\u{2640}", emoji: "🏽‍♀", name: "woman superhero: medium skin tone", key: ":woman_superhero_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9B8}\u{1F3FE}\u{200D}\u{2640}", emoji: "🏾‍♀", name: "woman superhero: medium-dark skin tone", key: ":woman_superhero_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9B8}\u{1F3FF}\u{200D}\u{2640}", emoji: "🏿‍♀", name: "woman superhero: dark skin tone", key: ":woman_superhero_dark_skin_tone:", group: "People & Body", },
  // { unicode: "\u{1F9B9}", emoji: "🦹", name: "supervillain", key: ":supervillain:", group: "People & Body", },
  { unicode: "\u{1F9B9}\u{1F3FB}", emoji: "🏻", name: "supervillain: light skin tone", key: ":supervillain_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9B9}\u{1F3FC}", emoji: "🏼", name: "supervillain: medium-light skin tone", key: ":supervillain_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9B9}\u{1F3FD}", emoji: "🏽", name: "supervillain: medium skin tone", key: ":supervillain_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9B9}\u{1F3FE}", emoji: "🏾", name: "supervillain: medium-dark skin tone", key: ":supervillain_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9B9}\u{1F3FF}", emoji: "🏿", name: "supervillain: dark skin tone", key: ":supervillain_dark_skin_tone:", group: "People & Body", },
  // { unicode: "\u{1F9B9}\u{200D}\u{2642}", emoji: "🦹‍♂", name: "man supervillain", key: ":man_supervillain:", group: "People & Body", },
  { unicode: "\u{1F9B9}\u{1F3FB}\u{200D}\u{2642}", emoji: "🏻‍♂", name: "man supervillain: light skin tone", key: ":man_supervillain_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9B9}\u{1F3FC}\u{200D}\u{2642}", emoji: "🏼‍♂", name: "man supervillain: medium-light skin tone", key: ":man_supervillain_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9B9}\u{1F3FD}\u{200D}\u{2642}", emoji: "🏽‍♂", name: "man supervillain: medium skin tone", key: ":man_supervillain_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9B9}\u{1F3FE}\u{200D}\u{2642}", emoji: "🏾‍♂", name: "man supervillain: medium-dark skin tone", key: ":man_supervillain_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9B9}\u{1F3FF}\u{200D}\u{2642}", emoji: "🏿‍♂", name: "man supervillain: dark skin tone", key: ":man_supervillain_dark_skin_tone:", group: "People & Body", },
  // { unicode: "\u{1F9B9}\u{200D}\u{2640}", emoji: "🦹‍♀", name: "woman supervillain", key: ":woman_supervillain:", group: "People & Body", },
  { unicode: "\u{1F9B9}\u{1F3FB}\u{200D}\u{2640}", emoji: "🏻‍♀", name: "woman supervillain: light skin tone", key: ":woman_supervillain_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9B9}\u{1F3FC}\u{200D}\u{2640}", emoji: "🏼‍♀", name: "woman supervillain: medium-light skin tone", key: ":woman_supervillain_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9B9}\u{1F3FD}\u{200D}\u{2640}", emoji: "🏽‍♀", name: "woman supervillain: medium skin tone", key: ":woman_supervillain_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9B9}\u{1F3FE}\u{200D}\u{2640}", emoji: "🏾‍♀", name: "woman supervillain: medium-dark skin tone", key: ":woman_supervillain_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9B9}\u{1F3FF}\u{200D}\u{2640}", emoji: "🏿‍♀", name: "woman supervillain: dark skin tone", key: ":woman_supervillain_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D9}", emoji: "🧙", name: "mage", key: ":mage:", group: "People & Body", },
  { unicode: "\u{1F9D9}\u{1F3FB}", emoji: "🧙🏻", name: "mage: light skin tone", key: ":mage_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D9}\u{1F3FC}", emoji: "🧙🏼", name: "mage: medium-light skin tone", key: ":mage_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D9}\u{1F3FD}", emoji: "🧙🏽", name: "mage: medium skin tone", key: ":mage_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D9}\u{1F3FE}", emoji: "🧙🏾", name: "mage: medium-dark skin tone", key: ":mage_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D9}\u{1F3FF}", emoji: "🧙🏿", name: "mage: dark skin tone", key: ":mage_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D9}\u{200D}\u{2642}", emoji: "🧙‍♂", name: "man mage", key: ":man_mage:", group: "People & Body", },
  { unicode: "\u{1F9D9}\u{1F3FB}\u{200D}\u{2642}", emoji: "🧙🏻‍♂", name: "man mage: light skin tone", key: ":man_mage_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D9}\u{1F3FC}\u{200D}\u{2642}", emoji: "🧙🏼‍♂", name: "man mage: medium-light skin tone", key: ":man_mage_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D9}\u{1F3FD}\u{200D}\u{2642}", emoji: "🧙🏽‍♂", name: "man mage: medium skin tone", key: ":man_mage_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D9}\u{1F3FE}\u{200D}\u{2642}", emoji: "🧙🏾‍♂", name: "man mage: medium-dark skin tone", key: ":man_mage_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D9}\u{1F3FF}\u{200D}\u{2642}", emoji: "🧙🏿‍♂", name: "man mage: dark skin tone", key: ":man_mage_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D9}\u{200D}\u{2640}", emoji: "🧙‍♀", name: "woman mage", key: ":woman_mage:", group: "People & Body", },
  { unicode: "\u{1F9D9}\u{1F3FB}\u{200D}\u{2640}", emoji: "🧙🏻‍♀", name: "woman mage: light skin tone", key: ":woman_mage_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D9}\u{1F3FC}\u{200D}\u{2640}", emoji: "🧙🏼‍♀", name: "woman mage: medium-light skin tone", key: ":woman_mage_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D9}\u{1F3FD}\u{200D}\u{2640}", emoji: "🧙🏽‍♀", name: "woman mage: medium skin tone", key: ":woman_mage_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D9}\u{1F3FE}\u{200D}\u{2640}", emoji: "🧙🏾‍♀", name: "woman mage: medium-dark skin tone", key: ":woman_mage_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D9}\u{1F3FF}\u{200D}\u{2640}", emoji: "🧙🏿‍♀", name: "woman mage: dark skin tone", key: ":woman_mage_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DA}", emoji: "🧚", name: "fairy", key: ":fairy:", group: "People & Body", },
  { unicode: "\u{1F9DA}\u{1F3FB}", emoji: "🧚🏻", name: "fairy: light skin tone", key: ":fairy_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DA}\u{1F3FC}", emoji: "🧚🏼", name: "fairy: medium-light skin tone", key: ":fairy_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DA}\u{1F3FD}", emoji: "🧚🏽", name: "fairy: medium skin tone", key: ":fairy_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DA}\u{1F3FE}", emoji: "🧚🏾", name: "fairy: medium-dark skin tone", key: ":fairy_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DA}\u{1F3FF}", emoji: "🧚🏿", name: "fairy: dark skin tone", key: ":fairy_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DA}\u{200D}\u{2642}", emoji: "🧚‍♂", name: "man fairy", key: ":man_fairy:", group: "People & Body", },
  { unicode: "\u{1F9DA}\u{1F3FB}\u{200D}\u{2642}", emoji: "🧚🏻‍♂", name: "man fairy: light skin tone", key: ":man_fairy_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DA}\u{1F3FC}\u{200D}\u{2642}", emoji: "🧚🏼‍♂", name: "man fairy: medium-light skin tone", key: ":man_fairy_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DA}\u{1F3FD}\u{200D}\u{2642}", emoji: "🧚🏽‍♂", name: "man fairy: medium skin tone", key: ":man_fairy_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DA}\u{1F3FE}\u{200D}\u{2642}", emoji: "🧚🏾‍♂", name: "man fairy: medium-dark skin tone", key: ":man_fairy_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DA}\u{1F3FF}\u{200D}\u{2642}", emoji: "🧚🏿‍♂", name: "man fairy: dark skin tone", key: ":man_fairy_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DA}\u{200D}\u{2640}", emoji: "🧚‍♀", name: "woman fairy", key: ":woman_fairy:", group: "People & Body", },
  { unicode: "\u{1F9DA}\u{1F3FB}\u{200D}\u{2640}", emoji: "🧚🏻‍♀", name: "woman fairy: light skin tone", key: ":woman_fairy_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DA}\u{1F3FC}\u{200D}\u{2640}", emoji: "🧚🏼‍♀", name: "woman fairy: medium-light skin tone", key: ":woman_fairy_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DA}\u{1F3FD}\u{200D}\u{2640}", emoji: "🧚🏽‍♀", name: "woman fairy: medium skin tone", key: ":woman_fairy_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DA}\u{1F3FE}\u{200D}\u{2640}", emoji: "🧚🏾‍♀", name: "woman fairy: medium-dark skin tone", key: ":woman_fairy_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DA}\u{1F3FF}\u{200D}\u{2640}", emoji: "🧚🏿‍♀", name: "woman fairy: dark skin tone", key: ":woman_fairy_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DB}", emoji: "🧛", name: "vampire", key: ":vampire:", group: "People & Body", },
  { unicode: "\u{1F9DB}\u{1F3FB}", emoji: "🧛🏻", name: "vampire: light skin tone", key: ":vampire_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DB}\u{1F3FC}", emoji: "🧛🏼", name: "vampire: medium-light skin tone", key: ":vampire_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DB}\u{1F3FD}", emoji: "🧛🏽", name: "vampire: medium skin tone", key: ":vampire_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DB}\u{1F3FE}", emoji: "🧛🏾", name: "vampire: medium-dark skin tone", key: ":vampire_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DB}\u{1F3FF}", emoji: "🧛🏿", name: "vampire: dark skin tone", key: ":vampire_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DB}\u{200D}\u{2642}", emoji: "🧛‍♂", name: "man vampire", key: ":man_vampire:", group: "People & Body", },
  { unicode: "\u{1F9DB}\u{1F3FB}\u{200D}\u{2642}", emoji: "🧛🏻‍♂", name: "man vampire: light skin tone", key: ":man_vampire_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DB}\u{1F3FC}\u{200D}\u{2642}", emoji: "🧛🏼‍♂", name: "man vampire: medium-light skin tone", key: ":man_vampire_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DB}\u{1F3FD}\u{200D}\u{2642}", emoji: "🧛🏽‍♂", name: "man vampire: medium skin tone", key: ":man_vampire_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DB}\u{1F3FE}\u{200D}\u{2642}", emoji: "🧛🏾‍♂", name: "man vampire: medium-dark skin tone", key: ":man_vampire_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DB}\u{1F3FF}\u{200D}\u{2642}", emoji: "🧛🏿‍♂", name: "man vampire: dark skin tone", key: ":man_vampire_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DB}\u{200D}\u{2640}", emoji: "🧛‍♀", name: "woman vampire", key: ":woman_vampire:", group: "People & Body", },
  { unicode: "\u{1F9DB}\u{1F3FB}\u{200D}\u{2640}", emoji: "🧛🏻‍♀", name: "woman vampire: light skin tone", key: ":woman_vampire_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DB}\u{1F3FC}\u{200D}\u{2640}", emoji: "🧛🏼‍♀", name: "woman vampire: medium-light skin tone", key: ":woman_vampire_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DB}\u{1F3FD}\u{200D}\u{2640}", emoji: "🧛🏽‍♀", name: "woman vampire: medium skin tone", key: ":woman_vampire_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DB}\u{1F3FE}\u{200D}\u{2640}", emoji: "🧛🏾‍♀", name: "woman vampire: medium-dark skin tone", key: ":woman_vampire_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DB}\u{1F3FF}\u{200D}\u{2640}", emoji: "🧛🏿‍♀", name: "woman vampire: dark skin tone", key: ":woman_vampire_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DC}", emoji: "🧜", name: "merperson", key: ":merperson:", group: "People & Body", },
  { unicode: "\u{1F9DC}\u{1F3FB}", emoji: "🧜🏻", name: "merperson: light skin tone", key: ":merperson_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DC}\u{1F3FC}", emoji: "🧜🏼", name: "merperson: medium-light skin tone", key: ":merperson_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DC}\u{1F3FD}", emoji: "🧜🏽", name: "merperson: medium skin tone", key: ":merperson_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DC}\u{1F3FE}", emoji: "🧜🏾", name: "merperson: medium-dark skin tone", key: ":merperson_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DC}\u{1F3FF}", emoji: "🧜🏿", name: "merperson: dark skin tone", key: ":merperson_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DC}\u{200D}\u{2642}", emoji: "🧜‍♂", name: "merman", key: ":merman:", group: "People & Body", },
  { unicode: "\u{1F9DC}\u{1F3FB}\u{200D}\u{2642}", emoji: "🧜🏻‍♂", name: "merman: light skin tone", key: ":merman_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DC}\u{1F3FC}\u{200D}\u{2642}", emoji: "🧜🏼‍♂", name: "merman: medium-light skin tone", key: ":merman_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DC}\u{1F3FD}\u{200D}\u{2642}", emoji: "🧜🏽‍♂", name: "merman: medium skin tone", key: ":merman_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DC}\u{1F3FE}\u{200D}\u{2642}", emoji: "🧜🏾‍♂", name: "merman: medium-dark skin tone", key: ":merman_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DC}\u{1F3FF}\u{200D}\u{2642}", emoji: "🧜🏿‍♂", name: "merman: dark skin tone", key: ":merman_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DC}\u{200D}\u{2640}", emoji: "🧜‍♀", name: "mermaid", key: ":mermaid:", group: "People & Body", },
  { unicode: "\u{1F9DC}\u{1F3FB}\u{200D}\u{2640}", emoji: "🧜🏻‍♀", name: "mermaid: light skin tone", key: ":mermaid_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DC}\u{1F3FC}\u{200D}\u{2640}", emoji: "🧜🏼‍♀", name: "mermaid: medium-light skin tone", key: ":mermaid_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DC}\u{1F3FD}\u{200D}\u{2640}", emoji: "🧜🏽‍♀", name: "mermaid: medium skin tone", key: ":mermaid_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DC}\u{1F3FE}\u{200D}\u{2640}", emoji: "🧜🏾‍♀", name: "mermaid: medium-dark skin tone", key: ":mermaid_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DC}\u{1F3FF}\u{200D}\u{2640}", emoji: "🧜🏿‍♀", name: "mermaid: dark skin tone", key: ":mermaid_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DD}", emoji: "🧝", name: "elf", key: ":elf:", group: "People & Body", },
  { unicode: "\u{1F9DD}\u{1F3FB}", emoji: "🧝🏻", name: "elf: light skin tone", key: ":elf_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DD}\u{1F3FC}", emoji: "🧝🏼", name: "elf: medium-light skin tone", key: ":elf_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DD}\u{1F3FD}", emoji: "🧝🏽", name: "elf: medium skin tone", key: ":elf_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DD}\u{1F3FE}", emoji: "🧝🏾", name: "elf: medium-dark skin tone", key: ":elf_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DD}\u{1F3FF}", emoji: "🧝🏿", name: "elf: dark skin tone", key: ":elf_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DD}\u{200D}\u{2642}", emoji: "🧝‍♂", name: "man elf", key: ":man_elf:", group: "People & Body", },
  { unicode: "\u{1F9DD}\u{1F3FB}\u{200D}\u{2642}", emoji: "🧝🏻‍♂", name: "man elf: light skin tone", key: ":man_elf_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DD}\u{1F3FC}\u{200D}\u{2642}", emoji: "🧝🏼‍♂", name: "man elf: medium-light skin tone", key: ":man_elf_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DD}\u{1F3FD}\u{200D}\u{2642}", emoji: "🧝🏽‍♂", name: "man elf: medium skin tone", key: ":man_elf_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DD}\u{1F3FE}\u{200D}\u{2642}", emoji: "🧝🏾‍♂", name: "man elf: medium-dark skin tone", key: ":man_elf_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DD}\u{1F3FF}\u{200D}\u{2642}", emoji: "🧝🏿‍♂", name: "man elf: dark skin tone", key: ":man_elf_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DD}\u{200D}\u{2640}", emoji: "🧝‍♀", name: "woman elf", key: ":woman_elf:", group: "People & Body", },
  { unicode: "\u{1F9DD}\u{1F3FB}\u{200D}\u{2640}", emoji: "🧝🏻‍♀", name: "woman elf: light skin tone", key: ":woman_elf_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DD}\u{1F3FC}\u{200D}\u{2640}", emoji: "🧝🏼‍♀", name: "woman elf: medium-light skin tone", key: ":woman_elf_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DD}\u{1F3FD}\u{200D}\u{2640}", emoji: "🧝🏽‍♀", name: "woman elf: medium skin tone", key: ":woman_elf_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DD}\u{1F3FE}\u{200D}\u{2640}", emoji: "🧝🏾‍♀", name: "woman elf: medium-dark skin tone", key: ":woman_elf_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DD}\u{1F3FF}\u{200D}\u{2640}", emoji: "🧝🏿‍♀", name: "woman elf: dark skin tone", key: ":woman_elf_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9DE}", emoji: "🧞", name: "genie", key: ":genie:", group: "People & Body", },
  { unicode: "\u{1F9DE}\u{200D}\u{2642}", emoji: "🧞‍♂", name: "man genie", key: ":man_genie:", group: "People & Body", },
  { unicode: "\u{1F9DE}\u{200D}\u{2640}", emoji: "🧞‍♀", name: "woman genie", key: ":woman_genie:", group: "People & Body", },
  { unicode: "\u{1F9DF}", emoji: "🧟", name: "zombie", key: ":zombie:", group: "People & Body", },
  { unicode: "\u{1F9DF}\u{200D}\u{2642}", emoji: "🧟‍♂", name: "man zombie", key: ":man_zombie:", group: "People & Body", },
  { unicode: "\u{1F9DF}\u{200D}\u{2640}", emoji: "🧟‍♀", name: "woman zombie", key: ":woman_zombie:", group: "People & Body", },
  { unicode: "\u{1F486}", emoji: "💆", name: "person getting massage", key: ":person_getting_massage:", group: "People & Body", },
  { unicode: "\u{1F486}\u{1F3FB}", emoji: "💆🏻", name: "person getting massage: light skin tone", key: ":person_getting_massage_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F486}\u{1F3FC}", emoji: "💆🏼", name: "person getting massage: medium-light skin tone", key: ":person_getting_massage_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F486}\u{1F3FD}", emoji: "💆🏽", name: "person getting massage: medium skin tone", key: ":person_getting_massage_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F486}\u{1F3FE}", emoji: "💆🏾", name: "person getting massage: medium-dark skin tone", key: ":person_getting_massage_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F486}\u{1F3FF}", emoji: "💆🏿", name: "person getting massage: dark skin tone", key: ":person_getting_massage_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F486}\u{200D}\u{2642}", emoji: "💆‍♂", name: "man getting massage", key: ":man_getting_massage:", group: "People & Body", },
  { unicode: "\u{1F486}\u{1F3FB}\u{200D}\u{2642}", emoji: "💆🏻‍♂", name: "man getting massage: light skin tone", key: ":man_getting_massage_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F486}\u{1F3FC}\u{200D}\u{2642}", emoji: "💆🏼‍♂", name: "man getting massage: medium-light skin tone", key: ":man_getting_massage_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F486}\u{1F3FD}\u{200D}\u{2642}", emoji: "💆🏽‍♂", name: "man getting massage: medium skin tone", key: ":man_getting_massage_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F486}\u{1F3FE}\u{200D}\u{2642}", emoji: "💆🏾‍♂", name: "man getting massage: medium-dark skin tone", key: ":man_getting_massage_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F486}\u{1F3FF}\u{200D}\u{2642}", emoji: "💆🏿‍♂", name: "man getting massage: dark skin tone", key: ":man_getting_massage_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F486}\u{200D}\u{2640}", emoji: "💆‍♀", name: "woman getting massage", key: ":woman_getting_massage:", group: "People & Body", },
  { unicode: "\u{1F486}\u{1F3FB}\u{200D}\u{2640}", emoji: "💆🏻‍♀", name: "woman getting massage: light skin tone", key: ":woman_getting_massage_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F486}\u{1F3FC}\u{200D}\u{2640}", emoji: "💆🏼‍♀", name: "woman getting massage: medium-light skin tone", key: ":woman_getting_massage_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F486}\u{1F3FD}\u{200D}\u{2640}", emoji: "💆🏽‍♀", name: "woman getting massage: medium skin tone", key: ":woman_getting_massage_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F486}\u{1F3FE}\u{200D}\u{2640}", emoji: "💆🏾‍♀", name: "woman getting massage: medium-dark skin tone", key: ":woman_getting_massage_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F486}\u{1F3FF}\u{200D}\u{2640}", emoji: "💆🏿‍♀", name: "woman getting massage: dark skin tone", key: ":woman_getting_massage_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F487}", emoji: "💇", name: "person getting haircut", key: ":person_getting_haircut:", group: "People & Body", },
  { unicode: "\u{1F487}\u{1F3FB}", emoji: "💇🏻", name: "person getting haircut: light skin tone", key: ":person_getting_haircut_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F487}\u{1F3FC}", emoji: "💇🏼", name: "person getting haircut: medium-light skin tone", key: ":person_getting_haircut_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F487}\u{1F3FD}", emoji: "💇🏽", name: "person getting haircut: medium skin tone", key: ":person_getting_haircut_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F487}\u{1F3FE}", emoji: "💇🏾", name: "person getting haircut: medium-dark skin tone", key: ":person_getting_haircut_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F487}\u{1F3FF}", emoji: "💇🏿", name: "person getting haircut: dark skin tone", key: ":person_getting_haircut_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F487}\u{200D}\u{2642}", emoji: "💇‍♂", name: "man getting haircut", key: ":man_getting_haircut:", group: "People & Body", },
  { unicode: "\u{1F487}\u{1F3FB}\u{200D}\u{2642}", emoji: "💇🏻‍♂", name: "man getting haircut: light skin tone", key: ":man_getting_haircut_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F487}\u{1F3FC}\u{200D}\u{2642}", emoji: "💇🏼‍♂", name: "man getting haircut: medium-light skin tone", key: ":man_getting_haircut_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F487}\u{1F3FD}\u{200D}\u{2642}", emoji: "💇🏽‍♂", name: "man getting haircut: medium skin tone", key: ":man_getting_haircut_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F487}\u{1F3FE}\u{200D}\u{2642}", emoji: "💇🏾‍♂", name: "man getting haircut: medium-dark skin tone", key: ":man_getting_haircut_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F487}\u{1F3FF}\u{200D}\u{2642}", emoji: "💇🏿‍♂", name: "man getting haircut: dark skin tone", key: ":man_getting_haircut_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F487}\u{200D}\u{2640}", emoji: "💇‍♀", name: "woman getting haircut", key: ":woman_getting_haircut:", group: "People & Body", },
  { unicode: "\u{1F487}\u{1F3FB}\u{200D}\u{2640}", emoji: "💇🏻‍♀", name: "woman getting haircut: light skin tone", key: ":woman_getting_haircut_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F487}\u{1F3FC}\u{200D}\u{2640}", emoji: "💇🏼‍♀", name: "woman getting haircut: medium-light skin tone", key: ":woman_getting_haircut_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F487}\u{1F3FD}\u{200D}\u{2640}", emoji: "💇🏽‍♀", name: "woman getting haircut: medium skin tone", key: ":woman_getting_haircut_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F487}\u{1F3FE}\u{200D}\u{2640}", emoji: "💇🏾‍♀", name: "woman getting haircut: medium-dark skin tone", key: ":woman_getting_haircut_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F487}\u{1F3FF}\u{200D}\u{2640}", emoji: "💇🏿‍♀", name: "woman getting haircut: dark skin tone", key: ":woman_getting_haircut_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6B6}", emoji: "🚶", name: "person walking", key: ":person_walking:", group: "People & Body", },
  { unicode: "\u{1F6B6}\u{1F3FB}", emoji: "🚶🏻", name: "person walking: light skin tone", key: ":person_walking_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6B6}\u{1F3FC}", emoji: "🚶🏼", name: "person walking: medium-light skin tone", key: ":person_walking_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6B6}\u{1F3FD}", emoji: "🚶🏽", name: "person walking: medium skin tone", key: ":person_walking_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6B6}\u{1F3FE}", emoji: "🚶🏾", name: "person walking: medium-dark skin tone", key: ":person_walking_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6B6}\u{1F3FF}", emoji: "🚶🏿", name: "person walking: dark skin tone", key: ":person_walking_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6B6}\u{200D}\u{2642}", emoji: "🚶‍♂", name: "man walking", key: ":man_walking:", group: "People & Body", },
  { unicode: "\u{1F6B6}\u{1F3FB}\u{200D}\u{2642}", emoji: "🚶🏻‍♂", name: "man walking: light skin tone", key: ":man_walking_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6B6}\u{1F3FC}\u{200D}\u{2642}", emoji: "🚶🏼‍♂", name: "man walking: medium-light skin tone", key: ":man_walking_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6B6}\u{1F3FD}\u{200D}\u{2642}", emoji: "🚶🏽‍♂", name: "man walking: medium skin tone", key: ":man_walking_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6B6}\u{1F3FE}\u{200D}\u{2642}", emoji: "🚶🏾‍♂", name: "man walking: medium-dark skin tone", key: ":man_walking_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6B6}\u{1F3FF}\u{200D}\u{2642}", emoji: "🚶🏿‍♂", name: "man walking: dark skin tone", key: ":man_walking_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6B6}\u{200D}\u{2640}", emoji: "🚶‍♀", name: "woman walking", key: ":woman_walking:", group: "People & Body", },
  { unicode: "\u{1F6B6}\u{1F3FB}\u{200D}\u{2640}", emoji: "🚶🏻‍♀", name: "woman walking: light skin tone", key: ":woman_walking_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6B6}\u{1F3FC}\u{200D}\u{2640}", emoji: "🚶🏼‍♀", name: "woman walking: medium-light skin tone", key: ":woman_walking_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6B6}\u{1F3FD}\u{200D}\u{2640}", emoji: "🚶🏽‍♀", name: "woman walking: medium skin tone", key: ":woman_walking_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6B6}\u{1F3FE}\u{200D}\u{2640}", emoji: "🚶🏾‍♀", name: "woman walking: medium-dark skin tone", key: ":woman_walking_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6B6}\u{1F3FF}\u{200D}\u{2640}", emoji: "🚶🏿‍♀", name: "woman walking: dark skin tone", key: ":woman_walking_dark_skin_tone:", group: "People & Body", },
  // { unicode: "\u{1F9CD}", emoji: "🧍", name: "person standing", key: ":person_standing:", group: "People & Body", },
  { unicode: "\u{1F9CD}\u{1F3FB}", emoji: "🏻", name: "person standing: light skin tone", key: ":person_standing_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9CD}\u{1F3FC}", emoji: "🏼", name: "person standing: medium-light skin tone", key: ":person_standing_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9CD}\u{1F3FD}", emoji: "🏽", name: "person standing: medium skin tone", key: ":person_standing_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9CD}\u{1F3FE}", emoji: "🏾", name: "person standing: medium-dark skin tone", key: ":person_standing_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9CD}\u{1F3FF}", emoji: "🏿", name: "person standing: dark skin tone", key: ":person_standing_dark_skin_tone:", group: "People & Body", },
  // { unicode: "\u{1F9CD}\u{200D}\u{2642}", emoji: "🧍‍♂", name: "man standing", key: ":man_standing:", group: "People & Body", },
  { unicode: "\u{1F9CD}\u{1F3FB}\u{200D}\u{2642}", emoji: "🏻‍♂", name: "man standing: light skin tone", key: ":man_standing_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9CD}\u{1F3FC}\u{200D}\u{2642}", emoji: "🏼‍♂", name: "man standing: medium-light skin tone", key: ":man_standing_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9CD}\u{1F3FD}\u{200D}\u{2642}", emoji: "🏽‍♂", name: "man standing: medium skin tone", key: ":man_standing_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9CD}\u{1F3FE}\u{200D}\u{2642}", emoji: "🏾‍♂", name: "man standing: medium-dark skin tone", key: ":man_standing_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9CD}\u{1F3FF}\u{200D}\u{2642}", emoji: "🏿‍♂", name: "man standing: dark skin tone", key: ":man_standing_dark_skin_tone:", group: "People & Body", },
  // { unicode: "\u{1F9CD}\u{200D}\u{2640}", emoji: "🧍‍♀", name: "woman standing", key: ":woman_standing:", group: "People & Body", },
  { unicode: "\u{1F9CD}\u{1F3FB}\u{200D}\u{2640}", emoji: "🏻‍♀", name: "woman standing: light skin tone", key: ":woman_standing_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9CD}\u{1F3FC}\u{200D}\u{2640}", emoji: "🏼‍♀", name: "woman standing: medium-light skin tone", key: ":woman_standing_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9CD}\u{1F3FD}\u{200D}\u{2640}", emoji: "🏽‍♀", name: "woman standing: medium skin tone", key: ":woman_standing_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9CD}\u{1F3FE}\u{200D}\u{2640}", emoji: "🏾‍♀", name: "woman standing: medium-dark skin tone", key: ":woman_standing_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9CD}\u{1F3FF}\u{200D}\u{2640}", emoji: "🏿‍♀", name: "woman standing: dark skin tone", key: ":woman_standing_dark_skin_tone:", group: "People & Body", },
  // { unicode: "\u{1F9CE}", emoji: "🧎", name: "person kneeling", key: ":person_kneeling:", group: "People & Body", },
  { unicode: "\u{1F9CE}\u{1F3FB}", emoji: "🏻", name: "person kneeling: light skin tone", key: ":person_kneeling_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9CE}\u{1F3FC}", emoji: "🏼", name: "person kneeling: medium-light skin tone", key: ":person_kneeling_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9CE}\u{1F3FD}", emoji: "🏽", name: "person kneeling: medium skin tone", key: ":person_kneeling_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9CE}\u{1F3FE}", emoji: "🏾", name: "person kneeling: medium-dark skin tone", key: ":person_kneeling_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9CE}\u{1F3FF}", emoji: "🏿", name: "person kneeling: dark skin tone", key: ":person_kneeling_dark_skin_tone:", group: "People & Body", },
  // { unicode: "\u{1F9CE}\u{200D}\u{2642}", emoji: "🧎‍♂", name: "man kneeling", key: ":man_kneeling:", group: "People & Body", },
  { unicode: "\u{1F9CE}\u{1F3FB}\u{200D}\u{2642}", emoji: "🏻‍♂", name: "man kneeling: light skin tone", key: ":man_kneeling_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9CE}\u{1F3FC}\u{200D}\u{2642}", emoji: "🏼‍♂", name: "man kneeling: medium-light skin tone", key: ":man_kneeling_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9CE}\u{1F3FD}\u{200D}\u{2642}", emoji: "🏽‍♂", name: "man kneeling: medium skin tone", key: ":man_kneeling_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9CE}\u{1F3FE}\u{200D}\u{2642}", emoji: "🏾‍♂", name: "man kneeling: medium-dark skin tone", key: ":man_kneeling_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9CE}\u{1F3FF}\u{200D}\u{2642}", emoji: "🏿‍♂", name: "man kneeling: dark skin tone", key: ":man_kneeling_dark_skin_tone:", group: "People & Body", },
  // { unicode: "\u{1F9CE}\u{200D}\u{2640}", emoji: "🧎‍♀", name: "woman kneeling", key: ":woman_kneeling:", group: "People & Body", },
  { unicode: "\u{1F9CE}\u{1F3FB}\u{200D}\u{2640}", emoji: "🏻‍♀", name: "woman kneeling: light skin tone", key: ":woman_kneeling_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9CE}\u{1F3FC}\u{200D}\u{2640}", emoji: "🏼‍♀", name: "woman kneeling: medium-light skin tone", key: ":woman_kneeling_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9CE}\u{1F3FD}\u{200D}\u{2640}", emoji: "🏽‍♀", name: "woman kneeling: medium skin tone", key: ":woman_kneeling_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9CE}\u{1F3FE}\u{200D}\u{2640}", emoji: "🏾‍♀", name: "woman kneeling: medium-dark skin tone", key: ":woman_kneeling_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9CE}\u{1F3FF}\u{200D}\u{2640}", emoji: "🏿‍♀", name: "woman kneeling: dark skin tone", key: ":woman_kneeling_dark_skin_tone:", group: "People & Body", },
  // { unicode: "\u{1F9D1}\u{200D}\u{1F9AF}", emoji: "🧑‍🦯", name: "person with probing cane", key: ":person_with_probing_cane:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FB}\u{200D}\u{1F9AF}", emoji: "🧑🏻‍", name: "person with probing cane: light skin tone", key: ":person_with_probing_cane_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FC}\u{200D}\u{1F9AF}", emoji: "🧑🏼‍", name: "person with probing cane: medium-light skin tone", key: ":person_with_probing_cane_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FD}\u{200D}\u{1F9AF}", emoji: "🧑🏽‍", name: "person with probing cane: medium skin tone", key: ":person_with_probing_cane_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FE}\u{200D}\u{1F9AF}", emoji: "🧑🏾‍", name: "person with probing cane: medium-dark skin tone", key: ":person_with_probing_cane_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FF}\u{200D}\u{1F9AF}", emoji: "🧑🏿‍", name: "person with probing cane: dark skin tone", key: ":person_with_probing_cane_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{200D}\u{1F9AF}", emoji: "👨", name: "man with probing cane", key: ":man_with_probing_cane:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FB}\u{200D}\u{1F9AF}", emoji: "👨🏻", name: "man with probing cane: light skin tone", key: ":man_with_probing_cane_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FC}\u{200D}\u{1F9AF}", emoji: "👨🏼", name: "man with probing cane: medium-light skin tone", key: ":man_with_probing_cane_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FD}\u{200D}\u{1F9AF}", emoji: "👨🏽", name: "man with probing cane: medium skin tone", key: ":man_with_probing_cane_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FE}\u{200D}\u{1F9AF}", emoji: "👨🏾", name: "man with probing cane: medium-dark skin tone", key: ":man_with_probing_cane_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FF}\u{200D}\u{1F9AF}", emoji: "👨🏿", name: "man with probing cane: dark skin tone", key: ":man_with_probing_cane_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{200D}\u{1F9AF}", emoji: "👩", name: "woman with probing cane", key: ":woman_with_probing_cane:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FB}\u{200D}\u{1F9AF}", emoji: "👩🏻", name: "woman with probing cane: light skin tone", key: ":woman_with_probing_cane_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FC}\u{200D}\u{1F9AF}", emoji: "👩🏼", name: "woman with probing cane: medium-light skin tone", key: ":woman_with_probing_cane_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FD}\u{200D}\u{1F9AF}", emoji: "👩🏽", name: "woman with probing cane: medium skin tone", key: ":woman_with_probing_cane_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FE}\u{200D}\u{1F9AF}", emoji: "👩🏾", name: "woman with probing cane: medium-dark skin tone", key: ":woman_with_probing_cane_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FF}\u{200D}\u{1F9AF}", emoji: "👩🏿", name: "woman with probing cane: dark skin tone", key: ":woman_with_probing_cane_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{200D}\u{1F9BC}", emoji: "🧑‍", name: "person in motorized wheelchair", key: ":person_in_motorized_wheelchair:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FB}\u{200D}\u{1F9BC}", emoji: "🏻‍", name: "person in motorized wheelchair: light skin tone", key: ":person_in_motorized_wheelchair_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FC}\u{200D}\u{1F9BC}", emoji: "🏼‍", name: "person in motorized wheelchair: medium-light skin tone", key: ":person_in_motorized_wheelchair_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FD}\u{200D}\u{1F9BC}", emoji: "🏽‍", name: "person in motorized wheelchair: medium skin tone", key: ":person_in_motorized_wheelchair_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FE}\u{200D}\u{1F9BC}", emoji: "🏾‍", name: "person in motorized wheelchair: medium-dark skin tone", key: ":person_in_motorized_wheelchair_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FF}\u{200D}\u{1F9BC}", emoji: "🏿‍", name: "person in motorized wheelchair: dark skin tone", key: ":person_in_motorized_wheelchair_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{200D}\u{1F9BC}", emoji: "👨", name: "man in motorized wheelchair", key: ":man_in_motorized_wheelchair:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FB}\u{200D}\u{1F9BC}", emoji: "👨🏻", name: "man in motorized wheelchair: light skin tone", key: ":man_in_motorized_wheelchair_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FC}\u{200D}\u{1F9BC}", emoji: "👨🏼", name: "man in motorized wheelchair: medium-light skin tone", key: ":man_in_motorized_wheelchair_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FD}\u{200D}\u{1F9BC}", emoji: "👨🏽", name: "man in motorized wheelchair: medium skin tone", key: ":man_in_motorized_wheelchair_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FE}\u{200D}\u{1F9BC}", emoji: "👨🏾", name: "man in motorized wheelchair: medium-dark skin tone", key: ":man_in_motorized_wheelchair_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FF}\u{200D}\u{1F9BC}", emoji: "👨🏿", name: "man in motorized wheelchair: dark skin tone", key: ":man_in_motorized_wheelchair_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{200D}\u{1F9BC}", emoji: "👩", name: "woman in motorized wheelchair", key: ":woman_in_motorized_wheelchair:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FB}\u{200D}\u{1F9BC}", emoji: "👩🏻", name: "woman in motorized wheelchair: light skin tone", key: ":woman_in_motorized_wheelchair_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FC}\u{200D}\u{1F9BC}", emoji: "👩🏼", name: "woman in motorized wheelchair: medium-light skin tone", key: ":woman_in_motorized_wheelchair_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FD}\u{200D}\u{1F9BC}", emoji: "👩🏽", name: "woman in motorized wheelchair: medium skin tone", key: ":woman_in_motorized_wheelchair_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FE}\u{200D}\u{1F9BC}", emoji: "👩🏾", name: "woman in motorized wheelchair: medium-dark skin tone", key: ":woman_in_motorized_wheelchair_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FF}\u{200D}\u{1F9BC}", emoji: "👩🏿", name: "woman in motorized wheelchair: dark skin tone", key: ":woman_in_motorized_wheelchair_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{200D}\u{1F9BD}", emoji: "🧑", name: "person in manual wheelchair", key: ":person_in_manual_wheelchair:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FB}\u{200D}\u{1F9BD}", emoji: "🧑🏻‍", name: "person in manual wheelchair: light skin tone", key: ":person_in_manual_wheelchair_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FC}\u{200D}\u{1F9BD}", emoji: "🧑🏼‍", name: "person in manual wheelchair: medium-light skin tone", key: ":person_in_manual_wheelchair_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FD}\u{200D}\u{1F9BD}", emoji: "🧑🏽‍", name: "person in manual wheelchair: medium skin tone", key: ":person_in_manual_wheelchair_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FE}\u{200D}\u{1F9BD}", emoji: "🧑🏾‍", name: "person in manual wheelchair: medium-dark skin tone", key: ":person_in_manual_wheelchair_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FF}\u{200D}\u{1F9BD}", emoji: "🧑🏿‍", name: "person in manual wheelchair: dark skin tone", key: ":person_in_manual_wheelchair_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{200D}\u{1F9BD}", emoji: "👨", name: "man in manual wheelchair", key: ":man_in_manual_wheelchair:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FB}\u{200D}\u{1F9BD}", emoji: "👨🏻", name: "man in manual wheelchair: light skin tone", key: ":man_in_manual_wheelchair_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FC}\u{200D}\u{1F9BD}", emoji: "👨🏼", name: "man in manual wheelchair: medium-light skin tone", key: ":man_in_manual_wheelchair_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FD}\u{200D}\u{1F9BD}", emoji: "👨🏽", name: "man in manual wheelchair: medium skin tone", key: ":man_in_manual_wheelchair_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FE}\u{200D}\u{1F9BD}", emoji: "👨🏾", name: "man in manual wheelchair: medium-dark skin tone", key: ":man_in_manual_wheelchair_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FF}\u{200D}\u{1F9BD}", emoji: "👨🏿", name: "man in manual wheelchair: dark skin tone", key: ":man_in_manual_wheelchair_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{200D}\u{1F9BD}", emoji: "👩", name: "woman in manual wheelchair", key: ":woman_in_manual_wheelchair:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FB}\u{200D}\u{1F9BD}", emoji: "👩🏻", name: "woman in manual wheelchair: light skin tone", key: ":woman_in_manual_wheelchair_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FC}\u{200D}\u{1F9BD}", emoji: "👩🏼", name: "woman in manual wheelchair: medium-light skin tone", key: ":woman_in_manual_wheelchair_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FD}\u{200D}\u{1F9BD}", emoji: "👩🏽", name: "woman in manual wheelchair: medium skin tone", key: ":woman_in_manual_wheelchair_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FE}\u{200D}\u{1F9BD}", emoji: "👩🏾", name: "woman in manual wheelchair: medium-dark skin tone", key: ":woman_in_manual_wheelchair_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FF}\u{200D}\u{1F9BD}", emoji: "👩🏿", name: "woman in manual wheelchair: dark skin tone", key: ":woman_in_manual_wheelchair_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3C3}", emoji: "🏃", name: "person running", key: ":person_running:", group: "People & Body", },
  { unicode: "\u{1F3C3}\u{1F3FB}", emoji: "🏃🏻", name: "person running: light skin tone", key: ":person_running_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3C3}\u{1F3FC}", emoji: "🏃🏼", name: "person running: medium-light skin tone", key: ":person_running_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3C3}\u{1F3FD}", emoji: "🏃🏽", name: "person running: medium skin tone", key: ":person_running_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3C3}\u{1F3FE}", emoji: "🏃🏾", name: "person running: medium-dark skin tone", key: ":person_running_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3C3}\u{1F3FF}", emoji: "🏃🏿", name: "person running: dark skin tone", key: ":person_running_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3C3}\u{200D}\u{2642}", emoji: "🏃‍♂", name: "man running", key: ":man_running:", group: "People & Body", },
  { unicode: "\u{1F3C3}\u{1F3FB}\u{200D}\u{2642}", emoji: "🏃🏻‍♂", name: "man running: light skin tone", key: ":man_running_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3C3}\u{1F3FC}\u{200D}\u{2642}", emoji: "🏃🏼‍♂", name: "man running: medium-light skin tone", key: ":man_running_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3C3}\u{1F3FD}\u{200D}\u{2642}", emoji: "🏃🏽‍♂", name: "man running: medium skin tone", key: ":man_running_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3C3}\u{1F3FE}\u{200D}\u{2642}", emoji: "🏃🏾‍♂", name: "man running: medium-dark skin tone", key: ":man_running_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3C3}\u{1F3FF}\u{200D}\u{2642}", emoji: "🏃🏿‍♂", name: "man running: dark skin tone", key: ":man_running_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3C3}\u{200D}\u{2640}", emoji: "🏃‍♀", name: "woman running", key: ":woman_running:", group: "People & Body", },
  { unicode: "\u{1F3C3}\u{1F3FB}\u{200D}\u{2640}", emoji: "🏃🏻‍♀", name: "woman running: light skin tone", key: ":woman_running_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3C3}\u{1F3FC}\u{200D}\u{2640}", emoji: "🏃🏼‍♀", name: "woman running: medium-light skin tone", key: ":woman_running_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3C3}\u{1F3FD}\u{200D}\u{2640}", emoji: "🏃🏽‍♀", name: "woman running: medium skin tone", key: ":woman_running_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3C3}\u{1F3FE}\u{200D}\u{2640}", emoji: "🏃🏾‍♀", name: "woman running: medium-dark skin tone", key: ":woman_running_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3C3}\u{1F3FF}\u{200D}\u{2640}", emoji: "🏃🏿‍♀", name: "woman running: dark skin tone", key: ":woman_running_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F483}", emoji: "💃", name: "woman dancing", key: ":woman_dancing:", group: "People & Body", },
  { unicode: "\u{1F483}\u{1F3FB}", emoji: "💃🏻", name: "woman dancing: light skin tone", key: ":woman_dancing_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F483}\u{1F3FC}", emoji: "💃🏼", name: "woman dancing: medium-light skin tone", key: ":woman_dancing_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F483}\u{1F3FD}", emoji: "💃🏽", name: "woman dancing: medium skin tone", key: ":woman_dancing_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F483}\u{1F3FE}", emoji: "💃🏾", name: "woman dancing: medium-dark skin tone", key: ":woman_dancing_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F483}\u{1F3FF}", emoji: "💃🏿", name: "woman dancing: dark skin tone", key: ":woman_dancing_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F57A}", emoji: "🕺", name: "man dancing", key: ":man_dancing:", group: "People & Body", },
  { unicode: "\u{1F57A}\u{1F3FB}", emoji: "🕺🏻", name: "man dancing: light skin tone", key: ":man_dancing_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F57A}\u{1F3FC}", emoji: "🕺🏼", name: "man dancing: medium-light skin tone", key: ":man_dancing_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F57A}\u{1F3FD}", emoji: "🕺🏽", name: "man dancing: medium skin tone", key: ":man_dancing_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F57A}\u{1F3FE}", emoji: "🕺🏾", name: "man dancing: medium-dark skin tone", key: ":man_dancing_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F57A}\u{1F3FF}", emoji: "🕺🏿", name: "man dancing: dark skin tone", key: ":man_dancing_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F574}", emoji: "🕴", name: "man in suit levitating", key: ":man_in_suit_levitating:", group: "People & Body", },
  { unicode: "\u{1F574}\u{1F3FB}", emoji: "🕴🏻", name: "man in suit levitating: light skin tone", key: ":man_in_suit_levitating_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F574}\u{1F3FC}", emoji: "🕴🏼", name: "man in suit levitating: medium-light skin tone", key: ":man_in_suit_levitating_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F574}\u{1F3FD}", emoji: "🕴🏽", name: "man in suit levitating: medium skin tone", key: ":man_in_suit_levitating_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F574}\u{1F3FE}", emoji: "🕴🏾", name: "man in suit levitating: medium-dark skin tone", key: ":man_in_suit_levitating_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F574}\u{1F3FF}", emoji: "🕴🏿", name: "man in suit levitating: dark skin tone", key: ":man_in_suit_levitating_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F46F}", emoji: "👯", name: "people with bunny ears", key: ":people_with_bunny_ears:", group: "People & Body", },
  { unicode: "\u{1F46F}\u{200D}\u{2642}", emoji: "👯‍♂", name: "men with bunny ears", key: ":men_with_bunny_ears:", group: "People & Body", },
  { unicode: "\u{1F46F}\u{200D}\u{2640}", emoji: "👯‍♀", name: "women with bunny ears", key: ":women_with_bunny_ears:", group: "People & Body", },
  { unicode: "\u{1F9D6}", emoji: "🧖", name: "person in steamy room", key: ":person_in_steamy_room:", group: "People & Body", },
  { unicode: "\u{1F9D6}\u{1F3FB}", emoji: "🧖🏻", name: "person in steamy room: light skin tone", key: ":person_in_steamy_room_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D6}\u{1F3FC}", emoji: "🧖🏼", name: "person in steamy room: medium-light skin tone", key: ":person_in_steamy_room_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D6}\u{1F3FD}", emoji: "🧖🏽", name: "person in steamy room: medium skin tone", key: ":person_in_steamy_room_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D6}\u{1F3FE}", emoji: "🧖🏾", name: "person in steamy room: medium-dark skin tone", key: ":person_in_steamy_room_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D6}\u{1F3FF}", emoji: "🧖🏿", name: "person in steamy room: dark skin tone", key: ":person_in_steamy_room_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D6}\u{200D}\u{2642}", emoji: "🧖‍♂", name: "man in steamy room", key: ":man_in_steamy_room:", group: "People & Body", },
  { unicode: "\u{1F9D6}\u{1F3FB}\u{200D}\u{2642}", emoji: "🧖🏻‍♂", name: "man in steamy room: light skin tone", key: ":man_in_steamy_room_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D6}\u{1F3FC}\u{200D}\u{2642}", emoji: "🧖🏼‍♂", name: "man in steamy room: medium-light skin tone", key: ":man_in_steamy_room_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D6}\u{1F3FD}\u{200D}\u{2642}", emoji: "🧖🏽‍♂", name: "man in steamy room: medium skin tone", key: ":man_in_steamy_room_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D6}\u{1F3FE}\u{200D}\u{2642}", emoji: "🧖🏾‍♂", name: "man in steamy room: medium-dark skin tone", key: ":man_in_steamy_room_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D6}\u{1F3FF}\u{200D}\u{2642}", emoji: "🧖🏿‍♂", name: "man in steamy room: dark skin tone", key: ":man_in_steamy_room_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D6}\u{200D}\u{2640}", emoji: "🧖‍♀", name: "woman in steamy room", key: ":woman_in_steamy_room:", group: "People & Body", },
  { unicode: "\u{1F9D6}\u{1F3FB}\u{200D}\u{2640}", emoji: "🧖🏻‍♀", name: "woman in steamy room: light skin tone", key: ":woman_in_steamy_room_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D6}\u{1F3FC}\u{200D}\u{2640}", emoji: "🧖🏼‍♀", name: "woman in steamy room: medium-light skin tone", key: ":woman_in_steamy_room_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D6}\u{1F3FD}\u{200D}\u{2640}", emoji: "🧖🏽‍♀", name: "woman in steamy room: medium skin tone", key: ":woman_in_steamy_room_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D6}\u{1F3FE}\u{200D}\u{2640}", emoji: "🧖🏾‍♀", name: "woman in steamy room: medium-dark skin tone", key: ":woman_in_steamy_room_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D6}\u{1F3FF}\u{200D}\u{2640}", emoji: "🧖🏿‍♀", name: "woman in steamy room: dark skin tone", key: ":woman_in_steamy_room_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D7}", emoji: "🧗", name: "person climbing", key: ":person_climbing:", group: "People & Body", },
  { unicode: "\u{1F9D7}\u{1F3FB}", emoji: "🧗🏻", name: "person climbing: light skin tone", key: ":person_climbing_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D7}\u{1F3FC}", emoji: "🧗🏼", name: "person climbing: medium-light skin tone", key: ":person_climbing_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D7}\u{1F3FD}", emoji: "🧗🏽", name: "person climbing: medium skin tone", key: ":person_climbing_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D7}\u{1F3FE}", emoji: "🧗🏾", name: "person climbing: medium-dark skin tone", key: ":person_climbing_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D7}\u{1F3FF}", emoji: "🧗🏿", name: "person climbing: dark skin tone", key: ":person_climbing_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D7}\u{200D}\u{2642}", emoji: "🧗‍♂", name: "man climbing", key: ":man_climbing:", group: "People & Body", },
  { unicode: "\u{1F9D7}\u{1F3FB}\u{200D}\u{2642}", emoji: "🧗🏻‍♂", name: "man climbing: light skin tone", key: ":man_climbing_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D7}\u{1F3FC}\u{200D}\u{2642}", emoji: "🧗🏼‍♂", name: "man climbing: medium-light skin tone", key: ":man_climbing_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D7}\u{1F3FD}\u{200D}\u{2642}", emoji: "🧗🏽‍♂", name: "man climbing: medium skin tone", key: ":man_climbing_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D7}\u{1F3FE}\u{200D}\u{2642}", emoji: "🧗🏾‍♂", name: "man climbing: medium-dark skin tone", key: ":man_climbing_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D7}\u{1F3FF}\u{200D}\u{2642}", emoji: "🧗🏿‍♂", name: "man climbing: dark skin tone", key: ":man_climbing_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D7}\u{200D}\u{2640}", emoji: "🧗‍♀", name: "woman climbing", key: ":woman_climbing:", group: "People & Body", },
  { unicode: "\u{1F9D7}\u{1F3FB}\u{200D}\u{2640}", emoji: "🧗🏻‍♀", name: "woman climbing: light skin tone", key: ":woman_climbing_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D7}\u{1F3FC}\u{200D}\u{2640}", emoji: "🧗🏼‍♀", name: "woman climbing: medium-light skin tone", key: ":woman_climbing_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D7}\u{1F3FD}\u{200D}\u{2640}", emoji: "🧗🏽‍♀", name: "woman climbing: medium skin tone", key: ":woman_climbing_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D7}\u{1F3FE}\u{200D}\u{2640}", emoji: "🧗🏾‍♀", name: "woman climbing: medium-dark skin tone", key: ":woman_climbing_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D7}\u{1F3FF}\u{200D}\u{2640}", emoji: "🧗🏿‍♀", name: "woman climbing: dark skin tone", key: ":woman_climbing_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F93A}", emoji: "🤺", name: "person fencing", key: ":person_fencing:", group: "People & Body", },
  { unicode: "\u{1F3C7}", emoji: "🏇", name: "horse racing", key: ":horse_racing:", group: "People & Body", },
  { unicode: "\u{1F3C7}\u{1F3FB}", emoji: "🏇🏻", name: "horse racing: light skin tone", key: ":horse_racing_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3C7}\u{1F3FC}", emoji: "🏇🏼", name: "horse racing: medium-light skin tone", key: ":horse_racing_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3C7}\u{1F3FD}", emoji: "🏇🏽", name: "horse racing: medium skin tone", key: ":horse_racing_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3C7}\u{1F3FE}", emoji: "🏇🏾", name: "horse racing: medium-dark skin tone", key: ":horse_racing_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3C7}\u{1F3FF}", emoji: "🏇🏿", name: "horse racing: dark skin tone", key: ":horse_racing_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{26F7}", emoji: "⛷️", name: "skier", key: ":skier:", group: "People & Body", },
  { unicode: "\u{1F3C2}", emoji: "🏂", name: "snowboarder", key: ":snowboarder:", group: "People & Body", },
  { unicode: "\u{1F3C2}\u{1F3FB}", emoji: "🏂🏻", name: "snowboarder: light skin tone", key: ":snowboarder_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3C2}\u{1F3FC}", emoji: "🏂🏼", name: "snowboarder: medium-light skin tone", key: ":snowboarder_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3C2}\u{1F3FD}", emoji: "🏂🏽", name: "snowboarder: medium skin tone", key: ":snowboarder_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3C2}\u{1F3FE}", emoji: "🏂🏾", name: "snowboarder: medium-dark skin tone", key: ":snowboarder_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3C2}\u{1F3FF}", emoji: "🏂🏿", name: "snowboarder: dark skin tone", key: ":snowboarder_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3CC}", emoji: "🏌", name: "person golfing", key: ":person_golfing:", group: "People & Body", },
  { unicode: "\u{1F3CC}\u{1F3FB}", emoji: "🏌🏻", name: "person golfing: light skin tone", key: ":person_golfing_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3CC}\u{1F3FC}", emoji: "🏌🏼", name: "person golfing: medium-light skin tone", key: ":person_golfing_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3CC}\u{1F3FD}", emoji: "🏌🏽", name: "person golfing: medium skin tone", key: ":person_golfing_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3CC}\u{1F3FE}", emoji: "🏌🏾", name: "person golfing: medium-dark skin tone", key: ":person_golfing_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3CC}\u{1F3FF}", emoji: "🏌🏿", name: "person golfing: dark skin tone", key: ":person_golfing_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3CC}\u{200D}\u{2642}", emoji: "🏌‍♂", name: "man golfing", key: ":man_golfing:", group: "People & Body", },
  { unicode: "\u{1F3CC}\u{1F3FB}\u{200D}\u{2642}", emoji: "🏌🏻‍♂", name: "man golfing: light skin tone", key: ":man_golfing_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3CC}\u{1F3FC}\u{200D}\u{2642}", emoji: "🏌🏼‍♂", name: "man golfing: medium-light skin tone", key: ":man_golfing_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3CC}\u{1F3FD}\u{200D}\u{2642}", emoji: "🏌🏽‍♂", name: "man golfing: medium skin tone", key: ":man_golfing_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3CC}\u{1F3FE}\u{200D}\u{2642}", emoji: "🏌🏾‍♂", name: "man golfing: medium-dark skin tone", key: ":man_golfing_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3CC}\u{1F3FF}\u{200D}\u{2642}", emoji: "🏌🏿‍♂", name: "man golfing: dark skin tone", key: ":man_golfing_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3CC}\u{200D}\u{2640}", emoji: "🏌‍♀", name: "woman golfing", key: ":woman_golfing:", group: "People & Body", },
  { unicode: "\u{1F3CC}\u{1F3FB}\u{200D}\u{2640}", emoji: "🏌🏻‍♀", name: "woman golfing: light skin tone", key: ":woman_golfing_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3CC}\u{1F3FC}\u{200D}\u{2640}", emoji: "🏌🏼‍♀", name: "woman golfing: medium-light skin tone", key: ":woman_golfing_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3CC}\u{1F3FD}\u{200D}\u{2640}", emoji: "🏌🏽‍♀", name: "woman golfing: medium skin tone", key: ":woman_golfing_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3CC}\u{1F3FE}\u{200D}\u{2640}", emoji: "🏌🏾‍♀", name: "woman golfing: medium-dark skin tone", key: ":woman_golfing_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3CC}\u{1F3FF}\u{200D}\u{2640}", emoji: "🏌🏿‍♀", name: "woman golfing: dark skin tone", key: ":woman_golfing_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3C4}", emoji: "🏄", name: "person surfing", key: ":person_surfing:", group: "People & Body", },
  { unicode: "\u{1F3C4}\u{1F3FB}", emoji: "🏄🏻", name: "person surfing: light skin tone", key: ":person_surfing_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3C4}\u{1F3FC}", emoji: "🏄🏼", name: "person surfing: medium-light skin tone", key: ":person_surfing_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3C4}\u{1F3FD}", emoji: "🏄🏽", name: "person surfing: medium skin tone", key: ":person_surfing_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3C4}\u{1F3FE}", emoji: "🏄🏾", name: "person surfing: medium-dark skin tone", key: ":person_surfing_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3C4}\u{1F3FF}", emoji: "🏄🏿", name: "person surfing: dark skin tone", key: ":person_surfing_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3C4}\u{200D}\u{2642}", emoji: "🏄‍♂", name: "man surfing", key: ":man_surfing:", group: "People & Body", },
  { unicode: "\u{1F3C4}\u{1F3FB}\u{200D}\u{2642}", emoji: "🏄🏻‍♂", name: "man surfing: light skin tone", key: ":man_surfing_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3C4}\u{1F3FC}\u{200D}\u{2642}", emoji: "🏄🏼‍♂", name: "man surfing: medium-light skin tone", key: ":man_surfing_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3C4}\u{1F3FD}\u{200D}\u{2642}", emoji: "🏄🏽‍♂", name: "man surfing: medium skin tone", key: ":man_surfing_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3C4}\u{1F3FE}\u{200D}\u{2642}", emoji: "🏄🏾‍♂", name: "man surfing: medium-dark skin tone", key: ":man_surfing_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3C4}\u{1F3FF}\u{200D}\u{2642}", emoji: "🏄🏿‍♂", name: "man surfing: dark skin tone", key: ":man_surfing_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3C4}\u{200D}\u{2640}", emoji: "🏄‍♀", name: "woman surfing", key: ":woman_surfing:", group: "People & Body", },
  { unicode: "\u{1F3C4}\u{1F3FB}\u{200D}\u{2640}", emoji: "🏄🏻‍♀", name: "woman surfing: light skin tone", key: ":woman_surfing_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3C4}\u{1F3FC}\u{200D}\u{2640}", emoji: "🏄🏼‍♀", name: "woman surfing: medium-light skin tone", key: ":woman_surfing_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3C4}\u{1F3FD}\u{200D}\u{2640}", emoji: "🏄🏽‍♀", name: "woman surfing: medium skin tone", key: ":woman_surfing_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3C4}\u{1F3FE}\u{200D}\u{2640}", emoji: "🏄🏾‍♀", name: "woman surfing: medium-dark skin tone", key: ":woman_surfing_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3C4}\u{1F3FF}\u{200D}\u{2640}", emoji: "🏄🏿‍♀", name: "woman surfing: dark skin tone", key: ":woman_surfing_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6A3}", emoji: "🚣", name: "person rowing boat", key: ":person_rowing_boat:", group: "People & Body", },
  { unicode: "\u{1F6A3}\u{1F3FB}", emoji: "🚣🏻", name: "person rowing boat: light skin tone", key: ":person_rowing_boat_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6A3}\u{1F3FC}", emoji: "🚣🏼", name: "person rowing boat: medium-light skin tone", key: ":person_rowing_boat_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6A3}\u{1F3FD}", emoji: "🚣🏽", name: "person rowing boat: medium skin tone", key: ":person_rowing_boat_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6A3}\u{1F3FE}", emoji: "🚣🏾", name: "person rowing boat: medium-dark skin tone", key: ":person_rowing_boat_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6A3}\u{1F3FF}", emoji: "🚣🏿", name: "person rowing boat: dark skin tone", key: ":person_rowing_boat_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6A3}\u{200D}\u{2642}", emoji: "🚣‍♂", name: "man rowing boat", key: ":man_rowing_boat:", group: "People & Body", },
  { unicode: "\u{1F6A3}\u{1F3FB}\u{200D}\u{2642}", emoji: "🚣🏻‍♂", name: "man rowing boat: light skin tone", key: ":man_rowing_boat_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6A3}\u{1F3FC}\u{200D}\u{2642}", emoji: "🚣🏼‍♂", name: "man rowing boat: medium-light skin tone", key: ":man_rowing_boat_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6A3}\u{1F3FD}\u{200D}\u{2642}", emoji: "🚣🏽‍♂", name: "man rowing boat: medium skin tone", key: ":man_rowing_boat_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6A3}\u{1F3FE}\u{200D}\u{2642}", emoji: "🚣🏾‍♂", name: "man rowing boat: medium-dark skin tone", key: ":man_rowing_boat_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6A3}\u{1F3FF}\u{200D}\u{2642}", emoji: "🚣🏿‍♂", name: "man rowing boat: dark skin tone", key: ":man_rowing_boat_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6A3}\u{200D}\u{2640}", emoji: "🚣‍♀", name: "woman rowing boat", key: ":woman_rowing_boat:", group: "People & Body", },
  { unicode: "\u{1F6A3}\u{1F3FB}\u{200D}\u{2640}", emoji: "🚣🏻‍♀", name: "woman rowing boat: light skin tone", key: ":woman_rowing_boat_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6A3}\u{1F3FC}\u{200D}\u{2640}", emoji: "🚣🏼‍♀", name: "woman rowing boat: medium-light skin tone", key: ":woman_rowing_boat_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6A3}\u{1F3FD}\u{200D}\u{2640}", emoji: "🚣🏽‍♀", name: "woman rowing boat: medium skin tone", key: ":woman_rowing_boat_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6A3}\u{1F3FE}\u{200D}\u{2640}", emoji: "🚣🏾‍♀", name: "woman rowing boat: medium-dark skin tone", key: ":woman_rowing_boat_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6A3}\u{1F3FF}\u{200D}\u{2640}", emoji: "🚣🏿‍♀", name: "woman rowing boat: dark skin tone", key: ":woman_rowing_boat_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3CA}", emoji: "🏊", name: "person swimming", key: ":person_swimming:", group: "People & Body", },
  { unicode: "\u{1F3CA}\u{1F3FB}", emoji: "🏊🏻", name: "person swimming: light skin tone", key: ":person_swimming_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3CA}\u{1F3FC}", emoji: "🏊🏼", name: "person swimming: medium-light skin tone", key: ":person_swimming_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3CA}\u{1F3FD}", emoji: "🏊🏽", name: "person swimming: medium skin tone", key: ":person_swimming_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3CA}\u{1F3FE}", emoji: "🏊🏾", name: "person swimming: medium-dark skin tone", key: ":person_swimming_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3CA}\u{1F3FF}", emoji: "🏊🏿", name: "person swimming: dark skin tone", key: ":person_swimming_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3CA}\u{200D}\u{2642}", emoji: "🏊‍♂", name: "man swimming", key: ":man_swimming:", group: "People & Body", },
  { unicode: "\u{1F3CA}\u{1F3FB}\u{200D}\u{2642}", emoji: "🏊🏻‍♂", name: "man swimming: light skin tone", key: ":man_swimming_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3CA}\u{1F3FC}\u{200D}\u{2642}", emoji: "🏊🏼‍♂", name: "man swimming: medium-light skin tone", key: ":man_swimming_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3CA}\u{1F3FD}\u{200D}\u{2642}", emoji: "🏊🏽‍♂", name: "man swimming: medium skin tone", key: ":man_swimming_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3CA}\u{1F3FE}\u{200D}\u{2642}", emoji: "🏊🏾‍♂", name: "man swimming: medium-dark skin tone", key: ":man_swimming_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3CA}\u{1F3FF}\u{200D}\u{2642}", emoji: "🏊🏿‍♂", name: "man swimming: dark skin tone", key: ":man_swimming_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3CA}\u{200D}\u{2640}", emoji: "🏊‍♀", name: "woman swimming", key: ":woman_swimming:", group: "People & Body", },
  { unicode: "\u{1F3CA}\u{1F3FB}\u{200D}\u{2640}", emoji: "🏊🏻‍♀", name: "woman swimming: light skin tone", key: ":woman_swimming_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3CA}\u{1F3FC}\u{200D}\u{2640}", emoji: "🏊🏼‍♀", name: "woman swimming: medium-light skin tone", key: ":woman_swimming_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3CA}\u{1F3FD}\u{200D}\u{2640}", emoji: "🏊🏽‍♀", name: "woman swimming: medium skin tone", key: ":woman_swimming_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3CA}\u{1F3FE}\u{200D}\u{2640}", emoji: "🏊🏾‍♀", name: "woman swimming: medium-dark skin tone", key: ":woman_swimming_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3CA}\u{1F3FF}\u{200D}\u{2640}", emoji: "🏊🏿‍♀", name: "woman swimming: dark skin tone", key: ":woman_swimming_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{26F9}", emoji: "⛹", name: "person bouncing ball", key: ":person_bouncing_ball:", group: "People & Body", },
  { unicode: "\u{26F9}\u{1F3FB}", emoji: "⛹🏻", name: "person bouncing ball: light skin tone", key: ":person_bouncing_ball_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{26F9}\u{1F3FC}", emoji: "⛹🏼", name: "person bouncing ball: medium-light skin tone", key: ":person_bouncing_ball_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{26F9}\u{1F3FD}", emoji: "⛹🏽", name: "person bouncing ball: medium skin tone", key: ":person_bouncing_ball_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{26F9}\u{1F3FE}", emoji: "⛹🏾", name: "person bouncing ball: medium-dark skin tone", key: ":person_bouncing_ball_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{26F9}\u{1F3FF}", emoji: "⛹🏿", name: "person bouncing ball: dark skin tone", key: ":person_bouncing_ball_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{26F9}\u{200D}\u{2642}", emoji: "⛹‍♂", name: "man bouncing ball", key: ":man_bouncing_ball:", group: "People & Body", },
  { unicode: "\u{26F9}\u{1F3FB}\u{200D}\u{2642}", emoji: "⛹🏻‍♂", name: "man bouncing ball: light skin tone", key: ":man_bouncing_ball_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{26F9}\u{1F3FC}\u{200D}\u{2642}", emoji: "⛹🏼‍♂", name: "man bouncing ball: medium-light skin tone", key: ":man_bouncing_ball_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{26F9}\u{1F3FD}\u{200D}\u{2642}", emoji: "⛹🏽‍♂", name: "man bouncing ball: medium skin tone", key: ":man_bouncing_ball_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{26F9}\u{1F3FE}\u{200D}\u{2642}", emoji: "⛹🏾‍♂", name: "man bouncing ball: medium-dark skin tone", key: ":man_bouncing_ball_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{26F9}\u{1F3FF}\u{200D}\u{2642}", emoji: "⛹🏿‍♂", name: "man bouncing ball: dark skin tone", key: ":man_bouncing_ball_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{26F9}\u{200D}\u{2640}", emoji: "⛹‍♀", name: "woman bouncing ball", key: ":woman_bouncing_ball:", group: "People & Body", },
  { unicode: "\u{26F9}\u{1F3FB}\u{200D}\u{2640}", emoji: "⛹🏻‍♀", name: "woman bouncing ball: light skin tone", key: ":woman_bouncing_ball_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{26F9}\u{1F3FC}\u{200D}\u{2640}", emoji: "⛹🏼‍♀", name: "woman bouncing ball: medium-light skin tone", key: ":woman_bouncing_ball_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{26F9}\u{1F3FD}\u{200D}\u{2640}", emoji: "⛹🏽‍♀", name: "woman bouncing ball: medium skin tone", key: ":woman_bouncing_ball_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{26F9}\u{1F3FE}\u{200D}\u{2640}", emoji: "⛹🏾‍♀", name: "woman bouncing ball: medium-dark skin tone", key: ":woman_bouncing_ball_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{26F9}\u{1F3FF}\u{200D}\u{2640}", emoji: "⛹🏿‍♀", name: "woman bouncing ball: dark skin tone", key: ":woman_bouncing_ball_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3CB}", emoji: "🏋", name: "person lifting weights", key: ":person_lifting_weights:", group: "People & Body", },
  { unicode: "\u{1F3CB}\u{1F3FB}", emoji: "🏋🏻", name: "person lifting weights: light skin tone", key: ":person_lifting_weights_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3CB}\u{1F3FC}", emoji: "🏋🏼", name: "person lifting weights: medium-light skin tone", key: ":person_lifting_weights_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3CB}\u{1F3FD}", emoji: "🏋🏽", name: "person lifting weights: medium skin tone", key: ":person_lifting_weights_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3CB}\u{1F3FE}", emoji: "🏋🏾", name: "person lifting weights: medium-dark skin tone", key: ":person_lifting_weights_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3CB}\u{1F3FF}", emoji: "🏋🏿", name: "person lifting weights: dark skin tone", key: ":person_lifting_weights_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3CB}\u{200D}\u{2642}", emoji: "🏋‍♂", name: "man lifting weights", key: ":man_lifting_weights:", group: "People & Body", },
  { unicode: "\u{1F3CB}\u{1F3FB}\u{200D}\u{2642}", emoji: "🏋🏻‍♂", name: "man lifting weights: light skin tone", key: ":man_lifting_weights_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3CB}\u{1F3FC}\u{200D}\u{2642}", emoji: "🏋🏼‍♂", name: "man lifting weights: medium-light skin tone", key: ":man_lifting_weights_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3CB}\u{1F3FD}\u{200D}\u{2642}", emoji: "🏋🏽‍♂", name: "man lifting weights: medium skin tone", key: ":man_lifting_weights_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3CB}\u{1F3FE}\u{200D}\u{2642}", emoji: "🏋🏾‍♂", name: "man lifting weights: medium-dark skin tone", key: ":man_lifting_weights_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3CB}\u{1F3FF}\u{200D}\u{2642}", emoji: "🏋🏿‍♂", name: "man lifting weights: dark skin tone", key: ":man_lifting_weights_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3CB}\u{200D}\u{2640}", emoji: "🏋‍♀", name: "woman lifting weights", key: ":woman_lifting_weights:", group: "People & Body", },
  { unicode: "\u{1F3CB}\u{1F3FB}\u{200D}\u{2640}", emoji: "🏋🏻‍♀", name: "woman lifting weights: light skin tone", key: ":woman_lifting_weights_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3CB}\u{1F3FC}\u{200D}\u{2640}", emoji: "🏋🏼‍♀", name: "woman lifting weights: medium-light skin tone", key: ":woman_lifting_weights_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3CB}\u{1F3FD}\u{200D}\u{2640}", emoji: "🏋🏽‍♀", name: "woman lifting weights: medium skin tone", key: ":woman_lifting_weights_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3CB}\u{1F3FE}\u{200D}\u{2640}", emoji: "🏋🏾‍♀", name: "woman lifting weights: medium-dark skin tone", key: ":woman_lifting_weights_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F3CB}\u{1F3FF}\u{200D}\u{2640}", emoji: "🏋🏿‍♀", name: "woman lifting weights: dark skin tone", key: ":woman_lifting_weights_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6B4}", emoji: "🚴", name: "person biking", key: ":person_biking:", group: "People & Body", },
  { unicode: "\u{1F6B4}\u{1F3FB}", emoji: "🚴🏻", name: "person biking: light skin tone", key: ":person_biking_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6B4}\u{1F3FC}", emoji: "🚴🏼", name: "person biking: medium-light skin tone", key: ":person_biking_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6B4}\u{1F3FD}", emoji: "🚴🏽", name: "person biking: medium skin tone", key: ":person_biking_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6B4}\u{1F3FE}", emoji: "🚴🏾", name: "person biking: medium-dark skin tone", key: ":person_biking_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6B4}\u{1F3FF}", emoji: "🚴🏿", name: "person biking: dark skin tone", key: ":person_biking_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6B4}\u{200D}\u{2642}", emoji: "🚴‍♂", name: "man biking", key: ":man_biking:", group: "People & Body", },
  { unicode: "\u{1F6B4}\u{1F3FB}\u{200D}\u{2642}", emoji: "🚴🏻‍♂", name: "man biking: light skin tone", key: ":man_biking_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6B4}\u{1F3FC}\u{200D}\u{2642}", emoji: "🚴🏼‍♂", name: "man biking: medium-light skin tone", key: ":man_biking_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6B4}\u{1F3FD}\u{200D}\u{2642}", emoji: "🚴🏽‍♂", name: "man biking: medium skin tone", key: ":man_biking_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6B4}\u{1F3FE}\u{200D}\u{2642}", emoji: "🚴🏾‍♂", name: "man biking: medium-dark skin tone", key: ":man_biking_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6B4}\u{1F3FF}\u{200D}\u{2642}", emoji: "🚴🏿‍♂", name: "man biking: dark skin tone", key: ":man_biking_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6B4}\u{200D}\u{2640}", emoji: "🚴‍♀", name: "woman biking", key: ":woman_biking:", group: "People & Body", },
  { unicode: "\u{1F6B4}\u{1F3FB}\u{200D}\u{2640}", emoji: "🚴🏻‍♀", name: "woman biking: light skin tone", key: ":woman_biking_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6B4}\u{1F3FC}\u{200D}\u{2640}", emoji: "🚴🏼‍♀", name: "woman biking: medium-light skin tone", key: ":woman_biking_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6B4}\u{1F3FD}\u{200D}\u{2640}", emoji: "🚴🏽‍♀", name: "woman biking: medium skin tone", key: ":woman_biking_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6B4}\u{1F3FE}\u{200D}\u{2640}", emoji: "🚴🏾‍♀", name: "woman biking: medium-dark skin tone", key: ":woman_biking_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6B4}\u{1F3FF}\u{200D}\u{2640}", emoji: "🚴🏿‍♀", name: "woman biking: dark skin tone", key: ":woman_biking_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6B5}", emoji: "🚵", name: "person mountain biking", key: ":person_mountain_biking:", group: "People & Body", },
  { unicode: "\u{1F6B5}\u{1F3FB}", emoji: "🚵🏻", name: "person mountain biking: light skin tone", key: ":person_mountain_biking_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6B5}\u{1F3FC}", emoji: "🚵🏼", name: "person mountain biking: medium-light skin tone", key: ":person_mountain_biking_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6B5}\u{1F3FD}", emoji: "🚵🏽", name: "person mountain biking: medium skin tone", key: ":person_mountain_biking_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6B5}\u{1F3FE}", emoji: "🚵🏾", name: "person mountain biking: medium-dark skin tone", key: ":person_mountain_biking_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6B5}\u{1F3FF}", emoji: "🚵🏿", name: "person mountain biking: dark skin tone", key: ":person_mountain_biking_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6B5}\u{200D}\u{2642}", emoji: "🚵‍♂", name: "man mountain biking", key: ":man_mountain_biking:", group: "People & Body", },
  { unicode: "\u{1F6B5}\u{1F3FB}\u{200D}\u{2642}", emoji: "🚵🏻‍♂", name: "man mountain biking: light skin tone", key: ":man_mountain_biking_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6B5}\u{1F3FC}\u{200D}\u{2642}", emoji: "🚵🏼‍♂", name: "man mountain biking: medium-light skin tone", key: ":man_mountain_biking_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6B5}\u{1F3FD}\u{200D}\u{2642}", emoji: "🚵🏽‍♂", name: "man mountain biking: medium skin tone", key: ":man_mountain_biking_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6B5}\u{1F3FE}\u{200D}\u{2642}", emoji: "🚵🏾‍♂", name: "man mountain biking: medium-dark skin tone", key: ":man_mountain_biking_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6B5}\u{1F3FF}\u{200D}\u{2642}", emoji: "🚵🏿‍♂", name: "man mountain biking: dark skin tone", key: ":man_mountain_biking_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6B5}\u{200D}\u{2640}", emoji: "🚵‍♀", name: "woman mountain biking", key: ":woman_mountain_biking:", group: "People & Body", },
  { unicode: "\u{1F6B5}\u{1F3FB}\u{200D}\u{2640}", emoji: "🚵🏻‍♀", name: "woman mountain biking: light skin tone", key: ":woman_mountain_biking_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6B5}\u{1F3FC}\u{200D}\u{2640}", emoji: "🚵🏼‍♀", name: "woman mountain biking: medium-light skin tone", key: ":woman_mountain_biking_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6B5}\u{1F3FD}\u{200D}\u{2640}", emoji: "🚵🏽‍♀", name: "woman mountain biking: medium skin tone", key: ":woman_mountain_biking_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6B5}\u{1F3FE}\u{200D}\u{2640}", emoji: "🚵🏾‍♀", name: "woman mountain biking: medium-dark skin tone", key: ":woman_mountain_biking_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6B5}\u{1F3FF}\u{200D}\u{2640}", emoji: "🚵🏿‍♀", name: "woman mountain biking: dark skin tone", key: ":woman_mountain_biking_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F938}", emoji: "🤸", name: "person cartwheeling", key: ":person_cartwheeling:", group: "People & Body", },
  { unicode: "\u{1F938}\u{1F3FB}", emoji: "🤸🏻", name: "person cartwheeling: light skin tone", key: ":person_cartwheeling_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F938}\u{1F3FC}", emoji: "🤸🏼", name: "person cartwheeling: medium-light skin tone", key: ":person_cartwheeling_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F938}\u{1F3FD}", emoji: "🤸🏽", name: "person cartwheeling: medium skin tone", key: ":person_cartwheeling_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F938}\u{1F3FE}", emoji: "🤸🏾", name: "person cartwheeling: medium-dark skin tone", key: ":person_cartwheeling_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F938}\u{1F3FF}", emoji: "🤸🏿", name: "person cartwheeling: dark skin tone", key: ":person_cartwheeling_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F938}\u{200D}\u{2642}", emoji: "🤸‍♂", name: "man cartwheeling", key: ":man_cartwheeling:", group: "People & Body", },
  { unicode: "\u{1F938}\u{1F3FB}\u{200D}\u{2642}", emoji: "🤸🏻‍♂", name: "man cartwheeling: light skin tone", key: ":man_cartwheeling_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F938}\u{1F3FC}\u{200D}\u{2642}", emoji: "🤸🏼‍♂", name: "man cartwheeling: medium-light skin tone", key: ":man_cartwheeling_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F938}\u{1F3FD}\u{200D}\u{2642}", emoji: "🤸🏽‍♂", name: "man cartwheeling: medium skin tone", key: ":man_cartwheeling_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F938}\u{1F3FE}\u{200D}\u{2642}", emoji: "🤸🏾‍♂", name: "man cartwheeling: medium-dark skin tone", key: ":man_cartwheeling_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F938}\u{1F3FF}\u{200D}\u{2642}", emoji: "🤸🏿‍♂", name: "man cartwheeling: dark skin tone", key: ":man_cartwheeling_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F938}\u{200D}\u{2640}", emoji: "🤸‍♀", name: "woman cartwheeling", key: ":woman_cartwheeling:", group: "People & Body", },
  { unicode: "\u{1F938}\u{1F3FB}\u{200D}\u{2640}", emoji: "🤸🏻‍♀", name: "woman cartwheeling: light skin tone", key: ":woman_cartwheeling_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F938}\u{1F3FC}\u{200D}\u{2640}", emoji: "🤸🏼‍♀", name: "woman cartwheeling: medium-light skin tone", key: ":woman_cartwheeling_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F938}\u{1F3FD}\u{200D}\u{2640}", emoji: "🤸🏽‍♀", name: "woman cartwheeling: medium skin tone", key: ":woman_cartwheeling_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F938}\u{1F3FE}\u{200D}\u{2640}", emoji: "🤸🏾‍♀", name: "woman cartwheeling: medium-dark skin tone", key: ":woman_cartwheeling_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F938}\u{1F3FF}\u{200D}\u{2640}", emoji: "🤸🏿‍♀", name: "woman cartwheeling: dark skin tone", key: ":woman_cartwheeling_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F93C}", emoji: "🤼", name: "people wrestling", key: ":people_wrestling:", group: "People & Body", },
  { unicode: "\u{1F93C}\u{200D}\u{2642}", emoji: "🤼‍♂", name: "men wrestling", key: ":men_wrestling:", group: "People & Body", },
  { unicode: "\u{1F93C}\u{200D}\u{2640}", emoji: "🤼‍♀", name: "women wrestling", key: ":women_wrestling:", group: "People & Body", },
  { unicode: "\u{1F93D}", emoji: "🤽", name: "person playing water polo", key: ":person_playing_water_polo:", group: "People & Body", },
  { unicode: "\u{1F93D}\u{1F3FB}", emoji: "🤽🏻", name: "person playing water polo: light skin tone", key: ":person_playing_water_polo_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F93D}\u{1F3FC}", emoji: "🤽🏼", name: "person playing water polo: medium-light skin tone", key: ":person_playing_water_polo_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F93D}\u{1F3FD}", emoji: "🤽🏽", name: "person playing water polo: medium skin tone", key: ":person_playing_water_polo_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F93D}\u{1F3FE}", emoji: "🤽🏾", name: "person playing water polo: medium-dark skin tone", key: ":person_playing_water_polo_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F93D}\u{1F3FF}", emoji: "🤽🏿", name: "person playing water polo: dark skin tone", key: ":person_playing_water_polo_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F93D}\u{200D}\u{2642}", emoji: "🤽‍♂", name: "man playing water polo", key: ":man_playing_water_polo:", group: "People & Body", },
  { unicode: "\u{1F93D}\u{1F3FB}\u{200D}\u{2642}", emoji: "🤽🏻‍♂", name: "man playing water polo: light skin tone", key: ":man_playing_water_polo_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F93D}\u{1F3FC}\u{200D}\u{2642}", emoji: "🤽🏼‍♂", name: "man playing water polo: medium-light skin tone", key: ":man_playing_water_polo_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F93D}\u{1F3FD}\u{200D}\u{2642}", emoji: "🤽🏽‍♂", name: "man playing water polo: medium skin tone", key: ":man_playing_water_polo_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F93D}\u{1F3FE}\u{200D}\u{2642}", emoji: "🤽🏾‍♂", name: "man playing water polo: medium-dark skin tone", key: ":man_playing_water_polo_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F93D}\u{1F3FF}\u{200D}\u{2642}", emoji: "🤽🏿‍♂", name: "man playing water polo: dark skin tone", key: ":man_playing_water_polo_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F93D}\u{200D}\u{2640}", emoji: "🤽‍♀", name: "woman playing water polo", key: ":woman_playing_water_polo:", group: "People & Body", },
  { unicode: "\u{1F93D}\u{1F3FB}\u{200D}\u{2640}", emoji: "🤽🏻‍♀", name: "woman playing water polo: light skin tone", key: ":woman_playing_water_polo_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F93D}\u{1F3FC}\u{200D}\u{2640}", emoji: "🤽🏼‍♀", name: "woman playing water polo: medium-light skin tone", key: ":woman_playing_water_polo_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F93D}\u{1F3FD}\u{200D}\u{2640}", emoji: "🤽🏽‍♀", name: "woman playing water polo: medium skin tone", key: ":woman_playing_water_polo_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F93D}\u{1F3FE}\u{200D}\u{2640}", emoji: "🤽🏾‍♀", name: "woman playing water polo: medium-dark skin tone", key: ":woman_playing_water_polo_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F93D}\u{1F3FF}\u{200D}\u{2640}", emoji: "🤽🏿‍♀", name: "woman playing water polo: dark skin tone", key: ":woman_playing_water_polo_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F93E}", emoji: "🤾", name: "person playing handball", key: ":person_playing_handball:", group: "People & Body", },
  { unicode: "\u{1F93E}\u{1F3FB}", emoji: "🤾🏻", name: "person playing handball: light skin tone", key: ":person_playing_handball_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F93E}\u{1F3FC}", emoji: "🤾🏼", name: "person playing handball: medium-light skin tone", key: ":person_playing_handball_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F93E}\u{1F3FD}", emoji: "🤾🏽", name: "person playing handball: medium skin tone", key: ":person_playing_handball_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F93E}\u{1F3FE}", emoji: "🤾🏾", name: "person playing handball: medium-dark skin tone", key: ":person_playing_handball_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F93E}\u{1F3FF}", emoji: "🤾🏿", name: "person playing handball: dark skin tone", key: ":person_playing_handball_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F93E}\u{200D}\u{2642}", emoji: "🤾‍♂", name: "man playing handball", key: ":man_playing_handball:", group: "People & Body", },
  { unicode: "\u{1F93E}\u{1F3FB}\u{200D}\u{2642}", emoji: "🤾🏻‍♂", name: "man playing handball: light skin tone", key: ":man_playing_handball_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F93E}\u{1F3FC}\u{200D}\u{2642}", emoji: "🤾🏼‍♂", name: "man playing handball: medium-light skin tone", key: ":man_playing_handball_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F93E}\u{1F3FD}\u{200D}\u{2642}", emoji: "🤾🏽‍♂", name: "man playing handball: medium skin tone", key: ":man_playing_handball_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F93E}\u{1F3FE}\u{200D}\u{2642}", emoji: "🤾🏾‍♂", name: "man playing handball: medium-dark skin tone", key: ":man_playing_handball_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F93E}\u{1F3FF}\u{200D}\u{2642}", emoji: "🤾🏿‍♂", name: "man playing handball: dark skin tone", key: ":man_playing_handball_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F93E}\u{200D}\u{2640}", emoji: "🤾‍♀", name: "woman playing handball", key: ":woman_playing_handball:", group: "People & Body", },
  { unicode: "\u{1F93E}\u{1F3FB}\u{200D}\u{2640}", emoji: "🤾🏻‍♀", name: "woman playing handball: light skin tone", key: ":woman_playing_handball_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F93E}\u{1F3FC}\u{200D}\u{2640}", emoji: "🤾🏼‍♀", name: "woman playing handball: medium-light skin tone", key: ":woman_playing_handball_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F93E}\u{1F3FD}\u{200D}\u{2640}", emoji: "🤾🏽‍♀", name: "woman playing handball: medium skin tone", key: ":woman_playing_handball_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F93E}\u{1F3FE}\u{200D}\u{2640}", emoji: "🤾🏾‍♀", name: "woman playing handball: medium-dark skin tone", key: ":woman_playing_handball_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F93E}\u{1F3FF}\u{200D}\u{2640}", emoji: "🤾🏿‍♀", name: "woman playing handball: dark skin tone", key: ":woman_playing_handball_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F939}", emoji: "🤹", name: "person juggling", key: ":person_juggling:", group: "People & Body", },
  { unicode: "\u{1F939}\u{1F3FB}", emoji: "🤹🏻", name: "person juggling: light skin tone", key: ":person_juggling_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F939}\u{1F3FC}", emoji: "🤹🏼", name: "person juggling: medium-light skin tone", key: ":person_juggling_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F939}\u{1F3FD}", emoji: "🤹🏽", name: "person juggling: medium skin tone", key: ":person_juggling_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F939}\u{1F3FE}", emoji: "🤹🏾", name: "person juggling: medium-dark skin tone", key: ":person_juggling_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F939}\u{1F3FF}", emoji: "🤹🏿", name: "person juggling: dark skin tone", key: ":person_juggling_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F939}\u{200D}\u{2642}", emoji: "🤹‍♂", name: "man juggling", key: ":man_juggling:", group: "People & Body", },
  { unicode: "\u{1F939}\u{1F3FB}\u{200D}\u{2642}", emoji: "🤹🏻‍♂", name: "man juggling: light skin tone", key: ":man_juggling_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F939}\u{1F3FC}\u{200D}\u{2642}", emoji: "🤹🏼‍♂", name: "man juggling: medium-light skin tone", key: ":man_juggling_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F939}\u{1F3FD}\u{200D}\u{2642}", emoji: "🤹🏽‍♂", name: "man juggling: medium skin tone", key: ":man_juggling_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F939}\u{1F3FE}\u{200D}\u{2642}", emoji: "🤹🏾‍♂", name: "man juggling: medium-dark skin tone", key: ":man_juggling_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F939}\u{1F3FF}\u{200D}\u{2642}", emoji: "🤹🏿‍♂", name: "man juggling: dark skin tone", key: ":man_juggling_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F939}\u{200D}\u{2640}", emoji: "🤹‍♀", name: "woman juggling", key: ":woman_juggling:", group: "People & Body", },
  { unicode: "\u{1F939}\u{1F3FB}\u{200D}\u{2640}", emoji: "🤹🏻‍♀", name: "woman juggling: light skin tone", key: ":woman_juggling_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F939}\u{1F3FC}\u{200D}\u{2640}", emoji: "🤹🏼‍♀", name: "woman juggling: medium-light skin tone", key: ":woman_juggling_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F939}\u{1F3FD}\u{200D}\u{2640}", emoji: "🤹🏽‍♀", name: "woman juggling: medium skin tone", key: ":woman_juggling_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F939}\u{1F3FE}\u{200D}\u{2640}", emoji: "🤹🏾‍♀", name: "woman juggling: medium-dark skin tone", key: ":woman_juggling_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F939}\u{1F3FF}\u{200D}\u{2640}", emoji: "🤹🏿‍♀", name: "woman juggling: dark skin tone", key: ":woman_juggling_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D8}", emoji: "🧘", name: "person in lotus position", key: ":person_in_lotus_position:", group: "People & Body", },
  { unicode: "\u{1F9D8}\u{1F3FB}", emoji: "🧘🏻", name: "person in lotus position: light skin tone", key: ":person_in_lotus_position_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D8}\u{1F3FC}", emoji: "🧘🏼", name: "person in lotus position: medium-light skin tone", key: ":person_in_lotus_position_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D8}\u{1F3FD}", emoji: "🧘🏽", name: "person in lotus position: medium skin tone", key: ":person_in_lotus_position_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D8}\u{1F3FE}", emoji: "🧘🏾", name: "person in lotus position: medium-dark skin tone", key: ":person_in_lotus_position_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D8}\u{1F3FF}", emoji: "🧘🏿", name: "person in lotus position: dark skin tone", key: ":person_in_lotus_position_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D8}\u{200D}\u{2642}", emoji: "🧘‍♂", name: "man in lotus position", key: ":man_in_lotus_position:", group: "People & Body", },
  { unicode: "\u{1F9D8}\u{1F3FB}\u{200D}\u{2642}", emoji: "🧘🏻‍♂", name: "man in lotus position: light skin tone", key: ":man_in_lotus_position_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D8}\u{1F3FC}\u{200D}\u{2642}", emoji: "🧘🏼‍♂", name: "man in lotus position: medium-light skin tone", key: ":man_in_lotus_position_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D8}\u{1F3FD}\u{200D}\u{2642}", emoji: "🧘🏽‍♂", name: "man in lotus position: medium skin tone", key: ":man_in_lotus_position_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D8}\u{1F3FE}\u{200D}\u{2642}", emoji: "🧘🏾‍♂", name: "man in lotus position: medium-dark skin tone", key: ":man_in_lotus_position_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D8}\u{1F3FF}\u{200D}\u{2642}", emoji: "🧘🏿‍♂", name: "man in lotus position: dark skin tone", key: ":man_in_lotus_position_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D8}\u{200D}\u{2640}", emoji: "🧘‍♀", name: "woman in lotus position", key: ":woman_in_lotus_position:", group: "People & Body", },
  { unicode: "\u{1F9D8}\u{1F3FB}\u{200D}\u{2640}", emoji: "🧘🏻‍♀", name: "woman in lotus position: light skin tone", key: ":woman_in_lotus_position_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D8}\u{1F3FC}\u{200D}\u{2640}", emoji: "🧘🏼‍♀", name: "woman in lotus position: medium-light skin tone", key: ":woman_in_lotus_position_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D8}\u{1F3FD}\u{200D}\u{2640}", emoji: "🧘🏽‍♀", name: "woman in lotus position: medium skin tone", key: ":woman_in_lotus_position_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D8}\u{1F3FE}\u{200D}\u{2640}", emoji: "🧘🏾‍♀", name: "woman in lotus position: medium-dark skin tone", key: ":woman_in_lotus_position_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D8}\u{1F3FF}\u{200D}\u{2640}", emoji: "🧘🏿‍♀", name: "woman in lotus position: dark skin tone", key: ":woman_in_lotus_position_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6C0}", emoji: "🛀", name: "person taking bath", key: ":person_taking_bath:", group: "People & Body", },
  { unicode: "\u{1F6C0}\u{1F3FB}", emoji: "🛀🏻", name: "person taking bath: light skin tone", key: ":person_taking_bath_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6C0}\u{1F3FC}", emoji: "🛀🏼", name: "person taking bath: medium-light skin tone", key: ":person_taking_bath_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6C0}\u{1F3FD}", emoji: "🛀🏽", name: "person taking bath: medium skin tone", key: ":person_taking_bath_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6C0}\u{1F3FE}", emoji: "🛀🏾", name: "person taking bath: medium-dark skin tone", key: ":person_taking_bath_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6C0}\u{1F3FF}", emoji: "🛀🏿", name: "person taking bath: dark skin tone", key: ":person_taking_bath_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6CC}", emoji: "🛌", name: "person in bed", key: ":person_in_bed:", group: "People & Body", },
  { unicode: "\u{1F6CC}\u{1F3FB}", emoji: "🛌🏻", name: "person in bed: light skin tone", key: ":person_in_bed_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6CC}\u{1F3FC}", emoji: "🛌🏼", name: "person in bed: medium-light skin tone", key: ":person_in_bed_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6CC}\u{1F3FD}", emoji: "🛌🏽", name: "person in bed: medium skin tone", key: ":person_in_bed_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6CC}\u{1F3FE}", emoji: "🛌🏾", name: "person in bed: medium-dark skin tone", key: ":person_in_bed_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F6CC}\u{1F3FF}", emoji: "🛌🏿", name: "person in bed: dark skin tone", key: ":person_in_bed_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{200D}\u{1F91D}\u{200D}\u{1F9D1}", emoji: "🧑‍🤝‍🧑", name: "people holding hands", key: ":people_holding_hands:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FB}\u{200D}\u{1F91D}\u{200D}\u{1F9D1}\u{1F3FB}", emoji: "🧑🏻‍🤝‍🧑🏻", name: "people holding hands: light skin tone", key: ":people_holding_hands_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FB}\u{200D}\u{1F91D}\u{200D}\u{1F9D1}\u{1F3FC}", emoji: "🧑🏻‍🤝‍🧑🏼", name: "people holding hands: light skin tone, medium-light skin tone", key: ":people_holding_hands_light_skin_tone_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FB}\u{200D}\u{1F91D}\u{200D}\u{1F9D1}\u{1F3FD}", emoji: "🧑🏻‍🤝‍🧑🏽", name: "people holding hands: light skin tone, medium skin tone", key: ":people_holding_hands_light_skin_tone_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FB}\u{200D}\u{1F91D}\u{200D}\u{1F9D1}\u{1F3FE}", emoji: "🧑🏻‍🤝‍🧑🏾", name: "people holding hands: light skin tone, medium-dark skin tone", key: ":people_holding_hands_light_skin_tone_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FB}\u{200D}\u{1F91D}\u{200D}\u{1F9D1}\u{1F3FF}", emoji: "🧑🏻‍🤝‍🧑🏿", name: "people holding hands: light skin tone, dark skin tone", key: ":people_holding_hands_light_skin_tone_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FC}\u{200D}\u{1F91D}\u{200D}\u{1F9D1}\u{1F3FB}", emoji: "🧑🏼‍🤝‍🧑🏻", name: "people holding hands: medium-light skin tone, light skin tone", key: ":people_holding_hands_medium_light_skin_tone_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FC}\u{200D}\u{1F91D}\u{200D}\u{1F9D1}\u{1F3FC}", emoji: "🧑🏼‍🤝‍🧑🏼", name: "people holding hands: medium-light skin tone", key: ":people_holding_hands_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FC}\u{200D}\u{1F91D}\u{200D}\u{1F9D1}\u{1F3FD}", emoji: "🧑🏼‍🤝‍🧑🏽", name: "people holding hands: medium-light skin tone, medium skin tone", key: ":people_holding_hands_medium_light_skin_tone_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FC}\u{200D}\u{1F91D}\u{200D}\u{1F9D1}\u{1F3FE}", emoji: "🧑🏼‍🤝‍🧑🏾", name: "people holding hands: medium-light skin tone, medium-dark skin tone", key: ":people_holding_hands_medium_light_skin_tone_medium-dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FC}\u{200D}\u{1F91D}\u{200D}\u{1F9D1}\u{1F3FF}", emoji: "🧑🏼‍🤝‍🧑🏿", name: "people holding hands: medium-light skin tone, dark skin tone", key: ":people_holding_hands_medium_light_skin_tone_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FD}\u{200D}\u{1F91D}\u{200D}\u{1F9D1}\u{1F3FB}", emoji: "🧑🏽‍🤝‍🧑🏻", name: "people holding hands: medium skin tone, light skin tone", key: ":people_holding_hands_medium_skin_tone_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FD}\u{200D}\u{1F91D}\u{200D}\u{1F9D1}\u{1F3FC}", emoji: "🧑🏽‍🤝‍🧑🏼", name: "people holding hands: medium skin tone, medium-light skin tone", key: ":people_holding_hands_medium_skin_tone_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FD}\u{200D}\u{1F91D}\u{200D}\u{1F9D1}\u{1F3FD}", emoji: "🧑🏽‍🤝‍🧑🏽", name: "people holding hands: medium skin tone", key: ":people_holding_hands_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FD}\u{200D}\u{1F91D}\u{200D}\u{1F9D1}\u{1F3FE}", emoji: "🧑🏽‍🤝‍🧑🏾", name: "people holding hands: medium skin tone, medium-dark skin tone", key: ":people_holding_hands_medium_skin_tone_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FD}\u{200D}\u{1F91D}\u{200D}\u{1F9D1}\u{1F3FF}", emoji: "🧑🏽‍🤝‍🧑🏿", name: "people holding hands: medium skin tone, dark skin tone", key: ":people_holding_hands_medium_skin_tone_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FE}\u{200D}\u{1F91D}\u{200D}\u{1F9D1}\u{1F3FB}", emoji: "🧑🏾‍🤝‍🧑🏻", name: "people holding hands: medium-dark skin tone, light skin tone", key: ":people_holding_hands_medium_dark_skin_tone_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FE}\u{200D}\u{1F91D}\u{200D}\u{1F9D1}\u{1F3FC}", emoji: "🧑🏾‍🤝‍🧑🏼", name: "people holding hands: medium-dark skin tone, medium-light skin tone", key: ":people_holding_hands_medium_dark_skin_tone_medium-light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FE}\u{200D}\u{1F91D}\u{200D}\u{1F9D1}\u{1F3FD}", emoji: "🧑🏾‍🤝‍🧑🏽", name: "people holding hands: medium-dark skin tone, medium skin tone", key: ":people_holding_hands_medium_dark_skin_tone_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FE}\u{200D}\u{1F91D}\u{200D}\u{1F9D1}\u{1F3FE}", emoji: "🧑🏾‍🤝‍🧑🏾", name: "people holding hands: medium-dark skin tone", key: ":people_holding_hands_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FE}\u{200D}\u{1F91D}\u{200D}\u{1F9D1}\u{1F3FF}", emoji: "🧑🏾‍🤝‍🧑🏿", name: "people holding hands: medium-dark skin tone, dark skin tone", key: ":people_holding_hands_medium_dark_skin_tone_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FF}\u{200D}\u{1F91D}\u{200D}\u{1F9D1}\u{1F3FB}", emoji: "🧑🏿‍🤝‍🧑🏻", name: "people holding hands: dark skin tone, light skin tone", key: ":people_holding_hands_dark_skin_tone_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FF}\u{200D}\u{1F91D}\u{200D}\u{1F9D1}\u{1F3FC}", emoji: "🧑🏿‍🤝‍🧑🏼", name: "people holding hands: dark skin tone, medium-light skin tone", key: ":people_holding_hands_dark_skin_tone_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FF}\u{200D}\u{1F91D}\u{200D}\u{1F9D1}\u{1F3FD}", emoji: "🧑🏿‍🤝‍🧑🏽", name: "people holding hands: dark skin tone, medium skin tone", key: ":people_holding_hands_dark_skin_tone_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FF}\u{200D}\u{1F91D}\u{200D}\u{1F9D1}\u{1F3FE}", emoji: "🧑🏿‍🤝‍🧑🏾", name: "people holding hands: dark skin tone, medium-dark skin tone", key: ":people_holding_hands_dark_skin_tone_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F9D1}\u{1F3FF}\u{200D}\u{1F91D}\u{200D}\u{1F9D1}\u{1F3FF}", emoji: "🧑🏿‍🤝‍🧑🏿", name: "people holding hands: dark skin tone", key: ":people_holding_hands_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F46D}", emoji: "👭", name: "women holding hands", key: ":women_holding_hands:", group: "People & Body", },
  { unicode: "\u{1F46D}\u{1F3FB}", emoji: "👭🏻", name: "women holding hands: light skin tone", key: ":women_holding_hands_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FB}\u{200D}\u{1F91D}\u{200D}\u{1F469}\u{1F3FC}", emoji: "👩🏻‍🤝‍👩🏼", name: "women holding hands: light skin tone, medium-light skin tone", key: ":women_holding_hands_light_skin_tone_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FB}\u{200D}\u{1F91D}\u{200D}\u{1F469}\u{1F3FD}", emoji: "👩🏻‍🤝‍👩🏽", name: "women holding hands: light skin tone, medium skin tone", key: ":women_holding_hands_light_skin_tone_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FB}\u{200D}\u{1F91D}\u{200D}\u{1F469}\u{1F3FE}", emoji: "👩🏻‍🤝‍👩🏾", name: "women holding hands: light skin tone, medium-dark skin tone", key: ":women_holding_hands_light_skin_tone_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FB}\u{200D}\u{1F91D}\u{200D}\u{1F469}\u{1F3FF}", emoji: "👩🏻‍🤝‍👩🏿", name: "women holding hands: light skin tone, dark skin tone", key: ":women_holding_hands_light_skin_tone_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FC}\u{200D}\u{1F91D}\u{200D}\u{1F469}\u{1F3FB}", emoji: "👩🏼‍🤝‍👩🏻", name: "women holding hands: medium-light skin tone, light skin tone", key: ":women_holding_hands_medium_light_skin_tone_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F46D}\u{1F3FC}", emoji: "👭🏼", name: "women holding hands: medium-light skin tone", key: ":women_holding_hands_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FC}\u{200D}\u{1F91D}\u{200D}\u{1F469}\u{1F3FD}", emoji: "👩🏼‍🤝‍👩🏽", name: "women holding hands: medium-light skin tone, medium skin tone", key: ":women_holding_hands_medium_light_skin_tone_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FC}\u{200D}\u{1F91D}\u{200D}\u{1F469}\u{1F3FE}", emoji: "👩🏼‍🤝‍👩🏾", name: "women holding hands: medium-light skin tone, medium-dark skin tone", key: ":women_holding_hands_medium_light_skin_tone_medium-dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FC}\u{200D}\u{1F91D}\u{200D}\u{1F469}\u{1F3FF}", emoji: "👩🏼‍🤝‍👩🏿", name: "women holding hands: medium-light skin tone, dark skin tone", key: ":women_holding_hands_medium_light_skin_tone_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FD}\u{200D}\u{1F91D}\u{200D}\u{1F469}\u{1F3FB}", emoji: "👩🏽‍🤝‍👩🏻", name: "women holding hands: medium skin tone, light skin tone", key: ":women_holding_hands_medium_skin_tone_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FD}\u{200D}\u{1F91D}\u{200D}\u{1F469}\u{1F3FC}", emoji: "👩🏽‍🤝‍👩🏼", name: "women holding hands: medium skin tone, medium-light skin tone", key: ":women_holding_hands_medium_skin_tone_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F46D}\u{1F3FD}", emoji: "👭🏽", name: "women holding hands: medium skin tone", key: ":women_holding_hands_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FD}\u{200D}\u{1F91D}\u{200D}\u{1F469}\u{1F3FE}", emoji: "👩🏽‍🤝‍👩🏾", name: "women holding hands: medium skin tone, medium-dark skin tone", key: ":women_holding_hands_medium_skin_tone_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FD}\u{200D}\u{1F91D}\u{200D}\u{1F469}\u{1F3FF}", emoji: "👩🏽‍🤝‍👩🏿", name: "women holding hands: medium skin tone, dark skin tone", key: ":women_holding_hands_medium_skin_tone_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FE}\u{200D}\u{1F91D}\u{200D}\u{1F469}\u{1F3FB}", emoji: "👩🏾‍🤝‍👩🏻", name: "women holding hands: medium-dark skin tone, light skin tone", key: ":women_holding_hands_medium_dark_skin_tone_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FE}\u{200D}\u{1F91D}\u{200D}\u{1F469}\u{1F3FC}", emoji: "👩🏾‍🤝‍👩🏼", name: "women holding hands: medium-dark skin tone, medium-light skin tone", key: ":women_holding_hands_medium_dark_skin_tone_medium-light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FE}\u{200D}\u{1F91D}\u{200D}\u{1F469}\u{1F3FD}", emoji: "👩🏾‍🤝‍👩🏽", name: "women holding hands: medium-dark skin tone, medium skin tone", key: ":women_holding_hands_medium_dark_skin_tone_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F46D}\u{1F3FE}", emoji: "👭🏾", name: "women holding hands: medium-dark skin tone", key: ":women_holding_hands_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FE}\u{200D}\u{1F91D}\u{200D}\u{1F469}\u{1F3FF}", emoji: "👩🏾‍🤝‍👩🏿", name: "women holding hands: medium-dark skin tone, dark skin tone", key: ":women_holding_hands_medium_dark_skin_tone_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FF}\u{200D}\u{1F91D}\u{200D}\u{1F469}\u{1F3FB}", emoji: "👩🏿‍🤝‍👩🏻", name: "women holding hands: dark skin tone, light skin tone", key: ":women_holding_hands_dark_skin_tone_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FF}\u{200D}\u{1F91D}\u{200D}\u{1F469}\u{1F3FC}", emoji: "👩🏿‍🤝‍👩🏼", name: "women holding hands: dark skin tone, medium-light skin tone", key: ":women_holding_hands_dark_skin_tone_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FF}\u{200D}\u{1F91D}\u{200D}\u{1F469}\u{1F3FD}", emoji: "👩🏿‍🤝‍👩🏽", name: "women holding hands: dark skin tone, medium skin tone", key: ":women_holding_hands_dark_skin_tone_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FF}\u{200D}\u{1F91D}\u{200D}\u{1F469}\u{1F3FE}", emoji: "👩🏿‍🤝‍👩🏾", name: "women holding hands: dark skin tone, medium-dark skin tone", key: ":women_holding_hands_dark_skin_tone_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F46D}\u{1F3FF}", emoji: "👭🏿", name: "women holding hands: dark skin tone", key: ":women_holding_hands_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F46B}", emoji: "👫", name: "woman and man holding hands", key: ":woman_and_man_holding_hands:", group: "People & Body", },
  { unicode: "\u{1F46B}\u{1F3FB}", emoji: "👫🏻", name: "woman and man holding hands: light skin tone", key: ":woman_and_man_holding_hands_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FB}\u{200D}\u{1F91D}\u{200D}\u{1F468}\u{1F3FC}", emoji: "👩🏻‍🤝‍👨🏼", name: "woman and man holding hands: light skin tone, medium-light skin tone", key: ":woman_and_man_holding_hands_light_skin_tone_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FB}\u{200D}\u{1F91D}\u{200D}\u{1F468}\u{1F3FD}", emoji: "👩🏻‍🤝‍👨🏽", name: "woman and man holding hands: light skin tone, medium skin tone", key: ":woman_and_man_holding_hands_light_skin_tone_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FB}\u{200D}\u{1F91D}\u{200D}\u{1F468}\u{1F3FE}", emoji: "👩🏻‍🤝‍👨🏾", name: "woman and man holding hands: light skin tone, medium-dark skin tone", key: ":woman_and_man_holding_hands_light_skin_tone_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FB}\u{200D}\u{1F91D}\u{200D}\u{1F468}\u{1F3FF}", emoji: "👩🏻‍🤝‍👨🏿", name: "woman and man holding hands: light skin tone, dark skin tone", key: ":woman_and_man_holding_hands_light_skin_tone_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FC}\u{200D}\u{1F91D}\u{200D}\u{1F468}\u{1F3FB}", emoji: "👩🏼‍🤝‍👨🏻", name: "woman and man holding hands: medium-light skin tone, light skin tone", key: ":woman_and_man_holding_hands_medium_light_skin_tone_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F46B}\u{1F3FC}", emoji: "👫🏼", name: "woman and man holding hands: medium-light skin tone", key: ":woman_and_man_holding_hands_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FC}\u{200D}\u{1F91D}\u{200D}\u{1F468}\u{1F3FD}", emoji: "👩🏼‍🤝‍👨🏽", name: "woman and man holding hands: medium-light skin tone, medium skin tone", key: ":woman_and_man_holding_hands_medium_light_skin_tone_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FC}\u{200D}\u{1F91D}\u{200D}\u{1F468}\u{1F3FE}", emoji: "👩🏼‍🤝‍👨🏾", name: "woman and man holding hands: medium-light skin tone, medium-dark skin tone", key: ":woman_and_man_holding_hands_medium_light_skin_tone_medium-dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FC}\u{200D}\u{1F91D}\u{200D}\u{1F468}\u{1F3FF}", emoji: "👩🏼‍🤝‍👨🏿", name: "woman and man holding hands: medium-light skin tone, dark skin tone", key: ":woman_and_man_holding_hands_medium_light_skin_tone_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FD}\u{200D}\u{1F91D}\u{200D}\u{1F468}\u{1F3FB}", emoji: "👩🏽‍🤝‍👨🏻", name: "woman and man holding hands: medium skin tone, light skin tone", key: ":woman_and_man_holding_hands_medium_skin_tone_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FD}\u{200D}\u{1F91D}\u{200D}\u{1F468}\u{1F3FC}", emoji: "👩🏽‍🤝‍👨🏼", name: "woman and man holding hands: medium skin tone, medium-light skin tone", key: ":woman_and_man_holding_hands_medium_skin_tone_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F46B}\u{1F3FD}", emoji: "👫🏽", name: "woman and man holding hands: medium skin tone", key: ":woman_and_man_holding_hands_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FD}\u{200D}\u{1F91D}\u{200D}\u{1F468}\u{1F3FE}", emoji: "👩🏽‍🤝‍👨🏾", name: "woman and man holding hands: medium skin tone, medium-dark skin tone", key: ":woman_and_man_holding_hands_medium_skin_tone_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FD}\u{200D}\u{1F91D}\u{200D}\u{1F468}\u{1F3FF}", emoji: "👩🏽‍🤝‍👨🏿", name: "woman and man holding hands: medium skin tone, dark skin tone", key: ":woman_and_man_holding_hands_medium_skin_tone_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FE}\u{200D}\u{1F91D}\u{200D}\u{1F468}\u{1F3FB}", emoji: "👩🏾‍🤝‍👨🏻", name: "woman and man holding hands: medium-dark skin tone, light skin tone", key: ":woman_and_man_holding_hands_medium_dark_skin_tone_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FE}\u{200D}\u{1F91D}\u{200D}\u{1F468}\u{1F3FC}", emoji: "👩🏾‍🤝‍👨🏼", name: "woman and man holding hands: medium-dark skin tone, medium-light skin tone", key: ":woman_and_man_holding_hands_medium_dark_skin_tone_medium-light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FE}\u{200D}\u{1F91D}\u{200D}\u{1F468}\u{1F3FD}", emoji: "👩🏾‍🤝‍👨🏽", name: "woman and man holding hands: medium-dark skin tone, medium skin tone", key: ":woman_and_man_holding_hands_medium_dark_skin_tone_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F46B}\u{1F3FE}", emoji: "👫🏾", name: "woman and man holding hands: medium-dark skin tone", key: ":woman_and_man_holding_hands_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FE}\u{200D}\u{1F91D}\u{200D}\u{1F468}\u{1F3FF}", emoji: "👩🏾‍🤝‍👨🏿", name: "woman and man holding hands: medium-dark skin tone, dark skin tone", key: ":woman_and_man_holding_hands_medium_dark_skin_tone_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FF}\u{200D}\u{1F91D}\u{200D}\u{1F468}\u{1F3FB}", emoji: "👩🏿‍🤝‍👨🏻", name: "woman and man holding hands: dark skin tone, light skin tone", key: ":woman_and_man_holding_hands_dark_skin_tone_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FF}\u{200D}\u{1F91D}\u{200D}\u{1F468}\u{1F3FC}", emoji: "👩🏿‍🤝‍👨🏼", name: "woman and man holding hands: dark skin tone, medium-light skin tone", key: ":woman_and_man_holding_hands_dark_skin_tone_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FF}\u{200D}\u{1F91D}\u{200D}\u{1F468}\u{1F3FD}", emoji: "👩🏿‍🤝‍👨🏽", name: "woman and man holding hands: dark skin tone, medium skin tone", key: ":woman_and_man_holding_hands_dark_skin_tone_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F469}\u{1F3FF}\u{200D}\u{1F91D}\u{200D}\u{1F468}\u{1F3FE}", emoji: "👩🏿‍🤝‍👨🏾", name: "woman and man holding hands: dark skin tone, medium-dark skin tone", key: ":woman_and_man_holding_hands_dark_skin_tone_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F46B}\u{1F3FF}", emoji: "👫🏿", name: "woman and man holding hands: dark skin tone", key: ":woman_and_man_holding_hands_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F46C}", emoji: "👬", name: "men holding hands", key: ":men_holding_hands:", group: "People & Body", },
  { unicode: "\u{1F46C}\u{1F3FB}", emoji: "👬🏻", name: "men holding hands: light skin tone", key: ":men_holding_hands_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FB}\u{200D}\u{1F91D}\u{200D}\u{1F468}\u{1F3FC}", emoji: "👨🏻‍🤝‍👨🏼", name: "men holding hands: light skin tone, medium-light skin tone", key: ":men_holding_hands_light_skin_tone_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FB}\u{200D}\u{1F91D}\u{200D}\u{1F468}\u{1F3FD}", emoji: "👨🏻‍🤝‍👨🏽", name: "men holding hands: light skin tone, medium skin tone", key: ":men_holding_hands_light_skin_tone_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FB}\u{200D}\u{1F91D}\u{200D}\u{1F468}\u{1F3FE}", emoji: "👨🏻‍🤝‍👨🏾", name: "men holding hands: light skin tone, medium-dark skin tone", key: ":men_holding_hands_light_skin_tone_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FB}\u{200D}\u{1F91D}\u{200D}\u{1F468}\u{1F3FF}", emoji: "👨🏻‍🤝‍👨🏿", name: "men holding hands: light skin tone, dark skin tone", key: ":men_holding_hands_light_skin_tone_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FC}\u{200D}\u{1F91D}\u{200D}\u{1F468}\u{1F3FB}", emoji: "👨🏼‍🤝‍👨🏻", name: "men holding hands: medium-light skin tone, light skin tone", key: ":men_holding_hands_medium_light_skin_tone_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F46C}\u{1F3FC}", emoji: "👬🏼", name: "men holding hands: medium-light skin tone", key: ":men_holding_hands_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FC}\u{200D}\u{1F91D}\u{200D}\u{1F468}\u{1F3FD}", emoji: "👨🏼‍🤝‍👨🏽", name: "men holding hands: medium-light skin tone, medium skin tone", key: ":men_holding_hands_medium_light_skin_tone_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FC}\u{200D}\u{1F91D}\u{200D}\u{1F468}\u{1F3FE}", emoji: "👨🏼‍🤝‍👨🏾", name: "men holding hands: medium-light skin tone, medium-dark skin tone", key: ":men_holding_hands_medium_light_skin_tone_medium-dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FC}\u{200D}\u{1F91D}\u{200D}\u{1F468}\u{1F3FF}", emoji: "👨🏼‍🤝‍👨🏿", name: "men holding hands: medium-light skin tone, dark skin tone", key: ":men_holding_hands_medium_light_skin_tone_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FD}\u{200D}\u{1F91D}\u{200D}\u{1F468}\u{1F3FB}", emoji: "👨🏽‍🤝‍👨🏻", name: "men holding hands: medium skin tone, light skin tone", key: ":men_holding_hands_medium_skin_tone_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FD}\u{200D}\u{1F91D}\u{200D}\u{1F468}\u{1F3FC}", emoji: "👨🏽‍🤝‍👨🏼", name: "men holding hands: medium skin tone, medium-light skin tone", key: ":men_holding_hands_medium_skin_tone_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F46C}\u{1F3FD}", emoji: "👬🏽", name: "men holding hands: medium skin tone", key: ":men_holding_hands_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FD}\u{200D}\u{1F91D}\u{200D}\u{1F468}\u{1F3FE}", emoji: "👨🏽‍🤝‍👨🏾", name: "men holding hands: medium skin tone, medium-dark skin tone", key: ":men_holding_hands_medium_skin_tone_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FD}\u{200D}\u{1F91D}\u{200D}\u{1F468}\u{1F3FF}", emoji: "👨🏽‍🤝‍👨🏿", name: "men holding hands: medium skin tone, dark skin tone", key: ":men_holding_hands_medium_skin_tone_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FE}\u{200D}\u{1F91D}\u{200D}\u{1F468}\u{1F3FB}", emoji: "👨🏾‍🤝‍👨🏻", name: "men holding hands: medium-dark skin tone, light skin tone", key: ":men_holding_hands_medium_dark_skin_tone_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FE}\u{200D}\u{1F91D}\u{200D}\u{1F468}\u{1F3FC}", emoji: "👨🏾‍🤝‍👨🏼", name: "men holding hands: medium-dark skin tone, medium-light skin tone", key: ":men_holding_hands_medium_dark_skin_tone_medium-light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FE}\u{200D}\u{1F91D}\u{200D}\u{1F468}\u{1F3FD}", emoji: "👨🏾‍🤝‍👨🏽", name: "men holding hands: medium-dark skin tone, medium skin tone", key: ":men_holding_hands_medium_dark_skin_tone_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F46C}\u{1F3FE}", emoji: "👬🏾", name: "men holding hands: medium-dark skin tone", key: ":men_holding_hands_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FE}\u{200D}\u{1F91D}\u{200D}\u{1F468}\u{1F3FF}", emoji: "👨🏾‍🤝‍👨🏿", name: "men holding hands: medium-dark skin tone, dark skin tone", key: ":men_holding_hands_medium_dark_skin_tone_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FF}\u{200D}\u{1F91D}\u{200D}\u{1F468}\u{1F3FB}", emoji: "👨🏿‍🤝‍👨🏻", name: "men holding hands: dark skin tone, light skin tone", key: ":men_holding_hands_dark_skin_tone_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FF}\u{200D}\u{1F91D}\u{200D}\u{1F468}\u{1F3FC}", emoji: "👨🏿‍🤝‍👨🏼", name: "men holding hands: dark skin tone, medium-light skin tone", key: ":men_holding_hands_dark_skin_tone_medium_light_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FF}\u{200D}\u{1F91D}\u{200D}\u{1F468}\u{1F3FD}", emoji: "👨🏿‍🤝‍👨🏽", name: "men holding hands: dark skin tone, medium skin tone", key: ":men_holding_hands_dark_skin_tone_medium_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F468}\u{1F3FF}\u{200D}\u{1F91D}\u{200D}\u{1F468}\u{1F3FE}", emoji: "👨🏿‍🤝‍👨🏾", name: "men holding hands: dark skin tone, medium-dark skin tone", key: ":men_holding_hands_dark_skin_tone_medium_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F46C}\u{1F3FF}", emoji: "👬🏿", name: "men holding hands: dark skin tone", key: ":men_holding_hands_dark_skin_tone:", group: "People & Body", },
  { unicode: "\u{1F48F}", emoji: "💏", name: "kiss", key: ":kiss:", group: "People & Body", },
  { unicode: "\u{1F469}\u{200D}\u{2764}\u{200D}\u{1F48B}\u{200D}\u{1F468}", emoji: "👩‍❤‍💋‍👨", name: "kiss: woman, man", key: ":kiss_woman_man:", group: "People & Body", },
  { unicode: "\u{1F468}\u{200D}\u{2764}\u{200D}\u{1F48B}\u{200D}\u{1F468}", emoji: "👨‍❤‍💋‍👨", name: "kiss: man, man", key: ":kiss_man_man:", group: "People & Body", },
  { unicode: "\u{1F469}\u{200D}\u{2764}\u{200D}\u{1F48B}\u{200D}\u{1F469}", emoji: "👩‍❤‍💋‍👩", name: "kiss: woman, woman", key: ":kiss_woman_woman:", group: "People & Body", },
  { unicode: "\u{1F491}", emoji: "💑", name: "couple with heart", key: ":couple_with_heart:", group: "People & Body", },
  { unicode: "\u{1F469}\u{200D}\u{2764}\u{200D}\u{1F468}", emoji: "👩‍❤‍👨", name: "couple with heart: woman, man", key: ":couple_with_heart_woman_man:", group: "People & Body", },
  { unicode: "\u{1F468}\u{200D}\u{2764}\u{200D}\u{1F468}", emoji: "👨‍❤‍👨", name: "couple with heart: man, man", key: ":couple_with_heart_man_man:", group: "People & Body", },
  { unicode: "\u{1F469}\u{200D}\u{2764}\u{200D}\u{1F469}", emoji: "👩‍❤‍👩", name: "couple with heart: woman, woman", key: ":couple_with_heart_woman_woman:", group: "People & Body", },
  { unicode: "\u{1F46A}", emoji: "👪", name: "family", key: ":family:", group: "People & Body", },
  { unicode: "\u{1F468}\u{200D}\u{1F469}\u{200D}\u{1F466}", emoji: "👨‍👩‍👦", name: "family: man, woman, boy", key: ":family_man_woman_boy:", group: "People & Body", },
  { unicode: "\u{1F468}\u{200D}\u{1F469}\u{200D}\u{1F467}", emoji: "👨‍👩‍👧", name: "family: man, woman, girl", key: ":family_man_woman_girl:", group: "People & Body", },
  { unicode: "\u{1F468}\u{200D}\u{1F469}\u{200D}\u{1F467}\u{200D}\u{1F466}", emoji: "👨‍👩‍👧‍👦", name: "family: man, woman, girl, boy", key: ":family_man_woman_girl_boy:", group: "People & Body", },
  { unicode: "\u{1F468}\u{200D}\u{1F469}\u{200D}\u{1F466}\u{200D}\u{1F466}", emoji: "👨‍👩‍👦‍👦", name: "family: man, woman, boy, boy", key: ":family_man_woman_boy_boy:", group: "People & Body", },
  { unicode: "\u{1F468}\u{200D}\u{1F469}\u{200D}\u{1F467}\u{200D}\u{1F467}", emoji: "👨‍👩‍👧‍👧", name: "family: man, woman, girl, girl", key: ":family_man_woman_girl_girl:", group: "People & Body", },
  { unicode: "\u{1F468}\u{200D}\u{1F468}\u{200D}\u{1F466}", emoji: "👨‍👨‍👦", name: "family: man, man, boy", key: ":family_man_man_boy:", group: "People & Body", },
  { unicode: "\u{1F468}\u{200D}\u{1F468}\u{200D}\u{1F467}", emoji: "👨‍👨‍👧", name: "family: man, man, girl", key: ":family_man_man_girl:", group: "People & Body", },
  { unicode: "\u{1F468}\u{200D}\u{1F468}\u{200D}\u{1F467}\u{200D}\u{1F466}", emoji: "👨‍👨‍👧‍👦", name: "family: man, man, girl, boy", key: ":family_man_man_girl_boy:", group: "People & Body", },
  { unicode: "\u{1F468}\u{200D}\u{1F468}\u{200D}\u{1F466}\u{200D}\u{1F466}", emoji: "👨‍👨‍👦‍👦", name: "family: man, man, boy, boy", key: ":family_man_man_boy_boy:", group: "People & Body", },
  { unicode: "\u{1F468}\u{200D}\u{1F468}\u{200D}\u{1F467}\u{200D}\u{1F467}", emoji: "👨‍👨‍👧‍👧", name: "family: man, man, girl, girl", key: ":family_man_man_girl_girl:", group: "People & Body", },
  { unicode: "\u{1F469}\u{200D}\u{1F469}\u{200D}\u{1F466}", emoji: "👩‍👩‍👦", name: "family: woman, woman, boy", key: ":family_woman_woman_boy:", group: "People & Body", },
  { unicode: "\u{1F469}\u{200D}\u{1F469}\u{200D}\u{1F467}", emoji: "👩‍👩‍👧", name: "family: woman, woman, girl", key: ":family_woman_woman_girl:", group: "People & Body", },
  { unicode: "\u{1F469}\u{200D}\u{1F469}\u{200D}\u{1F467}\u{200D}\u{1F466}", emoji: "👩‍👩‍👧‍👦", name: "family: woman, woman, girl, boy", key: ":family_woman_woman_girl_boy:", group: "People & Body", },
  { unicode: "\u{1F469}\u{200D}\u{1F469}\u{200D}\u{1F466}\u{200D}\u{1F466}", emoji: "👩‍👩‍👦‍👦", name: "family: woman, woman, boy, boy", key: ":family_woman_woman_boy_boy:", group: "People & Body", },
  { unicode: "\u{1F469}\u{200D}\u{1F469}\u{200D}\u{1F467}\u{200D}\u{1F467}", emoji: "👩‍👩‍👧‍👧", name: "family: woman, woman, girl, girl", key: ":family_woman_woman_girl_girl:", group: "People & Body", },
  { unicode: "\u{1F468}\u{200D}\u{1F466}", emoji: "👨‍👦", name: "family: man, boy", key: ":family_man_boy:", group: "People & Body", },
  { unicode: "\u{1F468}\u{200D}\u{1F466}\u{200D}\u{1F466}", emoji: "👨‍👦‍👦", name: "family: man, boy, boy", key: ":family_man_boy_boy:", group: "People & Body", },
  { unicode: "\u{1F468}\u{200D}\u{1F467}", emoji: "👨‍👧", name: "family: man, girl", key: ":family_man_girl:", group: "People & Body", },
  { unicode: "\u{1F468}\u{200D}\u{1F467}\u{200D}\u{1F466}", emoji: "👨‍👧‍👦", name: "family: man, girl, boy", key: ":family_man_girl_boy:", group: "People & Body", },
  { unicode: "\u{1F468}\u{200D}\u{1F467}\u{200D}\u{1F467}", emoji: "👨‍👧‍👧", name: "family: man, girl, girl", key: ":family_man_girl_girl:", group: "People & Body", },
  { unicode: "\u{1F469}\u{200D}\u{1F466}", emoji: "👩‍👦", name: "family: woman, boy", key: ":family_woman_boy:", group: "People & Body", },
  { unicode: "\u{1F469}\u{200D}\u{1F466}\u{200D}\u{1F466}", emoji: "👩‍👦‍👦", name: "family: woman, boy, boy", key: ":family_woman_boy_boy:", group: "People & Body", },
  { unicode: "\u{1F469}\u{200D}\u{1F467}", emoji: "👩‍👧", name: "family: woman, girl", key: ":family_woman_girl:", group: "People & Body", },
  { unicode: "\u{1F469}\u{200D}\u{1F467}\u{200D}\u{1F466}", emoji: "👩‍👧‍👦", name: "family: woman, girl, boy", key: ":family_woman_girl_boy:", group: "People & Body", },
  { unicode: "\u{1F469}\u{200D}\u{1F467}\u{200D}\u{1F467}", emoji: "👩‍👧‍👧", name: "family: woman, girl, girl", key: ":family_woman_girl_girl:", group: "People & Body", },
  { unicode: "\u{1F5E3}\u{FE0F}", emoji: "🗣️", name: "speaking head", key: ":speaking_head:", group: "People & Body", },
  { unicode: "\u{1F464}", emoji: "👤", name: "bust in silhouette", key: ":bust_in_silhouette:", group: "People & Body", },
  { unicode: "\u{1F465}", emoji: "👥", name: "busts in silhouette", key: ":busts_in_silhouette:", group: "People & Body", },
  { unicode: "\u{1F463}", emoji: "👣", name: "footprints", key: ":footprints:", group: "People & Body", },
  { unicode: "\u{1F3FB}", emoji: "🏻", name: "light skin tone", key: ":light_skin_tone:", group: "Component", },
  { unicode: "\u{1F3FC}", emoji: "🏼", name: "medium-light skin tone", key: ":medium_light_skin_tone:", group: "Component", },
  { unicode: "\u{1F3FD}", emoji: "🏽", name: "medium skin tone", key: ":medium_skin_tone:", group: "Component", },
  { unicode: "\u{1F3FE}", emoji: "🏾", name: "medium-dark skin tone", key: ":medium_dark_skin_tone:", group: "Component", },
  { unicode: "\u{1F3FF}", emoji: "🏿", name: "dark skin tone", key: ":dark_skin_tone:", group: "Component", },
  { unicode: "\u{1F9B0}", emoji: "🦰", name: "red hair", key: ":red_hair:", group: "Component", },
  { unicode: "\u{1F9B1}", emoji: "🦱", name: "curly hair", key: ":curly_hair:", group: "Component", },
  { unicode: "\u{1F9B3}", emoji: "🦳", name: "white hair", key: ":white_hair:", group: "Component", },
  { unicode: "\u{1F9B2}", emoji: "🦲", name: "bald", key: ":bald:", group: "Component", },
  { unicode: "\u{1F435}", emoji: "🐵", name: "monkey face", key: ":monkey_face:", group: "Animals & Nature", },
  { unicode: "\u{1F412}", emoji: "🐒", name: "monkey", key: ":monkey:", group: "Animals & Nature", },
  { unicode: "\u{1F98D}", emoji: "🦍", name: "gorilla", key: ":gorilla:", group: "Animals & Nature", },
  // { unicode: "\u{1F9A7}", emoji: "🦧", name: "orangutan", key: ":orangutan:", group: "Animals & Nature", },
  { unicode: "\u{1F436}", emoji: "🐶", name: "dog face", key: ":dog_face:", group: "Animals & Nature", },
  { unicode: "\u{1F415}", emoji: "🐕", name: "dog", key: ":dog:", group: "Animals & Nature", },
  // { unicode: "\u{1F9AE}", emoji: "🦮", name: "guide dog", key: ":guide_dog:", group: "Animals & Nature", },
  // { unicode: "\u{1F415}\u{200D}\u{1F9BA}", emoji: "🐕‍🦺", name: "service dog", key: ":service_dog:", group: "Animals & Nature", },
  { unicode: "\u{1F429}", emoji: "🐩", name: "poodle", key: ":poodle:", group: "Animals & Nature", },
  { unicode: "\u{1F43A}", emoji: "🐺", name: "wolf", key: ":wolf:", group: "Animals & Nature", },
  { unicode: "\u{1F98A}", emoji: "🦊", name: "fox", key: ":fox:", group: "Animals & Nature", },
  // { unicode: "\u{1F99D}", emoji: "🦝", name: "raccoon", key: ":raccoon:", group: "Animals & Nature", },
  { unicode: "\u{1F431}", emoji: "🐱", name: "cat face", key: ":cat_face:", group: "Animals & Nature", },
  { unicode: "\u{1F408}", emoji: "🐈", name: "cat", key: ":cat:", group: "Animals & Nature", },
  { unicode: "\u{1F981}", emoji: "🦁", name: "lion", key: ":lion:", group: "Animals & Nature", },
  { unicode: "\u{1F42F}", emoji: "🐯", name: "tiger face", key: ":tiger_face:", group: "Animals & Nature", },
  { unicode: "\u{1F405}", emoji: "🐅", name: "tiger", key: ":tiger:", group: "Animals & Nature", },
  { unicode: "\u{1F406}", emoji: "🐆", name: "leopard", key: ":leopard:", group: "Animals & Nature", },
  { unicode: "\u{1F434}", emoji: "🐴", name: "horse face", key: ":horse_face:", group: "Animals & Nature", },
  { unicode: "\u{1F40E}", emoji: "🐎", name: "horse", key: ":horse:", group: "Animals & Nature", },
  { unicode: "\u{1F984}", emoji: "🦄", name: "unicorn", key: ":unicorn:", group: "Animals & Nature", },
  { unicode: "\u{1F993}", emoji: "🦓", name: "zebra", key: ":zebra:", group: "Animals & Nature", },
  { unicode: "\u{1F98C}", emoji: "🦌", name: "deer", key: ":deer:", group: "Animals & Nature", },
  { unicode: "\u{1F42E}", emoji: "🐮", name: "cow face", key: ":cow_face:", group: "Animals & Nature", },
  { unicode: "\u{1F402}", emoji: "🐂", name: "ox", key: ":ox:", group: "Animals & Nature", },
  { unicode: "\u{1F403}", emoji: "🐃", name: "water buffalo", key: ":water_buffalo:", group: "Animals & Nature", },
  { unicode: "\u{1F404}", emoji: "🐄", name: "cow", key: ":cow:", group: "Animals & Nature", },
  { unicode: "\u{1F437}", emoji: "🐷", name: "pig face", key: ":pig_face:", group: "Animals & Nature", },
  { unicode: "\u{1F416}", emoji: "🐖", name: "pig", key: ":pig:", group: "Animals & Nature", },
  { unicode: "\u{1F417}", emoji: "🐗", name: "boar", key: ":boar:", group: "Animals & Nature", },
  { unicode: "\u{1F43D}", emoji: "🐽", name: "pig nose", key: ":pig_nose:", group: "Animals & Nature", },
  { unicode: "\u{1F40F}", emoji: "🐏", name: "ram", key: ":ram:", group: "Animals & Nature", },
  { unicode: "\u{1F411}", emoji: "🐑", name: "ewe", key: ":ewe:", group: "Animals & Nature", },
  { unicode: "\u{1F410}", emoji: "🐐", name: "goat", key: ":goat:", group: "Animals & Nature", },
  { unicode: "\u{1F42A}", emoji: "🐪", name: "camel", key: ":camel:", group: "Animals & Nature", },
  { unicode: "\u{1F42B}", emoji: "🐫", name: "two-hump camel", key: ":two_hump_camel:", group: "Animals & Nature", },
  // { unicode: "\u{1F999}", emoji: "🦙", name: "llama", key: ":llama:", group: "Animals & Nature", },
  { unicode: "\u{1F992}", emoji: "🦒", name: "giraffe", key: ":giraffe:", group: "Animals & Nature", },
  { unicode: "\u{1F418}", emoji: "🐘", name: "elephant", key: ":elephant:", group: "Animals & Nature", },
  { unicode: "\u{1F98F}", emoji: "🦏", name: "rhinoceros", key: ":rhinoceros:", group: "Animals & Nature", },
  // { unicode: "\u{1F99B}", emoji: "🦛", name: "hippopotamus", key: ":hippopotamus:", group: "Animals & Nature", },
  { unicode: "\u{1F42D}", emoji: "🐭", name: "mouse face", key: ":mouse_face:", group: "Animals & Nature", },
  { unicode: "\u{1F401}", emoji: "🐁", name: "mouse", key: ":mouse:", group: "Animals & Nature", },
  { unicode: "\u{1F400}", emoji: "🐀", name: "rat", key: ":rat:", group: "Animals & Nature", },
  { unicode: "\u{1F439}", emoji: "🐹", name: "hamster", key: ":hamster:", group: "Animals & Nature", },
  { unicode: "\u{1F430}", emoji: "🐰", name: "rabbit face", key: ":rabbit_face:", group: "Animals & Nature", },
  { unicode: "\u{1F407}", emoji: "🐇", name: "rabbit", key: ":rabbit:", group: "Animals & Nature", },
  { unicode: "\u{1F43F}\u{FE0F}", emoji: "🐿️", name: "chipmunk", key: ":chipmunk:", group: "Animals & Nature", },
  { unicode: "\u{1F994}", emoji: "🦔", name: "hedgehog", key: ":hedgehog:", group: "Animals & Nature", },
  { unicode: "\u{1F987}", emoji: "🦇", name: "bat", key: ":bat:", group: "Animals & Nature", },
  { unicode: "\u{1F43B}", emoji: "🐻", name: "bear", key: ":bear:", group: "Animals & Nature", },
  { unicode: "\u{1F428}", emoji: "🐨", name: "koala", key: ":koala:", group: "Animals & Nature", },
  { unicode: "\u{1F43C}", emoji: "🐼", name: "panda", key: ":panda:", group: "Animals & Nature", },
  // { unicode: "\u{1F9A5}", emoji: "🦥", name: "sloth", key: ":sloth:", group: "Animals & Nature", },
  // { unicode: "\u{1F9A6}", emoji: "🦦", name: "otter", key: ":otter:", group: "Animals & Nature", },
  // { unicode: "\u{1F9A8}", emoji: "🦨", name: "skunk", key: ":skunk:", group: "Animals & Nature", },
  // { unicode: "\u{1F998}", emoji: "🦘", name: "kangaroo", key: ":kangaroo:", group: "Animals & Nature", },
  // { unicode: "\u{1F9A1}", emoji: "🦡", name: "badger", key: ":badger:", group: "Animals & Nature", },
  { unicode: "\u{1F43E}", emoji: "🐾", name: "paw prints", key: ":paw_prints:", group: "Animals & Nature", },
  { unicode: "\u{1F983}", emoji: "🦃", name: "turkey", key: ":turkey:", group: "Animals & Nature", },
  { unicode: "\u{1F414}", emoji: "🐔", name: "chicken", key: ":chicken:", group: "Animals & Nature", },
  { unicode: "\u{1F413}", emoji: "🐓", name: "rooster", key: ":rooster:", group: "Animals & Nature", },
  { unicode: "\u{1F423}", emoji: "🐣", name: "hatching chick", key: ":hatching_chick:", group: "Animals & Nature", },
  { unicode: "\u{1F424}", emoji: "🐤", name: "baby chick", key: ":baby_chick:", group: "Animals & Nature", },
  { unicode: "\u{1F425}", emoji: "🐥", name: "front-facing baby chick", key: ":front_facing_baby_chick:", group: "Animals & Nature", },
  { unicode: "\u{1F426}", emoji: "🐦", name: "bird", key: ":bird:", group: "Animals & Nature", },
  { unicode: "\u{1F427}", emoji: "🐧", name: "penguin", key: ":penguin:", group: "Animals & Nature", },
  { unicode: "\u{1F54A}\u{FE0F}", emoji: "🕊️", name: "dove", key: ":dove:", group: "Animals & Nature", },
  { unicode: "\u{1F985}", emoji: "🦅", name: "eagle", key: ":eagle:", group: "Animals & Nature", },
  { unicode: "\u{1F986}", emoji: "🦆", name: "duck", key: ":duck:", group: "Animals & Nature", },
  // { unicode: "\u{1F9A2}", emoji: "🦢", name: "swan", key: ":swan:", group: "Animals & Nature", },
  { unicode: "\u{1F989}", emoji: "🦉", name: "owl", key: ":owl:", group: "Animals & Nature", },
  // { unicode: "\u{1F9A9}", emoji: "🦩", name: "flamingo", key: ":flamingo:", group: "Animals & Nature", },
  // { unicode: "\u{1F99A}", emoji: "🦚", name: "peacock", key: ":peacock:", group: "Animals & Nature", },
  // { unicode: "\u{1F99C}", emoji: "🦜", name: "parrot", key: ":parrot:", group: "Animals & Nature", },
  { unicode: "\u{1F438}", emoji: "🐸", name: "frog", key: ":frog:", group: "Animals & Nature", },
  { unicode: "\u{1F40A}", emoji: "🐊", name: "crocodile", key: ":crocodile:", group: "Animals & Nature", },
  { unicode: "\u{1F422}", emoji: "🐢", name: "turtle", key: ":turtle:", group: "Animals & Nature", },
  { unicode: "\u{1F98E}", emoji: "🦎", name: "lizard", key: ":lizard:", group: "Animals & Nature", },
  { unicode: "\u{1F40D}", emoji: "🐍", name: "snake", key: ":snake:", group: "Animals & Nature", },
  { unicode: "\u{1F432}", emoji: "🐲", name: "dragon face", key: ":dragon_face:", group: "Animals & Nature", },
  { unicode: "\u{1F409}", emoji: "🐉", name: "dragon", key: ":dragon:", group: "Animals & Nature", },
  { unicode: "\u{1F995}", emoji: "🦕", name: "sauropod", key: ":sauropod:", group: "Animals & Nature", },
  { unicode: "\u{1F996}", emoji: "🦖", name: "T-Rex", key: ":T_Rex:", group: "Animals & Nature", },
  { unicode: "\u{1F433}", emoji: "🐳", name: "spouting whale", key: ":spouting_whale:", group: "Animals & Nature", },
  { unicode: "\u{1F40B}", emoji: "🐋", name: "whale", key: ":whale:", group: "Animals & Nature", },
  { unicode: "\u{1F42C}", emoji: "🐬", name: "dolphin", key: ":dolphin:", group: "Animals & Nature", },
  { unicode: "\u{1F41F}", emoji: "🐟", name: "fish", key: ":fish:", group: "Animals & Nature", },
  { unicode: "\u{1F420}", emoji: "🐠", name: "tropical fish", key: ":tropical_fish:", group: "Animals & Nature", },
  { unicode: "\u{1F421}", emoji: "🐡", name: "blowfish", key: ":blowfish:", group: "Animals & Nature", },
  { unicode: "\u{1F988}", emoji: "🦈", name: "shark", key: ":shark:", group: "Animals & Nature", },
  { unicode: "\u{1F419}", emoji: "🐙", name: "octopus", key: ":octopus:", group: "Animals & Nature", },
  { unicode: "\u{1F41A}", emoji: "🐚", name: "spiral shell", key: ":spiral_shell:", group: "Animals & Nature", },
  { unicode: "\u{1F40C}", emoji: "🐌", name: "snail", key: ":snail:", group: "Animals & Nature", },
  { unicode: "\u{1F98B}", emoji: "🦋", name: "butterfly", key: ":butterfly:", group: "Animals & Nature", },
  { unicode: "\u{1F41B}", emoji: "🐛", name: "bug", key: ":bug:", group: "Animals & Nature", },
  { unicode: "\u{1F41C}", emoji: "🐜", name: "ant", key: ":ant:", group: "Animals & Nature", },
  { unicode: "\u{1F41D}", emoji: "🐝", name: "honeybee", key: ":honeybee:", group: "Animals & Nature", },
  { unicode: "\u{1F41E}", emoji: "🐞", name: "lady beetle", key: ":lady_beetle:", group: "Animals & Nature", },
  { unicode: "\u{1F997}", emoji: "🦗", name: "cricket", key: ":cricket:", group: "Animals & Nature", },
  { unicode: "\u{1F577}\u{FE0F}", emoji: "🕷️", name: "spider", key: ":spider:", group: "Animals & Nature", },
  { unicode: "\u{1F578}\u{FE0F}", emoji: "🕸️", name: "spider web", key: ":spider_web:", group: "Animals & Nature", },
  { unicode: "\u{1F982}", emoji: "🦂", name: "scorpion", key: ":scorpion:", group: "Animals & Nature", },
  // { unicode: "\u{1F99F}", emoji: "🦟", name: "mosquito", key: ":mosquito:", group: "Animals & Nature", },
  // { unicode: "\u{1F9A0}", emoji: "🦠", name: "microbe", key: ":microbe:", group: "Animals & Nature", },
  { unicode: "\u{1F490}", emoji: "💐", name: "bouquet", key: ":bouquet:", group: "Animals & Nature", },
  { unicode: "\u{1F338}", emoji: "🌸", name: "cherry blossom", key: ":cherry_blossom:", group: "Animals & Nature", },
  { unicode: "\u{1F4AE}", emoji: "💮", name: "white flower", key: ":white_flower:", group: "Animals & Nature", },
  { unicode: "\u{1F3F5}\u{FE0F}", emoji: "🏵️", name: "rosette", key: ":rosette:", group: "Animals & Nature", },
  { unicode: "\u{1F339}", emoji: "🌹", name: "rose", key: ":rose:", group: "Animals & Nature", },
  { unicode: "\u{1F940}", emoji: "🥀", name: "wilted flower", key: ":wilted_flower:", group: "Animals & Nature", },
  { unicode: "\u{1F33A}", emoji: "🌺", name: "hibiscus", key: ":hibiscus:", group: "Animals & Nature", },
  { unicode: "\u{1F33B}", emoji: "🌻", name: "sunflower", key: ":sunflower:", group: "Animals & Nature", },
  { unicode: "\u{1F33C}", emoji: "🌼", name: "blossom", key: ":blossom:", group: "Animals & Nature", },
  { unicode: "\u{1F337}", emoji: "🌷", name: "tulip", key: ":tulip:", group: "Animals & Nature", },
  { unicode: "\u{1F331}", emoji: "🌱", name: "seedling", key: ":seedling:", group: "Animals & Nature", },
  { unicode: "\u{1F332}", emoji: "🌲", name: "evergreen tree", key: ":evergreen_tree:", group: "Animals & Nature", },
  { unicode: "\u{1F333}", emoji: "🌳", name: "deciduous tree", key: ":deciduous_tree:", group: "Animals & Nature", },
  { unicode: "\u{1F334}", emoji: "🌴", name: "palm tree", key: ":palm_tree:", group: "Animals & Nature", },
  { unicode: "\u{1F335}", emoji: "🌵", name: "cactus", key: ":cactus:", group: "Animals & Nature", },
  { unicode: "\u{1F33E}", emoji: "🌾", name: "sheaf of rice", key: ":sheaf_of_rice:", group: "Animals & Nature", },
  { unicode: "\u{1F33F}", emoji: "🌿", name: "herb", key: ":herb:", group: "Animals & Nature", },
  { unicode: "\u{2618}\u{FE0F}", emoji: "☘️", name: "shamrock", key: ":shamrock:", group: "Animals & Nature", },
  { unicode: "\u{1F340}", emoji: "🍀", name: "four leaf clover", key: ":four_leaf_clover:", group: "Animals & Nature", },
  { unicode: "\u{1F341}", emoji: "🍁", name: "maple leaf", key: ":maple_leaf:", group: "Animals & Nature", },
  { unicode: "\u{1F342}", emoji: "🍂", name: "fallen leaf", key: ":fallen_leaf:", group: "Animals & Nature", },
  { unicode: "\u{1F343}", emoji: "🍃", name: "leaf fluttering in wind", key: ":leaf_fluttering_in_wind:", group: "Animals & Nature", },
  { unicode: "\u{1F347}", emoji: "🍇", name: "grapes", key: ":grapes:", group: "Food & Drink", },
  { unicode: "\u{1F348}", emoji: "🍈", name: "melon", key: ":melon:", group: "Food & Drink", },
  { unicode: "\u{1F349}", emoji: "🍉", name: "watermelon", key: ":watermelon:", group: "Food & Drink", },
  { unicode: "\u{1F34A}", emoji: "🍊", name: "tangerine", key: ":tangerine:", group: "Food & Drink", },
  { unicode: "\u{1F34B}", emoji: "🍋", name: "lemon", key: ":lemon:", group: "Food & Drink", },
  { unicode: "\u{1F34C}", emoji: "🍌", name: "banana", key: ":banana:", group: "Food & Drink", },
  { unicode: "\u{1F34D}", emoji: "🍍", name: "pineapple", key: ":pineapple:", group: "Food & Drink", },
  // { unicode: "\u{1F96D}", emoji: "🥭", name: "mango", key: ":mango:", group: "Food & Drink", },
  { unicode: "\u{1F34E}", emoji: "🍎", name: "red apple", key: ":red_apple:", group: "Food & Drink", },
  { unicode: "\u{1F34F}", emoji: "🍏", name: "green apple", key: ":green_apple:", group: "Food & Drink", },
  { unicode: "\u{1F350}", emoji: "🍐", name: "pear", key: ":pear:", group: "Food & Drink", },
  { unicode: "\u{1F351}", emoji: "🍑", name: "peach", key: ":peach:", group: "Food & Drink", },
  { unicode: "\u{1F352}", emoji: "🍒", name: "cherries", key: ":cherries:", group: "Food & Drink", },
  { unicode: "\u{1F353}", emoji: "🍓", name: "strawberry", key: ":strawberry:", group: "Food & Drink", },
  { unicode: "\u{1F95D}", emoji: "🥝", name: "kiwi fruit", key: ":kiwi_fruit:", group: "Food & Drink", },
  { unicode: "\u{1F345}", emoji: "🍅", name: "tomato", key: ":tomato:", group: "Food & Drink", },
  { unicode: "\u{1F965}", emoji: "🥥", name: "coconut", key: ":coconut:", group: "Food & Drink", },
  { unicode: "\u{1F951}", emoji: "🥑", name: "avocado", key: ":avocado:", group: "Food & Drink", },
  { unicode: "\u{1F346}", emoji: "🍆", name: "eggplant", key: ":eggplant:", group: "Food & Drink", },
  { unicode: "\u{1F954}", emoji: "🥔", name: "potato", key: ":potato:", group: "Food & Drink", },
  { unicode: "\u{1F955}", emoji: "🥕", name: "carrot", key: ":carrot:", group: "Food & Drink", },
  { unicode: "\u{1F33D}", emoji: "🌽", name: "ear of corn", key: ":ear_of_corn:", group: "Food & Drink", },
  { unicode: "\u{1F336}\u{FE0F}", emoji: "🌶️", name: "hot pepper", key: ":hot_pepper:", group: "Food & Drink", },
  { unicode: "\u{1F952}", emoji: "🥒", name: "cucumber", key: ":cucumber:", group: "Food & Drink", },
  // { unicode: "\u{1F96C}", emoji: "🥬", name: "leafy green", key: ":leafy_green:", group: "Food & Drink", },
  { unicode: "\u{1F966}", emoji: "🥦", name: "broccoli", key: ":broccoli:", group: "Food & Drink", },
  // { unicode: "\u{1F9C4}", emoji: "🧄", name: "garlic", key: ":garlic:", group: "Food & Drink", },
  // { unicode: "\u{1F9C5}", emoji: "🧅", name: "onion", key: ":onion:", group: "Food & Drink", },
  { unicode: "\u{1F344}", emoji: "🍄", name: "mushroom", key: ":mushroom:", group: "Food & Drink", },
  { unicode: "\u{1F95C}", emoji: "🥜", name: "peanuts", key: ":peanuts:", group: "Food & Drink", },
  { unicode: "\u{1F330}", emoji: "🌰", name: "chestnut", key: ":chestnut:", group: "Food & Drink", },
  { unicode: "\u{1F35E}", emoji: "🍞", name: "bread", key: ":bread:", group: "Food & Drink", },
  { unicode: "\u{1F950}", emoji: "🥐", name: "croissant", key: ":croissant:", group: "Food & Drink", },
  { unicode: "\u{1F956}", emoji: "🥖", name: "baguette bread", key: ":baguette_bread:", group: "Food & Drink", },
  { unicode: "\u{1F968}", emoji: "🥨", name: "pretzel", key: ":pretzel:", group: "Food & Drink", },
  // { unicode: "\u{1F96F}", emoji: "🥯", name: "bagel", key: ":bagel:", group: "Food & Drink", },
  { unicode: "\u{1F95E}", emoji: "🥞", name: "pancakes", key: ":pancakes:", group: "Food & Drink", },
  // { unicode: "\u{1F9C7}", emoji: "🧇", name: "waffle", key: ":waffle:", group: "Food & Drink", },
  { unicode: "\u{1F9C0}", emoji: "🧀", name: "cheese wedge", key: ":cheese_wedge:", group: "Food & Drink", },
  { unicode: "\u{1F356}", emoji: "🍖", name: "meat on bone", key: ":meat_on_bone:", group: "Food & Drink", },
  { unicode: "\u{1F357}", emoji: "🍗", name: "poultry leg", key: ":poultry_leg:", group: "Food & Drink", },
  { unicode: "\u{1F969}", emoji: "🥩", name: "cut of meat", key: ":cut_of_meat:", group: "Food & Drink", },
  { unicode: "\u{1F953}", emoji: "🥓", name: "bacon", key: ":bacon:", group: "Food & Drink", },
  { unicode: "\u{1F354}", emoji: "🍔", name: "hamburger", key: ":hamburger:", group: "Food & Drink", },
  { unicode: "\u{1F35F}", emoji: "🍟", name: "french fries", key: ":french_fries:", group: "Food & Drink", },
  { unicode: "\u{1F355}", emoji: "🍕", name: "pizza", key: ":pizza:", group: "Food & Drink", },
  { unicode: "\u{1F32D}", emoji: "🌭", name: "hot dog", key: ":hot_dog:", group: "Food & Drink", },
  { unicode: "\u{1F96A}", emoji: "🥪", name: "sandwich", key: ":sandwich:", group: "Food & Drink", },
  { unicode: "\u{1F32E}", emoji: "🌮", name: "taco", key: ":taco:", group: "Food & Drink", },
  { unicode: "\u{1F32F}", emoji: "🌯", name: "burrito", key: ":burrito:", group: "Food & Drink", },
  { unicode: "\u{1F959}", emoji: "🥙", name: "stuffed flatbread", key: ":stuffed_flatbread:", group: "Food & Drink", },
  // { unicode: "\u{1F9C6}", emoji: "🧆", name: "falafel", key: ":falafel:", group: "Food & Drink", },
  { unicode: "\u{1F95A}", emoji: "🥚", name: "egg", key: ":egg:", group: "Food & Drink", },
  { unicode: "\u{1F373}", emoji: "🍳", name: "cooking", key: ":cooking:", group: "Food & Drink", },
  { unicode: "\u{1F958}", emoji: "🥘", name: "shallow pan of food", key: ":shallow_pan_of_food:", group: "Food & Drink", },
  { unicode: "\u{1F372}", emoji: "🍲", name: "pot of food", key: ":pot_of_food:", group: "Food & Drink", },
  { unicode: "\u{1F963}", emoji: "🥣", name: "bowl with spoon", key: ":bowl_with_spoon:", group: "Food & Drink", },
  { unicode: "\u{1F957}", emoji: "🥗", name: "green salad", key: ":green_salad:", group: "Food & Drink", },
  { unicode: "\u{1F37F}", emoji: "🍿", name: "popcorn", key: ":popcorn:", group: "Food & Drink", },
  // { unicode: "\u{1F9C8}", emoji: "🧈", name: "butter", key: ":butter:", group: "Food & Drink", },
  // { unicode: "\u{1F9C2}", emoji: "🧂", name: "salt", key: ":salt:", group: "Food & Drink", },
  { unicode: "\u{1F96B}", emoji: "🥫", name: "canned food", key: ":canned_food:", group: "Food & Drink", },
  { unicode: "\u{1F371}", emoji: "🍱", name: "bento box", key: ":bento_box:", group: "Food & Drink", },
  { unicode: "\u{1F358}", emoji: "🍘", name: "rice cracker", key: ":rice_cracker:", group: "Food & Drink", },
  { unicode: "\u{1F359}", emoji: "🍙", name: "rice ball", key: ":rice_ball:", group: "Food & Drink", },
  { unicode: "\u{1F35A}", emoji: "🍚", name: "cooked rice", key: ":cooked_rice:", group: "Food & Drink", },
  { unicode: "\u{1F35B}", emoji: "🍛", name: "curry rice", key: ":curry_rice:", group: "Food & Drink", },
  { unicode: "\u{1F35C}", emoji: "🍜", name: "steaming bowl", key: ":steaming_bowl:", group: "Food & Drink", },
  { unicode: "\u{1F35D}", emoji: "🍝", name: "spaghetti", key: ":spaghetti:", group: "Food & Drink", },
  { unicode: "\u{1F360}", emoji: "🍠", name: "roasted sweet potato", key: ":roasted_sweet_potato:", group: "Food & Drink", },
  { unicode: "\u{1F362}", emoji: "🍢", name: "oden", key: ":oden:", group: "Food & Drink", },
  { unicode: "\u{1F363}", emoji: "🍣", name: "sushi", key: ":sushi:", group: "Food & Drink", },
  { unicode: "\u{1F364}", emoji: "🍤", name: "fried shrimp", key: ":fried_shrimp:", group: "Food & Drink", },
  { unicode: "\u{1F365}", emoji: "🍥", name: "fish cake with swirl", key: ":fish_cake_with_swirl:", group: "Food & Drink", },
  // { unicode: "\u{1F96E}", emoji: "🥮", name: "moon cake", key: ":moon_cake:", group: "Food & Drink", },
  { unicode: "\u{1F361}", emoji: "🍡", name: "dango", key: ":dango:", group: "Food & Drink", },
  { unicode: "\u{1F95F}", emoji: "🥟", name: "dumpling", key: ":dumpling:", group: "Food & Drink", },
  { unicode: "\u{1F960}", emoji: "🥠", name: "fortune cookie", key: ":fortune_cookie:", group: "Food & Drink", },
  { unicode: "\u{1F961}", emoji: "🥡", name: "takeout box", key: ":takeout_box:", group: "Food & Drink", },
  { unicode: "\u{1F980}", emoji: "🦀", name: "crab", key: ":crab:", group: "Food & Drink", },
  // { unicode: "\u{1F99E}", emoji: "🦞", name: "lobster", key: ":lobster:", group: "Food & Drink", },
  { unicode: "\u{1F990}", emoji: "🦐", name: "shrimp", key: ":shrimp:", group: "Food & Drink", },
  { unicode: "\u{1F991}", emoji: "🦑", name: "squid", key: ":squid:", group: "Food & Drink", },
  // { unicode: "\u{1F9AA}", emoji: "🦪", name: "oyster", key: ":oyster:", group: "Food & Drink", },
  { unicode: "\u{1F366}", emoji: "🍦", name: "soft ice cream", key: ":soft_ice_cream:", group: "Food & Drink", },
  { unicode: "\u{1F367}", emoji: "🍧", name: "shaved ice", key: ":shaved_ice:", group: "Food & Drink", },
  { unicode: "\u{1F368}", emoji: "🍨", name: "ice cream", key: ":ice_cream:", group: "Food & Drink", },
  { unicode: "\u{1F369}", emoji: "🍩", name: "doughnut", key: ":doughnut:", group: "Food & Drink", },
  { unicode: "\u{1F36A}", emoji: "🍪", name: "cookie", key: ":cookie:", group: "Food & Drink", },
  { unicode: "\u{1F382}", emoji: "🎂", name: "birthday cake", key: ":birthday_cake:", group: "Food & Drink", },
  { unicode: "\u{1F370}", emoji: "🍰", name: "shortcake", key: ":shortcake:", group: "Food & Drink", },
  // { unicode: "\u{1F9C1}", emoji: "🧁", name: "cupcake", key: ":cupcake:", group: "Food & Drink", },
  { unicode: "\u{1F967}", emoji: "🥧", name: "pie", key: ":pie:", group: "Food & Drink", },
  { unicode: "\u{1F36B}", emoji: "🍫", name: "chocolate bar", key: ":chocolate_bar:", group: "Food & Drink", },
  { unicode: "\u{1F36C}", emoji: "🍬", name: "candy", key: ":candy:", group: "Food & Drink", },
  { unicode: "\u{1F36D}", emoji: "🍭", name: "lollipop", key: ":lollipop:", group: "Food & Drink", },
  { unicode: "\u{1F36E}", emoji: "🍮", name: "custard", key: ":custard:", group: "Food & Drink", },
  { unicode: "\u{1F36F}", emoji: "🍯", name: "honey pot", key: ":honey_pot:", group: "Food & Drink", },
  { unicode: "\u{1F37C}", emoji: "🍼", name: "baby bottle", key: ":baby_bottle:", group: "Food & Drink", },
  { unicode: "\u{1F95B}", emoji: "🥛", name: "glass of milk", key: ":glass_of_milk:", group: "Food & Drink", },
  { unicode: "\u{2615}", emoji: "☕", name: "hot beverage", key: ":hot_beverage:", group: "Food & Drink", },
  { unicode: "\u{1F375}", emoji: "🍵", name: "teacup without handle", key: ":teacup_without_handle:", group: "Food & Drink", },
  { unicode: "\u{1F376}", emoji: "🍶", name: "sake", key: ":sake:", group: "Food & Drink", },
  { unicode: "\u{1F37E}", emoji: "🍾", name: "bottle with popping cork", key: ":bottle_with_popping_cork:", group: "Food & Drink", },
  { unicode: "\u{1F377}", emoji: "🍷", name: "wine glass", key: ":wine_glass:", group: "Food & Drink", },
  { unicode: "\u{1F378}", emoji: "🍸", name: "cocktail glass", key: ":cocktail_glass:", group: "Food & Drink", },
  { unicode: "\u{1F379}", emoji: "🍹", name: "tropical drink", key: ":tropical_drink:", group: "Food & Drink", },
  { unicode: "\u{1F37A}", emoji: "🍺", name: "beer mug", key: ":beer_mug:", group: "Food & Drink", },
  { unicode: "\u{1F37B}", emoji: "🍻", name: "clinking beer mugs", key: ":clinking_beer_mugs:", group: "Food & Drink", },
  { unicode: "\u{1F942}", emoji: "🥂", name: "clinking glasses", key: ":clinking_glasses:", group: "Food & Drink", },
  { unicode: "\u{1F943}", emoji: "🥃", name: "tumbler glass", key: ":tumbler_glass:", group: "Food & Drink", },
  { unicode: "\u{1F964}", emoji: "🥤", name: "cup with straw", key: ":cup_with_straw:", group: "Food & Drink", },
  // { unicode: "\u{1F9C3}", emoji: "🧃", name: "beverage box", key: ":beverage_box:", group: "Food & Drink", },
  // { unicode: "\u{1F9C9}", emoji: "🧉", name: "mate", key: ":mate:", group: "Food & Drink", },
  // { unicode: "\u{1F9CA}", emoji: "🧊", name: "ice", key: ":ice:", group: "Food & Drink", },
  { unicode: "\u{1F962}", emoji: "🥢", name: "chopsticks", key: ":chopsticks:", group: "Food & Drink", },
  { unicode: "\u{1F37D}\u{FE0F}", emoji: "🍽️", name: "fork and knife with plate", key: ":fork_and_knife_with_plate:", group: "Food & Drink", },
  { unicode: "\u{1F374}", emoji: "🍴", name: "fork and knife", key: ":fork_and_knife:", group: "Food & Drink", },
  { unicode: "\u{1F944}", emoji: "🥄", name: "spoon", key: ":spoon:", group: "Food & Drink", },
  { unicode: "\u{1F52A}", emoji: "🔪", name: "kitchen knife", key: ":kitchen_knife:", group: "Food & Drink", },
  { unicode: "\u{1F3FA}", emoji: "🏺", name: "amphora", key: ":amphora:", group: "Food & Drink", },
  { unicode: "\u{1F30D}", emoji: "🌍", name: "globe showing Europe-Africa", key: ":globe_showing_Europe_Africa:", group: "Travel & Places", },
  { unicode: "\u{1F30E}", emoji: "🌎", name: "globe showing Americas", key: ":globe_showing_Americas:", group: "Travel & Places", },
  { unicode: "\u{1F30F}", emoji: "🌏", name: "globe showing Asia-Australia", key: ":globe_showing_Asia_Australia:", group: "Travel & Places", },
  { unicode: "\u{1F310}", emoji: "🌐", name: "globe with meridians", key: ":globe_with_meridians:", group: "Travel & Places", },
  { unicode: "\u{1F5FA}\u{FE0F}", emoji: "🗺️", name: "world map", key: ":world_map:", group: "Travel & Places", },
  { unicode: "\u{1F5FE}", emoji: "🗾", name: "map of Japan", key: ":map_of_Japan:", group: "Travel & Places", },
  // { unicode: "\u{1F9ED}", emoji: "🧭", name: "compass", key: ":compass:", group: "Travel & Places", },
  { unicode: "\u{1F3D4}\u{FE0F}", emoji: "🏔️", name: "snow-capped mountain", key: ":snow_capped_mountain:", group: "Travel & Places", },
  { unicode: "\u{26F0}\u{FE0F}", emoji: "⛰️", name: "mountain", key: ":mountain:", group: "Travel & Places", },
  { unicode: "\u{1F30B}", emoji: "🌋", name: "volcano", key: ":volcano:", group: "Travel & Places", },
  { unicode: "\u{1F5FB}", emoji: "🗻", name: "mount fuji", key: ":mount_fuji:", group: "Travel & Places", },
  { unicode: "\u{1F3D5}\u{FE0F}", emoji: "🏕️", name: "camping", key: ":camping:", group: "Travel & Places", },
  { unicode: "\u{1F3D6}\u{FE0F}", emoji: "🏖️", name: "beach with umbrella", key: ":beach_with_umbrella:", group: "Travel & Places", },
  { unicode: "\u{1F3DC}\u{FE0F}", emoji: "🏜️", name: "desert", key: ":desert:", group: "Travel & Places", },
  { unicode: "\u{1F3DD}\u{FE0F}", emoji: "🏝️", name: "desert island", key: ":desert_island:", group: "Travel & Places", },
  { unicode: "\u{1F3DE}\u{FE0F}", emoji: "🏞️", name: "national park", key: ":national_park:", group: "Travel & Places", },
  { unicode: "\u{1F3DF}\u{FE0F}", emoji: "🏟️", name: "stadium", key: ":stadium:", group: "Travel & Places", },
  { unicode: "\u{1F3DB}\u{FE0F}", emoji: "🏛️", name: "classical building", key: ":classical_building:", group: "Travel & Places", },
  { unicode: "\u{1F3D7}\u{FE0F}", emoji: "🏗️", name: "building construction", key: ":building_construction:", group: "Travel & Places", },
  // { unicode: "\u{1F9F1}", emoji: "🧱", name: "brick", key: ":brick:", group: "Travel & Places", },
  { unicode: "\u{1F3D8}\u{FE0F}", emoji: "🏘️", name: "houses", key: ":houses:", group: "Travel & Places", },
  { unicode: "\u{1F3DA}\u{FE0F}", emoji: "🏚️", name: "derelict house", key: ":derelict_house:", group: "Travel & Places", },
  { unicode: "\u{1F3E0}", emoji: "🏠", name: "house", key: ":house:", group: "Travel & Places", },
  { unicode: "\u{1F3E1}", emoji: "🏡", name: "house with garden", key: ":house_with_garden:", group: "Travel & Places", },
  { unicode: "\u{1F3E2}", emoji: "🏢", name: "office building", key: ":office_building:", group: "Travel & Places", },
  { unicode: "\u{1F3E3}", emoji: "🏣", name: "Japanese post office", key: ":Japanese_post_office:", group: "Travel & Places", },
  { unicode: "\u{1F3E4}", emoji: "🏤", name: "post office", key: ":post_office:", group: "Travel & Places", },
  { unicode: "\u{1F3E5}", emoji: "🏥", name: "hospital", key: ":hospital:", group: "Travel & Places", },
  { unicode: "\u{1F3E6}", emoji: "🏦", name: "bank", key: ":bank:", group: "Travel & Places", },
  { unicode: "\u{1F3E8}", emoji: "🏨", name: "hotel", key: ":hotel:", group: "Travel & Places", },
  { unicode: "\u{1F3E9}", emoji: "🏩", name: "love hotel", key: ":love_hotel:", group: "Travel & Places", },
  { unicode: "\u{1F3EA}", emoji: "🏪", name: "convenience store", key: ":convenience_store:", group: "Travel & Places", },
  { unicode: "\u{1F3EB}", emoji: "🏫", name: "school", key: ":school:", group: "Travel & Places", },
  { unicode: "\u{1F3EC}", emoji: "🏬", name: "department store", key: ":department_store:", group: "Travel & Places", },
  { unicode: "\u{1F3ED}", emoji: "🏭", name: "factory", key: ":factory:", group: "Travel & Places", },
  { unicode: "\u{1F3EF}", emoji: "🏯", name: "Japanese castle", key: ":Japanese_castle:", group: "Travel & Places", },
  { unicode: "\u{1F3F0}", emoji: "🏰", name: "castle", key: ":castle:", group: "Travel & Places", },
  { unicode: "\u{1F492}", emoji: "💒", name: "wedding", key: ":wedding:", group: "Travel & Places", },
  { unicode: "\u{1F5FC}", emoji: "🗼", name: "Tokyo tower", key: ":Tokyo_tower:", group: "Travel & Places", },
  { unicode: "\u{1F5FD}", emoji: "🗽", name: "Statue of Liberty", key: ":Statue_of_Liberty:", group: "Travel & Places", },
  { unicode: "\u{26EA}", emoji: "⛪", name: "church", key: ":church:", group: "Travel & Places", },
  { unicode: "\u{1F54C}", emoji: "🕌", name: "mosque", key: ":mosque:", group: "Travel & Places", },
  { unicode: "\u{1F6D5}", emoji: "🛕", name: "hindu temple", key: ":hindu_temple:", group: "Travel & Places", },
  { unicode: "\u{1F54D}", emoji: "🕍", name: "synagogue", key: ":synagogue:", group: "Travel & Places", },
  { unicode: "\u{26E9}\u{FE0F}", emoji: "⛩️", name: "shinto shrine", key: ":shinto_shrine:", group: "Travel & Places", },
  { unicode: "\u{1F54B}", emoji: "🕋", name: "kaaba", key: ":kaaba:", group: "Travel & Places", },
  { unicode: "\u{26F2}", emoji: "⛲", name: "fountain", key: ":fountain:", group: "Travel & Places", },
  { unicode: "\u{26FA}", emoji: "⛺", name: "tent", key: ":tent:", group: "Travel & Places", },
  { unicode: "\u{1F301}", emoji: "🌁", name: "foggy", key: ":foggy:", group: "Travel & Places", },
  { unicode: "\u{1F303}", emoji: "🌃", name: "night with stars", key: ":night_with_stars:", group: "Travel & Places", },
  { unicode: "\u{1F3D9}\u{FE0F}", emoji: "🏙️", name: "cityscape", key: ":cityscape:", group: "Travel & Places", },
  { unicode: "\u{1F304}", emoji: "🌄", name: "sunrise over mountains", key: ":sunrise_over_mountains:", group: "Travel & Places", },
  { unicode: "\u{1F305}", emoji: "🌅", name: "sunrise", key: ":sunrise:", group: "Travel & Places", },
  { unicode: "\u{1F306}", emoji: "🌆", name: "cityscape at dusk", key: ":cityscape_at_dusk:", group: "Travel & Places", },
  { unicode: "\u{1F307}", emoji: "🌇", name: "sunset", key: ":sunset:", group: "Travel & Places", },
  { unicode: "\u{1F309}", emoji: "🌉", name: "bridge at night", key: ":bridge_at_night:", group: "Travel & Places", },
  { unicode: "\u{2668}\u{FE0F}", emoji: "♨️", name: "hot springs", key: ":hot_springs:", group: "Travel & Places", },
  { unicode: "\u{1F3A0}", emoji: "🎠", name: "carousel horse", key: ":carousel_horse:", group: "Travel & Places", },
  { unicode: "\u{1F3A1}", emoji: "🎡", name: "ferris wheel", key: ":ferris_wheel:", group: "Travel & Places", },
  { unicode: "\u{1F3A2}", emoji: "🎢", name: "roller coaster", key: ":roller_coaster:", group: "Travel & Places", },
  { unicode: "\u{1F488}", emoji: "💈", name: "barber pole", key: ":barber_pole:", group: "Travel & Places", },
  { unicode: "\u{1F3AA}", emoji: "🎪", name: "circus tent", key: ":circus_tent:", group: "Travel & Places", },
  { unicode: "\u{1F682}", emoji: "🚂", name: "locomotive", key: ":locomotive:", group: "Travel & Places", },
  { unicode: "\u{1F683}", emoji: "🚃", name: "railway car", key: ":railway_car:", group: "Travel & Places", },
  { unicode: "\u{1F684}", emoji: "🚄", name: "high-speed train", key: ":high_speed_train:", group: "Travel & Places", },
  { unicode: "\u{1F685}", emoji: "🚅", name: "bullet train", key: ":bullet_train:", group: "Travel & Places", },
  { unicode: "\u{1F686}", emoji: "🚆", name: "train", key: ":train:", group: "Travel & Places", },
  { unicode: "\u{1F687}", emoji: "🚇", name: "metro", key: ":metro:", group: "Travel & Places", },
  { unicode: "\u{1F688}", emoji: "🚈", name: "light rail", key: ":light_rail:", group: "Travel & Places", },
  { unicode: "\u{1F689}", emoji: "🚉", name: "station", key: ":station:", group: "Travel & Places", },
  { unicode: "\u{1F68A}", emoji: "🚊", name: "tram", key: ":tram:", group: "Travel & Places", },
  { unicode: "\u{1F69D}", emoji: "🚝", name: "monorail", key: ":monorail:", group: "Travel & Places", },
  { unicode: "\u{1F69E}", emoji: "🚞", name: "mountain railway", key: ":mountain_railway:", group: "Travel & Places", },
  { unicode: "\u{1F68B}", emoji: "🚋", name: "tram car", key: ":tram_car:", group: "Travel & Places", },
  { unicode: "\u{1F68C}", emoji: "🚌", name: "bus", key: ":bus:", group: "Travel & Places", },
  { unicode: "\u{1F68D}", emoji: "🚍", name: "oncoming bus", key: ":oncoming_bus:", group: "Travel & Places", },
  { unicode: "\u{1F68E}", emoji: "🚎", name: "trolleybus", key: ":trolleybus:", group: "Travel & Places", },
  { unicode: "\u{1F690}", emoji: "🚐", name: "minibus", key: ":minibus:", group: "Travel & Places", },
  { unicode: "\u{1F691}", emoji: "🚑", name: "ambulance", key: ":ambulance:", group: "Travel & Places", },
  { unicode: "\u{1F692}", emoji: "🚒", name: "fire engine", key: ":fire_engine:", group: "Travel & Places", },
  { unicode: "\u{1F693}", emoji: "🚓", name: "police car", key: ":police_car:", group: "Travel & Places", },
  { unicode: "\u{1F694}", emoji: "🚔", name: "oncoming police car", key: ":oncoming_police_car:", group: "Travel & Places", },
  { unicode: "\u{1F695}", emoji: "🚕", name: "taxi", key: ":taxi:", group: "Travel & Places", },
  { unicode: "\u{1F696}", emoji: "🚖", name: "oncoming taxi", key: ":oncoming_taxi:", group: "Travel & Places", },
  { unicode: "\u{1F697}", emoji: "🚗", name: "automobile", key: ":automobile:", group: "Travel & Places", },
  { unicode: "\u{1F698}", emoji: "🚘", name: "oncoming automobile", key: ":oncoming_automobile:", group: "Travel & Places", },
  { unicode: "\u{1F699}", emoji: "🚙", name: "sport utility vehicle", key: ":sport_utility_vehicle:", group: "Travel & Places", },
  { unicode: "\u{1F69A}", emoji: "🚚", name: "delivery truck", key: ":delivery_truck:", group: "Travel & Places", },
  { unicode: "\u{1F69B}", emoji: "🚛", name: "articulated lorry", key: ":articulated_lorry:", group: "Travel & Places", },
  { unicode: "\u{1F69C}", emoji: "🚜", name: "tractor", key: ":tractor:", group: "Travel & Places", },
  { unicode: "\u{1F3CE}\u{FE0F}", emoji: "🏎️", name: "racing car", key: ":racing_car:", group: "Travel & Places", },
  { unicode: "\u{1F3CD}\u{FE0F}", emoji: "🏍️", name: "motorcycle", key: ":motorcycle:", group: "Travel & Places", },
  { unicode: "\u{1F6F5}", emoji: "🛵", name: "motor scooter", key: ":motor_scooter:", group: "Travel & Places", },
  // { unicode: "\u{1F9BD}", emoji: "🦽", name: "manual wheelchair", key: ":manual_wheelchair:", group: "Travel & Places", },
  // { unicode: "\u{1F9BC}", emoji: "🦼", name: "motorized wheelchair", key: ":motorized_wheelchair:", group: "Travel & Places", },
  // { unicode: "\u{1F6FA}", emoji: "🛺", name: "auto rickshaw", key: ":auto_rickshaw:", group: "Travel & Places", },
  { unicode: "\u{1F6B2}", emoji: "🚲", name: "bicycle", key: ":bicycle:", group: "Travel & Places", },
  { unicode: "\u{1F6F4}", emoji: "🛴", name: "kick scooter", key: ":kick_scooter:", group: "Travel & Places", },
  // { unicode: "\u{1F6F9}", emoji: "🛹", name: "skateboard", key: ":skateboard:", group: "Travel & Places", },
  { unicode: "\u{1F68F}", emoji: "🚏", name: "bus stop", key: ":bus_stop:", group: "Travel & Places", },
  { unicode: "\u{1F6E3}\u{FE0F}", emoji: "🛣️", name: "motorway", key: ":motorway:", group: "Travel & Places", },
  { unicode: "\u{1F6E4}\u{FE0F}", emoji: "🛤️", name: "railway track", key: ":railway_track:", group: "Travel & Places", },
  { unicode: "\u{1F6E2}\u{FE0F}", emoji: "🛢️", name: "oil drum", key: ":oil_drum:", group: "Travel & Places", },
  { unicode: "\u{26FD}", emoji: "⛽", name: "fuel pump", key: ":fuel_pump:", group: "Travel & Places", },
  { unicode: "\u{1F6A8}", emoji: "🚨", name: "police car light", key: ":police_car_light:", group: "Travel & Places", },
  { unicode: "\u{1F6A5}", emoji: "🚥", name: "horizontal traffic light", key: ":horizontal_traffic_light:", group: "Travel & Places", },
  { unicode: "\u{1F6A6}", emoji: "🚦", name: "vertical traffic light", key: ":vertical_traffic_light:", group: "Travel & Places", },
  { unicode: "\u{1F6D1}", emoji: "🛑", name: "stop sign", key: ":stop_sign:", group: "Travel & Places", },
  { unicode: "\u{1F6A7}", emoji: "🚧", name: "construction", key: ":construction:", group: "Travel & Places", },
  { unicode: "\u{2693}", emoji: "⚓", name: "anchor", key: ":anchor:", group: "Travel & Places", },
  { unicode: "\u{26F5}", emoji: "⛵", name: "sailboat", key: ":sailboat:", group: "Travel & Places", },
  { unicode: "\u{1F6F6}", emoji: "🛶", name: "canoe", key: ":canoe:", group: "Travel & Places", },
  { unicode: "\u{1F6A4}", emoji: "🚤", name: "speedboat", key: ":speedboat:", group: "Travel & Places", },
  { unicode: "\u{1F6F3}\u{FE0F}", emoji: "🛳️", name: "passenger ship", key: ":passenger_ship:", group: "Travel & Places", },
  { unicode: "\u{26F4}\u{FE0F}", emoji: "⛴️", name: "ferry", key: ":ferry:", group: "Travel & Places", },
  { unicode: "\u{1F6E5}\u{FE0F}", emoji: "🛥️", name: "motor boat", key: ":motor_boat:", group: "Travel & Places", },
  { unicode: "\u{1F6A2}", emoji: "🚢", name: "ship", key: ":ship:", group: "Travel & Places", },
  { unicode: "\u{2708}\u{FE0F}", emoji: "✈️", name: "airplane", key: ":airplane:", group: "Travel & Places", },
  { unicode: "\u{1F6E9}\u{FE0F}", emoji: "🛩️", name: "small airplane", key: ":small_airplane:", group: "Travel & Places", },
  { unicode: "\u{1F6EB}", emoji: "🛫", name: "airplane departure", key: ":airplane_departure:", group: "Travel & Places", },
  { unicode: "\u{1F6EC}", emoji: "🛬", name: "airplane arrival", key: ":airplane_arrival:", group: "Travel & Places", },
  // { unicode: "\u{1FA82}", emoji: "🪂", name: "parachute", key: ":parachute:", group: "Travel & Places", },
  { unicode: "\u{1F4BA}", emoji: "💺", name: "seat", key: ":seat:", group: "Travel & Places", },
  { unicode: "\u{1F681}", emoji: "🚁", name: "helicopter", key: ":helicopter:", group: "Travel & Places", },
  { unicode: "\u{1F69F}", emoji: "🚟", name: "suspension railway", key: ":suspension_railway:", group: "Travel & Places", },
  { unicode: "\u{1F6A0}", emoji: "🚠", name: "mountain cableway", key: ":mountain_cableway:", group: "Travel & Places", },
  { unicode: "\u{1F6A1}", emoji: "🚡", name: "aerial tramway", key: ":aerial_tramway:", group: "Travel & Places", },
  { unicode: "\u{1F6F0}\u{FE0F}", emoji: "🛰️", name: "satellite", key: ":satellite:", group: "Travel & Places", },
  { unicode: "\u{1F680}", emoji: "🚀", name: "rocket", key: ":rocket:", group: "Travel & Places", },
  { unicode: "\u{1F6F8}", emoji: "🛸", name: "flying saucer", key: ":flying_saucer:", group: "Travel & Places", },
  { unicode: "\u{1F6CE}\u{FE0F}", emoji: "🛎️", name: "bellhop bell", key: ":bellhop_bell:", group: "Travel & Places", },
  // { unicode: "\u{1F9F3}", emoji: "🧳", name: "luggage", key: ":luggage:", group: "Travel & Places", },
  { unicode: "\u{231B}", emoji: "⌛", name: "hourglass done", key: ":hourglass_done:", group: "Travel & Places", },
  { unicode: "\u{23F3}", emoji: "⏳", name: "hourglass not done", key: ":hourglass_not_done:", group: "Travel & Places", },
  { unicode: "\u{231A}", emoji: "⌚", name: "watch", key: ":watch:", group: "Travel & Places", },
  { unicode: "\u{23F0}", emoji: "⏰", name: "alarm clock", key: ":alarm_clock:", group: "Travel & Places", },
  { unicode: "\u{23F1}\u{FE0F}", emoji: "⏱️", name: "stopwatch", key: ":stopwatch:", group: "Travel & Places", },
  { unicode: "\u{23F2}", emoji: "⏲", name: "timer clock", key: ":timer_clock:", group: "Travel & Places", },
  { unicode: "\u{1F570}\u{FE0F}", emoji: "🕰️", name: "mantelpiece clock", key: ":mantelpiece_clock:", group: "Travel & Places", },
  { unicode: "\u{1F55B}", emoji: "🕛", name: "twelve o’clock", key: ":twelve_o_clock:", group: "Travel & Places", },
  { unicode: "\u{1F567}", emoji: "🕧", name: "twelve-thirty", key: ":twelve_thirty:", group: "Travel & Places", },
  { unicode: "\u{1F550}", emoji: "🕐", name: "one o’clock", key: ":one_o_clock:", group: "Travel & Places", },
  { unicode: "\u{1F55C}", emoji: "🕜", name: "one-thirty", key: ":one_thirty:", group: "Travel & Places", },
  { unicode: "\u{1F551}", emoji: "🕑", name: "two o’clock", key: ":two_o_clock:", group: "Travel & Places", },
  { unicode: "\u{1F55D}", emoji: "🕝", name: "two-thirty", key: ":two_thirty:", group: "Travel & Places", },
  { unicode: "\u{1F552}", emoji: "🕒", name: "three o’clock", key: ":three_o_clock:", group: "Travel & Places", },
  { unicode: "\u{1F55E}", emoji: "🕞", name: "three-thirty", key: ":three_thirty:", group: "Travel & Places", },
  { unicode: "\u{1F553}", emoji: "🕓", name: "four o’clock", key: ":four_o_clock:", group: "Travel & Places", },
  { unicode: "\u{1F55F}", emoji: "🕟", name: "four-thirty", key: ":four_thirty:", group: "Travel & Places", },
  { unicode: "\u{1F554}", emoji: "🕔", name: "five o’clock", key: ":five_o_clock:", group: "Travel & Places", },
  { unicode: "\u{1F560}", emoji: "🕠", name: "five-thirty", key: ":five_thirty:", group: "Travel & Places", },
  { unicode: "\u{1F555}", emoji: "🕕", name: "six o’clock", key: ":six_o_clock:", group: "Travel & Places", },
  { unicode: "\u{1F561}", emoji: "🕡", name: "six-thirty", key: ":six_thirty:", group: "Travel & Places", },
  { unicode: "\u{1F556}", emoji: "🕖", name: "seven o’clock", key: ":seven_o_clock:", group: "Travel & Places", },
  { unicode: "\u{1F562}", emoji: "🕢", name: "seven-thirty", key: ":seven_thirty:", group: "Travel & Places", },
  { unicode: "\u{1F557}", emoji: "🕗", name: "eight o’clock", key: ":eight_o_clock:", group: "Travel & Places", },
  { unicode: "\u{1F563}", emoji: "🕣", name: "eight-thirty", key: ":eight_thirty:", group: "Travel & Places", },
  { unicode: "\u{1F558}", emoji: "🕘", name: "nine o’clock", key: ":nine_o_clock:", group: "Travel & Places", },
  { unicode: "\u{1F564}", emoji: "🕤", name: "nine-thirty", key: ":nine_thirty:", group: "Travel & Places", },
  { unicode: "\u{1F559}", emoji: "🕙", name: "ten o’clock", key: ":ten_o_clock:", group: "Travel & Places", },
  { unicode: "\u{1F565}", emoji: "🕥", name: "ten-thirty", key: ":ten_thirty:", group: "Travel & Places", },
  { unicode: "\u{1F55A}", emoji: "🕚", name: "eleven o’clock", key: ":eleven_o_clock:", group: "Travel & Places", },
  { unicode: "\u{1F566}", emoji: "🕦", name: "eleven-thirty", key: ":eleven_thirty:", group: "Travel & Places", },
  { unicode: "\u{1F311}", emoji: "🌑", name: "new moon", key: ":new_moon:", group: "Travel & Places", },
  { unicode: "\u{1F312}", emoji: "🌒", name: "waxing crescent moon", key: ":waxing_crescent_moon:", group: "Travel & Places", },
  { unicode: "\u{1F313}", emoji: "🌓", name: "first quarter moon", key: ":first_quarter_moon:", group: "Travel & Places", },
  { unicode: "\u{1F314}", emoji: "🌔", name: "waxing gibbous moon", key: ":waxing_gibbous_moon:", group: "Travel & Places", },
  { unicode: "\u{1F315}", emoji: "🌕", name: "full moon", key: ":full_moon:", group: "Travel & Places", },
  { unicode: "\u{1F316}", emoji: "🌖", name: "waning gibbous moon", key: ":waning_gibbous_moon:", group: "Travel & Places", },
  { unicode: "\u{1F317}", emoji: "🌗", name: "last quarter moon", key: ":last_quarter_moon:", group: "Travel & Places", },
  { unicode: "\u{1F318}", emoji: "🌘", name: "waning crescent moon", key: ":waning_crescent_moon:", group: "Travel & Places", },
  { unicode: "\u{1F319}", emoji: "🌙", name: "crescent moon", key: ":crescent_moon:", group: "Travel & Places", },
  { unicode: "\u{1F31A}", emoji: "🌚", name: "new moon face", key: ":new_moon_face:", group: "Travel & Places", },
  { unicode: "\u{1F31B}", emoji: "🌛", name: "first quarter moon face", key: ":first_quarter_moon_face:", group: "Travel & Places", },
  { unicode: "\u{1F31C}", emoji: "🌜", name: "last quarter moon face", key: ":last_quarter_moon_face:", group: "Travel & Places", },
  { unicode: "\u{1F321}\u{FE0F}", emoji: "🌡️", name: "thermometer", key: ":thermometer:", group: "Travel & Places", },
  { unicode: "\u{2600}\u{FE0F}", emoji: "☀️", name: "sun", key: ":sun:", group: "Travel & Places", },
  { unicode: "\u{1F31D}", emoji: "🌝", name: "full moon face", key: ":full_moon_face:", group: "Travel & Places", },
  { unicode: "\u{1F31E}", emoji: "🌞", name: "sun with face", key: ":sun_with_face:", group: "Travel & Places", },
  // { unicode: "\u{1FA90}", emoji: "🪐", name: "ringed planet", key: ":ringed_planet:", group: "Travel & Places", },
  { unicode: "\u{2B50}", emoji: "⭐", name: "star", key: ":star:", group: "Travel & Places", },
  { unicode: "\u{1F31F}", emoji: "🌟", name: "glowing star", key: ":glowing_star:", group: "Travel & Places", },
  { unicode: "\u{1F320}", emoji: "🌠", name: "shooting star", key: ":shooting_star:", group: "Travel & Places", },
  { unicode: "\u{1F30C}", emoji: "🌌", name: "milky way", key: ":milky_way:", group: "Travel & Places", },
  { unicode: "\u{2601}\u{FE0F}", emoji: "☁️", name: "cloud", key: ":cloud:", group: "Travel & Places", },
  { unicode: "\u{26C5}", emoji: "⛅", name: "sun behind cloud", key: ":sun_behind_cloud:", group: "Travel & Places", },
  { unicode: "\u{26C8}\u{FE0F}", emoji: "⛈️", name: "cloud with lightning and rain", key: ":cloud_with_lightning_and_rain:", group: "Travel & Places", },
  { unicode: "\u{1F324}\u{FE0F}", emoji: "🌤️", name: "sun behind small cloud", key: ":sun_behind_small_cloud:", group: "Travel & Places", },
  { unicode: "\u{1F325}\u{FE0F}", emoji: "🌥️", name: "sun behind large cloud", key: ":sun_behind_large_cloud:", group: "Travel & Places", },
  { unicode: "\u{1F326}\u{FE0F}", emoji: "🌦️", name: "sun behind rain cloud", key: ":sun_behind_rain_cloud:", group: "Travel & Places", },
  { unicode: "\u{1F327}\u{FE0F}", emoji: "🌧️", name: "cloud with rain", key: ":cloud_with_rain:", group: "Travel & Places", },
  { unicode: "\u{1F328}\u{FE0F}", emoji: "🌨️", name: "cloud with snow", key: ":cloud_with_snow:", group: "Travel & Places", },
  { unicode: "\u{1F329}\u{FE0F}", emoji: "🌩️", name: "cloud with lightning", key: ":cloud_with_lightning:", group: "Travel & Places", },
  { unicode: "\u{1F32A}\u{FE0F}", emoji: "🌪️", name: "tornado", key: ":tornado:", group: "Travel & Places", },
  { unicode: "\u{1F32B}\u{FE0F}", emoji: "🌫️", name: "fog", key: ":fog:", group: "Travel & Places", },
  { unicode: "\u{1F32C}\u{FE0F}", emoji: "🌬️", name: "wind face", key: ":wind_face:", group: "Travel & Places", },
  { unicode: "\u{1F300}", emoji: "🌀", name: "cyclone", key: ":cyclone:", group: "Travel & Places", },
  { unicode: "\u{1F308}", emoji: "🌈", name: "rainbow", key: ":rainbow:", group: "Travel & Places", },
  { unicode: "\u{1F302}", emoji: "🌂", name: "closed umbrella", key: ":closed_umbrella:", group: "Travel & Places", },
  { unicode: "\u{2602}\u{FE0F}", emoji: "☂️", name: "umbrella", key: ":umbrella:", group: "Travel & Places", },
  { unicode: "\u{2614}", emoji: "☔", name: "umbrella with rain drops", key: ":umbrella_with_rain_drops:", group: "Travel & Places", },
  { unicode: "\u{26F1}\u{FE0F}", emoji: "⛱️", name: "umbrella on ground", key: ":umbrella_on_ground:", group: "Travel & Places", },
  { unicode: "\u{26A1}", emoji: "⚡", name: "high voltage", key: ":high_voltage:", group: "Travel & Places", },
  { unicode: "\u{2744}\u{FE0F}", emoji: "❄️", name: "snowflake", key: ":snowflake:", group: "Travel & Places", },
  { unicode: "\u{2603}\u{FE0F}", emoji: "☃️", name: "snowman", key: ":snowman:", group: "Travel & Places", },
  { unicode: "\u{26C4}", emoji: "⛄", name: "snowman without snow", key: ":snowman_without_snow:", group: "Travel & Places", },
  { unicode: "\u{2604}\u{FE0F}", emoji: "☄️", name: "comet", key: ":comet:", group: "Travel & Places", },
  { unicode: "\u{1F525}", emoji: "🔥", name: "fire", key: ":fire:", group: "Travel & Places", },
  { unicode: "\u{1F4A7}", emoji: "💧", name: "droplet", key: ":droplet:", group: "Travel & Places", },
  { unicode: "\u{1F30A}", emoji: "🌊", name: "water wave", key: ":water_wave:", group: "Travel & Places", },
  { unicode: "\u{1F383}", emoji: "🎃", name: "jack-o-lantern", key: ":jack_o_lantern:", group: "Activities", },
  { unicode: "\u{1F384}", emoji: "🎄", name: "Christmas tree", key: ":Christmas_tree:", group: "Activities", },
  { unicode: "\u{1F386}", emoji: "🎆", name: "fireworks", key: ":fireworks:", group: "Activities", },
  { unicode: "\u{1F387}", emoji: "🎇", name: "sparkler", key: ":sparkler:", group: "Activities", },
  // { unicode: "\u{1F9E8}", emoji: "🧨", name: "firecracker", key: ":firecracker:", group: "Activities", },
  { unicode: "\u{2728}", emoji: "✨", name: "sparkles", key: ":sparkles:", group: "Activities", },
  { unicode: "\u{1F388}", emoji: "🎈", name: "balloon", key: ":balloon:", group: "Activities", },
  { unicode: "\u{1F389}", emoji: "🎉", name: "party popper", key: ":party_popper:", group: "Activities", },
  { unicode: "\u{1F38A}", emoji: "🎊", name: "confetti ball", key: ":confetti_ball:", group: "Activities", },
  { unicode: "\u{1F38B}", emoji: "🎋", name: "tanabata tree", key: ":tanabata_tree:", group: "Activities", },
  { unicode: "\u{1F38D}", emoji: "🎍", name: "pine decoration", key: ":pine_decoration:", group: "Activities", },
  { unicode: "\u{1F38E}", emoji: "🎎", name: "Japanese dolls", key: ":Japanese_dolls:", group: "Activities", },
  { unicode: "\u{1F38F}", emoji: "🎏", name: "carp streamer", key: ":carp_streamer:", group: "Activities", },
  { unicode: "\u{1F390}", emoji: "🎐", name: "wind chime", key: ":wind_chime:", group: "Activities", },
  { unicode: "\u{1F391}", emoji: "🎑", name: "moon viewing ceremony", key: ":moon_viewing_ceremony:", group: "Activities", },
  // { unicode: "\u{1F9E7}", emoji: "🧧", name: "red envelope", key: ":red_envelope:", group: "Activities", },
  { unicode: "\u{1F380}", emoji: "🎀", name: "ribbon", key: ":ribbon:", group: "Activities", },
  { unicode: "\u{1F381}", emoji: "🎁", name: "wrapped gift", key: ":wrapped_gift:", group: "Activities", },
  { unicode: "\u{1F397}\u{FE0F}", emoji: "🎗️", name: "reminder ribbon", key: ":reminder_ribbon:", group: "Activities", },
  { unicode: "\u{1F39F}\u{FE0F}", emoji: "🎟️", name: "admission tickets", key: ":admission_tickets:", group: "Activities", },
  { unicode: "\u{1F3AB}", emoji: "🎫", name: "ticket", key: ":ticket:", group: "Activities", },
  { unicode: "\u{1F396}\u{FE0F}", emoji: "🎖️", name: "military medal", key: ":military_medal:", group: "Activities", },
  { unicode: "\u{1F3C6}", emoji: "🏆", name: "trophy", key: ":trophy:", group: "Activities", },
  { unicode: "\u{1F3C5}", emoji: "🏅", name: "sports medal", key: ":sports_medal:", group: "Activities", },
  { unicode: "\u{1F947}", emoji: "🥇", name: "1st place medal", key: ":1st_place_medal:", group: "Activities", },
  { unicode: "\u{1F948}", emoji: "🥈", name: "2nd place medal", key: ":2nd_place_medal:", group: "Activities", },
  { unicode: "\u{1F949}", emoji: "🥉", name: "3rd place medal", key: ":3rd_place_medal:", group: "Activities", },
  { unicode: "\u{26BD}", emoji: "⚽", name: "soccer ball", key: ":soccer_ball:", group: "Activities", },
  { unicode: "\u{26BE}", emoji: "⚾", name: "baseball", key: ":baseball:", group: "Activities", },
  // { unicode: "\u{1F94E}", emoji: "🥎", name: "softball", key: ":softball:", group: "Activities", },
  { unicode: "\u{1F3C0}", emoji: "🏀", name: "basketball", key: ":basketball:", group: "Activities", },
  { unicode: "\u{1F3D0}", emoji: "🏐", name: "volleyball", key: ":volleyball:", group: "Activities", },
  { unicode: "\u{1F3C8}", emoji: "🏈", name: "american football", key: ":american_football:", group: "Activities", },
  { unicode: "\u{1F3C9}", emoji: "🏉", name: "rugby football", key: ":rugby_football:", group: "Activities", },
  { unicode: "\u{1F3BE}", emoji: "🎾", name: "tennis", key: ":tennis:", group: "Activities", },
  // { unicode: "\u{1F94F}", emoji: "🥏", name: "flying disc", key: ":flying_disc:", group: "Activities", },
  { unicode: "\u{1F3B3}", emoji: "🎳", name: "bowling", key: ":bowling:", group: "Activities", },
  { unicode: "\u{1F3CF}", emoji: "🏏", name: "cricket game", key: ":cricket_game:", group: "Activities", },
  { unicode: "\u{1F3D1}", emoji: "🏑", name: "field hockey", key: ":field_hockey:", group: "Activities", },
  { unicode: "\u{1F3D2}", emoji: "🏒", name: "ice hockey", key: ":ice_hockey:", group: "Activities", },
  // { unicode: "\u{1F94D}", emoji: "🥍", name: "lacrosse", key: ":lacrosse:", group: "Activities", },
  { unicode: "\u{1F3D3}", emoji: "🏓", name: "ping pong", key: ":ping_pong:", group: "Activities", },
  { unicode: "\u{1F3F8}", emoji: "🏸", name: "badminton", key: ":badminton:", group: "Activities", },
  { unicode: "\u{1F94A}", emoji: "🥊", name: "boxing glove", key: ":boxing_glove:", group: "Activities", },
  { unicode: "\u{1F94B}", emoji: "🥋", name: "martial arts uniform", key: ":martial_arts_uniform:", group: "Activities", },
  { unicode: "\u{1F945}", emoji: "🥅", name: "goal net", key: ":goal_net:", group: "Activities", },
  { unicode: "\u{26F3}", emoji: "⛳", name: "flag in hole", key: ":flag_in_hole:", group: "Activities", },
  { unicode: "\u{26F8}\u{FE0F}", emoji: "⛸️", name: "ice skate", key: ":ice_skate:", group: "Activities", },
  { unicode: "\u{1F3A3}", emoji: "🎣", name: "fishing pole", key: ":fishing_pole:", group: "Activities", },
  // { unicode: "\u{1F93F}", emoji: "🤿", name: "diving mask", key: ":diving_mask:", group: "Activities", },
  { unicode: "\u{1F3BD}", emoji: "🎽", name: "running shirt", key: ":running_shirt:", group: "Activities", },
  { unicode: "\u{1F3BF}", emoji: "🎿", name: "skis", key: ":skis:", group: "Activities", },
  { unicode: "\u{1F6F7}", emoji: "🛷", name: "sled", key: ":sled:", group: "Activities", },
  { unicode: "\u{1F94C}", emoji: "🥌", name: "curling stone", key: ":curling_stone:", group: "Activities", },
  { unicode: "\u{1F3AF}", emoji: "🎯", name: "direct hit", key: ":direct_hit:", group: "Activities", },
  // { unicode: "\u{1FA80}", emoji: "🪀", name: "yo-yo", key: ":yo_yo:", group: "Activities", },
  // { unicode: "\u{1FA81}", emoji: "🪁", name: "kite", key: ":kite:", group: "Activities", },
  { unicode: "\u{1F3B1}", emoji: "🎱", name: "pool 8 ball", key: ":pool_8_ball:", group: "Activities", },
  { unicode: "\u{1F52E}", emoji: "🔮", name: "crystal ball", key: ":crystal_ball:", group: "Activities", },
  // { unicode: "\u{1F9FF}", emoji: "🧿", name: "nazar amulet", key: ":nazar_amulet:", group: "Activities", },
  { unicode: "\u{1F3AE}", emoji: "🎮", name: "video game", key: ":video_game:", group: "Activities", },
  { unicode: "\u{1F579}\u{FE0F}", emoji: "🕹️", name: "joystick", key: ":joystick:", group: "Activities", },
  { unicode: "\u{1F3B0}", emoji: "🎰", name: "slot machine", key: ":slot_machine:", group: "Activities", },
  { unicode: "\u{1F3B2}", emoji: "🎲", name: "game die", key: ":game_die:", group: "Activities", },
  // { unicode: "\u{1F9E9}", emoji: "🧩", name: "puzzle piece", key: ":puzzle_piece:", group: "Activities", },
  // { unicode: "\u{1F9F8}", emoji: "🧸", name: "teddy bear", key: ":teddy_bear:", group: "Activities", },
  { unicode: "\u{2660}\u{FE0F}", emoji: "♠️", name: "spade suit", key: ":spade_suit:", group: "Activities", },
  { unicode: "\u{2665}\u{FE0F}", emoji: "♥️", name: "heart suit", key: ":heart_suit:", group: "Activities", },
  { unicode: "\u{2666}\u{FE0F}", emoji: "♦️", name: "diamond suit", key: ":diamond_suit:", group: "Activities", },
  { unicode: "\u{2663}\u{FE0F}", emoji: "♣️", name: "club suit", key: ":club_suit:", group: "Activities", },
  { unicode: "\u{265F}\u{FE0F}", emoji: "♟️", name: "chess pawn", key: ":chess_pawn:", group: "Activities", },
  { unicode: "\u{1F0CF}", emoji: "🃏", name: "joker", key: ":joker:", group: "Activities", },
  { unicode: "\u{1F004}", emoji: "🀄", name: "mahjong red dragon", key: ":mahjong_red_dragon:", group: "Activities", },
  { unicode: "\u{1F3B4}", emoji: "🎴", name: "flower playing cards", key: ":flower_playing_cards:", group: "Activities", },
  { unicode: "\u{1F3AD}", emoji: "🎭", name: "performing arts", key: ":performing_arts:", group: "Activities", },
  { unicode: "\u{1F5BC}\u{FE0F}", emoji: "🖼️", name: "framed picture", key: ":framed_picture:", group: "Activities", },
  { unicode: "\u{1F3A8}", emoji: "🎨", name: "artist palette", key: ":artist_palette:", group: "Activities", },
  // { unicode: "\u{1F9F5}", emoji: "🧵", name: "thread", key: ":thread:", group: "Activities", },
  // { unicode: "\u{1F9F6}", emoji: "🧶", name: "yarn", key: ":yarn:", group: "Activities", },
  { unicode: "\u{1F453}", emoji: "👓", name: "glasses", key: ":glasses:", group: "Objects", },
  { unicode: "\u{1F576}\u{FE0F}", emoji: "🕶️", name: "sunglasses", key: ":sunglasses:", group: "Objects", },
  // { unicode: "\u{1F97D}", emoji: "🥽", name: "goggles", key: ":goggles:", group: "Objects", },
  // { unicode: "\u{1F97C}", emoji: "🥼", name: "lab coat", key: ":lab_coat:", group: "Objects", },
  // { unicode: "\u{1F9BA}", emoji: "🦺", name: "safety vest", key: ":safety_vest:", group: "Objects", },
  { unicode: "\u{1F454}", emoji: "👔", name: "necktie", key: ":necktie:", group: "Objects", },
  { unicode: "\u{1F455}", emoji: "👕", name: "t-shirt", key: ":t_shirt:", group: "Objects", },
  { unicode: "\u{1F456}", emoji: "👖", name: "jeans", key: ":jeans:", group: "Objects", },
  { unicode: "\u{1F9E3}", emoji: "🧣", name: "scarf", key: ":scarf:", group: "Objects", },
  { unicode: "\u{1F9E4}", emoji: "🧤", name: "gloves", key: ":gloves:", group: "Objects", },
  { unicode: "\u{1F9E5}", emoji: "🧥", name: "coat", key: ":coat:", group: "Objects", },
  { unicode: "\u{1F9E6}", emoji: "🧦", name: "socks", key: ":socks:", group: "Objects", },
  { unicode: "\u{1F457}", emoji: "👗", name: "dress", key: ":dress:", group: "Objects", },
  { unicode: "\u{1F458}", emoji: "👘", name: "kimono", key: ":kimono:", group: "Objects", },
  // { unicode: "\u{1F97B}", emoji: "🥻", name: "sari", key: ":sari:", group: "Objects", },
  // { unicode: "\u{1FA71}", emoji: "🩱", name: "one-piece swimsuit", key: ":one_piece_swimsuit:", group: "Objects", },
  // { unicode: "\u{1FA72}", emoji: "🩲", name: "briefs", key: ":briefs:", group: "Objects", },
  // { unicode: "\u{1FA73}", emoji: "🩳", name: "shorts", key: ":shorts:", group: "Objects", },
  { unicode: "\u{1F459}", emoji: "👙", name: "bikini", key: ":bikini:", group: "Objects", },
  { unicode: "\u{1F45A}", emoji: "👚", name: "woman’s clothes", key: ":womans_clothes:", group: "Objects", },
  { unicode: "\u{1F45B}", emoji: "👛", name: "purse", key: ":purse:", group: "Objects", },
  { unicode: "\u{1F45C}", emoji: "👜", name: "handbag", key: ":handbag:", group: "Objects", },
  { unicode: "\u{1F45D}", emoji: "👝", name: "clutch bag", key: ":clutch_bag:", group: "Objects", },
  { unicode: "\u{1F6CD}\u{FE0F}", emoji: "🛍️", name: "shopping bags", key: ":shopping_bags:", group: "Objects", },
  { unicode: "\u{1F392}", emoji: "🎒", name: "backpack", key: ":backpack:", group: "Objects", },
  { unicode: "\u{1F45E}", emoji: "👞", name: "man’s shoe", key: ":mans_shoe:", group: "Objects", },
  { unicode: "\u{1F45F}", emoji: "👟", name: "running shoe", key: ":running_shoe:", group: "Objects", },
  // { unicode: "\u{1F97E}", emoji: "🥾", name: "hiking boot", key: ":hiking_boot:", group: "Objects", },
  // { unicode: "\u{1F97F}", emoji: "🥿", name: "flat shoe", key: ":flat_shoe:", group: "Objects", },
  { unicode: "\u{1F460}", emoji: "👠", name: "high-heeled shoe", key: ":high_heeled_shoe:", group: "Objects", },
  { unicode: "\u{1F461}", emoji: "👡", name: "woman’s sandal", key: ":womans_sandal:", group: "Objects", },
  // { unicode: "\u{1FA70}", emoji: "🩰", name: "ballet shoes", key: ":ballet_shoes:", group: "Objects", },
  { unicode: "\u{1F462}", emoji: "👢", name: "woman’s boot", key: ":womans_boot:", group: "Objects", },
  { unicode: "\u{1F451}", emoji: "👑", name: "crown", key: ":crown:", group: "Objects", },
  { unicode: "\u{1F452}", emoji: "👒", name: "woman’s hat", key: ":womans_hat:", group: "Objects", },
  { unicode: "\u{1F3A9}", emoji: "🎩", name: "top hat", key: ":top_hat:", group: "Objects", },
  { unicode: "\u{1F393}", emoji: "🎓", name: "graduation cap", key: ":graduation_cap:", group: "Objects", },
  { unicode: "\u{1F9E2}", emoji: "🧢", name: "billed cap", key: ":billed_cap:", group: "Objects", },
  { unicode: "\u{26D1}\u{FE0F}", emoji: "⛑️", name: "rescue worker’s helmet", key: ":rescue_workers_helmet:", group: "Objects", },
  { unicode: "\u{1F4FF}", emoji: "📿", name: "prayer beads", key: ":prayer_beads:", group: "Objects", },
  { unicode: "\u{1F484}", emoji: "💄", name: "lipstick", key: ":lipstick:", group: "Objects", },
  { unicode: "\u{1F48D}", emoji: "💍", name: "ring", key: ":ring:", group: "Objects", },
  { unicode: "\u{1F48E}", emoji: "💎", name: "gem stone", key: ":gem_stone:", group: "Objects", },
  { unicode: "\u{1F507}", emoji: "🔇", name: "muted speaker", key: ":muted_speaker:", group: "Objects", },
  { unicode: "\u{1F508}", emoji: "🔈", name: "speaker low volume", key: ":speaker_low_volume:", group: "Objects", },
  { unicode: "\u{1F509}", emoji: "🔉", name: "speaker medium volume", key: ":speaker_medium_volume:", group: "Objects", },
  { unicode: "\u{1F50A}", emoji: "🔊", name: "speaker high volume", key: ":speaker_high_volume:", group: "Objects", },
  { unicode: "\u{1F4E2}", emoji: "📢", name: "loudspeaker", key: ":loudspeaker:", group: "Objects", },
  { unicode: "\u{1F4E3}", emoji: "📣", name: "megaphone", key: ":megaphone:", group: "Objects", },
  { unicode: "\u{1F4EF}", emoji: "📯", name: "postal horn", key: ":postal_horn:", group: "Objects", },
  { unicode: "\u{1F514}", emoji: "🔔", name: "bell", key: ":bell:", group: "Objects", },
  { unicode: "\u{1F515}", emoji: "🔕", name: "bell with slash", key: ":bell_with_slash:", group: "Objects", },
  { unicode: "\u{1F3BC}", emoji: "🎼", name: "musical score", key: ":musical_score:", group: "Objects", },
  { unicode: "\u{1F3B5}", emoji: "🎵", name: "musical note", key: ":musical_note:", group: "Objects", },
  { unicode: "\u{1F3B6}", emoji: "🎶", name: "musical notes", key: ":musical_notes:", group: "Objects", },
  { unicode: "\u{1F399}\u{FE0F}", emoji: "🎙️", name: "studio microphone", key: ":studio_microphone:", group: "Objects", },
  { unicode: "\u{1F39A}\u{FE0F}", emoji: "🎚️", name: "level slider", key: ":level_slider:", group: "Objects", },
  { unicode: "\u{1F39B}\u{FE0F}", emoji: "🎛️", name: "control knobs", key: ":control_knobs:", group: "Objects", },
  { unicode: "\u{1F3A4}", emoji: "🎤", name: "microphone", key: ":microphone:", group: "Objects", },
  { unicode: "\u{1F3A7}", emoji: "🎧", name: "headphone", key: ":headphone:", group: "Objects", },
  { unicode: "\u{1F4FB}", emoji: "📻", name: "radio", key: ":radio:", group: "Objects", },
  { unicode: "\u{1F3B7}", emoji: "🎷", name: "saxophone", key: ":saxophone:", group: "Objects", },
  { unicode: "\u{1F3B8}", emoji: "🎸", name: "guitar", key: ":guitar:", group: "Objects", },
  { unicode: "\u{1F3B9}", emoji: "🎹", name: "musical keyboard", key: ":musical_keyboard:", group: "Objects", },
  { unicode: "\u{1F3BA}", emoji: "🎺", name: "trumpet", key: ":trumpet:", group: "Objects", },
  { unicode: "\u{1F3BB}", emoji: "🎻", name: "violin", key: ":violin:", group: "Objects", },
  // { unicode: "\u{1FA95}", emoji: "🪕", name: "banjo", key: ":banjo:", group: "Objects", },
  { unicode: "\u{1F941}", emoji: "🥁", name: "drum", key: ":drum:", group: "Objects", },
  { unicode: "\u{1F4F1}", emoji: "📱", name: "mobile phone", key: ":mobile_phone:", group: "Objects", },
  { unicode: "\u{1F4F2}", emoji: "📲", name: "mobile phone with arrow", key: ":mobile_phone_with_arrow:", group: "Objects", },
  { unicode: "\u{260E}\u{FE0F}", emoji: "☎️", name: "telephone", key: ":telephone:", group: "Objects", },
  { unicode: "\u{1F4DE}", emoji: "📞", name: "telephone receiver", key: ":telephone_receiver:", group: "Objects", },
  { unicode: "\u{1F4DF}", emoji: "📟", name: "pager", key: ":pager:", group: "Objects", },
  { unicode: "\u{1F4E0}", emoji: "📠", name: "fax machine", key: ":fax_machine:", group: "Objects", },
  { unicode: "\u{1F50B}", emoji: "🔋", name: "battery", key: ":battery:", group: "Objects", },
  { unicode: "\u{1F50C}", emoji: "🔌", name: "electric plug", key: ":electric_plug:", group: "Objects", },
  { unicode: "\u{1F4BB}", emoji: "💻", name: "laptop", key: ":laptop:", group: "Objects", },
  { unicode: "\u{1F5A5}\u{FE0F}", emoji: "🖥️", name: "desktop computer", key: ":desktop_computer:", group: "Objects", },
  { unicode: "\u{1F5A8}\u{FE0F}", emoji: "🖨️", name: "printer", key: ":printer:", group: "Objects", },
  { unicode: "\u{2328}\u{FE0F}", emoji: "⌨️", name: "keyboard", key: ":keyboard:", group: "Objects", },
  { unicode: "\u{1F5B1}\u{FE0F}", emoji: "🖱️", name: "computer mouse", key: ":computer_mouse:", group: "Objects", },
  { unicode: "\u{1F5B2}\u{FE0F}", emoji: "🖲️", name: "trackball", key: ":trackball:", group: "Objects", },
  { unicode: "\u{1F4BD}", emoji: "💽", name: "computer disk", key: ":computer_disk:", group: "Objects", },
  { unicode: "\u{1F4BE}", emoji: "💾", name: "floppy disk", key: ":floppy_disk:", group: "Objects", },
  { unicode: "\u{1F4BF}", emoji: "💿", name: "optical disk", key: ":optical_disk:", group: "Objects", },
  { unicode: "\u{1F4C0}", emoji: "📀", name: "dvd", key: ":dvd:", group: "Objects", },
  // { unicode: "\u{1F9EE}", emoji: "🧮", name: "abacus", key: ":abacus:", group: "Objects", },
  { unicode: "\u{1F3A5}", emoji: "🎥", name: "movie camera", key: ":movie_camera:", group: "Objects", },
  { unicode: "\u{1F39E}\u{FE0F}", emoji: "🎞️", name: "film frames", key: ":film_frames:", group: "Objects", },
  { unicode: "\u{1F4FD}\u{FE0F}", emoji: "📽️", name: "film projector", key: ":film_projector:", group: "Objects", },
  { unicode: "\u{1F3AC}", emoji: "🎬", name: "clapper board", key: ":clapper_board:", group: "Objects", },
  { unicode: "\u{1F4FA}", emoji: "📺", name: "television", key: ":television:", group: "Objects", },
  { unicode: "\u{1F4F7}", emoji: "📷", name: "camera", key: ":camera:", group: "Objects", },
  { unicode: "\u{1F4F8}", emoji: "📸", name: "camera with flash", key: ":camera_with_flash:", group: "Objects", },
  { unicode: "\u{1F4F9}", emoji: "📹", name: "video camera", key: ":video_camera:", group: "Objects", },
  { unicode: "\u{1F4FC}", emoji: "📼", name: "videocassette", key: ":videocassette:", group: "Objects", },
  { unicode: "\u{1F50D}", emoji: "🔍", name: "magnifying glass tilted left", key: ":magnifying_glass_tilted_left:", group: "Objects", },
  { unicode: "\u{1F50E}", emoji: "🔎", name: "magnifying glass tilted right", key: ":magnifying_glass_tilted_right:", group: "Objects", },
  { unicode: "\u{1F56F}\u{FE0F}", emoji: "🕯️", name: "candle", key: ":candle:", group: "Objects", },
  { unicode: "\u{1F4A1}", emoji: "💡", name: "light bulb", key: ":light_bulb:", group: "Objects", },
  { unicode: "\u{1F526}", emoji: "🔦", name: "flashlight", key: ":flashlight:", group: "Objects", },
  { unicode: "\u{1F3EE}", emoji: "🏮", name: "red paper lantern", key: ":red_paper_lantern:", group: "Objects", },
  // { unicode: "\u{1FA94}", emoji: "🪔", name: "diya lamp", key: ":diya_lamp:", group: "Objects", },
  { unicode: "\u{1F4D4}", emoji: "📔", name: "notebook with decorative cover", key: ":notebook_with_decorative_cover:", group: "Objects", },
  { unicode: "\u{1F4D5}", emoji: "📕", name: "closed book", key: ":closed_book:", group: "Objects", },
  { unicode: "\u{1F4D6}", emoji: "📖", name: "open book", key: ":open_book:", group: "Objects", },
  { unicode: "\u{1F4D7}", emoji: "📗", name: "green book", key: ":green_book:", group: "Objects", },
  { unicode: "\u{1F4D8}", emoji: "📘", name: "blue book", key: ":blue_book:", group: "Objects", },
  { unicode: "\u{1F4D9}", emoji: "📙", name: "orange book", key: ":orange_book:", group: "Objects", },
  { unicode: "\u{1F4DA}", emoji: "📚", name: "books", key: ":books:", group: "Objects", },
  { unicode: "\u{1F4D3}", emoji: "📓", name: "notebook", key: ":notebook:", group: "Objects", },
  { unicode: "\u{1F4D2}", emoji: "📒", name: "ledger", key: ":ledger:", group: "Objects", },
  { unicode: "\u{1F4C3}", emoji: "📃", name: "page with curl", key: ":page_with_curl:", group: "Objects", },
  { unicode: "\u{1F4DC}", emoji: "📜", name: "scroll", key: ":scroll:", group: "Objects", },
  { unicode: "\u{1F4C4}", emoji: "📄", name: "page facing up", key: ":page_facing_up:", group: "Objects", },
  { unicode: "\u{1F4F0}", emoji: "📰", name: "newspaper", key: ":newspaper:", group: "Objects", },
  { unicode: "\u{1F5DE}\u{FE0F}", emoji: "🗞️", name: "rolled-up newspaper", key: ":rolled_up_newspaper:", group: "Objects", },
  { unicode: "\u{1F4D1}", emoji: "📑", name: "bookmark tabs", key: ":bookmark_tabs:", group: "Objects", },
  { unicode: "\u{1F516}", emoji: "🔖", name: "bookmark", key: ":bookmark:", group: "Objects", },
  { unicode: "\u{1F3F7}\u{FE0F}", emoji: "🏷️", name: "label", key: ":label:", group: "Objects", },
  { unicode: "\u{1F4B0}", emoji: "💰", name: "money bag", key: ":money_bag:", group: "Objects", },
  { unicode: "\u{1F4B4}", emoji: "💴", name: "yen banknote", key: ":yen_banknote:", group: "Objects", },
  { unicode: "\u{1F4B5}", emoji: "💵", name: "dollar banknote", key: ":dollar_banknote:", group: "Objects", },
  { unicode: "\u{1F4B6}", emoji: "💶", name: "euro banknote", key: ":euro_banknote:", group: "Objects", },
  { unicode: "\u{1F4B7}", emoji: "💷", name: "pound banknote", key: ":pound_banknote:", group: "Objects", },
  { unicode: "\u{1F4B8}", emoji: "💸", name: "money with wings", key: ":money_with_wings:", group: "Objects", },
  { unicode: "\u{1F4B3}", emoji: "💳", name: "credit card", key: ":credit_card:", group: "Objects", },
  // { unicode: "\u{1F9FE}", emoji: "🧾", name: "receipt", key: ":receipt:", group: "Objects", },
  { unicode: "\u{1F4B9}", emoji: "💹", name: "chart increasing with yen", key: ":chart_increasing_with_yen:", group: "Objects", },
  { unicode: "\u{1F4B1}", emoji: "💱", name: "currency exchange", key: ":currency_exchange:", group: "Objects", },
  { unicode: "\u{1F4B2}", emoji: "💲", name: "heavy dollar sign", key: ":heavy_dollar_sign:", group: "Objects", },
  { unicode: "\u{2709}\u{FE0F}", emoji: "✉️", name: "envelope", key: ":envelope:", group: "Objects", },
  { unicode: "\u{1F4E7}", emoji: "📧", name: "e-mail", key: ":e_mail:", group: "Objects", },
  { unicode: "\u{1F4E8}", emoji: "📨", name: "incoming envelope", key: ":incoming_envelope:", group: "Objects", },
  { unicode: "\u{1F4E9}", emoji: "📩", name: "envelope with arrow", key: ":envelope_with_arrow:", group: "Objects", },
  { unicode: "\u{1F4E4}", emoji: "📤", name: "outbox tray", key: ":outbox_tray:", group: "Objects", },
  { unicode: "\u{1F4E5}", emoji: "📥", name: "inbox tray", key: ":inbox_tray:", group: "Objects", },
  { unicode: "\u{1F4E6}", emoji: "📦", name: "package", key: ":package:", group: "Objects", },
  { unicode: "\u{1F4EB}", emoji: "📫", name: "closed mailbox with raised flag", key: ":closed_mailbox_with_raised_flag:", group: "Objects", },
  { unicode: "\u{1F4EA}", emoji: "📪", name: "closed mailbox with lowered flag", key: ":closed_mailbox_with_lowered_flag:", group: "Objects", },
  { unicode: "\u{1F4EC}", emoji: "📬", name: "open mailbox with raised flag", key: ":open_mailbox_with_raised_flag:", group: "Objects", },
  { unicode: "\u{1F4ED}", emoji: "📭", name: "open mailbox with lowered flag", key: ":open_mailbox_with_lowered_flag:", group: "Objects", },
  { unicode: "\u{1F4EE}", emoji: "📮", name: "postbox", key: ":postbox:", group: "Objects", },
  { unicode: "\u{1F5F3}\u{FE0F}", emoji: "🗳️", name: "ballot box with ballot", key: ":ballot_box_with_ballot:", group: "Objects", },
  { unicode: "\u{270F}\u{FE0F}", emoji: "✏️", name: "pencil", key: ":pencil:", group: "Objects", },
  { unicode: "\u{2712}\u{FE0F}", emoji: "✒️", name: "black nib", key: ":black_nib:", group: "Objects", },
  { unicode: "\u{1F58B}\u{FE0F}", emoji: "🖋️", name: "fountain pen", key: ":fountain_pen:", group: "Objects", },
  { unicode: "\u{1F58A}\u{FE0F}", emoji: "🖊️", name: "pen", key: ":pen:", group: "Objects", },
  { unicode: "\u{1F58C}\u{FE0F}", emoji: "🖌️", name: "paintbrush", key: ":paintbrush:", group: "Objects", },
  { unicode: "\u{1F58D}\u{FE0F}", emoji: "🖍️", name: "crayon", key: ":crayon:", group: "Objects", },
  { unicode: "\u{1F4DD}", emoji: "📝", name: "memo", key: ":memo:", group: "Objects", },
  { unicode: "\u{1F4BC}", emoji: "💼", name: "briefcase", key: ":briefcase:", group: "Objects", },
  { unicode: "\u{1F4C1}", emoji: "📁", name: "file folder", key: ":file_folder:", group: "Objects", },
  { unicode: "\u{1F4C2}", emoji: "📂", name: "open file folder", key: ":open_file_folder:", group: "Objects", },
  { unicode: "\u{1F5C2}\u{FE0F}", emoji: "🗂️", name: "card index dividers", key: ":card_index_dividers:", group: "Objects", },
  { unicode: "\u{1F4C5}", emoji: "📅", name: "calendar", key: ":calendar:", group: "Objects", },
  { unicode: "\u{1F4C6}", emoji: "📆", name: "tear-off calendar", key: ":tear_off_calendar:", group: "Objects", },
  { unicode: "\u{1F5D2}\u{FE0F}", emoji: "🗒️", name: "spiral notepad", key: ":spiral_notepad:", group: "Objects", },
  { unicode: "\u{1F5D3}\u{FE0F}", emoji: "🗓️", name: "spiral calendar", key: ":spiral_calendar:", group: "Objects", },
  { unicode: "\u{1F4C7}", emoji: "📇", name: "card index", key: ":card_index:", group: "Objects", },
  { unicode: "\u{1F4C8}", emoji: "📈", name: "chart increasing", key: ":chart_increasing:", group: "Objects", },
  { unicode: "\u{1F4C9}", emoji: "📉", name: "chart decreasing", key: ":chart_decreasing:", group: "Objects", },
  { unicode: "\u{1F4CA}", emoji: "📊", name: "bar chart", key: ":bar_chart:", group: "Objects", },
  { unicode: "\u{1F4CB}", emoji: "📋", name: "clipboard", key: ":clipboard:", group: "Objects", },
  { unicode: "\u{1F4CC}", emoji: "📌", name: "pushpin", key: ":pushpin:", group: "Objects", },
  { unicode: "\u{1F4CD}", emoji: "📍", name: "round pushpin", key: ":round_pushpin:", group: "Objects", },
  { unicode: "\u{1F4CE}", emoji: "📎", name: "paperclip", key: ":paperclip:", group: "Objects", },
  { unicode: "\u{1F587}\u{FE0F}", emoji: "🖇️", name: "linked paperclips", key: ":linked_paperclips:", group: "Objects", },
  { unicode: "\u{1F4CF}", emoji: "📏", name: "straight ruler", key: ":straight_ruler:", group: "Objects", },
  { unicode: "\u{1F4D0}", emoji: "📐", name: "triangular ruler", key: ":triangular_ruler:", group: "Objects", },
  { unicode: "\u{2702}\u{FE0F}", emoji: "✂️", name: "scissors", key: ":scissors:", group: "Objects", },
  { unicode: "\u{1F5C3}\u{FE0F}", emoji: "🗃️", name: "card file box", key: ":card_file_box:", group: "Objects", },
  { unicode: "\u{1F5C4}\u{FE0F}", emoji: "🗄️", name: "file cabinet", key: ":file_cabinet:", group: "Objects", },
  { unicode: "\u{1F5D1}\u{FE0F}", emoji: "🗑️", name: "wastebasket", key: ":wastebasket:", group: "Objects", },
  { unicode: "\u{1F512}", emoji: "🔒", name: "locked", key: ":locked:", group: "Objects", },
  { unicode: "\u{1F513}", emoji: "🔓", name: "unlocked", key: ":unlocked:", group: "Objects", },
  { unicode: "\u{1F50F}", emoji: "🔏", name: "locked with pen", key: ":locked_with_pen:", group: "Objects", },
  { unicode: "\u{1F510}", emoji: "🔐", name: "locked with key", key: ":locked_with_key:", group: "Objects", },
  { unicode: "\u{1F511}", emoji: "🔑", name: "key", key: ":key:", group: "Objects", },
  { unicode: "\u{1F5DD}\u{FE0F}", emoji: "🗝️", name: "old key", key: ":old_key:", group: "Objects", },
  { unicode: "\u{1F528}", emoji: "🔨", name: "hammer", key: ":hammer:", group: "Objects", },
  { unicode: "\u{1FA93}", emoji: "🪓", name: "axe", key: ":axe:", group: "Objects", },
  { unicode: "\u{26CF}\u{FE0F}", emoji: "⛏️", name: "pick", key: ":pick:", group: "Objects", },
  { unicode: "\u{2692}\u{FE0F}", emoji: "⚒️", name: "hammer and pick", key: ":hammer_and_pick:", group: "Objects", },
  { unicode: "\u{1F6E0}\u{FE0F}", emoji: "🛠️", name: "hammer and wrench", key: ":hammer_and_wrench:", group: "Objects", },
  { unicode: "\u{1F5E1}\u{FE0F}", emoji: "🗡️", name: "dagger", key: ":dagger:", group: "Objects", },
  { unicode: "\u{2694}\u{FE0F}", emoji: "⚔️", name: "crossed swords", key: ":crossed_swords:", group: "Objects", },
  { unicode: "\u{1F52B}", emoji: "🔫", name: "pistol", key: ":pistol:", group: "Objects", },
  { unicode: "\u{1F3F9}", emoji: "🏹", name: "bow and arrow", key: ":bow_and_arrow:", group: "Objects", },
  { unicode: "\u{1F6E1}\u{FE0F}", emoji: "🛡️", name: "shield", key: ":shield:", group: "Objects", },
  { unicode: "\u{1F527}", emoji: "🔧", name: "wrench", key: ":wrench:", group: "Objects", },
  { unicode: "\u{1F529}", emoji: "🔩", name: "nut and bolt", key: ":nut_and_bolt:", group: "Objects", },
  { unicode: "\u{2699}\u{FE0F}", emoji: "⚙️", name: "gear", key: ":gear:", group: "Objects", },
  { unicode: "\u{1F5DC}\u{FE0F}", emoji: "🗜️", name: "clamp", key: ":clamp:", group: "Objects", },
  { unicode: "\u{2696}\u{FE0F}", emoji: "⚖️", name: "balance scale", key: ":balance_scale:", group: "Objects", },
  // { unicode: "\u{1F9AF}", emoji: "🦯", name: "probing cane", key: ":probing_cane:", group: "Objects", },
  { unicode: "\u{1F517}", emoji: "🔗", name: "link", key: ":link:", group: "Objects", },
  { unicode: "\u{26D3}\u{FE0F}", emoji: "⛓️", name: "chains", key: ":chains:", group: "Objects", },
  // { unicode: "\u{1F9F0}", emoji: "🧰", name: "toolbox", key: ":toolbox:", group: "Objects", },
  // { unicode: "\u{1F9F2}", emoji: "🧲", name: "magnet", key: ":magnet:", group: "Objects", },
  { unicode: "\u{2697}\u{FE0F}", emoji: "⚗️", name: "alembic", key: ":alembic:", group: "Objects", },
  // { unicode: "\u{1F9EA}", emoji: "🧪", name: "test tube", key: ":test_tube:", group: "Objects", },
  // { unicode: "\u{1F9EB}", emoji: "🧫", name: "petri dish", key: ":petri_dish:", group: "Objects", },
  // { unicode: "\u{1F9EC}", emoji: "🧬", name: "dna", key: ":dna:", group: "Objects", },
  { unicode: "\u{1F52C}", emoji: "🔬", name: "microscope", key: ":microscope:", group: "Objects", },
  { unicode: "\u{1F52D}", emoji: "🔭", name: "telescope", key: ":telescope:", group: "Objects", },
  { unicode: "\u{1F4E1}", emoji: "📡", name: "satellite antenna", key: ":satellite_antenna:", group: "Objects", },
  { unicode: "\u{1F489}", emoji: "💉", name: "syringe", key: ":syringe:", group: "Objects", },
  // { unicode: "\u{1FA78}", emoji: "🩸", name: "drop of blood", key: ":drop_of_blood:", group: "Objects", },
  { unicode: "\u{1F48A}", emoji: "💊", name: "pill", key: ":pill:", group: "Objects", },
  // { unicode: "\u{1FA79}", emoji: "🩹", name: "adhesive bandage", key: ":adhesive_bandage:", group: "Objects", },
  // { unicode: "\u{1FA7A}", emoji: "🩺", name: "stethoscope", key: ":stethoscope:", group: "Objects", },
  { unicode: "\u{1F6AA}", emoji: "🚪", name: "door", key: ":door:", group: "Objects", },
  { unicode: "\u{1F6CF}\u{FE0F}", emoji: "🛏️", name: "bed", key: ":bed:", group: "Objects", },
  { unicode: "\u{1F6CB}\u{FE0F}", emoji: "🛋️", name: "couch and lamp", key: ":couch_and_lamp:", group: "Objects", },
  // { unicode: "\u{1FA91}", emoji: "🪑", name: "chair", key: ":chair:", group: "Objects", },
  { unicode: "\u{1F6BD}", emoji: "🚽", name: "toilet", key: ":toilet:", group: "Objects", },
  { unicode: "\u{1F6BF}", emoji: "🚿", name: "shower", key: ":shower:", group: "Objects", },
  { unicode: "\u{1F6C1}", emoji: "🛁", name: "bathtub", key: ":bathtub:", group: "Objects", },
  // { unicode: "\u{1FA92}", emoji: "🪒", name: "razor", key: ":razor:", group: "Objects", },
  // { unicode: "\u{1F9F4}", emoji: "🧴", name: "lotion bottle", key: ":lotion_bottle:", group: "Objects", },
  // { unicode: "\u{1F9F7}", emoji: "🧷", name: "safety pin", key: ":safety_pin:", group: "Objects", },
  // { unicode: "\u{1F9F9}", emoji: "🧹", name: "broom", key: ":broom:", group: "Objects", },
  // { unicode: "\u{1F9FA}", emoji: "🧺", name: "basket", key: ":basket:", group: "Objects", },
  // { unicode: "\u{1F9FB}", emoji: "🧻", name: "roll of paper", key: ":roll_of_paper:", group: "Objects", },
  // { unicode: "\u{1F9FC}", emoji: "🧼", name: "soap", key: ":soap:", group: "Objects", },
  // { unicode: "\u{1F9FD}", emoji: "🧽", name: "sponge", key: ":sponge:", group: "Objects", },
  // { unicode: "\u{1F9EF}", emoji: "🧯", name: "fire extinguisher", key: ":fire_extinguisher:", group: "Objects", },
  { unicode: "\u{1F6D2}", emoji: "🛒", name: "shopping cart", key: ":shopping_cart:", group: "Objects", },
  { unicode: "\u{1F6AC}", emoji: "🚬", name: "cigarette", key: ":cigarette:", group: "Objects", },
  { unicode: "\u{26B0}\u{FE0F}", emoji: "⚰️", name: "coffin", key: ":coffin:", group: "Objects", },
  { unicode: "\u{26B1}\u{FE0F}", emoji: "⚱️", name: "funeral urn", key: ":funeral_urn:", group: "Objects", },
  { unicode: "\u{1F5FF}", emoji: "🗿", name: "moai", key: ":moai:", group: "Objects", },
  { unicode: "\u{1F3E7}", emoji: "🏧", name: "ATM sign", key: ":ATM_sign:", group: "Symbols", },
  { unicode: "\u{1F6AE}", emoji: "🚮", name: "litter in bin sign", key: ":litter_in_bin_sign:", group: "Symbols", },
  { unicode: "\u{1F6B0}", emoji: "🚰", name: "potable water", key: ":potable_water:", group: "Symbols", },
  { unicode: "\u{267F}", emoji: "♿", name: "wheelchair symbol", key: ":wheelchair_symbol:", group: "Symbols", },
  { unicode: "\u{1F6B9}", emoji: "🚹", name: "men’s room", key: ":mens_room:", group: "Symbols", },
  { unicode: "\u{1F6BA}", emoji: "🚺", name: "women’s room", key: ":womens_room:", group: "Symbols", },
  { unicode: "\u{1F6BB}", emoji: "🚻", name: "restroom", key: ":restroom:", group: "Symbols", },
  { unicode: "\u{1F6BC}", emoji: "🚼", name: "baby symbol", key: ":baby_symbol:", group: "Symbols", },
  { unicode: "\u{1F6BE}", emoji: "🚾", name: "water closet", key: ":water_closet:", group: "Symbols", },
  { unicode: "\u{1F6C2}", emoji: "🛂", name: "passport control", key: ":passport_control:", group: "Symbols", },
  { unicode: "\u{1F6C3}", emoji: "🛃", name: "customs", key: ":customs:", group: "Symbols", },
  { unicode: "\u{1F6C4}", emoji: "🛄", name: "baggage claim", key: ":baggage_claim:", group: "Symbols", },
  { unicode: "\u{1F6C5}", emoji: "🛅", name: "left luggage", key: ":left_luggage:", group: "Symbols", },
  { unicode: "\u{26A0}\u{FE0F}", emoji: "⚠️", name: "warning", key: ":warning:", group: "Symbols", },
  { unicode: "\u{1F6B8}", emoji: "🚸", name: "children crossing", key: ":children_crossing:", group: "Symbols", },
  { unicode: "\u{26D4}", emoji: "⛔", name: "no entry", key: ":no_entry:", group: "Symbols", },
  { unicode: "\u{1F6AB}", emoji: "🚫", name: "prohibited", key: ":prohibited:", group: "Symbols", },
  { unicode: "\u{1F6B3}", emoji: "🚳", name: "no bicycles", key: ":no_bicycles:", group: "Symbols", },
  { unicode: "\u{1F6AD}", emoji: "🚭", name: "no smoking", key: ":no_smoking:", group: "Symbols", },
  { unicode: "\u{1F6AF}", emoji: "🚯", name: "no littering", key: ":no_littering:", group: "Symbols", },
  { unicode: "\u{1F6B1}", emoji: "🚱", name: "non-potable water", key: ":non_potable_water:", group: "Symbols", },
  { unicode: "\u{1F6B7}", emoji: "🚷", name: "no pedestrians", key: ":no_pedestrians:", group: "Symbols", },
  { unicode: "\u{1F4F5}", emoji: "📵", name: "no mobile phones", key: ":no_mobile_phones:", group: "Symbols", },
  { unicode: "\u{1F51E}", emoji: "🔞", name: "no one under eighteen", key: ":no_one_under_eighteen:", group: "Symbols", },
  { unicode: "\u{2622}\u{FE0F}", emoji: "☢️", name: "radioactive", key: ":radioactive:", group: "Symbols", },
  { unicode: "\u{2623}\u{FE0F}", emoji: "☣️", name: "biohazard", key: ":biohazard:", group: "Symbols", },
  { unicode: "\u{2B06}\u{FE0F}", emoji: "⬆️", name: "up arrow", key: ":up_arrow:", group: "Symbols", },
  { unicode: "\u{2197}\u{FE0F}", emoji: "↗️", name: "up-right arrow", key: ":up_right_arrow:", group: "Symbols", },
  { unicode: "\u{27A1}\u{FE0F}", emoji: "➡️", name: "right arrow", key: ":right_arrow:", group: "Symbols", },
  { unicode: "\u{2198}\u{FE0F}", emoji: "↘️", name: "down-right arrow", key: ":down_right_arrow:", group: "Symbols", },
  { unicode: "\u{2B07}\u{FE0F}", emoji: "⬇️", name: "down arrow", key: ":down_arrow:", group: "Symbols", },
  { unicode: "\u{2199}\u{FE0F}", emoji: "↙️", name: "down-left arrow", key: ":down_left_arrow:", group: "Symbols", },
  { unicode: "\u{2B05}\u{FE0F}", emoji: "⬅️", name: "left arrow", key: ":left_arrow:", group: "Symbols", },
  { unicode: "\u{2196}\u{FE0F}", emoji: "↖️", name: "up-left arrow", key: ":up_left_arrow:", group: "Symbols", },
  { unicode: "\u{2195}\u{FE0F}", emoji: "↕️", name: "up-down arrow", key: ":up_down_arrow:", group: "Symbols", },
  { unicode: "\u{2194}\u{FE0F}", emoji: "↔️", name: "left-right arrow", key: ":left_right_arrow:", group: "Symbols", },
  { unicode: "\u{21A9}\u{FE0F}", emoji: "↩️", name: "right arrow curving left", key: ":right_arrow_curving_left:", group: "Symbols", },
  { unicode: "\u{21AA}\u{FE0F}", emoji: "↪️", name: "left arrow curving right", key: ":left_arrow_curving_right:", group: "Symbols", },
  { unicode: "\u{2934}\u{FE0F}", emoji: "⤴️", name: "right arrow curving up", key: ":right_arrow_curving_up:", group: "Symbols", },
  { unicode: "\u{2935}\u{FE0F}", emoji: "⤵️", name: "right arrow curving down", key: ":right_arrow_curving_down:", group: "Symbols", },
  { unicode: "\u{1F503}", emoji: "🔃", name: "clockwise vertical arrows", key: ":clockwise_vertical_arrows:", group: "Symbols", },
  { unicode: "\u{1F504}", emoji: "🔄", name: "counterclockwise arrows button", key: ":counterclockwise_arrows_button:", group: "Symbols", },
  { unicode: "\u{1F519}", emoji: "🔙", name: "BACK arrow", key: ":BACK_arrow:", group: "Symbols", },
  { unicode: "\u{1F51A}", emoji: "🔚", name: "END arrow", key: ":END_arrow:", group: "Symbols", },
  { unicode: "\u{1F51B}", emoji: "🔛", name: "ON! arrow", key: ":ON!_arrow:", group: "Symbols", },
  { unicode: "\u{1F51C}", emoji: "🔜", name: "SOON arrow", key: ":SOON_arrow:", group: "Symbols", },
  { unicode: "\u{1F51D}", emoji: "🔝", name: "TOP arrow", key: ":TOP_arrow:", group: "Symbols", },
  { unicode: "\u{1F6D0}", emoji: "🛐", name: "place of worship", key: ":place_of_worship:", group: "Symbols", },
  { unicode: "\u{269B}\u{FE0F}", emoji: "⚛️", name: "atom symbol", key: ":atom_symbol:", group: "Symbols", },
  { unicode: "\u{1F549}\u{FE0F}", emoji: "🕉️", name: "om", key: ":om:", group: "Symbols", },
  { unicode: "\u{2721}\u{FE0F}", emoji: "✡️", name: "star of David", key: ":star_of_David:", group: "Symbols", },
  { unicode: "\u{2638}\u{FE0F}", emoji: "☸️", name: "wheel of dharma", key: ":wheel_of_dharma:", group: "Symbols", },
  { unicode: "\u{262F}\u{FE0F}", emoji: "☯️", name: "yin yang", key: ":yin_yang:", group: "Symbols", },
  { unicode: "\u{271D}\u{FE0F}", emoji: "✝️", name: "latin cross", key: ":latin_cross:", group: "Symbols", },
  { unicode: "\u{2626}\u{FE0F}", emoji: "☦️", name: "orthodox cross", key: ":orthodox_cross:", group: "Symbols", },
  { unicode: "\u{262A}\u{FE0F}", emoji: "☪️", name: "star and crescent", key: ":star_and_crescent:", group: "Symbols", },
  { unicode: "\u{262E}\u{FE0F}", emoji: "☮️", name: "peace symbol", key: ":peace_symbol:", group: "Symbols", },
  { unicode: "\u{1F54E}", emoji: "🕎", name: "menorah", key: ":menorah:", group: "Symbols", },
  { unicode: "\u{1F52F}", emoji: "🔯", name: "dotted six-pointed star", key: ":dotted_six_pointed_star:", group: "Symbols", },
  { unicode: "\u{2648}", emoji: "♈", name: "Aries", key: ":Aries:", group: "Symbols", },
  { unicode: "\u{2649}", emoji: "♉", name: "Taurus", key: ":Taurus:", group: "Symbols", },
  { unicode: "\u{264A}", emoji: "♊", name: "Gemini", key: ":Gemini:", group: "Symbols", },
  { unicode: "\u{264B}", emoji: "♋", name: "Cancer", key: ":Cancer:", group: "Symbols", },
  { unicode: "\u{264C}", emoji: "♌", name: "Leo", key: ":Leo:", group: "Symbols", },
  { unicode: "\u{264D}", emoji: "♍", name: "Virgo", key: ":Virgo:", group: "Symbols", },
  { unicode: "\u{264E}", emoji: "♎", name: "Libra", key: ":Libra:", group: "Symbols", },
  { unicode: "\u{264F}", emoji: "♏", name: "Scorpio", key: ":Scorpio:", group: "Symbols", },
  { unicode: "\u{2650}", emoji: "♐", name: "Sagittarius", key: ":Sagittarius:", group: "Symbols", },
  { unicode: "\u{2651}", emoji: "♑", name: "Capricorn", key: ":Capricorn:", group: "Symbols", },
  { unicode: "\u{2652}", emoji: "♒", name: "Aquarius", key: ":Aquarius:", group: "Symbols", },
  { unicode: "\u{2653}", emoji: "♓", name: "Pisces", key: ":Pisces:", group: "Symbols", },
  { unicode: "\u{26CE}", emoji: "⛎", name: "Ophiuchus", key: ":Ophiuchus:", group: "Symbols", },
  { unicode: "\u{1F500}", emoji: "🔀", name: "shuffle tracks button", key: ":shuffle_tracks_button:", group: "Symbols", },
  { unicode: "\u{1F501}", emoji: "🔁", name: "repeat button", key: ":repeat_button:", group: "Symbols", },
  { unicode: "\u{1F502}", emoji: "🔂", name: "repeat single button", key: ":repeat_single_button:", group: "Symbols", },
  { unicode: "\u{25B6}\u{FE0F}", emoji: "▶️", name: "play button", key: ":play_button:", group: "Symbols", },
  { unicode: "\u{23E9}", emoji: "⏩", name: "fast-forward button", key: ":fast_forward_button:", group: "Symbols", },
  { unicode: "\u{23ED}", emoji: "⏭", name: "next track button", key: ":next_track_button:", group: "Symbols", },
  { unicode: "\u{23EF}", emoji: "⏯", name: "play or pause button", key: ":play_or_pause_button:", group: "Symbols", },
  { unicode: "\u{25C0}\u{FE0F}", emoji: "◀️", name: "reverse button", key: ":reverse_button:", group: "Symbols", },
  { unicode: "\u{23EA}", emoji: "⏪", name: "fast reverse button", key: ":fast_reverse_button:", group: "Symbols", },
  { unicode: "\u{23EE}", emoji: "⏮", name: "last track button", key: ":last_track_button:", group: "Symbols", },
  { unicode: "\u{1F53C}", emoji: "🔼", name: "upwards button", key: ":upwards_button:", group: "Symbols", },
  { unicode: "\u{23EB}", emoji: "⏫", name: "fast up button", key: ":fast_up_button:", group: "Symbols", },
  { unicode: "\u{1F53D}", emoji: "🔽", name: "downwards button", key: ":downwards_button:", group: "Symbols", },
  { unicode: "\u{23EC}", emoji: "⏬", name: "fast down button", key: ":fast_down_button:", group: "Symbols", },
  { unicode: "\u{23F8}", emoji: "⏸", name: "pause button", key: ":pause_button:", group: "Symbols", },
  { unicode: "\u{23F9}", emoji: "⏹", name: "stop button", key: ":stop_button:", group: "Symbols", },
  { unicode: "\u{23FA}", emoji: "⏺", name: "record button", key: ":record_button:", group: "Symbols", },
  { unicode: "\u{23CF}\u{FE0F}", emoji: "⏏️", name: "eject button", key: ":eject_button:", group: "Symbols", },
  { unicode: "\u{1F3A6}", emoji: "🎦", name: "cinema", key: ":cinema:", group: "Symbols", },
  { unicode: "\u{1F505}", emoji: "🔅", name: "dim button", key: ":dim_button:", group: "Symbols", },
  { unicode: "\u{1F506}", emoji: "🔆", name: "bright button", key: ":bright_button:", group: "Symbols", },
  { unicode: "\u{1F4F6}", emoji: "📶", name: "antenna bars", key: ":antenna_bars:", group: "Symbols", },
  { unicode: "\u{1F4F3}", emoji: "📳", name: "vibration mode", key: ":vibration_mode:", group: "Symbols", },
  { unicode: "\u{1F4F4}", emoji: "📴", name: "mobile phone off", key: ":mobile_phone_off:", group: "Symbols", },
  { unicode: "\u{2640}\u{FE0F}", emoji: "♀️", name: "female sign", key: ":female_sign:", group: "Symbols", },
  { unicode: "\u{2642}\u{FE0F}", emoji: "♂️", name: "male sign", key: ":male_sign:", group: "Symbols", },
  { unicode: "\u{2695}\u{FE0F}", emoji: "⚕️", name: "medical symbol", key: ":medical_symbol:", group: "Symbols", },
  { unicode: "\u{267E}\u{FE0F}", emoji: "♾️", name: "infinity", key: ":infinity:", group: "Symbols", },
  { unicode: "\u{267B}\u{FE0F}", emoji: "♻️", name: "recycling symbol", key: ":recycling_symbol:", group: "Symbols", },
  { unicode: "\u{269C}\u{FE0F}", emoji: "⚜️", name: "fleur-de-lis", key: ":fleur_de-lis:", group: "Symbols", },
  { unicode: "\u{1F531}", emoji: "🔱", name: "trident emblem", key: ":trident_emblem:", group: "Symbols", },
  { unicode: "\u{1F4DB}", emoji: "📛", name: "name badge", key: ":name_badge:", group: "Symbols", },
  { unicode: "\u{1F530}", emoji: "🔰", name: "Japanese symbol for beginner", key: ":Japanese_symbol_for_beginner:", group: "Symbols", },
  { unicode: "\u{2B55}", emoji: "⭕", name: "hollow red circle", key: ":hollow_red_circle:", group: "Symbols", },
  { unicode: "\u{2705}", emoji: "✅", name: "check mark button", key: ":check_mark_button:", group: "Symbols", },
  { unicode: "\u{2611}\u{FE0F}", emoji: "☑️", name: "check box with check", key: ":check_box_with_check:", group: "Symbols", },
  { unicode: "\u{2714}\u{FE0F}", emoji: "✔️", name: "check mark", key: ":check_mark:", group: "Symbols", },
  { unicode: "\u{2716}\u{FE0F}", emoji: "✖️", name: "multiplication sign", key: ":multiplication_sign:", group: "Symbols", },
  { unicode: "\u{274C}", emoji: "❌", name: "cross mark", key: ":cross_mark:", group: "Symbols", },
  { unicode: "\u{274E}", emoji: "❎", name: "cross mark button", key: ":cross_mark_button:", group: "Symbols", },
  { unicode: "\u{2795}", emoji: "➕", name: "plus sign", key: ":plus_sign:", group: "Symbols", },
  { unicode: "\u{2796}", emoji: "➖", name: "minus sign", key: ":minus_sign:", group: "Symbols", },
  { unicode: "\u{2797}", emoji: "➗", name: "division sign", key: ":division_sign:", group: "Symbols", },
  { unicode: "\u{27B0}", emoji: "➰", name: "curly loop", key: ":curly_loop:", group: "Symbols", },
  { unicode: "\u{27BF}", emoji: "➿", name: "double curly loop", key: ":double_curly_loop:", group: "Symbols", },
  { unicode: "\u{303D}\u{FE0F}", emoji: "〽️", name: "part alternation mark", key: ":part_alternation_mark:", group: "Symbols", },
  { unicode: "\u{2733}\u{FE0F}", emoji: "✳️", name: "eight-spoked asterisk", key: ":eight_spoked_asterisk:", group: "Symbols", },
  { unicode: "\u{2734}\u{FE0F}", emoji: "✴️", name: "eight-pointed star", key: ":eight_pointed_star:", group: "Symbols", },
  { unicode: "\u{2747}\u{FE0F}", emoji: "❇️", name: "sparkle", key: ":sparkle:", group: "Symbols", },
  { unicode: "\u{203C}\u{FE0F}", emoji: "‼️", name: "double exclamation mark", key: ":double_exclamation_mark:", group: "Symbols", },
  { unicode: "\u{2049}\u{FE0F}", emoji: "⁉️", name: "exclamation question mark", key: ":exclamation_question_mark:", group: "Symbols", },
  { unicode: "\u{2753}", emoji: "❓", name: "question mark", key: ":question_mark:", group: "Symbols", },
  { unicode: "\u{2755}", emoji: "❕", name: "white exclamation mark", key: ":white_exclamation_mark:", group: "Symbols", },
  { unicode: "\u{2757}", emoji: "❗", name: "exclamation mark", key: ":exclamation_mark:", group: "Symbols", },
  { unicode: "\u{3030}\u{FE0F}", emoji: "〰️", name: "wavy dash", key: ":wavy_dash:", group: "Symbols", },
  { unicode: "\u{00A9}\u{FE0F}", emoji: "©️", name: "copyright", key: ":copyright:", group: "Symbols", },
  { unicode: "\u{00AE}\u{FE0F}", emoji: "®️", name: "registered", key: ":registered:", group: "Symbols", },
  { unicode: "\u{2122}\u{FE0F}", emoji: "™️", name: "trade mark", key: ":trade_mark:", group: "Symbols", },
  { unicode: "\u{0023}\u{FE0F}\u{20E3}", emoji: "#️⃣", name: "keycap: #", key: ":keycap_#:", group: "Symbols", },
  { unicode: "\u{002A}\u{FE0F}\u{20E3}", emoji: "*️⃣", name: "keycap: *", key: ":keycap_*:", group: "Symbols", },
  { unicode: "\u{0030}\u{FE0F}\u{20E3}", emoji: "0️⃣", name: "keycap: 0", key: ":keycap_0:", group: "Symbols", },
  { unicode: "\u{0031}\u{FE0F}\u{20E3}", emoji: "1️⃣", name: "keycap: 1", key: ":keycap_1:", group: "Symbols", },
  { unicode: "\u{0032}\u{FE0F}\u{20E3}", emoji: "2️⃣", name: "keycap: 2", key: ":keycap_2:", group: "Symbols", },
  { unicode: "\u{0033}\u{FE0F}\u{20E3}", emoji: "3️⃣", name: "keycap: 3", key: ":keycap_3:", group: "Symbols", },
  { unicode: "\u{0034}\u{FE0F}\u{20E3}", emoji: "4️⃣", name: "keycap: 4", key: ":keycap_4:", group: "Symbols", },
  { unicode: "\u{0035}\u{FE0F}\u{20E3}", emoji: "5️⃣", name: "keycap: 5", key: ":keycap_5:", group: "Symbols", },
  { unicode: "\u{0036}\u{FE0F}\u{20E3}", emoji: "6️⃣", name: "keycap: 6", key: ":keycap_6:", group: "Symbols", },
  { unicode: "\u{0037}\u{FE0F}\u{20E3}", emoji: "7️⃣", name: "keycap: 7", key: ":keycap_7:", group: "Symbols", },
  { unicode: "\u{0038}\u{FE0F}\u{20E3}", emoji: "8️⃣", name: "keycap: 8", key: ":keycap_8:", group: "Symbols", },
  { unicode: "\u{0039}\u{FE0F}\u{20E3}", emoji: "9️⃣", name: "keycap: 9", key: ":keycap_9:", group: "Symbols", },
  { unicode: "\u{1F51F}", emoji: "🔟", name: "keycap: 10", key: ":keycap_10:", group: "Symbols", },
  { unicode: "\u{1F520}", emoji: "🔠", name: "input latin uppercase", key: ":input_latin_uppercase:", group: "Symbols", },
  { unicode: "\u{1F521}", emoji: "🔡", name: "input latin lowercase", key: ":input_latin_lowercase:", group: "Symbols", },
  { unicode: "\u{1F522}", emoji: "🔢", name: "input numbers", key: ":input_numbers:", group: "Symbols", },
  { unicode: "\u{1F523}", emoji: "🔣", name: "input symbols", key: ":input_symbols:", group: "Symbols", },
  { unicode: "\u{1F524}", emoji: "🔤", name: "input latin letters", key: ":input_latin_letters:", group: "Symbols", },
  { unicode: "\u{1F170}\u{FE0F}", emoji: "🅰️", name: "A button (blood type)", key: ":A_button_(blood_type):", group: "Symbols", },
  { unicode: "\u{1F18E}", emoji: "🆎", name: "AB button (blood type)", key: ":AB_button_(blood_type):", group: "Symbols", },
  { unicode: "\u{1F171}\u{FE0F}", emoji: "🅱️", name: "B button (blood type)", key: ":B_button_(blood_type):", group: "Symbols", },
  { unicode: "\u{1F191}", emoji: "🆑", name: "CL button", key: ":CL_button:", group: "Symbols", },
  { unicode: "\u{1F192}", emoji: "🆒", name: "COOL button", key: ":COOL_button:", group: "Symbols", },
  { unicode: "\u{1F193}", emoji: "🆓", name: "FREE button", key: ":FREE_button:", group: "Symbols", },
  { unicode: "\u{2139}\u{FE0F}", emoji: "ℹ️", name: "information", key: ":information:", group: "Symbols", },
  { unicode: "\u{1F194}", emoji: "🆔", name: "ID button", key: ":ID_button:", group: "Symbols", },
  { unicode: "\u{24C2}\u{FE0F}", emoji: "Ⓜ️", name: "circled M", key: ":circled_M:", group: "Symbols", },
  { unicode: "\u{1F195}", emoji: "🆕", name: "NEW button", key: ":NEW_button:", group: "Symbols", },
  { unicode: "\u{1F196}", emoji: "🆖", name: "NG button", key: ":NG_button:", group: "Symbols", },
  { unicode: "\u{1F17E}\u{FE0F}", emoji: "🅾️", name: "O button (blood type)", key: ":O_button_(blood_type):", group: "Symbols", },
  { unicode: "\u{1F197}", emoji: "🆗", name: "OK button", key: ":OK_button:", group: "Symbols", },
  { unicode: "\u{1F17F}\u{FE0F}", emoji: "🅿️", name: "P button", key: ":P_button:", group: "Symbols", },
  { unicode: "\u{1F198}", emoji: "🆘", name: "SOS button", key: ":SOS_button:", group: "Symbols", },
  { unicode: "\u{1F199}", emoji: "🆙", name: "UP! button", key: ":UP!_button:", group: "Symbols", },
  { unicode: "\u{1F19A}", emoji: "🆚", name: "VS button", key: ":VS_button:", group: "Symbols", },
  { unicode: "\u{1F201}", emoji: "🈁", name: "Japanese here button", key: ":Japanese_here_button:", group: "Symbols", },
  { unicode: "\u{1F202}\u{FE0F}", emoji: "🈂️", name: "Japanese service charge button", key: ":Japanese_service_charge_button:", group: "Symbols", },
  { unicode: "\u{1F237}\u{FE0F}", emoji: "🈷️", name: "Japanese monthly amount button", key: ":Japanese_monthly_amount_button:", group: "Symbols", },
  { unicode: "\u{1F236}", emoji: "🈶", name: "Japanese not free of charge button", key: ":Japanese_not_free_of_charge_button:", group: "Symbols", },
  { unicode: "\u{1F22F}", emoji: "🈯", name: "Japanese reserved button", key: ":Japanese_reserved_button:", group: "Symbols", },
  { unicode: "\u{1F250}", emoji: "🉐", name: "Japanese bargain button", key: ":Japanese_bargain_button:", group: "Symbols", },
  { unicode: "\u{1F239}", emoji: "🈹", name: "Japanese discount button", key: ":Japanese_discount_button:", group: "Symbols", },
  { unicode: "\u{1F21A}", emoji: "🈚", name: "Japanese free of charge button", key: ":Japanese_free_of_charge_button:", group: "Symbols", },
  { unicode: "\u{1F232}", emoji: "🈲", name: "Japanese prohibited button", key: ":Japanese_prohibited_button:", group: "Symbols", },
  { unicode: "\u{1F251}", emoji: "🉑", name: "Japanese acceptable button", key: ":Japanese_acceptable_button:", group: "Symbols", },
  { unicode: "\u{1F238}", emoji: "🈸", name: "Japanese application button", key: ":Japanese_application_button:", group: "Symbols", },
  { unicode: "\u{1F234}", emoji: "🈴", name: "Japanese passing grade button", key: ":Japanese_passing_grade_button:", group: "Symbols", },
  { unicode: "\u{1F233}", emoji: "🈳", name: "Japanese vacancy button", key: ":Japanese_vacancy_button:", group: "Symbols", },
  { unicode: "\u{3297}\u{FE0F}", emoji: "㊗️", name: "Japanese congratulations button", key: ":Japanese_congratulations_button:", group: "Symbols", },
  { unicode: "\u{3299}\u{FE0F}", emoji: "㊙️", name: "Japanese secret button", key: ":Japanese_secret_button:", group: "Symbols", },
  { unicode: "\u{1F23A}", emoji: "🈺", name: "Japanese open for business button", key: ":Japanese_open_for_business_button:", group: "Symbols", },
  { unicode: "\u{1F235}", emoji: "🈵", name: "Japanese no vacancy button", key: ":Japanese_no_vacancy_button:", group: "Symbols", },
  { unicode: "\u{1F534}", emoji: "🔴", name: "red circle", key: ":red_circle:", group: "Symbols", },
  // { unicode: "\u{1F7E0}", emoji: "🟠", name: "orange circle", key: ":orange_circle:", group: "Symbols", },
  // { unicode: "\u{1F7E1}", emoji: "🟡", name: "yellow circle", key: ":yellow_circle:", group: "Symbols", },
  // { unicode: "\u{1F7E2}", emoji: "🟢", name: "green circle", key: ":green_circle:", group: "Symbols", },
  { unicode: "\u{1F535}", emoji: "🔵", name: "blue circle", key: ":blue_circle:", group: "Symbols", },
  // { unicode: "\u{1F7E3}", emoji: "🟣", name: "purple circle", key: ":purple_circle:", group: "Symbols", },
  // { unicode: "\u{1F7E4}", emoji: "🟤", name: "brown circle", key: ":brown_circle:", group: "Symbols", },
  { unicode: "\u{26AB}", emoji: "⚫", name: "black circle", key: ":black_circle:", group: "Symbols", },
  { unicode: "\u{26AA}", emoji: "⚪", name: "white circle", key: ":white_circle:", group: "Symbols", },
  // { unicode: "\u{1F7E5}", emoji: "🟥", name: "red square", key: ":red_square:", group: "Symbols", },
  // { unicode: "\u{1F7E7}", emoji: "🟧", name: "orange square", key: ":orange_square:", group: "Symbols", },
  // { unicode: "\u{1F7E8}", emoji: "🟨", name: "yellow square", key: ":yellow_square:", group: "Symbols", },
  // { unicode: "\u{1F7E9}", emoji: "🟩", name: "green square", key: ":green_square:", group: "Symbols", },
  // { unicode: "\u{1F7E6}", emoji: "🟦", name: "blue square", key: ":blue_square:", group: "Symbols", },
  // { unicode: "\u{1F7EA}", emoji: "🟪", name: "purple square", key: ":purple_square:", group: "Symbols", },
  // { unicode: "\u{1F7EB}", emoji: "🟫", name: "brown square", key: ":brown_square:", group: "Symbols", },
  { unicode: "\u{2B1B}", emoji: "⬛", name: "black large square", key: ":black_large_square:", group: "Symbols", },
  { unicode: "\u{2B1C}", emoji: "⬜", name: "white large square", key: ":white_large_square:", group: "Symbols", },
  { unicode: "\u{25FC}\u{FE0F}", emoji: "◼️", name: "black medium square", key: ":black_medium_square:", group: "Symbols", },
  { unicode: "\u{25FB}\u{FE0F}", emoji: "◻️", name: "white medium square", key: ":white_medium_square:", group: "Symbols", },
  { unicode: "\u{25FE}", emoji: "◾", name: "black medium-small square", key: ":black_medium_small_square:", group: "Symbols", },
  { unicode: "\u{25FD}", emoji: "◽", name: "white medium-small square", key: ":white_medium_small_square:", group: "Symbols", },
  { unicode: "\u{25AA}\u{FE0F}", emoji: "▪️", name: "black small square", key: ":black_small_square:", group: "Symbols", },
  { unicode: "\u{25AB}\u{FE0F}", emoji: "▫️", name: "white small square", key: ":white_small_square:", group: "Symbols", },
  { unicode: "\u{1F536}", emoji: "🔶", name: "large orange diamond", key: ":large_orange_diamond:", group: "Symbols", },
  { unicode: "\u{1F537}", emoji: "🔷", name: "large blue diamond", key: ":large_blue_diamond:", group: "Symbols", },
  { unicode: "\u{1F538}", emoji: "🔸", name: "small orange diamond", key: ":small_orange_diamond:", group: "Symbols", },
  { unicode: "\u{1F539}", emoji: "🔹", name: "small blue diamond", key: ":small_blue_diamond:", group: "Symbols", },
  { unicode: "\u{1F53A}", emoji: "🔺", name: "red triangle pointed up", key: ":red_triangle_pointed_up:", group: "Symbols", },
  { unicode: "\u{1F53B}", emoji: "🔻", name: "red triangle pointed down", key: ":red_triangle_pointed_down:", group: "Symbols", },
  { unicode: "\u{1F4A0}", emoji: "💠", name: "diamond with a dot", key: ":diamond_with_a_dot:", group: "Symbols", },
  { unicode: "\u{1F518}", emoji: "🔘", name: "radio button", key: ":radio_button:", group: "Symbols", },
  { unicode: "\u{1F533}", emoji: "🔳", name: "white square button", key: ":white_square_button:", group: "Symbols", },
  { unicode: "\u{1F532}", emoji: "🔲", name: "black square button", key: ":black_square_button:", group: "Symbols", },
  { unicode: "\u{1F3C1}", emoji: "🏁", name: "chequered flag", key: ":chequered_flag:", group: "Flags", },
  { unicode: "\u{1F6A9}", emoji: "🚩", name: "triangular flag", key: ":triangular_flag:", group: "Flags", },
  { unicode: "\u{1F38C}", emoji: "🎌", name: "crossed flags", key: ":crossed_flags:", group: "Flags", },
  { unicode: "\u{1F3F4}", emoji: "🏴", name: "black flag", key: ":black_flag:", group: "Flags", },
  { unicode: "\u{1F3F3}\u{FE0F}", emoji: "🏳️", name: "white flag", key: ":white_flag:", group: "Flags", },
  { unicode: "\u{1F3F3}\u{200D}\u{1F308}", emoji: "🏳‍🌈", name: "rainbow flag", key: ":rainbow_flag:", group: "Flags", },
  { unicode: "\u{1F3F4}\u{200D}\u{2620}\u{FE0F}", emoji: "🏴‍☠️", name: "pirate flag", key: ":pirate_flag:", group: "Flags", },
  { unicode: "\u{1F1E6}\u{1F1E8}", emoji: "🇦🇨", name: "flag: Ascension Island", key: ":flag_Ascension_Island:", group: "Flags", },
  { unicode: "\u{1F1E6}\u{1F1E9}", emoji: "🇦🇩", name: "flag: Andorra", key: ":flag_Andorra:", group: "Flags", },
  { unicode: "\u{1F1E6}\u{1F1EA}", emoji: "🇦🇪", name: "flag: United Arab Emirates", key: ":flag_United_Arab_Emirates:", group: "Flags", },
  { unicode: "\u{1F1E6}\u{1F1EB}", emoji: "🇦🇫", name: "flag: Afghanistan", key: ":flag_Afghanistan:", group: "Flags", },
  { unicode: "\u{1F1E6}\u{1F1EC}", emoji: "🇦🇬", name: "flag: Antigua & Barbuda", key: ":flag_Antigua_&_Barbuda:", group: "Flags", },
  { unicode: "\u{1F1E6}\u{1F1EE}", emoji: "🇦🇮", name: "flag: Anguilla", key: ":flag_Anguilla:", group: "Flags", },
  { unicode: "\u{1F1E6}\u{1F1F1}", emoji: "🇦🇱", name: "flag: Albania", key: ":flag_Albania:", group: "Flags", },
  { unicode: "\u{1F1E6}\u{1F1F2}", emoji: "🇦🇲", name: "flag: Armenia", key: ":flag_Armenia:", group: "Flags", },
  { unicode: "\u{1F1E6}\u{1F1F4}", emoji: "🇦🇴", name: "flag: Angola", key: ":flag_Angola:", group: "Flags", },
  { unicode: "\u{1F1E6}\u{1F1F6}", emoji: "🇦🇶", name: "flag: Antarctica", key: ":flag_Antarctica:", group: "Flags", },
  { unicode: "\u{1F1E6}\u{1F1F7}", emoji: "🇦🇷", name: "flag: Argentina", key: ":flag_Argentina:", group: "Flags", },
  { unicode: "\u{1F1E6}\u{1F1F8}", emoji: "🇦🇸", name: "flag: American Samoa", key: ":flag_American_Samoa:", group: "Flags", },
  { unicode: "\u{1F1E6}\u{1F1F9}", emoji: "🇦🇹", name: "flag: Austria", key: ":flag_Austria:", group: "Flags", },
  { unicode: "\u{1F1E6}\u{1F1FA}", emoji: "🇦🇺", name: "flag: Australia", key: ":flag_Australia:", group: "Flags", },
  { unicode: "\u{1F1E6}\u{1F1FC}", emoji: "🇦🇼", name: "flag: Aruba", key: ":flag_Aruba:", group: "Flags", },
  { unicode: "\u{1F1E6}\u{1F1FD}", emoji: "🇦🇽", name: "flag: Åland Islands", key: ":flag_Åland_Islands:", group: "Flags", },
  { unicode: "\u{1F1E6}\u{1F1FF}", emoji: "🇦🇿", name: "flag: Azerbaijan", key: ":flag_Azerbaijan:", group: "Flags", },
  { unicode: "\u{1F1E7}\u{1F1E6}", emoji: "🇧🇦", name: "flag: Bosnia & Herzegovina", key: ":flag_Bosnia_&_Herzegovina:", group: "Flags", },
  { unicode: "\u{1F1E7}\u{1F1E7}", emoji: "🇧🇧", name: "flag: Barbados", key: ":flag_Barbados:", group: "Flags", },
  { unicode: "\u{1F1E7}\u{1F1E9}", emoji: "🇧🇩", name: "flag: Bangladesh", key: ":flag_Bangladesh:", group: "Flags", },
  { unicode: "\u{1F1E7}\u{1F1EA}", emoji: "🇧🇪", name: "flag: Belgium", key: ":flag_Belgium:", group: "Flags", },
  { unicode: "\u{1F1E7}\u{1F1EB}", emoji: "🇧🇫", name: "flag: Burkina Faso", key: ":flag_Burkina_Faso:", group: "Flags", },
  { unicode: "\u{1F1E7}\u{1F1EC}", emoji: "🇧🇬", name: "flag: Bulgaria", key: ":flag_Bulgaria:", group: "Flags", },
  { unicode: "\u{1F1E7}\u{1F1ED}", emoji: "🇧🇭", name: "flag: Bahrain", key: ":flag_Bahrain:", group: "Flags", },
  { unicode: "\u{1F1E7}\u{1F1EE}", emoji: "🇧🇮", name: "flag: Burundi", key: ":flag_Burundi:", group: "Flags", },
  { unicode: "\u{1F1E7}\u{1F1EF}", emoji: "🇧🇯", name: "flag: Benin", key: ":flag_Benin:", group: "Flags", },
  { unicode: "\u{1F1E7}\u{1F1F1}", emoji: "🇧🇱", name: "flag: St. Barthélemy", key: ":flag_St._Barthélemy:", group: "Flags", },
  { unicode: "\u{1F1E7}\u{1F1F2}", emoji: "🇧🇲", name: "flag: Bermuda", key: ":flag_Bermuda:", group: "Flags", },
  { unicode: "\u{1F1E7}\u{1F1F3}", emoji: "🇧🇳", name: "flag: Brunei", key: ":flag_Brunei:", group: "Flags", },
  { unicode: "\u{1F1E7}\u{1F1F4}", emoji: "🇧🇴", name: "flag: Bolivia", key: ":flag_Bolivia:", group: "Flags", },
  { unicode: "\u{1F1E7}\u{1F1F6}", emoji: "🇧🇶", name: "flag: Caribbean Netherlands", key: ":flag_Caribbean_Netherlands:", group: "Flags", },
  { unicode: "\u{1F1E7}\u{1F1F7}", emoji: "🇧🇷", name: "flag: Brazil", key: ":flag_Brazil:", group: "Flags", },
  { unicode: "\u{1F1E7}\u{1F1F8}", emoji: "🇧🇸", name: "flag: Bahamas", key: ":flag_Bahamas:", group: "Flags", },
  { unicode: "\u{1F1E7}\u{1F1F9}", emoji: "🇧🇹", name: "flag: Bhutan", key: ":flag_Bhutan:", group: "Flags", },
  { unicode: "\u{1F1E7}\u{1F1FB}", emoji: "🇧🇻", name: "flag: Bouvet Island", key: ":flag_Bouvet_Island:", group: "Flags", },
  { unicode: "\u{1F1E7}\u{1F1FC}", emoji: "🇧🇼", name: "flag: Botswana", key: ":flag_Botswana:", group: "Flags", },
  { unicode: "\u{1F1E7}\u{1F1FE}", emoji: "🇧🇾", name: "flag: Belarus", key: ":flag_Belarus:", group: "Flags", },
  { unicode: "\u{1F1E7}\u{1F1FF}", emoji: "🇧🇿", name: "flag: Belize", key: ":flag_Belize:", group: "Flags", },
  { unicode: "\u{1F1E8}\u{1F1E6}", emoji: "🇨🇦", name: "flag: Canada", key: ":flag_Canada:", group: "Flags", },
  { unicode: "\u{1F1E8}\u{1F1E8}", emoji: "🇨🇨", name: "flag: Cocos (Keeling) Islands", key: ":flag_Cocos_(Keeling)_Islands:", group: "Flags", },
  { unicode: "\u{1F1E8}\u{1F1E9}", emoji: "🇨🇩", name: "flag: Congo - Kinshasa", key: ":flag_Congo___Kinshasa:", group: "Flags", },
  { unicode: "\u{1F1E8}\u{1F1EB}", emoji: "🇨🇫", name: "flag: Central African Republic", key: ":flag_Central_African_Republic:", group: "Flags", },
  { unicode: "\u{1F1E8}\u{1F1EC}", emoji: "🇨🇬", name: "flag: Congo - Brazzaville", key: ":flag_Congo___Brazzaville:", group: "Flags", },
  { unicode: "\u{1F1E8}\u{1F1ED}", emoji: "🇨🇭", name: "flag: Switzerland", key: ":flag_Switzerland:", group: "Flags", },
  { unicode: "\u{1F1E8}\u{1F1EE}", emoji: "🇨🇮", name: "flag: Côte d’Ivoire", key: ":flag_Côte_dIvoire:", group: "Flags", },
  { unicode: "\u{1F1E8}\u{1F1F0}", emoji: "🇨🇰", name: "flag: Cook Islands", key: ":flag_Cook_Islands:", group: "Flags", },
  { unicode: "\u{1F1E8}\u{1F1F1}", emoji: "🇨🇱", name: "flag: Chile", key: ":flag_Chile:", group: "Flags", },
  { unicode: "\u{1F1E8}\u{1F1F2}", emoji: "🇨🇲", name: "flag: Cameroon", key: ":flag_Cameroon:", group: "Flags", },
  { unicode: "\u{1F1E8}\u{1F1F3}", emoji: "🇨🇳", name: "flag: China", key: ":flag_China:", group: "Flags", },
  { unicode: "\u{1F1E8}\u{1F1F4}", emoji: "🇨🇴", name: "flag: Colombia", key: ":flag_Colombia:", group: "Flags", },
  { unicode: "\u{1F1E8}\u{1F1F5}", emoji: "🇨🇵", name: "flag: Clipperton Island", key: ":flag_Clipperton_Island:", group: "Flags", },
  { unicode: "\u{1F1E8}\u{1F1F7}", emoji: "🇨🇷", name: "flag: Costa Rica", key: ":flag_Costa_Rica:", group: "Flags", },
  { unicode: "\u{1F1E8}\u{1F1FA}", emoji: "🇨🇺", name: "flag: Cuba", key: ":flag_Cuba:", group: "Flags", },
  { unicode: "\u{1F1E8}\u{1F1FB}", emoji: "🇨🇻", name: "flag: Cape Verde", key: ":flag_Cape_Verde:", group: "Flags", },
  { unicode: "\u{1F1E8}\u{1F1FC}", emoji: "🇨🇼", name: "flag: Curaçao", key: ":flag_Curaçao:", group: "Flags", },
  { unicode: "\u{1F1E8}\u{1F1FD}", emoji: "🇨🇽", name: "flag: Christmas Island", key: ":flag_Christmas_Island:", group: "Flags", },
  { unicode: "\u{1F1E8}\u{1F1FE}", emoji: "🇨🇾", name: "flag: Cyprus", key: ":flag_Cyprus:", group: "Flags", },
  { unicode: "\u{1F1E8}\u{1F1FF}", emoji: "🇨🇿", name: "flag: Czechia", key: ":flag_Czechia:", group: "Flags", },
  { unicode: "\u{1F1E9}\u{1F1EA}", emoji: "🇩🇪", name: "flag: Germany", key: ":flag_Germany:", group: "Flags", },
  { unicode: "\u{1F1E9}\u{1F1EC}", emoji: "🇩🇬", name: "flag: Diego Garcia", key: ":flag_Diego_Garcia:", group: "Flags", },
  { unicode: "\u{1F1E9}\u{1F1EF}", emoji: "🇩🇯", name: "flag: Djibouti", key: ":flag_Djibouti:", group: "Flags", },
  { unicode: "\u{1F1E9}\u{1F1F0}", emoji: "🇩🇰", name: "flag: Denmark", key: ":flag_Denmark:", group: "Flags", },
  { unicode: "\u{1F1E9}\u{1F1F2}", emoji: "🇩🇲", name: "flag: Dominica", key: ":flag_Dominica:", group: "Flags", },
  { unicode: "\u{1F1E9}\u{1F1F4}", emoji: "🇩🇴", name: "flag: Dominican Republic", key: ":flag_Dominican_Republic:", group: "Flags", },
  { unicode: "\u{1F1E9}\u{1F1FF}", emoji: "🇩🇿", name: "flag: Algeria", key: ":flag_Algeria:", group: "Flags", },
  { unicode: "\u{1F1EA}\u{1F1E6}", emoji: "🇪🇦", name: "flag: Ceuta & Melilla", key: ":flag_Ceuta_&_Melilla:", group: "Flags", },
  { unicode: "\u{1F1EA}\u{1F1E8}", emoji: "🇪🇨", name: "flag: Ecuador", key: ":flag_Ecuador:", group: "Flags", },
  { unicode: "\u{1F1EA}\u{1F1EA}", emoji: "🇪🇪", name: "flag: Estonia", key: ":flag_Estonia:", group: "Flags", },
  { unicode: "\u{1F1EA}\u{1F1EC}", emoji: "🇪🇬", name: "flag: Egypt", key: ":flag_Egypt:", group: "Flags", },
  { unicode: "\u{1F1EA}\u{1F1ED}", emoji: "🇪🇭", name: "flag: Western Sahara", key: ":flag_Western_Sahara:", group: "Flags", },
  { unicode: "\u{1F1EA}\u{1F1F7}", emoji: "🇪🇷", name: "flag: Eritrea", key: ":flag_Eritrea:", group: "Flags", },
  { unicode: "\u{1F1EA}\u{1F1F8}", emoji: "🇪🇸", name: "flag: Spain", key: ":flag_Spain:", group: "Flags", },
  { unicode: "\u{1F1EA}\u{1F1F9}", emoji: "🇪🇹", name: "flag: Ethiopia", key: ":flag_Ethiopia:", group: "Flags", },
  { unicode: "\u{1F1EA}\u{1F1FA}", emoji: "🇪🇺", name: "flag: European Union", key: ":flag_European_Union:", group: "Flags", },
  { unicode: "\u{1F1EB}\u{1F1EE}", emoji: "🇫🇮", name: "flag: Finland", key: ":flag_Finland:", group: "Flags", },
  { unicode: "\u{1F1EB}\u{1F1EF}", emoji: "🇫🇯", name: "flag: Fiji", key: ":flag_Fiji:", group: "Flags", },
  { unicode: "\u{1F1EB}\u{1F1F0}", emoji: "🇫🇰", name: "flag: Falkland Islands", key: ":flag_Falkland_Islands:", group: "Flags", },
  { unicode: "\u{1F1EB}\u{1F1F2}", emoji: "🇫🇲", name: "flag: Micronesia", key: ":flag_Micronesia:", group: "Flags", },
  { unicode: "\u{1F1EB}\u{1F1F4}", emoji: "🇫🇴", name: "flag: Faroe Islands", key: ":flag_Faroe_Islands:", group: "Flags", },
  { unicode: "\u{1F1EB}\u{1F1F7}", emoji: "🇫🇷", name: "flag: France", key: ":flag_France:", group: "Flags", },
  { unicode: "\u{1F1EC}\u{1F1E6}", emoji: "🇬🇦", name: "flag: Gabon", key: ":flag_Gabon:", group: "Flags", },
  { unicode: "\u{1F1EC}\u{1F1E7}", emoji: "🇬🇧", name: "flag: United Kingdom", key: ":flag_United_Kingdom:", group: "Flags", },
  { unicode: "\u{1F1EC}\u{1F1E9}", emoji: "🇬🇩", name: "flag: Grenada", key: ":flag_Grenada:", group: "Flags", },
  { unicode: "\u{1F1EC}\u{1F1EA}", emoji: "🇬🇪", name: "flag: Georgia", key: ":flag_Georgia:", group: "Flags", },
  { unicode: "\u{1F1EC}\u{1F1EB}", emoji: "🇬🇫", name: "flag: French Guiana", key: ":flag_French_Guiana:", group: "Flags", },
  { unicode: "\u{1F1EC}\u{1F1EC}", emoji: "🇬🇬", name: "flag: Guernsey", key: ":flag_Guernsey:", group: "Flags", },
  { unicode: "\u{1F1EC}\u{1F1ED}", emoji: "🇬🇭", name: "flag: Ghana", key: ":flag_Ghana:", group: "Flags", },
  { unicode: "\u{1F1EC}\u{1F1EE}", emoji: "🇬🇮", name: "flag: Gibraltar", key: ":flag_Gibraltar:", group: "Flags", },
  { unicode: "\u{1F1EC}\u{1F1F1}", emoji: "🇬🇱", name: "flag: Greenland", key: ":flag_Greenland:", group: "Flags", },
  { unicode: "\u{1F1EC}\u{1F1F2}", emoji: "🇬🇲", name: "flag: Gambia", key: ":flag_Gambia:", group: "Flags", },
  { unicode: "\u{1F1EC}\u{1F1F3}", emoji: "🇬🇳", name: "flag: Guinea", key: ":flag_Guinea:", group: "Flags", },
  { unicode: "\u{1F1EC}\u{1F1F5}", emoji: "🇬🇵", name: "flag: Guadeloupe", key: ":flag_Guadeloupe:", group: "Flags", },
  { unicode: "\u{1F1EC}\u{1F1F6}", emoji: "🇬🇶", name: "flag: Equatorial Guinea", key: ":flag_Equatorial_Guinea:", group: "Flags", },
  { unicode: "\u{1F1EC}\u{1F1F7}", emoji: "🇬🇷", name: "flag: Greece", key: ":flag_Greece:", group: "Flags", },
  { unicode: "\u{1F1EC}\u{1F1F8}", emoji: "🇬🇸", name: "flag: South Georgia & South Sandwich Islands", key: ":flag_South_Georgia_&_South_Sandwich_Islands:", group: "Flags", },
  { unicode: "\u{1F1EC}\u{1F1F9}", emoji: "🇬🇹", name: "flag: Guatemala", key: ":flag_Guatemala:", group: "Flags", },
  { unicode: "\u{1F1EC}\u{1F1FA}", emoji: "🇬🇺", name: "flag: Guam", key: ":flag_Guam:", group: "Flags", },
  { unicode: "\u{1F1EC}\u{1F1FC}", emoji: "🇬🇼", name: "flag: Guinea-Bissau", key: ":flag_Guinea_Bissau:", group: "Flags", },
  { unicode: "\u{1F1EC}\u{1F1FE}", emoji: "🇬🇾", name: "flag: Guyana", key: ":flag_Guyana:", group: "Flags", },
  { unicode: "\u{1F1ED}\u{1F1F0}", emoji: "🇭🇰", name: "flag: Hong Kong SAR China", key: ":flag_Hong_Kong_SAR_China:", group: "Flags", },
  { unicode: "\u{1F1ED}\u{1F1F2}", emoji: "🇭🇲", name: "flag: Heard & McDonald Islands", key: ":flag_Heard_&_McDonald_Islands:", group: "Flags", },
  { unicode: "\u{1F1ED}\u{1F1F3}", emoji: "🇭🇳", name: "flag: Honduras", key: ":flag_Honduras:", group: "Flags", },
  { unicode: "\u{1F1ED}\u{1F1F7}", emoji: "🇭🇷", name: "flag: Croatia", key: ":flag_Croatia:", group: "Flags", },
  { unicode: "\u{1F1ED}\u{1F1F9}", emoji: "🇭🇹", name: "flag: Haiti", key: ":flag_Haiti:", group: "Flags", },
  { unicode: "\u{1F1ED}\u{1F1FA}", emoji: "🇭🇺", name: "flag: Hungary", key: ":flag_Hungary:", group: "Flags", },
  { unicode: "\u{1F1EE}\u{1F1E8}", emoji: "🇮🇨", name: "flag: Canary Islands", key: ":flag_Canary_Islands:", group: "Flags", },
  { unicode: "\u{1F1EE}\u{1F1E9}", emoji: "🇮🇩", name: "flag: Indonesia", key: ":flag_Indonesia:", group: "Flags", },
  { unicode: "\u{1F1EE}\u{1F1EA}", emoji: "🇮🇪", name: "flag: Ireland", key: ":flag_Ireland:", group: "Flags", },
  { unicode: "\u{1F1EE}\u{1F1F1}", emoji: "🇮🇱", name: "flag: Israel", key: ":flag_Israel:", group: "Flags", },
  { unicode: "\u{1F1EE}\u{1F1F2}", emoji: "🇮🇲", name: "flag: Isle of Man", key: ":flag_Isle_of_Man:", group: "Flags", },
  { unicode: "\u{1F1EE}\u{1F1F3}", emoji: "🇮🇳", name: "flag: India", key: ":flag_India:", group: "Flags", },
  { unicode: "\u{1F1EE}\u{1F1F4}", emoji: "🇮🇴", name: "flag: British Indian Ocean Territory", key: ":flag_British_Indian_Ocean_Territory:", group: "Flags", },
  { unicode: "\u{1F1EE}\u{1F1F6}", emoji: "🇮🇶", name: "flag: Iraq", key: ":flag_Iraq:", group: "Flags", },
  { unicode: "\u{1F1EE}\u{1F1F7}", emoji: "🇮🇷", name: "flag: Iran", key: ":flag_Iran:", group: "Flags", },
  { unicode: "\u{1F1EE}\u{1F1F8}", emoji: "🇮🇸", name: "flag: Iceland", key: ":flag_Iceland:", group: "Flags", },
  { unicode: "\u{1F1EE}\u{1F1F9}", emoji: "🇮🇹", name: "flag: Italy", key: ":flag_Italy:", group: "Flags", },
  { unicode: "\u{1F1EF}\u{1F1EA}", emoji: "🇯🇪", name: "flag: Jersey", key: ":flag_Jersey:", group: "Flags", },
  { unicode: "\u{1F1EF}\u{1F1F2}", emoji: "🇯🇲", name: "flag: Jamaica", key: ":flag_Jamaica:", group: "Flags", },
  { unicode: "\u{1F1EF}\u{1F1F4}", emoji: "🇯🇴", name: "flag: Jordan", key: ":flag_Jordan:", group: "Flags", },
  { unicode: "\u{1F1EF}\u{1F1F5}", emoji: "🇯🇵", name: "flag: Japan", key: ":flag_Japan:", group: "Flags", },
  { unicode: "\u{1F1F0}\u{1F1EA}", emoji: "🇰🇪", name: "flag: Kenya", key: ":flag_Kenya:", group: "Flags", },
  { unicode: "\u{1F1F0}\u{1F1EC}", emoji: "🇰🇬", name: "flag: Kyrgyzstan", key: ":flag_Kyrgyzstan:", group: "Flags", },
  { unicode: "\u{1F1F0}\u{1F1ED}", emoji: "🇰🇭", name: "flag: Cambodia", key: ":flag_Cambodia:", group: "Flags", },
  { unicode: "\u{1F1F0}\u{1F1EE}", emoji: "🇰🇮", name: "flag: Kiribati", key: ":flag_Kiribati:", group: "Flags", },
  { unicode: "\u{1F1F0}\u{1F1F2}", emoji: "🇰🇲", name: "flag: Comoros", key: ":flag_Comoros:", group: "Flags", },
  { unicode: "\u{1F1F0}\u{1F1F3}", emoji: "🇰🇳", name: "flag: St. Kitts & Nevis", key: ":flag_St._Kitts_&_Nevis:", group: "Flags", },
  { unicode: "\u{1F1F0}\u{1F1F5}", emoji: "🇰🇵", name: "flag: North Korea", key: ":flag_North_Korea:", group: "Flags", },
  { unicode: "\u{1F1F0}\u{1F1F7}", emoji: "🇰🇷", name: "flag: South Korea", key: ":flag_South_Korea:", group: "Flags", },
  { unicode: "\u{1F1F0}\u{1F1FC}", emoji: "🇰🇼", name: "flag: Kuwait", key: ":flag_Kuwait:", group: "Flags", },
  { unicode: "\u{1F1F0}\u{1F1FE}", emoji: "🇰🇾", name: "flag: Cayman Islands", key: ":flag_Cayman_Islands:", group: "Flags", },
  { unicode: "\u{1F1F0}\u{1F1FF}", emoji: "🇰🇿", name: "flag: Kazakhstan", key: ":flag_Kazakhstan:", group: "Flags", },
  { unicode: "\u{1F1F1}\u{1F1E6}", emoji: "🇱🇦", name: "flag: Laos", key: ":flag_Laos:", group: "Flags", },
  { unicode: "\u{1F1F1}\u{1F1E7}", emoji: "🇱🇧", name: "flag: Lebanon", key: ":flag_Lebanon:", group: "Flags", },
  { unicode: "\u{1F1F1}\u{1F1E8}", emoji: "🇱🇨", name: "flag: St. Lucia", key: ":flag_St._Lucia:", group: "Flags", },
  { unicode: "\u{1F1F1}\u{1F1EE}", emoji: "🇱🇮", name: "flag: Liechtenstein", key: ":flag_Liechtenstein:", group: "Flags", },
  { unicode: "\u{1F1F1}\u{1F1F0}", emoji: "🇱🇰", name: "flag: Sri Lanka", key: ":flag_Sri_Lanka:", group: "Flags", },
  { unicode: "\u{1F1F1}\u{1F1F7}", emoji: "🇱🇷", name: "flag: Liberia", key: ":flag_Liberia:", group: "Flags", },
  { unicode: "\u{1F1F1}\u{1F1F8}", emoji: "🇱🇸", name: "flag: Lesotho", key: ":flag_Lesotho:", group: "Flags", },
  { unicode: "\u{1F1F1}\u{1F1F9}", emoji: "🇱🇹", name: "flag: Lithuania", key: ":flag_Lithuania:", group: "Flags", },
  { unicode: "\u{1F1F1}\u{1F1FA}", emoji: "🇱🇺", name: "flag: Luxembourg", key: ":flag_Luxembourg:", group: "Flags", },
  { unicode: "\u{1F1F1}\u{1F1FB}", emoji: "🇱🇻", name: "flag: Latvia", key: ":flag_Latvia:", group: "Flags", },
  { unicode: "\u{1F1F1}\u{1F1FE}", emoji: "🇱🇾", name: "flag: Libya", key: ":flag_Libya:", group: "Flags", },
  { unicode: "\u{1F1F2}\u{1F1E6}", emoji: "🇲🇦", name: "flag: Morocco", key: ":flag_Morocco:", group: "Flags", },
  { unicode: "\u{1F1F2}\u{1F1E8}", emoji: "🇲🇨", name: "flag: Monaco", key: ":flag_Monaco:", group: "Flags", },
  { unicode: "\u{1F1F2}\u{1F1E9}", emoji: "🇲🇩", name: "flag: Moldova", key: ":flag_Moldova:", group: "Flags", },
  { unicode: "\u{1F1F2}\u{1F1EA}", emoji: "🇲🇪", name: "flag: Montenegro", key: ":flag_Montenegro:", group: "Flags", },
  { unicode: "\u{1F1F2}\u{1F1EB}", emoji: "🇲🇫", name: "flag: St. Martin", key: ":flag_St._Martin:", group: "Flags", },
  { unicode: "\u{1F1F2}\u{1F1EC}", emoji: "🇲🇬", name: "flag: Madagascar", key: ":flag_Madagascar:", group: "Flags", },
  { unicode: "\u{1F1F2}\u{1F1ED}", emoji: "🇲🇭", name: "flag: Marshall Islands", key: ":flag_Marshall_Islands:", group: "Flags", },
  { unicode: "\u{1F1F2}\u{1F1F0}", emoji: "🇲🇰", name: "flag: North Macedonia", key: ":flag_North_Macedonia:", group: "Flags", },
  { unicode: "\u{1F1F2}\u{1F1F1}", emoji: "🇲🇱", name: "flag: Mali", key: ":flag_Mali:", group: "Flags", },
  { unicode: "\u{1F1F2}\u{1F1F2}", emoji: "🇲🇲", name: "flag: Myanmar (Burma)", key: ":flag_Myanmar_(Burma):", group: "Flags", },
  { unicode: "\u{1F1F2}\u{1F1F3}", emoji: "🇲🇳", name: "flag: Mongolia", key: ":flag_Mongolia:", group: "Flags", },
  { unicode: "\u{1F1F2}\u{1F1F4}", emoji: "🇲🇴", name: "flag: Macao SAR China", key: ":flag_Macao_SAR_China:", group: "Flags", },
  { unicode: "\u{1F1F2}\u{1F1F5}", emoji: "🇲🇵", name: "flag: Northern Mariana Islands", key: ":flag_Northern_Mariana_Islands:", group: "Flags", },
  { unicode: "\u{1F1F2}\u{1F1F6}", emoji: "🇲🇶", name: "flag: Martinique", key: ":flag_Martinique:", group: "Flags", },
  { unicode: "\u{1F1F2}\u{1F1F7}", emoji: "🇲🇷", name: "flag: Mauritania", key: ":flag_Mauritania:", group: "Flags", },
  { unicode: "\u{1F1F2}\u{1F1F8}", emoji: "🇲🇸", name: "flag: Montserrat", key: ":flag_Montserrat:", group: "Flags", },
  { unicode: "\u{1F1F2}\u{1F1F9}", emoji: "🇲🇹", name: "flag: Malta", key: ":flag_Malta:", group: "Flags", },
  { unicode: "\u{1F1F2}\u{1F1FA}", emoji: "🇲🇺", name: "flag: Mauritius", key: ":flag_Mauritius:", group: "Flags", },
  { unicode: "\u{1F1F2}\u{1F1FB}", emoji: "🇲🇻", name: "flag: Maldives", key: ":flag_Maldives:", group: "Flags", },
  { unicode: "\u{1F1F2}\u{1F1FC}", emoji: "🇲🇼", name: "flag: Malawi", key: ":flag_Malawi:", group: "Flags", },
  { unicode: "\u{1F1F2}\u{1F1FD}", emoji: "🇲🇽", name: "flag: Mexico", key: ":flag_Mexico:", group: "Flags", },
  { unicode: "\u{1F1F2}\u{1F1FE}", emoji: "🇲🇾", name: "flag: Malaysia", key: ":flag_Malaysia:", group: "Flags", },
  { unicode: "\u{1F1F2}\u{1F1FF}", emoji: "🇲🇿", name: "flag: Mozambique", key: ":flag_Mozambique:", group: "Flags", },
  { unicode: "\u{1F1F3}\u{1F1E6}", emoji: "🇳🇦", name: "flag: Namibia", key: ":flag_Namibia:", group: "Flags", },
  { unicode: "\u{1F1F3}\u{1F1E8}", emoji: "🇳🇨", name: "flag: New Caledonia", key: ":flag_New_Caledonia:", group: "Flags", },
  { unicode: "\u{1F1F3}\u{1F1EA}", emoji: "🇳🇪", name: "flag: Niger", key: ":flag_Niger:", group: "Flags", },
  { unicode: "\u{1F1F3}\u{1F1EB}", emoji: "🇳🇫", name: "flag: Norfolk Island", key: ":flag_Norfolk_Island:", group: "Flags", },
  { unicode: "\u{1F1F3}\u{1F1EC}", emoji: "🇳🇬", name: "flag: Nigeria", key: ":flag_Nigeria:", group: "Flags", },
  { unicode: "\u{1F1F3}\u{1F1EE}", emoji: "🇳🇮", name: "flag: Nicaragua", key: ":flag_Nicaragua:", group: "Flags", },
  { unicode: "\u{1F1F3}\u{1F1F1}", emoji: "🇳🇱", name: "flag: Netherlands", key: ":flag_Netherlands:", group: "Flags", },
  { unicode: "\u{1F1F3}\u{1F1F4}", emoji: "🇳🇴", name: "flag: Norway", key: ":flag_Norway:", group: "Flags", },
  { unicode: "\u{1F1F3}\u{1F1F5}", emoji: "🇳🇵", name: "flag: Nepal", key: ":flag_Nepal:", group: "Flags", },
  { unicode: "\u{1F1F3}\u{1F1F7}", emoji: "🇳🇷", name: "flag: Nauru", key: ":flag_Nauru:", group: "Flags", },
  { unicode: "\u{1F1F3}\u{1F1FA}", emoji: "🇳🇺", name: "flag: Niue", key: ":flag_Niue:", group: "Flags", },
  { unicode: "\u{1F1F3}\u{1F1FF}", emoji: "🇳🇿", name: "flag: New Zealand", key: ":flag_New_Zealand:", group: "Flags", },
  { unicode: "\u{1F1F4}\u{1F1F2}", emoji: "🇴🇲", name: "flag: Oman", key: ":flag_Oman:", group: "Flags", },
  { unicode: "\u{1F1F5}\u{1F1E6}", emoji: "🇵🇦", name: "flag: Panama", key: ":flag_Panama:", group: "Flags", },
  { unicode: "\u{1F1F5}\u{1F1EA}", emoji: "🇵🇪", name: "flag: Peru", key: ":flag_Peru:", group: "Flags", },
  { unicode: "\u{1F1F5}\u{1F1EB}", emoji: "🇵🇫", name: "flag: French Polynesia", key: ":flag_French_Polynesia:", group: "Flags", },
  { unicode: "\u{1F1F5}\u{1F1EC}", emoji: "🇵🇬", name: "flag: Papua New Guinea", key: ":flag_Papua_New_Guinea:", group: "Flags", },
  { unicode: "\u{1F1F5}\u{1F1ED}", emoji: "🇵🇭", name: "flag: Philippines", key: ":flag_Philippines:", group: "Flags", },
  { unicode: "\u{1F1F5}\u{1F1F0}", emoji: "🇵🇰", name: "flag: Pakistan", key: ":flag_Pakistan:", group: "Flags", },
  { unicode: "\u{1F1F5}\u{1F1F1}", emoji: "🇵🇱", name: "flag: Poland", key: ":flag_Poland:", group: "Flags", },
  { unicode: "\u{1F1F5}\u{1F1F2}", emoji: "🇵🇲", name: "flag: St. Pierre & Miquelon", key: ":flag_St._Pierre_&_Miquelon:", group: "Flags", },
  { unicode: "\u{1F1F5}\u{1F1F3}", emoji: "🇵🇳", name: "flag: Pitcairn Islands", key: ":flag_Pitcairn_Islands:", group: "Flags", },
  { unicode: "\u{1F1F5}\u{1F1F7}", emoji: "🇵🇷", name: "flag: Puerto Rico", key: ":flag_Puerto_Rico:", group: "Flags", },
  { unicode: "\u{1F1F5}\u{1F1F8}", emoji: "🇵🇸", name: "flag: Palestinian Territories", key: ":flag_Palestinian_Territories:", group: "Flags", },
  { unicode: "\u{1F1F5}\u{1F1F9}", emoji: "🇵🇹", name: "flag: Portugal", key: ":flag_Portugal:", group: "Flags", },
  { unicode: "\u{1F1F5}\u{1F1FC}", emoji: "🇵🇼", name: "flag: Palau", key: ":flag_Palau:", group: "Flags", },
  { unicode: "\u{1F1F5}\u{1F1FE}", emoji: "🇵🇾", name: "flag: Paraguay", key: ":flag_Paraguay:", group: "Flags", },
  { unicode: "\u{1F1F6}\u{1F1E6}", emoji: "🇶🇦", name: "flag: Qatar", key: ":flag_Qatar:", group: "Flags", },
  { unicode: "\u{1F1F7}\u{1F1EA}", emoji: "🇷🇪", name: "flag: Réunion", key: ":flag_Réunion:", group: "Flags", },
  { unicode: "\u{1F1F7}\u{1F1F4}", emoji: "🇷🇴", name: "flag: Romania", key: ":flag_Romania:", group: "Flags", },
  { unicode: "\u{1F1F7}\u{1F1F8}", emoji: "🇷🇸", name: "flag: Serbia", key: ":flag_Serbia:", group: "Flags", },
  { unicode: "\u{1F1F7}\u{1F1FA}", emoji: "🇷🇺", name: "flag: Russia", key: ":flag_Russia:", group: "Flags", },
  { unicode: "\u{1F1F7}\u{1F1FC}", emoji: "🇷🇼", name: "flag: Rwanda", key: ":flag_Rwanda:", group: "Flags", },
  { unicode: "\u{1F1F8}\u{1F1E6}", emoji: "🇸🇦", name: "flag: Saudi Arabia", key: ":flag_Saudi_Arabia:", group: "Flags", },
  { unicode: "\u{1F1F8}\u{1F1E7}", emoji: "🇸🇧", name: "flag: Solomon Islands", key: ":flag_Solomon_Islands:", group: "Flags", },
  { unicode: "\u{1F1F8}\u{1F1E8}", emoji: "🇸🇨", name: "flag: Seychelles", key: ":flag_Seychelles:", group: "Flags", },
  { unicode: "\u{1F1F8}\u{1F1E9}", emoji: "🇸🇩", name: "flag: Sudan", key: ":flag_Sudan:", group: "Flags", },
  { unicode: "\u{1F1F8}\u{1F1EA}", emoji: "🇸🇪", name: "flag: Sweden", key: ":flag_Sweden:", group: "Flags", },
  { unicode: "\u{1F1F8}\u{1F1EC}", emoji: "🇸🇬", name: "flag: Singapore", key: ":flag_Singapore:", group: "Flags", },
  { unicode: "\u{1F1F8}\u{1F1ED}", emoji: "🇸🇭", name: "flag: St. Helena", key: ":flag_St._Helena:", group: "Flags", },
  { unicode: "\u{1F1F8}\u{1F1EE}", emoji: "🇸🇮", name: "flag: Slovenia", key: ":flag_Slovenia:", group: "Flags", },
  { unicode: "\u{1F1F8}\u{1F1EF}", emoji: "🇸🇯", name: "flag: Svalbard & Jan Mayen", key: ":flag_Svalbard_&_Jan_Mayen:", group: "Flags", },
  { unicode: "\u{1F1F8}\u{1F1F0}", emoji: "🇸🇰", name: "flag: Slovakia", key: ":flag_Slovakia:", group: "Flags", },
  { unicode: "\u{1F1F8}\u{1F1F1}", emoji: "🇸🇱", name: "flag: Sierra Leone", key: ":flag_Sierra_Leone:", group: "Flags", },
  { unicode: "\u{1F1F8}\u{1F1F2}", emoji: "🇸🇲", name: "flag: San Marino", key: ":flag_San_Marino:", group: "Flags", },
  { unicode: "\u{1F1F8}\u{1F1F3}", emoji: "🇸🇳", name: "flag: Senegal", key: ":flag_Senegal:", group: "Flags", },
  { unicode: "\u{1F1F8}\u{1F1F4}", emoji: "🇸🇴", name: "flag: Somalia", key: ":flag_Somalia:", group: "Flags", },
  { unicode: "\u{1F1F8}\u{1F1F7}", emoji: "🇸🇷", name: "flag: Suriname", key: ":flag_Suriname:", group: "Flags", },
  { unicode: "\u{1F1F8}\u{1F1F8}", emoji: "🇸🇸", name: "flag: South Sudan", key: ":flag_South_Sudan:", group: "Flags", },
  { unicode: "\u{1F1F8}\u{1F1F9}", emoji: "🇸🇹", name: "flag: São Tomé & Príncipe", key: ":flag_São_Tomé_&_Príncipe:", group: "Flags", },
  { unicode: "\u{1F1F8}\u{1F1FB}", emoji: "🇸🇻", name: "flag: El Salvador", key: ":flag_El_Salvador:", group: "Flags", },
  { unicode: "\u{1F1F8}\u{1F1FD}", emoji: "🇸🇽", name: "flag: Sint Maarten", key: ":flag_Sint_Maarten:", group: "Flags", },
  { unicode: "\u{1F1F8}\u{1F1FE}", emoji: "🇸🇾", name: "flag: Syria", key: ":flag_Syria:", group: "Flags", },
  { unicode: "\u{1F1F8}\u{1F1FF}", emoji: "🇸🇿", name: "flag: Eswatini", key: ":flag_Eswatini:", group: "Flags", },
  { unicode: "\u{1F1F9}\u{1F1E6}", emoji: "🇹🇦", name: "flag: Tristan da Cunha", key: ":flag_Tristan_da_Cunha:", group: "Flags", },
  { unicode: "\u{1F1F9}\u{1F1E8}", emoji: "🇹🇨", name: "flag: Turks & Caicos Islands", key: ":flag_Turks_&_Caicos_Islands:", group: "Flags", },
  { unicode: "\u{1F1F9}\u{1F1E9}", emoji: "🇹🇩", name: "flag: Chad", key: ":flag_Chad:", group: "Flags", },
  { unicode: "\u{1F1F9}\u{1F1EB}", emoji: "🇹🇫", name: "flag: French Southern Territories", key: ":flag_French_Southern_Territories:", group: "Flags", },
  { unicode: "\u{1F1F9}\u{1F1EC}", emoji: "🇹🇬", name: "flag: Togo", key: ":flag_Togo:", group: "Flags", },
  { unicode: "\u{1F1F9}\u{1F1ED}", emoji: "🇹🇭", name: "flag: Thailand", key: ":flag_Thailand:", group: "Flags", },
  { unicode: "\u{1F1F9}\u{1F1EF}", emoji: "🇹🇯", name: "flag: Tajikistan", key: ":flag_Tajikistan:", group: "Flags", },
  { unicode: "\u{1F1F9}\u{1F1F0}", emoji: "🇹🇰", name: "flag: Tokelau", key: ":flag_Tokelau:", group: "Flags", },
  { unicode: "\u{1F1F9}\u{1F1F1}", emoji: "🇹🇱", name: "flag: Timor-Leste", key: ":flag_Timor_Leste:", group: "Flags", },
  { unicode: "\u{1F1F9}\u{1F1F2}", emoji: "🇹🇲", name: "flag: Turkmenistan", key: ":flag_Turkmenistan:", group: "Flags", },
  { unicode: "\u{1F1F9}\u{1F1F3}", emoji: "🇹🇳", name: "flag: Tunisia", key: ":flag_Tunisia:", group: "Flags", },
  { unicode: "\u{1F1F9}\u{1F1F4}", emoji: "🇹🇴", name: "flag: Tonga", key: ":flag_Tonga:", group: "Flags", },
  { unicode: "\u{1F1F9}\u{1F1F7}", emoji: "🇹🇷", name: "flag: Turkey", key: ":flag_Turkey:", group: "Flags", },
  { unicode: "\u{1F1F9}\u{1F1F9}", emoji: "🇹🇹", name: "flag: Trinidad & Tobago", key: ":flag_Trinidad_&_Tobago:", group: "Flags", },
  { unicode: "\u{1F1F9}\u{1F1FB}", emoji: "🇹🇻", name: "flag: Tuvalu", key: ":flag_Tuvalu:", group: "Flags", },
  { unicode: "\u{1F1F9}\u{1F1FC}", emoji: "🇹🇼", name: "flag: Taiwan", key: ":flag_Taiwan:", group: "Flags", },
  { unicode: "\u{1F1F9}\u{1F1FF}", emoji: "🇹🇿", name: "flag: Tanzania", key: ":flag_Tanzania:", group: "Flags", },
  { unicode: "\u{1F1FA}\u{1F1E6}", emoji: "🇺🇦", name: "flag: Ukraine", key: ":flag_Ukraine:", group: "Flags", },
  { unicode: "\u{1F1FA}\u{1F1EC}", emoji: "🇺🇬", name: "flag: Uganda", key: ":flag_Uganda:", group: "Flags", },
  { unicode: "\u{1F1FA}\u{1F1F2}", emoji: "🇺🇲", name: "flag: U.S. Outlying Islands", key: ":flag_U.S._Outlying_Islands:", group: "Flags", },
  { unicode: "\u{1F1FA}\u{1F1F3}", emoji: "🇺🇳", name: "flag: United Nations", key: ":flag_United_Nations:", group: "Flags", },
  { unicode: "\u{1F1FA}\u{1F1F8}", emoji: "🇺🇸", name: "flag: United States", key: ":flag_United_States:", group: "Flags", },
  { unicode: "\u{1F1FA}\u{1F1FE}", emoji: "🇺🇾", name: "flag: Uruguay", key: ":flag_Uruguay:", group: "Flags", },
  { unicode: "\u{1F1FA}\u{1F1FF}", emoji: "🇺🇿", name: "flag: Uzbekistan", key: ":flag_Uzbekistan:", group: "Flags", },
  { unicode: "\u{1F1FB}\u{1F1E6}", emoji: "🇻🇦", name: "flag: Vatican City", key: ":flag_Vatican_City:", group: "Flags", },
  { unicode: "\u{1F1FB}\u{1F1E8}", emoji: "🇻🇨", name: "flag: St. Vincent & Grenadines", key: ":flag_St._Vincent_&_Grenadines:", group: "Flags", },
  { unicode: "\u{1F1FB}\u{1F1EA}", emoji: "🇻🇪", name: "flag: Venezuela", key: ":flag_Venezuela:", group: "Flags", },
  { unicode: "\u{1F1FB}\u{1F1EC}", emoji: "🇻🇬", name: "flag: British Virgin Islands", key: ":flag_British_Virgin_Islands:", group: "Flags", },
  { unicode: "\u{1F1FB}\u{1F1EE}", emoji: "🇻🇮", name: "flag: U.S. Virgin Islands", key: ":flag_U.S._Virgin_Islands:", group: "Flags", },
  { unicode: "\u{1F1FB}\u{1F1F3}", emoji: "🇻🇳", name: "flag: Vietnam", key: ":flag_Vietnam:", group: "Flags", },
  { unicode: "\u{1F1FB}\u{1F1FA}", emoji: "🇻🇺", name: "flag: Vanuatu", key: ":flag_Vanuatu:", group: "Flags", },
  { unicode: "\u{1F1FC}\u{1F1EB}", emoji: "🇼🇫", name: "flag: Wallis & Futuna", key: ":flag_Wallis_&_Futuna:", group: "Flags", },
  { unicode: "\u{1F1FC}\u{1F1F8}", emoji: "🇼🇸", name: "flag: Samoa", key: ":flag_Samoa:", group: "Flags", },
  { unicode: "\u{1F1FD}\u{1F1F0}", emoji: "🇽🇰", name: "flag: Kosovo", key: ":flag_Kosovo:", group: "Flags", },
  { unicode: "\u{1F1FE}\u{1F1EA}", emoji: "🇾🇪", name: "flag: Yemen", key: ":flag_Yemen:", group: "Flags", },
  { unicode: "\u{1F1FE}\u{1F1F9}", emoji: "🇾🇹", name: "flag: Mayotte", key: ":flag_Mayotte:", group: "Flags", },
  { unicode: "\u{1F1FF}\u{1F1E6}", emoji: "🇿🇦", name: "flag: South Africa", key: ":flag_South_Africa:", group: "Flags", },
  { unicode: "\u{1F1FF}\u{1F1F2}", emoji: "🇿🇲", name: "flag: Zambia", key: ":flag_Zambia:", group: "Flags", },
  { unicode: "\u{1F1FF}\u{1F1FC}", emoji: "🇿🇼", name: "flag: Zimbabwe", key: ":flag_Zimbabwe:", group: "Flags", },
  { unicode: "\u{1F3F4}\u{E0067}\u{E0062}\u{E0065}\u{E006E}\u{E0067}\u{E007F}", emoji: "🏴󠁧󠁢󠁥󠁮󠁧󠁿", name: "flag: England", key: ":flag_England:", group: "Flags", },
  { unicode: "\u{1F3F4}\u{E0067}\u{E0062}\u{E0073}\u{E0063}\u{E0074}\u{E007F}", emoji: "🏴󠁧󠁢󠁳󠁣󠁴󠁿", name: "flag: Scotland", key: ":flag_Scotland:", group: "Flags", },
  { unicode: "\u{1F3F4}\u{E0067}\u{E0062}\u{E0077}\u{E006C}\u{E0073}\u{E007F}", emoji: "🏴󠁧󠁢󠁷󠁬󠁳󠁿", name: "flag: Wales", key: ":flag_Wales:", group: "Flags", },
];

module.exports = emojis;